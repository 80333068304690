import React, {Component} from 'react'
import styled from "styled-components"
import ArrowRightHovered from "../../images/navigationArrows/arrow-right-hovered.svg"
import ArrowLeftHovered from "../../images/navigationArrows/arrow-left-hover.svg"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";
import setBackgroundColor from "../../utils/setBackgroundColor";
import setFontColor from "../../utils/setFontColor";
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	background-color: ${({background, theme}) => setBackgroundColor(background, theme)};
	padding-top: ${({paddingTop}) => paddingTop}px;
	padding-bottom: ${({paddingBottom}) => paddingBottom}px;
	@media(min-width: 1280px) {
		flex-direction: row;
		justify-content: space-between;
		padding-right: 50px;
		padding-top: 80px;
	}
	@media(min-width: 1920px) {
		padding-right: 100px;
	}
`;

const BackgroundWrapper = styled.div`
	width: 100%;
	padding: 0;
    position: relative;
    background-color: ${({background, theme}) => setBackgroundColor(background, theme)};
    overflow: hidden;
    //height: 382px;
    @media(min-width: 769px) {
    	//height: 578px;
    }
    @media(min-width: 1280px) {
    	height: 720px;
    }
    @media(min-width: 1366px) {
    	height: 710px;
    }
    @media(min-width: 1920px) {
    	height: 977px;
    }
`;

const PictureWrapper = styled.div`
	//width: unset;
	//height: 100%;
	width: 100%;
	padding-top: 75%;
	//padding-top: 56.25%;
    background-position: center;
    background-repeat: no-repeat;
    //background-size: cover;
    background-size: contain;
    background-color: ${({background, theme}) => setBackgroundColor(background, theme)};
    background-image: url( ${({ bgImg }) => bgImg});
	z-index: 0;
	@media(min-width: 768px) {
		background-size: contain;
		//padding-top: 56.25%;
		padding-top: 75%;
	}
	@media(min-width: 1280px) {
		padding-top: 0;
		height: 100%;
	}
`;

const NavigationWrapper = styled.div`
	display: flex;
	flex-direction: row;
	//justify-content: space-between;
	margin: 0 auto;
	width: 100%;
	background-color: ${({background, theme}) => setBackgroundColor(background, theme)};
	padding-top: 25px;
	&.center-on-mobile {
		justify-content: center;
		@media(min-width: 768px) {
			justify-content: space-between;
		}
	}
	&.mobile {
		justify-content: center;
		margin-bottom: 15px;
		padding-top: 0;
		@media(min-width: 768px) {
			display: none;
		}
	}
	@media(min-width: 1280px) {
		flex-direction: column;
		justify-content: space-between;
		width: 5%;
	}
	
`;

const VideoDescription = styled.h5`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	letter-spacing: .8px;
	position: absolute;
	padding: 10px;
	right: 40px;
	bottom: 25px;
	width: auto;
	color: ${({background, theme}) => setFontColor(background, theme)};
	font-weight: ${({theme}) => theme.fontWeight.regular};
	span {  
	  &.black {
	      font-weight: ${({ theme }) => theme.fontWeight.black};
	  }
	}
`;

const SlideCounter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  margin: auto 0;
  &.invisible {
  	display: none;
  	margin: auto 30px auto 0;
  	@media(min-width: 768px) {
  		opacity: 0;
  		display: flex;
  		margin: 0;
  	}
  }
  @media(min-width: 768px) {
  	margin: auto 30px auto 0;
  }
  @media(min-width: 1280px) {
  	width: 100%;
  	flex-direction: column;
  	justify-content: space-between;
  	margin: 0;
  }
`;

const CurrentSlideNumber = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: ${({background, theme}) => setFontColor(background, theme)};
  font-family: ${({theme}) => theme.fontFamily.mainFont};
  font-weight: ${({theme}) => theme.fontWeight.bold};
  margin: 0 auto;
  padding-bottom: 40px;
  @media(min-width: 1920px) {
	font-size: 20px;
	line-height: 25px;
	padding-bottom: 40px; 	
  }
`;

const NumberOfSlides = styled.p`
  font-size: 16px;
  line-height: 19px;
  position: relative;
  margin-left: 50px;
  color: ${({background, theme}) => setFontColor(background, theme)};
  font-family: ${({theme}) => theme.fontFamily.mainFont};
  font-weight: ${({theme}) => theme.fontWeight.bold};
  @media(min-width: 1280px) {
  	margin: 0 auto;
  }
  @media(min-width: 1920px) {
	font-size: 20px;
	line-height: 25px; 
  }
  &:before{
    content: '';
    position: absolute;
    left: -39px;
    top: 8px;
	width: 30px;
	height: 1px;
    background-color: ${({background, theme}) => setFontColor(background, theme)};
    @media(min-width: 1280px) {
		height: 30px;
		top: -35px;
		width: 1px;
	    left: 10px;
	}	
    } 
  }
`;

const NavigationArrowsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-right: 0;
  //height: 75px;
  &.hidden-on-mobile {
  	display: none;
  	@media(min-width: 768px) {
  		display: flex;
  	}
  }
  @media(min-width: 1280px) {
  	width: 100%;
    flex-direction: column;
  	justify-content: space-between;
  }
`;

const SingleNavigationButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 43px;
  height: 43px;
  position: relative;
  transition: transform 0.15s ease-in-out;
  margin-left: 10px;
  @media(min-width: 1280px) {
	margin-top: 10px;
  }
  @media(min-width: 1920px) {
	width: 53px;
	height: 53px;
  }
  @media(min-width: 1024px) {
	  &.container-top{
	     &:hover  {
	        > div.circle {
	            transform: scale(1.113);
		        border: 2px solid ${({theme}) => theme.colors.pmmOrange};
		        box-shadow: 0 0 1px 0 ${({theme}) => theme.colors.pmmOrange} inset, 0 0 1px 0px ${({theme}) => theme.colors.pmmOrange};
	        }
	        > div.no-zoom {
	            transform: scale(1);
	            border: 2px solid ${({theme}) => theme.colors.pmmOrange};
				box-shadow: 0 0 1px 0 ${({theme}) => theme.colors.pmmOrange} inset, 0 0 1px 0px ${({theme}) => theme.colors.pmmOrange};
	        }
	     }
	  }
	  &.container-bottom{
	     &:hover  {
	        > div.circle {
	            transform: scale(1.113);
	            border: 2px solid ${({theme}) => theme.colors.pmmOrange};
				box-shadow: 0 0 1px 0 ${({theme}) => theme.colors.pmmOrange} inset, 0 0 1px 0px ${({theme}) => theme.colors.pmmOrange};
	        }
	        > div.no-zoom {
	            transform: scale(1);
	            border: 2px solid ${({theme}) => theme.colors.pmmOrange};
				box-shadow: 0 0 1px 0 ${({theme}) => theme.colors.pmmOrange} inset, 0 0 1px 0px ${({theme}) => theme.colors.pmmOrange};
	        }
	     }
	  }
  }
`;

const NavigationArrow = styled.div`
  width: 17px;
  height: 7px;
  position: absolute;
  top: 18px;
  right: 12px;
  cursor: pointer;
  z-index: 1;
  transition: transform 0s;
  background-size: contain;
  background-position: center;
  background-image: url( ${({bgImg}) => bgImg} );
  @media(min-width: 1920px) {
	top: 23px;
	right: 15px;
	width: 21px;
	height: 8px;
  }
`;

const Circle = styled.div`
  width: 43px;
  height: 43px;
  cursor: pointer;
  z-index: 1;
  border-radius: 50%;
  border: 2px solid ${({theme}) => theme.colors.pmmOrange};
  // box-shadow: 0 0 2px 0 ${({theme}) => theme.colors.pmmOrange} inset, 0 0 1px 0px ${({theme}) => theme.colors.pmmOrange};
  background: none;
  transition: transform 0.15s ease-in-out;
  @media(min-width: 1920px) {
	width: 53px;
	height: 53px;
  }
`;
const Content = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	width: 100%;
`;

class Gallery extends Component {
	state = {
		isOldBrowserEdge: false,
		currentImageIndex: 0,
	};

	componentDidMount() {
		if (typeof window !== "undefined") {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({isOldBrowserEdge: isOldBrowser});
		}
	}

	render() {
		const gallery = this.props.data;
		const { currentImageIndex } = this.state;
		const galleryData = (gallery.modulGaleria) || [];
		const singleImageContentArray = [];
		let moduleBackground = '';
		let paddingBottom = '';
		let paddingTop = '';


			galleryData.forEach(item => {
				if(item.__typename === "Craft_modulGaleria_zdjecie_BlockType") {
					singleImageContentArray.push({
						"image": (item.zdjecie && item.zdjecie[0] && item.zdjecie[0].url) || '',
						"description": (item.opisZdjecia),
					})
				} else if (item.__typename === "Craft_modulGaleria_tloModulu_BlockType") {
					moduleBackground = (item && item.wybierzTloModulu && item.wybierzTloModulu[0] && item.wybierzTloModulu[0].title) || ''
				}
				else if (item.__typename === "Craft_modulGaleria_marginesy_BlockType") {
					paddingTop = (item.marginesGorny && item.marginesGorny[0] && item.marginesGorny[0].title) || ''
					paddingBottom = (item.marginesDolny && item.marginesDolny[0] && item.marginesDolny[0].title) || ''

				}
			})
		const circleClassName = (this.state.isOldBrowserEdge) ? "no-zoom" : "circle";
		const numberOfImages = (singleImageContentArray.length < 10) ? '0' + singleImageContentArray.length : singleImageContentArray.length;

		var bgImageMultiple = singleImageContentArray[currentImageIndex]?.image || '';

		function changePhotoRight() {
			if (currentImageIndex < singleImageContentArray.length - 1) {
				bgImageMultiple = singleImageContentArray[currentImageIndex + 1]?.image || '';
				this.setState({currentImageIndex : currentImageIndex + 1})
			} else {
				this.setState({currentImageIndex : 0})
			}
		}

		function changePhotoLeft() {
			if (currentImageIndex > 0) {
				bgImageMultiple = singleImageContentArray[currentImageIndex - 1]?.image || '';
				this.setState({currentImageIndex : currentImageIndex - 1})
			} else {
				this.setState({currentImageIndex : singleImageContentArray.length - 1})
			}
		}
		const currentSlideNumber = (currentImageIndex < 9) ? "0" + (currentImageIndex + 1) : (currentImageIndex + 1);
		return (
			<ModuleWrapper background={moduleBackground} paddingTop={getPaddingTop(paddingTop)} paddingBottom={getPaddingBottom(paddingBottom)}>
				<NavigationWrapper className="mobile" background={moduleBackground}>
					<NavigationArrowsWrapper>
						<Content>
							<SingleNavigationButtonContainer isEdge={this.state.isOldBrowserEdge}
							                                 onClick={changePhotoRight.bind(this)}
							                                 className="container-top">
								<NavigationArrow className="arrow-right"
								                 bgImg={ArrowRightHovered}/>
								<Circle className={circleClassName}/>
							</SingleNavigationButtonContainer>
						</Content>
						<Content>
							<SingleNavigationButtonContainer isEdge={this.state.isOldBrowserEdge}
							                                 onClick={changePhotoLeft.bind(this)}
							                                 className="container-bottom" >
								<NavigationArrow className="arrow-left" bgImg={ArrowLeftHovered}/>
								<Circle className={circleClassName}/>
							</SingleNavigationButtonContainer>
						</Content>
					</NavigationArrowsWrapper>
				</NavigationWrapper>
				<BackgroundWrapper background={moduleBackground}>
					<PictureWrapper background={moduleBackground} bgImg={bgImageMultiple}>
						<VideoDescription dangerouslySetInnerHTML={getHtmlFromRedactorField(singleImageContentArray[currentImageIndex].description)}/>
					</PictureWrapper>
				</BackgroundWrapper>
				<NavigationWrapper className="center-on-mobile" background={moduleBackground}>
					<SlideCounter background={moduleBackground} className="invisible">
						<CurrentSlideNumber background={moduleBackground}>{currentSlideNumber}</CurrentSlideNumber>
						<NumberOfSlides background={moduleBackground}>{numberOfImages}</NumberOfSlides>
					</SlideCounter>
					<NavigationArrowsWrapper className="hidden-on-mobile">
						<Content>
							<SingleNavigationButtonContainer isEdge={this.state.isOldBrowserEdge}
							                                 onClick={changePhotoRight.bind(this)}
							                                 className="container-top">
								<NavigationArrow className="arrow-right"
								                 bgImg={ArrowRightHovered}/>
								<Circle className={circleClassName}/>
							</SingleNavigationButtonContainer>
						</Content>
						<Content>
							<SingleNavigationButtonContainer isEdge={this.state.isOldBrowserEdge}
							                                 onClick={changePhotoLeft.bind(this)}
							                                 className="container-bottom" >
								<NavigationArrow className="arrow-left" bgImg={ArrowLeftHovered}/>
								<Circle className={circleClassName}/>
							</SingleNavigationButtonContainer>
						</Content>
					</NavigationArrowsWrapper>
					<SlideCounter background={moduleBackground}>
						<CurrentSlideNumber background={moduleBackground}>{currentSlideNumber}</CurrentSlideNumber>
						<NumberOfSlides background={moduleBackground}>{numberOfImages}</NumberOfSlides>
					</SlideCounter>
				</NavigationWrapper>
			</ModuleWrapper>
		)
	}
}

export default Gallery;
