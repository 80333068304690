import React, {Component} from 'react'
import styled from "styled-components"
import SingleProject from "./SingleProject";
import ArrowDown from "../../images/projects/arrow-down.svg"
import Button from "../common/ButtonsSet";
import {Option, OptionList, SelectWindow} from "../common/CustomSelector";
import SingleProjectPast from "./SingleProjectPast";

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	min-height: 400px;
	width: 100%;
  	background-color: ${({theme}) => theme.colors.pmmDarkViolet};
    padding-top: ${({paddingTop}) => paddingTop}px;
	padding-bottom: ${({paddingBottom}) => paddingBottom}px;
`;

const ModuleWrapperPast = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	min-height: 280px;
	width: 100%;
  	background-image: linear-gradient(to bottom, transparent 0px, white 0px);
	@media(min-width: 768px) {
		background-image:  ${({ showNoResultsPastInfo }) => showNoResultsPastInfo ? "linear-gradient(to bottom, transparent 80px, white 80px)" :
		"linear-gradient(to bottom, transparent 360px, white 360px)"};
	}
	@media(min-width: 1024px) {
		background-image:  ${({ showNoResultsPastInfo }) => showNoResultsPastInfo ? "linear-gradient(to bottom, transparent 60px, white 60px)" :
		"linear-gradient(to bottom, transparent 360px, white 360px)"};
	}
	@media(min-width: 1280px) {
		background-image: ${({ showNoResultsPastInfo }) => showNoResultsPastInfo ? "linear-gradient(to bottom, transparent 60px, white 60px)" :
		"linear-gradient(to bottom, transparent 330px, white 330px)"};
	}
	@media(min-width: 1920px) {
		background-image: ${({ showNoResultsPastInfo }) => showNoResultsPastInfo ? "linear-gradient(to bottom, transparent 60px, white 60px)" :
		"linear-gradient(to bottom, transparent 480px, white 480px)"};
	}
`;

const HeaderFiltrationWrapper = styled.div`
	display: flex;
	flex-direction: column-reverse;
	padding: 30px 15px 0;
	@media(min-width: 768px) {
		flex-direction: row;
		justify-content: space-between;
		padding: 80px 30px 0;
		&.past {
			padding: 0px 30px;
		}
	}
	@media(min-width: 1280px) {
		padding: 50px 50px 0;
		&.past {
			padding: 0px 50px 0;
		}
	}
	@media(min-width: 1920px) {
		padding: 100px 100px 0;
		&.past {
			padding: 0 100px;
		}
	}
`;

const HeaderWrapper = styled.h3`
	width: 100%;
	position: relative;
	color: ${({theme}) => theme.colors.pmmWhite};
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media(min-width: 425px) {
		margin-top: 50px;
	}
	&.past {
		color: ${({theme}) => theme.colors.pmmDarkViolet};
		margin-top: 0;
	}
	@media(min-width: 768px) {
		width: 28%;
		margin-top: 0;
		&.past {
			color: ${({theme}) => theme.colors.pmmWhite};
		}
	}
	@media(min-width: 1024px) {
		width: 45%;
	}
`;

const FiltrationWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	padding: 0;
	margin: auto 0;
	@media(min-width: 425px) {
		flex-direction: row;
	}
	@media(min-width: 768px) {
		justify-content: flex-end;
		width: 70%;
		padding: 0;
	}
	@media(min-width: 1024px) {
		width: 55%;
	}
`;

const OptionsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	border: ${({theme}) => theme.colors.pmmOrange} 2px solid;
	border-radius: 50px;
	padding: 9px 10px;
	margin-right: 15px;
	background-color: transparent;
	cursor: pointer;
	position: relative;
	div {
		left: -14px;
		@media(min-width: 1920px) {
			left: 0;
		}
	}
	&:last-child {
		margin-right: 10px;
		min-width: 100px;
		@media(min-width: 425px) {
			margin-right: 25px;
			min-width: 108px;
		}
		@media(min-width: 1920px) {
			min-width: 160px;
		}
	}
	@media(min-width: 768px) {
		padding: 9px 15px;
	}
	@media(min-width: 1920px) {
		padding: 13px 25px;
		min-width: 160px;
	}	
`;
const SelectorsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	@media(min-width: 375px) {
		flex-direction: row;
		justify-content: flex-start;
	}
`

const ButtonWrapper = styled.div`
	margin: 20px -10px 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	@media(min-width: 425px) {
		flex-direction: column;
		justify-content: center;
		margin: 0 -10px;
	}
	button {
		padding: 10px 15px;
		font-size: 12px;
		line-height: 16px;
		@media(min-width: 1280px) {
			padding: 10px 20px;
		}
		@media(min-width: 1920px) {	
			padding: 15px 30px;
			font-size: 16px;
		}
	}
`

const CurrentOption = styled.p`
	font-size: 12px;
	line-height: 14px;
	position: relative;
	padding: 0 20px 0 10px;
	margin: auto 0;
	color: ${({theme}) => theme.colors.pmmWhite};
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	font-weight: ${({theme}) => theme.fontWeight.bold};
	text-transform: uppercase;
	@media(min-width: 1920px) {
		font-size: 16px;
		line-height: 19px;
		padding-right: 25px;
	}
`;

const ArrowImage = styled.img`
	width: 13px;
	height: 9px;
	margin: auto 0;
	@media(min-width: 1920px) {
		width: 16px;
		height: 11px;
	}	
`

const ProjectsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	@media(min-width: 768px) {
		padding: 10px 30px;
	}
	@media(min-width: 1280px) {
		padding: 30px 50px 80px;
		flex-direction: row;
		justify-content: space-between;
	}
	@media(min-width: 1920px) {
		padding: 80px 100px;
	}
`;

const NoResultsInfoWrapper = styled.div`
	margin-top: 15px;
	height: 160px;
	@media(min-width: 768px) {
		height: 440px;
		margin: 30px;
		&.past {
			margin-top: 30px;
		}
	}
	@media(min-width: 1280px) {
		margin: 50px;
		height: 260px;
		&.past {
			height: 400px;
		}
	}
	@media(min-width: 1920px) {
		height: 502px;
		margin: 0 100px;
		&.past {
			height: 502px;
			margin: 15px 100px;
		}
	}	
`;

const NoResultsBackground = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${({theme}) => theme.colors.pmmDarkViolet};
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding: 0 10px;
	&.past {
		background-color: ${({theme}) => theme.colors.pmmWhite};
	}
	@media(min-width: 768px) {
		flex-direction: column;
		justify-content: center;
		&.past {
			margin-top: 20px;
		}
	}
	@media(min-width: 1280px) {
		&.past {
			margin-top: 0;
		}
	}
 `;

const NoResultsIconWrapper = styled.img`
	width: 90px;
	@media(min-width: 768px) {
		width: 100px;
		margin: 0 auto;
	}
	@media(min-width: 1920px) {
		width: 130px;
	}
`;

const NoResultsTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`

const NoResultsHeaderWrapper = styled.h4`
	color: ${({theme}) => theme.colors.pmmWhite};
	text-align: left;
	margin-left: 25px;
	@media(min-width: 768px) {
		text-align: center;
		padding-top: 20px;
		margin-left: 0;
	}
	&.past {
		color: ${({theme}) => theme.colors.pmmDarkestBlue};
	}
`;

const NoResultsDescriptionWrapper = styled.p`
	color: ${({theme}) => theme.colors.pmmWhite};
	text-align: center;
	padding-top: 5px;
	display: none;
	@media(min-width: 768px) {
		display: block;
	}
	&.past {
		color: ${({theme}) => theme.colors.pmmDarkestBlue};
	}
	span {
		color: ${({theme}) => theme.colors.pmmOrange};
		font-weight: ${({theme}) => theme.fontWeight.regular};
		text-transform: uppercase;
	}
`;

class NowWeAreHelping extends Component {
	state = {
		isSelectCountryWindowOpen: false,
		isSelectYearWindowOpen: false,
		currentProjectIndex: 0,
		currentYearIndex: 0,
		isAnyCountrySelected: false,
		isAnyYearSelected: false,
		isOldBrowserEdge: false,
	};

	initialState = {
		isSelectCountryWindowOpen: false,
		isSelectYearWindowOpen: false,
		isAnyCountrySelected: false,
		isAnyYearSelected: false,
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickIfOutsideSelectWindow, false)
		if (typeof window !== "undefined") {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({isOldBrowserEdge: isOldBrowser});
		}
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickIfOutsideSelectWindow, false)
	}

	handleClickIfOutsideSelectWindow = (e) => {
		if (this.node.contains(e.target)) {
			return;
		}

		this.closeSelectCountryWindow();
		this.closeSelectYearWindow();
	}

	closeSelectCountryWindow() {
		if (this.state.isSelectCountryWindowOpen) {
			this.setState({isSelectCountryWindowOpen: false})
		}
	}
	toggleSelectCountryWindow() {
		this.setState({isSelectCountryWindowOpen: !this.state.isSelectCountryWindowOpen});
		this.setState({isSelectYearWindowOpen: false});
	}
	toggleFilterActive() {
		this.setState(this.initialState);
	}

	closeSelectYearWindow() {
		if (this.state.isSelectYearWindowOpen) {
			this.setState({isSelectYearWindowOpen: false})
		}
	}
	toggleSelectYearWindow() {
		this.setState({isSelectYearWindowOpen: !this.state.isSelectYearWindowOpen})
		this.setState({isSelectCountryWindowOpen: false});
	}

	changeProject(index) {
		this.setState({currentProjectIndex: index});
		this.setState({isAnyCountrySelected: true});
	}
	changeYear(index) {
		this.setState({currentYearIndex: index});
		this.setState({isAnyYearSelected: true});
	}

	render() {
		const NowWeAreHelping = this.props.data;
		const FiltrationData = this.props.projectsBackBtn;
		const FiltrationCategoryCountry = (FiltrationData && FiltrationData[0] && FiltrationData[0].projektyKategoriaFiltracjiKraj) || "";
		const FiltrationCategoryYear = (FiltrationData && FiltrationData[0] && FiltrationData[0].projektyKategoriaFiltracjiRok) || "";
		const FiltrationbuttonText = (FiltrationData && FiltrationData[0] && FiltrationData[0].projektyFiltracjaNapisNaPrzycisku) || "";
		const {countryCategories, yearCategories} = this.props;
		const {activeSite, newsBtns} = this.props;
		const {isOldBrowserEdge} = this.state;
		const {isSelectCountryWindowOpen, isSelectYearWindowOpen, currentProjectIndex, currentYearIndex } = this.state;
		const NowWeAreHelpingData = (NowWeAreHelping.modulObecniePomagamy && NowWeAreHelping.modulObecniePomagamy[0]) || '';
		const WeAlreadyHelpedData = (NowWeAreHelping.modulObecniePomagamy && NowWeAreHelping.modulObecniePomagamy[1]) || '';
		const Header = (NowWeAreHelpingData && NowWeAreHelpingData.naglowek) || '';
		const HeaderPast = (WeAlreadyHelpedData && WeAlreadyHelpedData.naglowek) || '';
		const NoResultsIcon = (NowWeAreHelpingData && NowWeAreHelpingData.brakWynikowIkona && NowWeAreHelpingData.brakWynikowIkona[0] &&
							   NowWeAreHelpingData.brakWynikowIkona[0].url) || '';
		const NoResultsIconPast = (WeAlreadyHelpedData && WeAlreadyHelpedData.brakWynikowIkona && WeAlreadyHelpedData.brakWynikowIkona[0] &&
								   WeAlreadyHelpedData.brakWynikowIkona[0].url) || '';
		const NoResultsHeader = (NowWeAreHelpingData && NowWeAreHelpingData.brakWynikowNaglowek) || '';
		const NoResultsHeaderPast = (WeAlreadyHelpedData && WeAlreadyHelpedData.brakWynikowNaglowek) || '';
		const NoResultsDescriptionGeneral = (NowWeAreHelpingData && NowWeAreHelpingData.brakWynikowOpisOgolny) || '';
		const NoResultsDescriptionGeneralPast = (WeAlreadyHelpedData && WeAlreadyHelpedData.brakWynikowOpisOgolny) || '';
		const NoResultsDescriptionBeforeYear = (NowWeAreHelpingData && NowWeAreHelpingData.brakWynikowOpisPrzedRokiem) || '';
		const NoResultsDescriptionBeforeYearPast = (WeAlreadyHelpedData && WeAlreadyHelpedData.brakWynikowOpisPrzedRokiem) || '';
		const NoResultsDescriptionAfterYear = (NowWeAreHelpingData && NowWeAreHelpingData.brakWynikowOpisPoRoku) || '';
		const NoResultsDescriptionAfterYearPast = (WeAlreadyHelpedData && WeAlreadyHelpedData.brakWynikowOpisPoRoku) || '';
		const SingleProjectsArray = [];
		const singleProjectData = (NowWeAreHelpingData && NowWeAreHelpingData.projekt) || [];
		const SingleProjectsArrayPast = [];
		const singleProjectDataPast = (WeAlreadyHelpedData && WeAlreadyHelpedData.projekt) || [];

		const filterBtnData = (newsBtns && newsBtns[0] && newsBtns[0].aktualnosciPrzyciskFiltracja && newsBtns[0].aktualnosciPrzyciskFiltracja[0]) || ""
		// const filterBtnText = (filterBtnData && filterBtnData.przyciskNapis) || ""
		const filterBtnType = (filterBtnData && filterBtnData.przyciskRodzaj && filterBtnData.przyciskRodzaj[0] && filterBtnData.przyciskRodzaj[0].title) || ""

		const countriesList = [];
		const yearsList = [];

		if(countryCategories.length > 0) {
			countryCategories.forEach(item => {
					const country = (item.title) || "";
					countriesList.push(country)
			})
		}
		if(yearCategories.length > 0) {
			yearCategories.forEach(item => {
				const year = (item.title) || "";
				yearsList.push(year)
			})
		}

		const currentProjectCountry = (this.state.isAnyCountrySelected) ? countriesList[currentProjectIndex] : FiltrationCategoryCountry;
		const currentProjectYear = (this.state.isAnyYearSelected) ? yearsList[currentYearIndex] : FiltrationCategoryYear;

		let filteredData;
		let filteredDataPast;

		if (this.state.isAnyCountrySelected && !this.state.isAnyYearSelected) {
			filteredData = singleProjectData.filter(item => {
				const countryCategoryArray = [];
				item.kategoriaFiltracjiKraj.forEach(element => {
					const CountryCat = (element.title) || "";
					countryCategoryArray.push(CountryCat)
				});
				return (countryCategoryArray.length) ? countryCategoryArray.includes(currentProjectCountry) : '';
			});
			filteredDataPast = singleProjectDataPast.filter(item => {
				const countryCategoryArray = [];
				item.kategoriaFiltracjiKraj.forEach(element => {
					const CountryCat = (element.title) || "";
					countryCategoryArray.push(CountryCat)
				});
				return (countryCategoryArray.length) ? countryCategoryArray.includes(currentProjectCountry) : '';
			})
		} else if (!this.state.isAnyCountrySelected && this.state.isAnyYearSelected) {
			filteredData = singleProjectData.filter(item => {
				const yearCategoryArray = [];
				item.kategoriaFiltracjiRok.forEach(element => {
					const YearCat = (element.title) || "";
					yearCategoryArray.push(YearCat)
				});
				return (yearCategoryArray.length) ? yearCategoryArray.includes(currentProjectYear) : '';
			});
			filteredDataPast = singleProjectDataPast.filter(item => {
				const yearCategoryArray = [];
				item.kategoriaFiltracjiRok.forEach(element => {
					const YearCat = (element.title) || "";
					yearCategoryArray.push(YearCat)
				});
				return (yearCategoryArray.length) ? yearCategoryArray.includes(currentProjectYear) : '';
			})
		}
		else if (this.state.isAnyCountrySelected && this.state.isAnyYearSelected){
			filteredData = singleProjectData.filter(item => {
				const yearCategoryArray = [];
				item.kategoriaFiltracjiRok.forEach(element => {
					const YearCat = (element.title) || "";
					yearCategoryArray.push(YearCat)
				});
				const countryCategoryArray = [];
				item.kategoriaFiltracjiKraj.forEach(element => {
					const CountryCat = (element.title) || "";
					countryCategoryArray.push(CountryCat)
				});

				if (countryCategoryArray.length && yearCategoryArray.length) {
					return yearCategoryArray.includes(currentProjectYear) && countryCategoryArray.includes(currentProjectCountry)
				} else {
					return null;
				}
			});
			filteredDataPast = singleProjectDataPast.filter(item => {
				const yearCategoryArray = [];
				item.kategoriaFiltracjiRok.forEach(element => {
					const YearCat = (element.title) || "";
					yearCategoryArray.push(YearCat)
				});
				const countryCategoryArray = [];
				item.kategoriaFiltracjiKraj.forEach(element => {
					const CountryCat = (element.title) || "";
					countryCategoryArray.push(CountryCat)
				});

				if (countryCategoryArray.length && yearCategoryArray.length) {
					return yearCategoryArray.includes(currentProjectYear) && countryCategoryArray.includes(currentProjectCountry)
				} else {
					return null;
				}
			})
		} else {
			filteredData = singleProjectData
			filteredDataPast = singleProjectDataPast
		}

		if(filteredData.length > 0) {
			filteredData.forEach(item => {
				SingleProjectsArray.push({
					"title": (item.gdziePomagamyTytul) || "",
					"description": (item.gdziePomagamyOpis) || "",
					"picture": (item.gdziePomagamyObrazek && item.gdziePomagamyObrazek[0] && item.gdziePomagamyObrazek[0].url) || "",
					"slug": (item.slug) || "",
				})
			})
		}

		if(filteredDataPast.length > 0) {
			filteredDataPast.forEach(item => {
				SingleProjectsArrayPast.push({
					"title": (item.gdziePomagamyTytul) || "",
					"description": (item.gdziePomagamyOpis) || "",
					"picture": (item.gdziePomagamyObrazek && item.gdziePomagamyObrazek[0] && item.gdziePomagamyObrazek[0].url) || "",
					"slug": (item.slug) || "",
				})
			})
		}

		let showNoResultsInfo;
		let showNoResultsPastInfo;
		if (filteredData.length <= 0 ) {
			showNoResultsInfo = true;
		}
		if (filteredDataPast.length <= 0 ) {
			showNoResultsPastInfo = true;
		}

		return (
			<div>
			<ModuleWrapper>
				<HeaderFiltrationWrapper>
					<HeaderWrapper>{Header}</HeaderWrapper>
					<FiltrationWrapper>
						<div ref={node => this.node = node}>
						<SelectorsWrapper>
							<OptionsWrapper onClick={this.toggleSelectCountryWindow.bind(this)}>
								<CurrentOption
									onClick={this.toggleSelectCountryWindow.bind(this)}>{currentProjectCountry}</CurrentOption>
									{isSelectCountryWindowOpen &&
								<SelectWindow onClick={this.toggleSelectCountryWindow.bind(this)}>
									<OptionList>
										{countriesList.map((project, key) =>
											<Option key={key} onClick={() => this.changeProject.bind(this)(countriesList.indexOf(project))} >{project}</Option>
										)}
									</OptionList>
								</SelectWindow> }
								<ArrowImage src={ArrowDown}/>
							</OptionsWrapper>
							<OptionsWrapper onClick={this.toggleSelectYearWindow.bind(this)}>
								<CurrentOption onClick={this.toggleSelectYearWindow.bind(this)}>{currentProjectYear}
								</CurrentOption>
									{isSelectYearWindowOpen &&
									<SelectWindow onClick={this.toggleSelectYearWindow.bind(this)}>
										<OptionList>
											{yearsList.map((year, key) =>
												<Option key={key} onClick={() => this.changeYear.bind(this)(yearsList.indexOf(year))}>{year}</Option>
											)}
										</OptionList>
									</SelectWindow> }
								<ArrowImage src={ArrowDown}/>
							</OptionsWrapper>
						</SelectorsWrapper>
						</div>
						<ButtonWrapper>
							<Button isOldBrowserEdge={isOldBrowserEdge} onClick={this.toggleFilterActive.bind(this)} btnType={filterBtnType} textBtn={FiltrationbuttonText}/>
						</ButtonWrapper>
					</FiltrationWrapper>
				</HeaderFiltrationWrapper>
				{!showNoResultsInfo && <ProjectsWrapper>
					{SingleProjectsArray.map((news, key) =>
						<SingleProject key={key} index={key} data={news} activeSite={activeSite}/>
					)}
				</ProjectsWrapper> }
				{showNoResultsInfo && <NoResultsInfoWrapper>
					<NoResultsBackground>
						<NoResultsIconWrapper src={NoResultsIcon}/>
						<NoResultsTextWrapper>
							<NoResultsHeaderWrapper className="semi-bold">{NoResultsHeader}</NoResultsHeaderWrapper>
							<NoResultsDescriptionWrapper className="regular custom-lh">{NoResultsDescriptionGeneral} {this.state.isAnyYearSelected && <>{NoResultsDescriptionBeforeYear} <span>{yearsList[currentYearIndex]}</span></>} {this.state.isAnyCountrySelected && <>{NoResultsDescriptionAfterYear} <span>{countriesList[currentProjectIndex]}</span></>}</NoResultsDescriptionWrapper>
						</NoResultsTextWrapper>
					</NoResultsBackground>
				</NoResultsInfoWrapper> }
			</ModuleWrapper>
				<ModuleWrapperPast showNoResultsPastInfo={showNoResultsPastInfo}>
					<HeaderFiltrationWrapper className="past">
						<HeaderWrapper className="past">{HeaderPast}</HeaderWrapper>
					</HeaderFiltrationWrapper>
					{!showNoResultsPastInfo && <ProjectsWrapper>
						{SingleProjectsArrayPast.map((news, key) =>
							<SingleProjectPast key={key} index={ key } data={ news } activeSite={activeSite}/>
						)}
					</ProjectsWrapper> }
					{showNoResultsPastInfo && <NoResultsInfoWrapper className="past">
						<NoResultsBackground className="past">
							<NoResultsIconWrapper src={NoResultsIconPast}/>
							<NoResultsTextWrapper>
								<NoResultsHeaderWrapper className="semi-bold past">{NoResultsHeaderPast}</NoResultsHeaderWrapper>
								<NoResultsDescriptionWrapper className="regular custom-lh past">{NoResultsDescriptionGeneralPast} {this.state.isAnyYearSelected && <>{NoResultsDescriptionBeforeYearPast} <span>{yearsList[currentYearIndex]}</span></>} {this.state.isAnyCountrySelected && <>{NoResultsDescriptionAfterYearPast} <span>{countriesList[currentProjectIndex]}</span></>}</NoResultsDescriptionWrapper>
							</NoResultsTextWrapper>
						</NoResultsBackground>
					</NoResultsInfoWrapper> }
				</ModuleWrapperPast>
			</div>
		)
	}
}

export default NowWeAreHelping;
