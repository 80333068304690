import React, {Component} from 'react'
import styled from "styled-components"
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";
import SingleManagementPerson from "./SingleManagementPerson";

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	background-color: ${({theme}) => theme.colors.pmmWhite};
	padding: ${({paddingTop}) => paddingTop}px 30px ${({paddingBottom}) => paddingBottom}px;
	@media(min-width: 1280px) {
		padding-right: 50px;
		padding-left: 50px;
	}
	@media(min-width: 1920px) {
		padding-right: 100px;
		padding-left: 100px;
	}
`;

const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	border-bottom: 2px solid ${({theme}) => theme.colors.pmmDarkestBlue};
	width: 100%;
	margin-bottom: 10px;
	
	@media(min-width: 1280px) {
		width: 65%;
	}
	@media(min-width: 1366px) {
		width: 74%;
	}
	@media(min-width: 1920px) {
		width: calc(50% - 25px);
	}
	@media(min-width: 2560px) {
		width: calc(50% - 35px);
	}
`;

const CategoryName = styled.h5`
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
	position: relative;
	background-color: white;
	padding: 8px 20px;
	margin-bottom: -2px;
	z-index: 10;
	border: 2px solid ${({theme}) => theme.colors.pmmDarkestBlue};
	
	&:after {
		content: '';
		position: absolute;
		bottom: -2px;
		right: -17px;
		height: 2px;
		width: 15px;
		background-color: white;
	}
		@media(min-width: 768px) {
		&:after {
			right: -32px;
			width: 30px;
		}
	}
`;

const PeopleInCategoryWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
	@media(min-width: 1024px) {
		justify-content: flex-start;
	}
`;

class ManagementModule extends Component {
	state = {
		isOldBrowserEdge: false,
		currentImageIndex: 0,
	};

	componentDidMount() {
		if (typeof window !== "undefined") {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({isOldBrowserEdge: isOldBrowser});
		}
	}

	render() {
		const managementModule = this.props.data;
		const managementModuleData = (managementModule.modulZarzad) || [];
		const peopleInManagementDataArray = [];
		let header;
		let paddingTop = '';
		let paddingBottom = '';

		managementModuleData.forEach(item => {
			if(item.__typename === "Craft_modulZarzad_marginesy_BlockType") {
				paddingTop = (item.marginesGorny && item.marginesGorny[0] && item.marginesGorny[0].title) || ''
				paddingBottom = (item.marginesDolny && item.marginesDolny[0] && item.marginesDolny[0].title) || ''
			} else if (item.__typename === "Craft_modulZarzad_naglowek_BlockType") {
				header = item.naglowek || ''

			} else if (item.__typename === "Craft_modulZarzad_osoba_BlockType")  {
				peopleInManagementDataArray.push({
					"name": item.imieINazwisko || '',
					"position": item.stanowisko || '',
					"description": item.opis || '',
					"image": (item.zdjecie && item.zdjecie[0] && item.zdjecie[0].url) || ''
				})
			}
		});

		return (
			<ModuleWrapper paddingTop={getPaddingTop(paddingTop)}
			               paddingBottom={getPaddingBottom(paddingBottom)}>
				<HeaderWrapper>
					<CategoryName className="header-with-line">
						{header}
					</CategoryName>
				</HeaderWrapper>
				<PeopleInCategoryWrapper>
					{peopleInManagementDataArray && peopleInManagementDataArray.map((item, key) => {
						const personImage = item.image;
						const personName = item.name;
						const personPosition = item.position;
						const personDescription = item.description;
						return (
							<SingleManagementPerson key={key} personImage={personImage} personName={personName}
							                        personPosition={personPosition} personDescription={personDescription}/>
						)
					})}
				</PeopleInCategoryWrapper>
			</ModuleWrapper>
		)
	}
}

export default ManagementModule;
