import React from 'react';
// import * as sha256 from "sha256";
import Button from "../common/ButtonsSet";
import {addNewPaymentToList} from "../../utils/addNewPaymentToList";
import {sendEmail} from "../../utils/addEmailToFreshmail";
import escapeDiacritics from "../../utils/escapeDacritics";
import Cookies from 'js-cookie';

// const payULink = "https://secure.payu.com/api/v2_1/orders";

// const urlencode = str => encodeURIComponent(str)
//     .replace(/!/g, '%21')
//     .replace(/'/g, '%27')
//     .replace(/\(/g, '%28')
//     .replace(/\)/g, '%29')
//     .replace(/\*/g, '%2A')
//     .replace(/~/g, '%7E')
//     .replace(/%20/g, '+');


const PayWithPayU = ({merchantPosId, secondaryKey, totalAmount, supportedCountry, btnType, textBtn, disabled, isProccesingDataAllowed, checkAllRequiredCheckboxes, smallerPaddingClass, person, isRecurring, craftProjectId, seoName, currentSlideNumber}) => {

    const project = `Zestaw za ${(totalAmount / 100).toFixed(2)} PLN dla ${supportedCountry}`;
    const projectName = (supportedCountry) ? supportedCountry : "Polska Misja Medyczna";
    const craftId = (supportedCountry === 'Akcja specjalna | Razem dla polskich lekarzy') ? "18132" : (supportedCountry === 'Polska Misja Medyczna') ? "0" : craftProjectId;
    const btnTextForSeo = (textBtn && textBtn.replace(/\s+/g, '-').toLowerCase()) || ""
    const btnTextForSeoWithoutDiacritics = escapeDiacritics(btnTextForSeo) + '-krok-' + currentSlideNumber
    let emailFreshmail = person.email;

    const freshmailData = new FormData();
    freshmailData.append("freshmailListId", "xms79axjk1");
    freshmailData.append("freshmailEmail", emailFreshmail);
    freshmailData.append("action", "freshmail/freshmail/ajax");

    const dataEntry = new FormData();
    dataEntry.append("firstName", person.firstName);
    dataEntry.append("lastName", person.lastName);
    dataEntry.append("email", person.email);
    dataEntry.append("project", projectName);
    dataEntry.append("title", project);
    dataEntry.append("provider", "1");
    dataEntry.append("currencyCode", "PLN");
    dataEntry.append("language", "pl");
    dataEntry.append("craftId", craftId);
    dataEntry.append("isRecurring", isRecurring);
    dataEntry.append("totalAmount", (totalAmount / 100).toString());

    // const [ip, setIp] = useState(0);
    //
    // useEffect(() => {
    //     fetch('https://ipapi.co/json/').then(response => response.json()).then(res => setIp(res.ip))
    // }, []);

    // const options = {
    //     ip: '123.123.123.123',
    //     merchantPosId: merchantPosId,
    //     secondaryKey: secondaryKey,
    //     description: project,
    //     totalAmount: totalAmount,
    //     currencyCode: "PLN",
    //     productName: project,
    //     productUnitPrice: totalAmount,
    //     productQuantity: "1",
    // };

    // const productString = `&products[0].name=${urlencode(options.productName)}&products[0].quantity=${options.productQuantity}&products[0].unitPrice=${options.totalAmount}`;
    // const signatureString = `currencyCode=PLN&customerIp=${options.ip}&description=${urlencode(options.description)}&merchantPosId=${options.merchantPosId}${productString}&totalAmount=${options.totalAmount}&${options.secondaryKey}`;
    // const signature = sha256(signatureString);
    // const signatureWithValue = 'signature=' + signature;
    // const openPayUSignature = `sender=${options.merchantPosId};algorithm=SHA-256;${signatureWithValue}`;

    const onSubmit = async (event) => {

        event.preventDefault();

        if (!disabled) {
			const totalAmountForCookie = (totalAmount / 100).toString();
			Cookies.set('valueForFbq', totalAmountForCookie);
			await addNewPaymentToList(dataEntry);
            if (isProccesingDataAllowed) {
                await sendEmail(freshmailData);
            }
        }
    };
    return (
        <form onSubmit={onSubmit} name={seoName + '-' + btnTextForSeoWithoutDiacritics}>
            <Button type="submit"
                    smallerPaddingClass={smallerPaddingClass}
                    onClick={checkAllRequiredCheckboxes}
                    btnType={btnType}
                    textBtn={textBtn}/>
        </form>
    );
};

export default PayWithPayU;
