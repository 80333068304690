export async function addNewPaymentToList(data) {
	const url = `${process.env.GATSBY_PAYMENTS_POST_URL}/payments`;

	const response = fetch(url, {
		method: "POST",
		headers: {
			"Accept": "application/json",
		},
		body: data,
	})
		.then(res => res.json())
		.then((data) => {
			document.location.href = data
		});
	// typeof window !== "undefined" && window.gtag("event", "click", {eventLabel: 'PayU payment', transport: 'beacon'})


	return response
}

