import React, {Component} from 'react';
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";
import styled from "styled-components";
import {PoseGroup} from "react-pose"
import {Option, OptionList, SelectedOption, SelectWindow, SelectWrapper} from "../common/CustomSelector";

const Header = styled.div`
	min-height: 70px;
	color: ${({theme}) => theme.colors.pmmWhite};
	font-weight: ${({theme}) => theme.fontWeight.regular};
	font-size: 14px;
	line-height: 32px;
	background-color: ${({theme}) => theme.colors.pmmDarkestBlue};
	//min-height: 60px;
	width: calc(100% + 4px);
	position: absolute;
	top: -2px;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: 10px;
	margin-left: -2px;
    span.black {
		font-weight: ${({theme}) => theme.fontWeight.black};
    }
    
	@media(min-width: 375px) {
		font-size: 18px;
		padding-left: 25px;
	}
    @media(min-width: 768px) {
		top: -36px;
		//min-height: 50px;
		width: auto;
		font-size: 20px;
		padding-left: 30px;
		padding-right: 30px;
		//top: -25px;
	}
	@media(min-width: 1280px) {
		width: auto;
		//top: -27px;
	}
	@media(min-width: 1920px) {
		font-size: 24px;
		padding-left: 35px;
		padding-right: 35px;
		min-height: 70px;
		top: -36px;
	}
`

const SelectorLabel = styled.div`
	white-space: nowrap;
`

class TabsHeader extends Component {

	state = {
		isSelectWindowOpen: false
	}

	toggleSelectWindow() {
		this.setState({isSelectWindowOpen: !this.state.isSelectWindowOpen})
	}

	componentDidMount() {
		!this.props.isDesktop && document.addEventListener('mousedown', this.handleClickIfOutsideSelectWindow, false)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickIfOutsideSelectWindow, false)
	}

	handleClickIfOutsideSelectWindow = (e) => {
		if (this.node && this.node.contains(e.target)) {
			return;
		}

		this.closeSelectWindow();
	}
	closeSelectWindow() {
		if (this.state.isSelectWindowOpen) {
			this.setState({isSelectWindowOpen: false})
		}
	}


	render() {
		const {allFirstTabsDataArray, projectNumber, isDesktop, mobileSelectorArray, changeProject, isMobileOptionSelected, globalData, isMobileProjectSetFromAnotherPage} = this.props
		const {isSelectWindowOpen } = this.state
		const placeholderText =(globalData.wplatyWybierzCelMobile && globalData.wplatyWybierzCelMobile[0] && globalData.wplatyWybierzCelMobile[0].naglowekPlaceholder) || ""
		const payForMobileHeader = (globalData && globalData.wplatyWplacamNaMobile) || ""
		const placeholder = <div className="placeholder">{placeholderText}</div>

		return (
			<>
				{isDesktop && <Header dangerouslySetInnerHTML={getHtmlFromRedactorField(allFirstTabsDataArray[projectNumber].header)}/>}
				<>
					{!isDesktop &&
					<>
						<Header>
							<SelectorLabel>{payForMobileHeader}</SelectorLabel>
							<SelectWrapper className="display-always">
								<SelectedOption className="cash-flow"
									onClick={this.toggleSelectWindow.bind(this)}>{(isMobileOptionSelected || isMobileProjectSetFromAnotherPage) ? mobileSelectorArray[projectNumber].titleVariety : placeholder}</SelectedOption>
								<div ref={node => this.node = node}>
									<PoseGroup>
										{isSelectWindowOpen &&
										<SelectWindow pose={isSelectWindowOpen ? "enter" : "exit"}
													  key="select-animation-window"
													  onClick={this.toggleSelectWindow.bind(this)}
													className="cash-flow">
											<OptionList>
												{mobileSelectorArray.map((project, key) =>
													<Option key={key}
															className="cash-flow"
															pose={isSelectWindowOpen ? "opened" : "closed"}
															onClick={() => changeProject(mobileSelectorArray, project.titleVariety)}>{project.titleVariety}</Option>
												)}
											</OptionList>
										</SelectWindow>
										}
									</PoseGroup>
								</div>
							</SelectWrapper>
						</Header>
					</>
					}
				</>


			</>
		);
	}
}

export default TabsHeader;
