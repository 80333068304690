import React, {Component} from 'react';
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";
import styled from "styled-components";

const FullWidthWrapper = styled.div`
	background-color: ${({theme}) => theme.colors.pmmWhite};
	display: flex;
	flex-direction: column;
	
		@media(min-width: 768px) {
		}
		@media(min-width: 1280px) {
			flex-direction: row;
		}
		@media(min-width: 1366px) {
		}
		@media(min-width: 1920px) {
		}
`

const Header = styled.h4`
	margin-bottom: 20px;
	margin-top: 40px;
	position: relative;
	display: table;
	z-index: 1;
	width: 200px;
	
	span {
		background-color: ${({theme}) => theme.colors.pmmWhite};
		padding-right: 20px;
	}
	
	&:before {
		content: '';
		position: absolute;
		bottom: 10px;
		left: 0;
		height: 2px;
		width: 100%;
		z-index:-1;
		background-color: ${({theme}) => theme.colors.pmmDarkViolet};
		
		//@media(min-width: 1280px) {
		//	bottom: 5px;
		//}
	}
	@media(min-width: 768px) {
		width: 210px;
	}
	@media(min-width: 1280px) {
		margin-top: 0;
		width: 215px;
	}
	@media(min-width: 1366px) {
		width: 220px;
	}
	@media(min-width: 1920px) {
		width: 270px;
 	 }
  	@media(min-width: 2200px) {
		width: 320px;
	}
`

const LeftInfo = styled.div`
	background-color: ${({theme}) => theme.colors.pmmDarkViolet};
	width: 100%;
	padding: 50px 10px;
	
		@media(min-width: 768px) {
			padding: 50px 30px;
			display: flex;
		}
		@media(min-width: 1280px) {
			padding: 50px;
			width: 36%;
			flex-direction: column;
		}
		@media(min-width: 1366px) {
			width: 35%;
			padding: 50px;
			flex-direction: column;
		}
		@media(min-width: 1920px) {
			padding: 140px 85px 140px 100px;
		}
`

const LeftHeader = styled.h3`
	margin-bottom: 30px;
`

const LeftDescription = styled.div`
	margin-bottom: 50px;
	@media(min-width: 768px){
		margin-bottom: 0;
	}
	@media(min-width: 1280px){
		text-align: start;
		margin-bottom: 50px;
	}
	@media(min-width: 1920px){
		margin-bottom: 135px;
	}
`

const InfoWrapper = styled.ul`
	h3 {
		padding-bottom: 20px;
	}
	@media(min-width: 768px){
		display: flex;
		flex-direction: column;
		margin-left: 100px;
		width: 100%;
	}
	@media(min-width: 1280px){
		padding-bottom: 80px;
		margin-left: 0;
		
		h3 {
			padding-bottom: 35px;
			text-align: start;
		}
	}
	@media(min-width: 1366px){
	}
	@media(min-width: 1920px){
		width: 100%;
	}
	
	
	&.mobile {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 80px;
		width: 100%;
		
		@media(min-width: 768px){
			display: none;
		}
	}
	
	li {
		display: flex;
		color: ${({theme}) => theme.colors.pmmTextOrange};
		padding: 10px 0;
		border-bottom: 1px solid ${({theme}) => theme.colors.pmmWhiteAlpha};
		font-size: ${({theme}) => theme.fontSize.f10};
		font-weight: ${({theme}) => theme.fontWeight.medium};
		line-height:  ${({theme}) => theme.lineHeight.line6};
		//width: calc(100% - 30px);

		span {
			color: ${({theme}) => theme.colors.pmmWhite};
			margin-right: 5px;
		}
			
		&:last-of-type {
			border-bottom: none;
		}	
			
		@media(min-width: 375px) {
			font-size: ${({theme}) => theme.fontSize.f12};
		}	
		@media(min-width: 768px){
			padding: 5px 0;
			width: 100%;
		}

		@media(min-width: 1920px){
			font-size: ${({theme}) => theme.fontSize.f3};
			justify-content: flex-start;
		}
	}
`
const ContactData = styled.h5`
	margin-top: 50px;
	line-height: 30px;

	@media(min-width: 768px) {
		align-items: center;
		justify-content: center;
  	}
`
const PeopleWrapper = styled.div`
	width: 100%;
	display: flex;
	padding: 0 10px 85px;
	flex-wrap: wrap;
	
	@media(min-width: 768px) {
		padding: 50px 30px 85px;
	}
	
	@media(min-width: 1280px) {
		width: 64%;
		padding: 50px 50px 20px;
	}
	@media(min-width: 1366px) {
		width: 65%;
		padding: 50px;
	}
	@media(min-width: 1920px) {
		padding: 150px 100px 0 80px;
	}
`

const CardWrapper = styled.div`
	background-color: ${({theme}) => theme.colors.pmmWhite};
	display: flex;
	flex-direction: column;
	width: 100%;
	@media(min-width: 470px) {
		width: 50%;
	}
	@media(min-width: 768px) {
		width: 33%;
	}
	@media(min-width: 1000px) {
		padding-right: 20px;
		width: 33%;
	}
	@media(min-width: 1366px) {
		padding-right: 17px;
	}
	@media(min-width: 1440px) {
		padding-right: 30px;
	}
	@media(min-width: 1920px) {
		padding-right: 85px;
	}
`

const PhotoAndContactDataWrapper = styled.div`
	display: flex;
	flex-direction: column;
	
	@media(min-width: 768px) {
		flex-direction: row;
  	}
  	@media(min-width: 1920px) {
		min-width: 270px;
	}
`

const PictureWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	position: relative;
	background: none;
	overflow: hidden;
	width: 200px;
	height: 200px;
	@media(min-width: 768px) {
		width: 210px;
		height: 210px;
	}
	@media(min-width: 1280px) {
		width: 215px;
		height: 215px;
	}
	@media(min-width: 1366px) {
		width: 230px;
		height: 230px;
	}
	@media(min-width: 1920px) {
		width: 270px;
		height: 270px;
	}
	@media(min-width: 2200px) {
		width: 320px;
		height: 320px;
	}
`

const ZoomedPicture = styled.div`
    position: absolute;
  	width: 200px;
	height: 200px;
    transition: transform .5s;
    background-size: cover;
    background-position: center;
    background-image: url( ${({bgImg}) => bgImg});
    background-repeat: no-repeat;
    &.animate {
   		transform: ${({ isOldBrowserEdge }) => isOldBrowserEdge ? "scale(1)" : "scale(1.1)"};
    }
    &:hover {
    	transform: ${({ isOldBrowserEdge }) => isOldBrowserEdge ? "scale(1)" : "scale(1.1)"};
    }  
    
    @media(min-width: 768px) {
		width: 210px;
  	}
  	@media(min-width: 1280px) {
		margin-bottom: 30px;
		width: 215px;
  	}
  	@media(min-width: 1366px) {
		width: 230px;
	}
	@media(min-width: 1920px) {
		width: 270px;
		height: 270px;
  }
  	@media(min-width: 2200px) {
		width: 320px;
		height: 320px;
	}
`;

const PersonInfoWrapper = styled.div`
	border: 2px solid ${({theme}) => theme.colors.pmmOrange};
	margin-top: -30px;
	position: relative;
	width: 200px;
	@media(min-width: 768px) {
		width: 210px;
  	}
	@media(min-width: 1280px) {
		margin-bottom: 30px;
		width: 215px;
  	}
  	@media(min-width: 1366px) {
		width: 230px;
	}
  	 @media(min-width: 1920px) {
		margin-bottom: 0;
		width: 270px;
  	}
  	@media(min-width: 2200px) {
		width: 320px;
	}
`
const TileImgDescription = styled.div`
	font-size: ${({ theme }) => theme.fontSize.h7_360};
	line-height: ${({ theme }) => theme.lineHeight.h7_360};
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	padding: 10px;
	background-color: ${({theme}) => theme.colors.pmmOrange};
	display: table;
	width: 100%;
	@media(min-width: 375px) {
		font-size: ${({ theme }) => theme.fontSize.h7_375};
		line-height: ${({ theme }) => theme.lineHeight.h7_375};
	}
	@media(min-width: 768px) {
		font-size: ${({ theme }) => theme.fontSize.h7_768};
		line-height: ${({ theme }) => theme.lineHeight.h7_768};
	}
	@media(min-width: 1280px) {
		font-size: ${({ theme }) => theme.fontSize.h7_1280};
		line-height: ${({ theme }) => theme.lineHeight.h7_1280};
	}
	@media(min-width: 1366px) {
		font-size: ${({ theme }) => theme.fontSize.h7_1366};
		line-height: ${({ theme }) => theme.lineHeight.h7_1366};
	}
	@media(min-width: 1920px) {
		font-size: ${({ theme }) => theme.fontSize.h7_1920};
		line-height: ${({ theme }) => theme.lineHeight.h7_1920};
	}

	div {
		width: 100px;
	}
`
const ContentWrapper = styled.div`
	padding: 0 10px 40px;
`
const Position = styled.h5`
	padding-top: 10px;
`

class ContactInfo extends Component {
	state = {
		isOldBrowserEdge: false,
	};

	componentDidMount() {
		if (typeof window !== "undefined") {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({isOldBrowserEdge: isOldBrowser});
		}
	}

	render() {
		const {data} = this.props;
		const isOldBrowserEdge = this.state.isOldBrowserEdge;
		const contactInfoData = (data && data.modulKontaktDane && data.modulKontaktDane[0]) || ""
		const contactInfoPeople = (data && data.modulKontaktOsoby) || []
		const header = contactInfoData.naglowek || ""
		const address = contactInfoData.adres || ""
		const tableTitle = contactInfoData.tytul || ""
		const table = contactInfoData.tabela || []

		let peopleArray = []

		contactInfoPeople.forEach(item => {
			peopleArray.push({
				'header': item.naglowek || "",
				'photoUrl': (item.zdjecie && item.zdjecie[0] && item.zdjecie[0].url) || "",
				'photoDescription': item.zdjeciePodpis || "",
				'position': item.zdjecieStanowisko || "",
				'contactDataDescription': item.daneKontaktowe || ""
			})
		})

		return (
			<FullWidthWrapper>
				<LeftInfo>
					<div>
						<LeftHeader className="white" dangerouslySetInnerHTML={getHtmlFromRedactorField(header)}/>
						<LeftDescription className="paragraph white"
										 dangerouslySetInnerHTML={getHtmlFromRedactorField(address)}/>
					</div>
					<InfoWrapper>
						<h3 className="white">{tableTitle}</h3>
						{table.map((item, key) =>
							<li key={key}><span>{item.opis}</span>{item.dane}</li>
						)}
					</InfoWrapper>
				</LeftInfo>
				<PeopleWrapper>
					{peopleArray.map((item, key) =>
						<CardWrapper key={key}>
							<Header className="semi-bold"><span>{item.header}</span></Header>
							<PhotoAndContactDataWrapper>
								<PictureWrapper>
									<ZoomedPicture isOldBrowserEdge={isOldBrowserEdge} bgImg={item.photoUrl}/>
								</PictureWrapper>
							</PhotoAndContactDataWrapper>
							<PersonInfoWrapper>
								<TileImgDescription className="h7 white"><div>{item.photoDescription}</div></TileImgDescription>
								<ContentWrapper>
									<Position className="regular letter-spacing-0 dark-blue">{item.position}</Position>
									<ContactData className="dark-blue semi-bold"
												 dangerouslySetInnerHTML={getHtmlFromRedactorField(item.contactDataDescription)}/>
								</ContentWrapper>
							</PersonInfoWrapper>
						</CardWrapper>)}
				</PeopleWrapper>
			</FullWidthWrapper>
		);
	}
}


export default ContactInfo;
