import React, {Component} from 'react';
import styled from "styled-components"
import {Link} from "gatsby";
import getSlug from "../../utils/getSlug";
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";

const BannerWrapper = styled.div`
  	padding: 150px 10px 40px;
	height: 100vh;
	color: ${({theme, isGreyBackground}) => isGreyBackground ? theme.colors.pmmDarkestBlue : theme.colors.pmmWhite};
	display: flex;
	flex-direction: column;
	width: 100%;
  	background-color: ${({theme, isGreyBackground}) => isGreyBackground ? theme.colors.pmmGreyBackground : 'inherit'};
		
	@media(min-width: 768px) {
	  padding: 230px 100px 40px;
		//min-height: 760px;
		height: auto;
	}
	@media(min-width: 1280px) {
      	padding: ${({isGreyBackground}) => isGreyBackground ? "125px 50px 40px" : "205px 50px 40px"};
		flex-direction: row;
		//min-height: 550px;
	}
	@media(min-width: 1920px) {
		padding: ${({isGreyBackground}) => isGreyBackground ? "140px 100px 60px" : "265px 100px 60px"};
		//min-height: 800px;
	}
`

const BtnWrapper = styled.div`
	@media(min-width: 1280px) {
		width: 300px;
		padding-top: 55px;
	}
	@media(min-width: 1366px) {
		width: 320px;
	}
	@media(min-width: 1920px) {
		width: 430px;
		padding-top: 75px;
	}
`
const InfoWrapper = styled.div`
	@media(min-width: 1280px) {
		width: auto;
	}
`

export const BtnWrapperForBgPreload = styled.div`
	// to preload image on hover - fixes flash for first hover
	background: url(${props => props.iconHoverBtn}) no-repeat -9999px -9999px;
`

const DateOrText = styled.h5`
	padding-top: ${({isGreyBackground}) => isGreyBackground ? "25px" : "70px"};
	@media(min-width: 768px) {
		padding-top: ${({isGreyBackground}) => isGreyBackground ? "35px" : "80px"};
	}
	@media(min-width: 1280px) {
		padding-top: 0;
	}
  &.text {
    @media(min-width: 1280px) {

      margin-top: 40px;
    }
  }
`

const Header = styled.h3`
	position: relative;
	margin-top: 30px;
	
	&::after {
		content: '';
		position: absolute;
		height: 3px;
		width: 60px;
		background-color: ${({theme, isGreyBackground}) => isGreyBackground ? theme.colors.pmmDarkestBlue : theme.colors.pmmWhite};
		bottom: -20px;
		left: 0;
	}
	@media(min-width: 1920px) {
		&::after {
			width: 85px;
			bottom: -30px;
		}
	}
`

const Description = styled.div`
	color: ${({theme, isGreyBackground}) => isGreyBackground ? theme.colors.pmmDarkestBlue : theme.colors.pmmWhite};
	padding-top: 60px;
		
	@media(min-width: 768px) {
		padding-top: 70px;
	}
	@media(min-width: 1280px) {
		width: 590px;
	}
	@media(min-width: 1366px) {
		width: 630px;
	}
	@media(min-width: 1920px) {
		padding-top: 75px;
		width: 860px;
	}
`

export const BtnWithIcon = styled.p`
	color: ${({theme, isGreyBackground}) => isGreyBackground ? theme.colors.pmmDarkestBlue : theme.colors.pmmWhite};
	font-weight: ${({theme}) => theme.fontWeight.medium};
	text-decoration: none;
	position: relative;
	padding-left: 40px;
	user-select: none;
	font-size: 12px;
	line-height: 14px;
	@media(min-width: 768px) {
		font-size: 14px;
		line-height: 16px;
	}
	@media(min-width: 1280px) {
		font-size: 12px;
		line-height: 14px;
	}
	@media(min-width: 1920px) {
		font-size: 14px;
		line-height: 16px;
	}
	&:before {
		content: '';
		background-position: center;
		width: 34px;
		height: 34px;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
    	background-image: url( ${({iconBtn}) => iconBtn});
    	background-repeat: no-repeat;
		position: absolute;
		border-radius: 100%;
	}
	
	&:hover {
		color: ${({theme}) => theme.colors.pmmOrange};

		&:before {
			background-image: url( ${({iconHoverBtn}) => iconHoverBtn});
		}
	}
	
	&.dark-violet {
		color: ${({theme}) => theme.colors.pmmDarkViolet};

		&:hover {
			color: ${({theme}) => theme.colors.pmmWhite};
		}
	}
	@media(min-width: 768px) {
		padding-left: 50px;
		&:before {
			width: 34px;
			height: 34px;
		}
	}
	// @media(min-width: 1920px) {
	// 	font-size:  ${({theme}) => theme.fontSize.f12};
	// 	line-height: ${({theme}) => theme.lineHeight.line16};
	// }
`

const StyledPictogram = styled.img`
	width: 100px;	
	margin-top: 25px;
  	@media(min-width: 1280px) {
		width: 150px;
    }
`;

class SingleNewsBanner extends Component {
	render() {
		const {bannerData, postDate, newsBtns, activeSite, isPrivacyPolicy, isGreyBackground, actWithUsBackButton} = this.props
		const pictogram = bannerData?.piktogram?.[0]?.url || '';
		const textOverHeader = bannerData?.tekstNadNaglowkiem || '';
		const bannerHeader = bannerData?.naglowek || ""
		const bannerDescription = bannerData?.opis || ""
		const newsBackBtnData = (newsBtns && newsBtns[0] && newsBtns[0].przyciskPowrot && newsBtns[0].przyciskPowrot[0]) || ""
		const textBtn = (newsBackBtnData && newsBackBtnData.przyciskNapis) || ""
		const toBtn = (newsBackBtnData && newsBackBtnData.przyciskWpis && newsBackBtnData.przyciskWpis[0] && newsBackBtnData.przyciskWpis[0].slug) || ""
		const hrefBtn = (newsBackBtnData && newsBackBtnData.przyciskLink) || ""
		const iconBtn = (newsBackBtnData && newsBackBtnData.przyciskIkona && newsBackBtnData.przyciskIkona[0] && newsBackBtnData.przyciskIkona[0].url) || ""
		const iconHoverBtn = (newsBackBtnData && newsBackBtnData.przyciskIkonaPoNajechaniu && newsBackBtnData.przyciskIkonaPoNajechaniu[0] && newsBackBtnData.przyciskIkonaPoNajechaniu[0].url) || ""

		const actWithUsBackButtonData = (actWithUsBackButton && actWithUsBackButton[0] && actWithUsBackButton[0].przyciskPowrot && actWithUsBackButton[0].przyciskPowrot[0]) || ""
		const textBtnActions = (actWithUsBackButtonData && actWithUsBackButtonData.przyciskNapis) || ""
		const toBtnActions = (actWithUsBackButtonData && actWithUsBackButtonData.przyciskWpis && actWithUsBackButtonData.przyciskWpis[0] && actWithUsBackButtonData.przyciskWpis[0].slug) || ""
		const hrefBtnActions = (actWithUsBackButtonData && actWithUsBackButtonData.przyciskLink) || ""
		const iconBtnActions = (actWithUsBackButtonData && actWithUsBackButtonData.przyciskIkona && actWithUsBackButtonData.przyciskIkona[0] && actWithUsBackButtonData.przyciskIkona[0].url) || ""
		const iconHoverBtnActions = (actWithUsBackButtonData && actWithUsBackButtonData.przyciskIkonaPoNajechaniu && actWithUsBackButtonData.przyciskIkonaPoNajechaniu[0] && actWithUsBackButtonData.przyciskIkonaPoNajechaniu[0].url) || ""

		const finalTextBtn = isGreyBackground ? textBtnActions : textBtn;
		const finalToBtn = isGreyBackground ? toBtnActions : toBtn;
		const finalHrefBtn = isGreyBackground ? hrefBtnActions : hrefBtn;
		const finalIconBtn = isGreyBackground ? iconBtnActions : iconBtn;
		const finalIconHoverBtn = isGreyBackground ? iconHoverBtnActions : iconHoverBtn;

		return (
			<BannerWrapper isGreyBackground={isGreyBackground}>
				<BtnWrapper>
					{!isPrivacyPolicy &&<BtnWrapperForBgPreload iconHoverBtn={finalIconHoverBtn}>
						{finalHrefBtn && <BtnWithIcon isGreyBackground={isGreyBackground} className="h8" href={finalHrefBtn} iconBtn={finalIconBtn} iconHoverBtn={finalIconHoverBtn}
							as={"a"}>{finalTextBtn}</BtnWithIcon>}
						{finalToBtn && <BtnWithIcon isGreyBackground={isGreyBackground} className="h8" as={Link} iconBtn={finalIconBtn} iconHoverBtn={finalIconHoverBtn}
							to={`/${getSlug(finalToBtn, activeSite)}`}>{finalTextBtn}</BtnWithIcon>}
						{!finalHrefBtn && !finalToBtn &&
						<BtnWithIcon isGreyBackground={isGreyBackground} iconBtn={finalIconBtn} iconHoverBtn={finalIconHoverBtn}>{finalTextBtn}</BtnWithIcon>}
					</BtnWrapperForBgPreload> }
				</BtnWrapper>
				<InfoWrapper>
					{pictogram && <StyledPictogram src={pictogram}/>}
					{textOverHeader && <DateOrText isGreyBackground={isGreyBackground} className="medium text">{textOverHeader}</DateOrText>}
					{!isPrivacyPolicy && !isGreyBackground && <DateOrText className="medium">{postDate}</DateOrText>}
					<Header isGreyBackground={isGreyBackground}>{bannerHeader}</Header>
					<Description isGreyBackground={isGreyBackground} className="paragraph" dangerouslySetInnerHTML={getHtmlFromRedactorField(bannerDescription)}/>
				</InfoWrapper>
			</BannerWrapper>
		);
	}
}

SingleNewsBanner.propTypes = {};

export default SingleNewsBanner;
