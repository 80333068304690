import React, { Component } from 'react'
import styled from 'styled-components'
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField'
import { isMobile } from 'react-device-detect'
import { Option, OptionList, SelectWindow } from '../common/CustomSelector'
import { PoseGroup } from 'react-pose'
import ArrowDownOrange from '../../images/workWithUs/arrow-down-orange.svg'
import { scrollToSectionName } from '../../utils/scrollToSectionName'

const ModuleWrapper = styled.div`
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-size: cover;
	background-position: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-right: 15px;
	padding-left: 15px;

	@media (min-width: 375px) {
		padding-right: 25px;
		padding-left: 25px;
		justify-content: flex-end;
		padding-bottom: 160px;
	}
	@media (min-width: 768px) {
		padding-right: 35px;
		padding-left: 35px;
		justify-content: flex-end;
		//padding-bottom: 180px;
		padding-bottom: 10%;
	}
	@media (min-width: 1366px) {
		justify-content: center;
		padding-bottom: 0;
	}
	&:before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: 1;
		background-image: ${({ isContactSubpage }) =>
	isContactSubpage
		? 'none'
		: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 10%,transparent 30%,transparent 70%, rgba(0, 0, 0, 0.4) 100%)'};
	}
	@media (orientation: landscape) and (max-width: 1024px) {
		background-image: url(${({ bgImgMobileLandscape }) => bgImgMobileLandscape});
	}
	@media (orientation: portrait) and (max-width: 1024px) {
		background-image: url(${({ bgImgMobile }) => bgImgMobile});
	}
	@media (min-width: 1025px) {
		background-image: url(${({ bgImg }) => bgImg});
	}
	@media (min-width: 1600px) {
		padding-right: 110px;
		padding-left: 110px;
		&:before {
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 11;
			background-image: ${({ isContactSubpage }) =>
	isContactSubpage
		? 'none'
		: 'linear-gradient(to right, rgba(0, 0, 0, .4) 10%,transparent 40%)'};
		}
	}
	@media (min-width: 1920px) {
		padding-right: 210px;
		padding-left: 210px;
	}
`

const TextBoxWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: auto;
	z-index: 100;
	margin-top: 40px;
`

const AnimatedSpanBackground = styled.div`
	div {
		display: inline;
		background: #ff4124;
		padding: 10px 0;
		box-shadow: 10px 0 0 #ff4124, -10px 0 0 #ff4124;
		@media (min-width: 1920px) {
			padding: 2px 0 6px;
		}
	}
`

const Header = styled.span`
	font-size: ${({ theme }) => theme.fontSize.h1_360};
	line-height: 65px;
	margin-left: 10px;
	position: relative;
	color: ${({ theme }) => theme.colors.pmmWhite};
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	@media (min-width: 375px) {
		font-size: ${({ theme }) => theme.fontSize.h1_375};
		line-height: 65px;
	}
	@media (min-width: 768px) {
		font-size: ${({ theme }) => theme.fontSize.h1_1366};
		line-height: 100px;
	}
	@media (min-width: 880px) {
		margin-left: 20px;
	}
	@media (min-width: 1920px) {
		font-size: ${({ theme }) => theme.fontSize.h1_1920};
		line-height: 120px;
	}
`

const ButtonsWrapper = styled.div`
	display: none;

	@media (min-width: 880px) {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		margin-top: 60px;
	}
	@media (min-width: 1440px) {
		position: relative;
		flex-direction: row;
		flex-wrap: nowrap;
		margin-top: 120px;
		justify-content: flex-start;

		&:before {
			content: '';
			top: -55px;
			left: 10px;
			position: absolute;
			width: 60px;
			height: 4px;
			background-color: ${({ theme }) => theme.colors.pmmWhite};
		}
	}
`

const BasicButtonWrapper = styled.div`
	display: flex;
	//width: 50%;
	justify-content: space-between;
	align-items: center;
	padding: 0 30px 0 10px;
	margin-top: 20px;
	&:first-of-type {
		width: 400px;
	}
	&:nth-of-type(2) {
		width: 400px;
	}
	&:nth-of-type(3) {
		width: calc(100% - 400px);
	}
	&:nth-of-type(4) {
		width: calc(100% - 400px);
	}
	@media (min-width: 880px) {
		&:first-of-type {
			order: 1;
		}
		&:nth-of-type(2) {
			order: 3;
		}
		&:nth-of-type(3) {
			order: 2;
		}
		&:nth-of-type(4) {
			order: 4;
		}
	}
	@media (min-width: 1440px) {
		order: initial;
		width: auto !important;
	}
`

const BasicButtonStyled = styled.button`
	cursor: pointer;
	max-height: 75px;
	vertical-align: middle;
	background-color: transparent;
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: ${({ theme }) => theme.fontSize.f4};
	line-height: ${({ theme }) => theme.lineHeight.line13};
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	color: ${({ theme }) => theme.colors.pmmOrange};
	border-radius: 50px;
	padding: 13px 30px;
	border: none;
	text-transform: uppercase;
	transition: margin 0.25s ease-out, padding 0.25s ease-out;
	position: relative;
	white-space: nowrap;
	z-index: 1;
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-items: center;
	img {
		margin-left: 25px;
		height: 45px;
	}

	&:before {
		content: '';
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		border-radius: 50px;
		background-color: ${({ theme }) => theme.colors.pmmWhite};
		transition: left 0.25s ease-out, width 0.25s ease-out;
		border: 2px solid ${({ theme }) => theme.colors.pmmOrange};
	}
	&.hover {
		&:hover {
			&:before {
				content: '';
				position: absolute;
				height: 100%;
				width: ${({ isOldBrowserEdge }) => (isOldBrowserEdge
	? '100%'
	: 'calc(100% + 20px)')};
				top: 0;
				left: ${({ isOldBrowserEdge }) => (isOldBrowserEdge
	? '0'
	: '-10px')};
				z-index: -1;
				border-radius: 50px;
			}
		}
	}

	@media (min-width: 768px) {
		font-size: ${({ theme }) => theme.fontSize.f4};
		line-height: ${({ theme }) => theme.lineHeight.line14};
		padding: 10px 20px;
	}

	@media (min-width: 880px) {
		&.first-btn,
		&.second-btn {
			width: 340px;
		}
		&.third-btn,
		&.fourth-btn {
			width: 250px;
		}
	}
	@media (min-width: 1440px) {
		&.first-btn,
		&.second-btn {
			width: auto;
		}
		&.third-btn,
		&.fourth-btn {
			width: auto;
		}
	}
	@media (min-width: 1920px) {
		font-size: ${({ theme }) => theme.fontSize.f6};
		line-height: ${({ theme }) => theme.lineHeight.line14};
		max-height: 75px;
		padding: 13px 30px;
	}
`

const OptionsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	border: ${({ theme }) => theme.colors.pmmOrange} 2px solid;
	border-radius: 50px;
	padding: 9px 10px;
	margin-right: 15px;
	background-color: ${({ theme }) => theme.colors.pmmWhite};
	cursor: pointer;
  	width: fit-content;
	position: relative;
	&.display-on-small-devices {
		display: flex;
		margin-top: 30px;
		position: relative;
		
		@media (min-width: 768px) {
			margin-top: 90px;
		}
		@media (min-width: 880px) {
			display: none;
		}
		
		div {
			left: -14px;
			@media (min-width: 1920px) {
				left: 0;
			}
		}
		&:last-child {
			margin-right: 10px;
			min-width: 100px;
			@media(min-width: 425px) {
				margin-right: 25px;
				min-width: 108px;
			}
			@media(min-width: 1920px) {
				min-width: 160px;
			}
		}
		@media(min-width: 768px) {
			padding: 15px;
		}
		@media(min-width: 1920px) {
			padding: 15px 25px;
			min-width: 160px;
		}	
	ś}
`

const CurrentOption = styled.p`
	font-size: 12px;
	line-height: 14px;
	position: relative;
	padding: 0 10px;
	margin: auto 0;
	color: ${({ theme }) => theme.colors.pmmOrange};
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	text-transform: uppercase;

	@media (min-width: 768px) {
		font-size: 16px;
	}
	@media (min-width: 1920px) {
		font-size: 16px;
		line-height: 19px;
		padding-right: 25px;
	}
`

const ArrowImage = styled.img`
	width: 13px;
	height: 9px;
	margin: 0 0 0 5px;

	@media (min-width: 375px) {
		margin: 0 0 0 15px;
	}
`

class BannerWithButtons extends Component {
	state = {
		isSelectWindowOpen: false,
	}

	componentDidMount () {
		document.addEventListener('mousedown',
			this.handleClickIfOutsideSelectWindow, false)
	}

	componentWillUnmount () {
		document.removeEventListener('mousedown',
			this.handleClickIfOutsideSelectWindow, false)
	}

	toggleSelectWindow () {
		this.setState({ isSelectWindowOpen: !this.state.isSelectWindowOpen })
	}

	handleClickIfOutsideSelectWindow = e => {
		if (this.node.contains(e.target)) {
			return
		}

		this.closeSelectWindow()
	}

	closeSelectWindow () {
		if (this.state.isSelectWindowOpen) {
			this.setState({ isSelectWindowOpen: false })
		}
	}

	render () {
		const { isSelectWindowOpen } = this.state
		const { data } = this.props
		const moduleData = data?.modulBanerCzteryPrzyciski?.[0] || ''
		const text = moduleData?.tekst || ''
		const backgroundPhotoMobile = moduleData?.zdjecieWTleMobile?.[0]?.url ||
			''
		const backgroundPhotoMobileLandscape = moduleData?.zdjecieWTleMobilePoziome?.[0]?.url ||
			''
		const backgroundPhoto = moduleData?.zdjecieWTle?.[0]?.url || ''
		const btn1text = moduleData?.przycisk1Napis || ''
		const btn1pictogram = moduleData?.przycisk1Piktogram?.[0]?.url || ''
		const btn2text = moduleData?.przycisk2Napis || ''
		const btn2pictogram = moduleData?.przycisk2Piktogram?.[0]?.url || ''
		const btn3text = moduleData?.przycisk3Napis || ''
		const btn3pictogram = moduleData?.przycisk3Piktogram?.[0]?.url || ''
		const btn4text = moduleData?.przycisk4Napis || ''
		const btn4pictogram = moduleData?.przycisk4Piktogram?.[0]?.url || ''
		const textOnMobile = moduleData?.tekstListaRozwijanaMobile || ''

		return (
			<ModuleWrapper
				bgImg={backgroundPhoto}
				bgImgMobile={backgroundPhotoMobile}
				bgImgMobileLandscape={backgroundPhotoMobileLandscape}
			>
				<TextBoxWrapper>
					<Header>
						<AnimatedSpanBackground>
							<span
								dangerouslySetInnerHTML={getHtmlFromRedactorField(
									text)}/>
						</AnimatedSpanBackground>
					</Header>
					<OptionsWrapper className="display-on-small-devices">
						<CurrentOption
							onClick={this.toggleSelectWindow.bind(this)}>
							{textOnMobile}
							<ArrowImage src={ArrowDownOrange}/>
						</CurrentOption>
						<div ref={node => (this.node = node)}>
							<PoseGroup>
								{isSelectWindowOpen && (
									<SelectWindow
										pose={isSelectWindowOpen
											? 'enter'
											: 'exit'}
										key="select-animation-window"
										onClick={this.toggleSelectWindow.bind(
											this)}
									>
										<OptionList>
											<Option
												key={1}
												pose={isSelectWindowOpen
													? 'opened'
													: 'closed'}
												onClick={() => scrollToSectionName(
													'scroll-przycisk-1')}
											>
												{btn1text}
											</Option>
											<Option
												key={2}
												pose={isSelectWindowOpen
													? 'opened'
													: 'closed'}
												onClick={() => scrollToSectionName(
													'scroll-przycisk-2')}
											>
												{btn2text}
											</Option>
											<Option
												key={3}
												pose={isSelectWindowOpen
													? 'opened'
													: 'closed'}
												onClick={() => scrollToSectionName(
													'scroll-przycisk-3')}
											>
												{btn3text}
											</Option>
											<Option
												key={4}
												pose={isSelectWindowOpen
													? 'opened'
													: 'closed'}
												onClick={() => scrollToSectionName(
													'scroll-przycisk-4')}
											>
												{btn4text}
											</Option>
										</OptionList>
									</SelectWindow>
								)}
							</PoseGroup>
						</div>
					</OptionsWrapper>
					<ButtonsWrapper>
						<BasicButtonWrapper>
							<BasicButtonStyled
								className={isMobile
									? `first-btn  white`
									: `first-btn hover`}
								onClick={() => scrollToSectionName(
									'scroll-przycisk-1')}
							>
								{btn1text} <img src={btn1pictogram}/>
							</BasicButtonStyled>
						</BasicButtonWrapper>
						<BasicButtonWrapper>
							<BasicButtonStyled
								className={isMobile
									? `second-btn white`
									: `second-btn hover`}
								onClick={() => scrollToSectionName(
									'scroll-przycisk-2')}
							>
								{btn2text} <img src={btn2pictogram}/>
							</BasicButtonStyled>
						</BasicButtonWrapper>
						<BasicButtonWrapper>
							<BasicButtonStyled
								className={isMobile
									? `third-btn white`
									: `third-btn hover`}
								onClick={() => scrollToSectionName(
									'scroll-przycisk-3')}
							>
								{btn3text} <img src={btn3pictogram}/>
							</BasicButtonStyled>
						</BasicButtonWrapper>
						<BasicButtonWrapper>
							<BasicButtonStyled
								className={isMobile
									? `fourth-btn white`
									: `fourth-btn hover`}
								onClick={() => scrollToSectionName(
									'scroll-przycisk-4')}
							>
								{btn4text} <img src={btn4pictogram}/>
							</BasicButtonStyled>
						</BasicButtonWrapper>
					</ButtonsWrapper>
				</TextBoxWrapper>
			</ModuleWrapper>
		)
	}
}

export default BannerWithButtons
