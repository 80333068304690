import React, {Component} from 'react';
import styled from "styled-components";
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";

const ScopeWrapper = styled.div`
	display: flex;
	flex-direction: column;
	
	@media(min-width: 768px){
		flex-direction: row;
	}
`

const Column = styled.div`
	width: 100%;
	padding: 10px;
	&.dark {
		background-color: ${({theme}) => theme.colors.pmmDarkViolet};
	}
	
	&.light {
		background-color: ${({theme}) => theme.colors.pmmWhite};
	}
	
	@media(min-width: 768px){
		width: 50%;
		&.dark {
			padding: 130px 40px 120px;
		}
		
		&.light {
			padding: 440px 40px 0; 
		}
	}
	@media(min-width: 1280px){
		&.dark {
			padding: 130px 50px 120px;
		}
		
		&.light {
			padding: 440px 50px 0; 
		}
	}
	@media(min-width: 1920px){
		&.dark {
			padding: 130px 100px 120px;
		}
		
		&.light {
			padding: 440px 100px 0 80px; 
		}
	}
`
const SectionName = styled.h5`
	padding-bottom: 70px;
	text-transform: uppercase;
`
const Header = styled.h3`
	margin-bottom: 50px;
	.orange {
		color: ${({theme}) => theme.colors.pmmOrange};
	}
	@media(min-width: 768px){
		margin-bottom: 150px;
	}
`

const InfoWrapper = styled.div`
	padding-bottom: 80px;
`

const InfoHeader = styled.h4`
	@media(min-width: 768px){
		padding-bottom: 40px;
	}
`

const Info = styled.div`
    //TODO: add rwd
	ul {
		margin-left: 60px;
		li {
			position: relative;
			padding-bottom: 20px;
			
			&:before {
				content: '';
				position: absolute;
				top: 18px;
				left: -60px;
				height: 3px;
				width: 30px;
				background-color: ${({theme}) => theme.colors.pmmOrange};
			}
		}
	}
`

class Scope extends Component {

	render() {
		const {data} = this.props
		const firstColumn = (data && data.modulZakres1Kolumna) || []
		const secondColumn = (data && data.modulZakres2Kolumna) || []
		let sectionName = ''
		let header = ''
		let firstColumnInfo = []
		let secondColumnInfo = []

		firstColumn.forEach(item => {
			if (item.__typename === "Craft_modulZakres1Kolumna_nazwaSekcji_BlockType") {
				sectionName = item.nazwaSekcji || ''
			} else if (item.__typename === "Craft_modulZakres1Kolumna_naglowek_BlockType") {
				header = item.naglowek || ''
			} else if (item.__typename === "Craft_modulZakres1Kolumna_dane_BlockType") {
				firstColumnInfo.push({
					'header': item.naglowek || "",
					'text': item.tekst || ""
				})
			}
		})

		secondColumn.forEach(item => {
			secondColumnInfo.push({
					'header': item.naglowek || "",
					'text': item.opis || "",
				})
		})

		return (
			<ScopeWrapper>
				<Column className="dark">
					<SectionName className="white">{sectionName}</SectionName>
					<Header className="white" dangerouslySetInnerHTML={getHtmlFromRedactorField(header)}/>
					{firstColumnInfo.map((item, key) =>
						<InfoWrapper key={key}>
							<InfoHeader className="white bold">{item.header}</InfoHeader>
							<Info className="paragraph white" dangerouslySetInnerHTML={getHtmlFromRedactorField(item.text)}/>
						</InfoWrapper>
					)}
				</Column>
				<Column className="light">
						{secondColumnInfo.map((item, key) =>
						<InfoWrapper key={key}>
							<InfoHeader className="darkest-blue bold">{item.header}</InfoHeader>
							<Info className="darkest-blue paragraph" dangerouslySetInnerHTML={getHtmlFromRedactorField(item.text)}/>
						</InfoWrapper>
					)}

				</Column>

			</ScopeWrapper>
		);
	}
}

export default Scope;
