import React, {Component} from 'react'
import styled from "styled-components"
import getSlug from "../../utils/getSlug";
import {Link} from "gatsby";

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
  	background-color: ${({theme}) => theme.colors.pmmWhite};
`;

const PictureWrapper = styled.div`
	margin: 50px auto 100px;
	width: 274px;
	cursor: pointer;
	height: 274px;
	background-size: cover;
    background-position: center;
    background-image: url( ${({bgImg}) => bgImg});
    position: relative;
    transition: transform .2s ease-in-out;
    &:hover {
    	transform: scale(1.05);
    }
`;

const Header = styled.p`
	font-size: 36px;
	line-height: 50px;
	letter-spacing: -1.5px;
	position: relative;
	text-align: center;
	padding: 50px 10% 0px;
	width: 100%;
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
	@media(min-width: 768px){
		font-size: 36px;
		line-height: 50px;
	}
	@media(min-width: 1280px){
		padding-bottom: 0;
	}
	@media(min-width: 1366px){
	}
	@media(min-width: 1920px){
		font-size: 50px;
		line-height: 52px;
	}
`;

const Description = styled.p`
	position: relative;
	text-align: center;
	padding: 10px 10%;
	width: 100%;
	font-size: 28px;
	line-height: 52px;
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	font-weight: ${({theme}) => theme.fontWeight.regular};
	@media(min-width: 1920px) {
		padding: 10px 265px;
	}
`;

class NextProject extends Component {

	render() {
		const nextProject = this.props.data;
		const {activeSite} = this.props;
		const nextProjectData = (nextProject.modulNastepnyProjekt && nextProject.modulNastepnyProjekt[0] && nextProject.modulNastepnyProjekt[0].wybierzProjekt &&
							     nextProject.modulNastepnyProjekt[0].wybierzProjekt[0]) || '';
		const nextProjectHeader = (nextProjectData && nextProjectData.zobaczRowniezNaglowek) || "";
		const nextProjectDescription = (nextProjectData && nextProjectData.zobaczRowniezOpis) || "";
		const nextProjectImage = (nextProjectData && nextProjectData.zobaczRowniezZdjecie && nextProjectData.zobaczRowniezZdjecie[0] &&
								  nextProjectData.zobaczRowniezZdjecie[0].url) || "" ;
		const slug = (nextProjectData && nextProjectData.slug) || "";

		return (
			<ModuleWrapper>
				<Header>{nextProjectHeader}</Header>
				<Description>{nextProjectDescription}</Description>
				<PictureWrapper bgImg={nextProjectImage} as={Link} to={`/${getSlug(slug, activeSite)}`}/>
			</ModuleWrapper>
		)
	}
}

export default NextProject;
