import React, {Component} from 'react'
import styled from "styled-components"
import SimpleMap from "./SimpleMap";

const ModuleWrapper = styled.div`
	height: 500px;
	width: 100%;
	position: relative;
	@media(min-width: 768px) {
		height: 600px;
	}
	@media(min-width: 1920px) {
		height: 721px;
	}
`;

class MapModule extends Component {
	state = {
		isOldBrowserEdge: false,
		currentImageIndex: 0,
	};

	componentDidMount() {
		if (typeof window !== "undefined") {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({isOldBrowserEdge: isOldBrowser});
		}
	}

	render() {
		const mapModule = this.props.data;
		const mapModuleData = (mapModule.modulMapa && mapModule.modulMapa[0]) || [];
		const mapLng = (mapModuleData && mapModuleData.mapa && mapModuleData.mapa.lng) || '';
		const mapLat = (mapModuleData && mapModuleData.mapa && mapModuleData.mapa.lat) || '';
		const mapAddress = (mapModuleData && mapModuleData.mapa && mapModuleData.mapa.address) || '';

		return (
			<ModuleWrapper>
				<SimpleMap lng={mapLng} lat={mapLat} address={mapAddress} >{mapLat}{mapLng}</SimpleMap>
			</ModuleWrapper>
		)
	}
}

export default MapModule;
