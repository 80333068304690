import React, {Component} from 'react'
import styled from "styled-components"
import {Link} from "gatsby";
import getSlug from "../../utils/getSlug";
import {BtnWithIcon, BtnWrapperForBgPreload} from "../news/SingleNewsBanner";

const ModuleWrapper = styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
	height: 100vh;
	background-size: cover;
	background-position: center;
	padding-right: 15px;
	padding-left: 15px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	@media(min-width: 425px) {
		padding-right: 30px;
		padding-left: 30px;
	}
    &:before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: 1;
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 10%,transparent 30%,transparent 70%, rgba(0, 0, 0, 0.4) 100%);
	}
  	@media(orientation: landscape) and (max-width: 1024px) {
  		background-image:url( ${({bgImgMobileLandscape}) => bgImgMobileLandscape});
  	}
  	@media(orientation: portrait) and (max-width: 1024px) {
  		background-image: url( ${({bgImgMobile}) => bgImgMobile});
  	}
  	@media(min-width: 1025px) {
  		background-image: url( ${({bgImg}) => bgImg});
  	}
    @media(min-width: 1280px) {
        padding-right: 50px;
		padding-left: 50px;
	    &:before {
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 11;
			background-image: linear-gradient(to right, rgba(0, 0, 0, .4) 10%,transparent 40%);
		}
  	}
    @media(min-width: 1920px) {
        padding-right: 100px;
		padding-left: 100px;
  	}
`;

const TextBoxWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	min-height: 240px;
	position: relative;
	//min-height: 150px;
	z-index: 100;
	background-color: ${({theme}) => theme.colors.pmmOrange};
	padding: 45px 20px 20px;
	margin: 0 0 15px;
	@media(min-width: 425px) {
		width: 260px;
	}
	@media(min-width: 768px) {
		//min-height: calc(30% + 45px);
		min-height: calc(360px + 29px);
		width: 280px;
		padding: 0px 30px 25px;
		margin-bottom: 30px;
	}
	@media(min-width: 1280px) {
		padding: 0px 30px 25px;
		//min-height: calc(40% + 29px);
		min-height: calc(360px + 19px);
		width: 252px;
	}
	@media(min-width: 1366px) {
		padding: 0px 20px 30px;
	}
	@media(min-width: 1920px) {
		width: 20%;
		padding: 0px 30px 65px;
		//min-height: calc(55% + 40px);
		min-height: calc(460px + 39px);
	}
	@media(min-width: 2100px) {
		//min-height: calc(40% + 50px);
	}
`;

const BackToAllWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding: 20px 0 50px;
	cursor: pointer;
	width: 100%;
	position: absolute;
	top: -70px;
	text-decoration: none;
	@media(min-width: 768px) {
		position: relative;
		top: 0;
	}
	@media(min-width: 1920px) {
		padding-bottom: 80px;
		padding-top: 30px;
	}
`;



const Header = styled.h3`
	color: ${({theme}) => theme.colors.pmmWhite};
	text-transform: uppercase;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		top: -10px;
		left: 0;
		width: 60px;
		height: 3px;
		background-color: ${({theme}) => theme.colors.pmmWhite};
		@media(min-width: 768px) {
			top: -15px;
			width: 80px;
			height: 4px;
		}
		@media(min-width: 1920px) {
			width: 100px;
			height: 5px;
			top: -25px;
		}
	}	
`;

const Description = styled.p`
	color: ${({theme}) => theme.colors.pmmWhite};
	margin: 20px 0 0;
	min-height: 119px;
	@media(min-width: 768px) {
		min-height: 190px;
		margin-top: 40px;
	}
	@media(min-width: 1280px) {
		min-height: 180px;
		margin-top: 30px;
	}
	@media(min-width: 1366px) {
		min-height: 189px;
	}
	@media(min-width: 1920px) {
		margin-top: 60px;
		min-height: 200px;
	}
`;

class SubpageBannerWithBack extends Component {

	render() {
		const SubpageBannerWithBack = this.props.data;
		const {projectsBackBtn, szkolenie, activeSite, hasSubmenu} = this.props;
		const courseBackBtn = (szkolenie && szkolenie[0] && szkolenie[0].przyciskPowrot && szkolenie[0].przyciskPowrot[0] && szkolenie[0].przyciskPowrot[0].przyciskWpis &&
							   szkolenie[0].przyciskPowrot[0].przyciskWpis[0] && szkolenie[0].przyciskPowrot[0].przyciskWpis[0].slug) || "";
		const courseBackBtnText = (szkolenie && szkolenie[0] && szkolenie[0].przyciskPowrot && szkolenie[0].przyciskPowrot[0] && szkolenie[0].przyciskPowrot[0].przyciskNapis) || "";
		const projectsBackBtnData = (projectsBackBtn && projectsBackBtn[0] && projectsBackBtn[0].przyciskPowrot && projectsBackBtn[0].przyciskPowrot && projectsBackBtn[0].przyciskPowrot[0]) || ""
		const subpageData = (SubpageBannerWithBack.modulBanerPodstronaProjektu && SubpageBannerWithBack.modulBanerPodstronaProjektu[0]) || ""
		const header = subpageData.naglowek || "";
		const description = subpageData.opis|| "";
		const backgroundPhotoMobile = (subpageData.zdjecieWTleMobile && subpageData.zdjecieWTleMobile[0] &&
			subpageData.zdjecieWTleMobile[0].url) || "";
		const backgroundPhoto = (subpageData.zdjecieWTle && subpageData.zdjecieWTle[0] &&
			subpageData.zdjecieWTle[0].url) || "";
		const backgroundPhotoMobileLandscape = (subpageData.zdjecieWTleMobilePoziome && subpageData.zdjecieWTleMobilePoziome[0] &&
			subpageData.zdjecieWTleMobilePoziome[0].url) || "";

		const textBtn = (projectsBackBtnData && projectsBackBtnData.przyciskNapis) || ""
		const toBtn = (projectsBackBtnData && projectsBackBtnData.przyciskWpis && projectsBackBtnData.przyciskWpis[0] && projectsBackBtnData.przyciskWpis[0].slug) || ""
		const hrefBtn = (projectsBackBtnData && projectsBackBtnData.przyciskLink) || ""
		const iconBtn = (projectsBackBtnData && projectsBackBtnData.przyciskIkona && projectsBackBtnData.przyciskIkona[0] && projectsBackBtnData.przyciskIkona[0].url) || ""
		const iconHoverBtn = (projectsBackBtnData && projectsBackBtnData.przyciskIkonaPoNajechaniu && projectsBackBtnData.przyciskIkonaPoNajechaniu[0] && projectsBackBtnData.przyciskIkonaPoNajechaniu[0].url) || ""
		const finalToBtn = (hasSubmenu) ? toBtn : courseBackBtn;
		const finalTextBtn = (hasSubmenu) ? textBtn :courseBackBtnText;
		return (
			<ModuleWrapper bgImg={backgroundPhoto} bgImgMobile={backgroundPhotoMobile}
						   bgImgMobileLandscape={backgroundPhotoMobileLandscape}>
				<TextBoxWrapper>
					<BackToAllWrapper>
						<BtnWrapperForBgPreload iconHoverBtn={iconHoverBtn}>
							{hrefBtn && <BtnWithIcon href={hrefBtn} iconBtn={iconBtn} iconHoverBtn={iconHoverBtn}
													 as={"a"} className='dark-violet'>{textBtn}</BtnWithIcon>}
							{toBtn && <BtnWithIcon as={Link} iconBtn={iconBtn} iconHoverBtn={iconHoverBtn}
												   to={`/${getSlug(finalToBtn, activeSite)}`} className='dark-violet'>{finalTextBtn}</BtnWithIcon>}
							{!hrefBtn && !toBtn &&
							<BtnWithIcon iconBtn={iconBtn} iconHoverBtn={iconHoverBtn} className='dark-violet'>{textBtn}</BtnWithIcon>}
						</BtnWrapperForBgPreload>
					</BackToAllWrapper>
					<Header className="negative-letter-spacing">{header}</Header>
					<Description className="subpage-paragraph">{description}</Description>
				</TextBoxWrapper>
			</ModuleWrapper>
		)
	}
}

export default SubpageBannerWithBack;
