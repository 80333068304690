import React, {Component} from 'react'
import styled from "styled-components"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";
import Button from "../common/ButtonsSet";

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
  	background-color: ${({theme}) => theme.colors.pmmWhite};
	padding: 40px 30px;
	@media(min-width: 1280px) {
		padding: 30px 50px;
	}
	@media(min-width: 1920px) {
		padding: 40px 100px;
	}
`;

const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 45%;
`;

const Header = styled.h3`
	position: relative;
	text-align: left;
	width: 100%;
	padding-bottom: 60px;
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
`;

const DescriptionAndTextBoxWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding-bottom: 50px;
`;

const DescriptionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 48%;
`;

const RedactorDescription = styled.div`
	position: relative;
	text-align: left;
	width: 100%;
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	span {  
	  &.black {
	      font-weight: ${({theme}) => theme.fontWeight.black};
	  }
	}
`;

const TextBoxWrapper = styled.div`
	width: 45%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`;

const TextBox = styled.div`
	width: 100%;
	min-height: 550px;
	border: 3px solid ${({theme}) => theme.colors.pmmOrange};
	padding: 45px 110px 45px 80px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const SingleInfoWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 100%;
	padding: 5px 0;
`;
const PictogramWrapper = styled.div`
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: center;
	padding: 0 20px;
	
`;
const Pictogram = styled.img`
	width: 32px;
	height: 32px;
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const Placeholder = styled.p`
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	text-transform: uppercase;
`;

const Info = styled.p`
	font-size: 24px;
	line-height: 40px;
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
`;

class AboutCourse extends Component {

    render() {
        const AboutCourse = this.props.data;
        const GlobalCourses = this.props.szkolenie;
        const GlobalCoursesData = (GlobalCourses && GlobalCourses[0]) || "";
        const AboutCourseData = (AboutCourse.modulOKursie && AboutCourse.modulOKursie[0]) || '';
        const AboutCourseHeader = (AboutCourseData && AboutCourseData.naglowek) || "";
        const AboutCourseDescription = (AboutCourseData && AboutCourseData.opis) || "";
        const AboutCourseSignInLink = (AboutCourseData && AboutCourseData.zapiszSieLink) || "";
        const AboutCourseChooseData = (AboutCourseData && AboutCourseData.wybierzSzkolenie && AboutCourseData.wybierzSzkolenie[0]) || "";

        // const title = AboutCourseChooseData.szkolenieWizytowkaTytul || "";
        const date = AboutCourseChooseData.szkolenieWizytowkaTermin || "";
        const place = AboutCourseChooseData.szkolenieWizytowkaMiejsce || "";
        const wholeAddress = AboutCourseChooseData.szkolenieWizytowkaAdresDokladny || "";
        const seatsLeft = AboutCourseChooseData.szkolenieWizytowkaPozostalo || "";
        const allSeats = AboutCourseChooseData.szkolenieWizytowkaWszystkichMiejsc || "";
        const price = AboutCourseChooseData.szkolenieWizytowkaCena || "";
        // const slug = AboutCourseChooseData.slug || "";

        const finalAddress = (wholeAddress !== "") ? wholeAddress : place;

        const datePlaceholder = GlobalCoursesData.szkolenieWizytowkaTerminPodpis || "";
        const placePlaceholder = GlobalCoursesData.szkolenieWizytowkaMiejscePodpis || "";
        const seatsLeftPlaceholder = GlobalCoursesData.szkolenieWizytowkaPozostaloPodpis || "";
        const pricePlaceholder = GlobalCoursesData.szkolenieWizytowkaCenaPodpis || "";
        const datePictogram = (GlobalCoursesData.szkolenieWizytowkaTerminPiktogram && GlobalCoursesData.szkolenieWizytowkaTerminPiktogram[0] &&
            GlobalCoursesData.szkolenieWizytowkaTerminPiktogram[0].url) || "";
        const placePictogram = (GlobalCoursesData.szkolenieWizytowkaMiejscePiktogram && GlobalCoursesData.szkolenieWizytowkaMiejscePiktogram[0] &&
            GlobalCoursesData.szkolenieWizytowkaMiejscePiktogram[0].url) || "";
        const seatsLeftPictogram = (GlobalCoursesData.szkolenieWizytowkaPozostaloPiktogram && GlobalCoursesData.szkolenieWizytowkaPozostaloPiktogram[0] &&
            GlobalCoursesData.szkolenieWizytowkaPozostaloPiktogram[0].url) || "";
        const pricePictogram = (GlobalCoursesData.szkolenieCenaPiktogram && GlobalCoursesData.szkolenieCenaPiktogram[0] &&
            GlobalCoursesData.szkolenieCenaPiktogram[0].url) || "";
        const textBtn = GlobalCoursesData.szkoleniePrzyciskNapisZapiszSie || "";
        const typeBtn = (GlobalCoursesData.szkoleniePrzyciskRodzajZapiszSie && GlobalCoursesData.szkoleniePrzyciskRodzajZapiszSie[0] &&
            GlobalCoursesData.szkoleniePrzyciskRodzajZapiszSie[0].title) || "";

        return (
            <ModuleWrapper>
                <HeaderWrapper>
                    <Header>{AboutCourseHeader}</Header>
                </HeaderWrapper>
                <DescriptionAndTextBoxWrapper>
                    <DescriptionWrapper>
                        <RedactorDescription className="paragraph text-in-border"
                                             dangerouslySetInnerHTML={getHtmlFromRedactorField(AboutCourseDescription)}/>
                    </DescriptionWrapper>
                    <TextBoxWrapper>
                        <TextBox>
                            {date && <SingleInfoWrapper>
                                <PictogramWrapper>
                                    <Pictogram src={datePictogram}/>
                                </PictogramWrapper>
                                <TextContainer>
                                    <Placeholder className="h8 black">{datePlaceholder}</Placeholder>
                                    <Info className="h7 regular">{date}</Info>
                                </TextContainer>
                            </SingleInfoWrapper>}
                            {finalAddress && <SingleInfoWrapper>
                                <PictogramWrapper>
                                    <Pictogram src={placePictogram}/>
                                </PictogramWrapper>
                                <TextContainer>
                                    <Placeholder className="h8 black">{placePlaceholder}</Placeholder>
                                    <Info className="h7 regular">{finalAddress}</Info>
                                </TextContainer>
                            </SingleInfoWrapper>}
                            {(seatsLeft && allSeats) && <SingleInfoWrapper>
                                <PictogramWrapper>
                                    <Pictogram src={seatsLeftPictogram}/>
                                </PictogramWrapper>
                                <TextContainer>
                                    <Placeholder className="h8 black">{seatsLeftPlaceholder}</Placeholder>
                                    <Info className="h7 regular">{seatsLeft} / {allSeats}</Info>
                                </TextContainer>
                            </SingleInfoWrapper>}
                            {price && <SingleInfoWrapper>
                                <PictogramWrapper>
                                    <Pictogram src={pricePictogram}/>
                                </PictogramWrapper>
                                <TextContainer>
                                    <Placeholder className="h8 black">{pricePlaceholder}</Placeholder>
                                    <Info className="h7 black">{price}</Info>
                                </TextContainer>
                            </SingleInfoWrapper>}
                            <ButtonWrapper>
                                <Button btnType={typeBtn} href={AboutCourseSignInLink}
                                        textBtn={textBtn}/>
                            </ButtonWrapper>
                        </TextBox>
                    </TextBoxWrapper>
                </DescriptionAndTextBoxWrapper>
            </ModuleWrapper>
        )
    }
}

export default AboutCourse;
