import React, {Component} from 'react'
import styled from "styled-components"
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";
import ContactForm from "./ContactForm";

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: ${({theme}) => theme.colors.pmmWhite};
	padding-right: 15px;
	padding-left: 15px;
	@media(min-width: 450px) {
		padding-right: 30px;
		padding-left: 30px;
	}
	@media(min-width: 1280px) {
		flex-direction: row-reverse;
		justify-content: space-between;
        padding-right: 50px;
		padding-left: 50px;
	}
	@media(min-width: 1920px) {
		padding-right: 100px;
		padding-left: 100px;
	}
`;

const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	width: 100%;
	height: 100vh;
	margin: 0px 0 15px;
	@media(min-width: 768px) {
		margin-bottom: 30px;
		height: auto;
		min-height: 360px;
		justify-content: flex-end;
	}
	@media(min-width: 1280px) {
		width: 252px;
		min-height: 350px;
	}
	@media(min-width: 1366px) {
		width: 252px;
		min-height: 360px;
	}
	@media(min-width: 1920px) {
		width: 344px;
		min-height: 460px;
	}
`;

const FormContainer = styled.div`
	display: none;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	&.mobile-visible {
		display: flex;
		@media(min-width: 768px) {
			display: none;
		}
	}
	@media(min-width: 768px) {
		display: flex;
		padding: 0 65px;
	}
	@media(min-width: 1280px) {
		width: 70%;
		justify-content: center;
	}
`;

const TextBoxWrapper = styled.div`
	min-height: 240px;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	z-index: 100;
	margin-top: 90px;
	border: 3px solid ${({theme}) => theme.colors.pmmOrange};
	background-color: transparent;
	padding: 40px 120px 26px 20px;
	@media(min-width: 425px) {
		padding-right: 140px;
	}
	@media(min-width: 450px) {
		width: 260px;
		padding: 50px 42px 30px 25px;
	}
	@media(min-width: 768px) {
		width: 280px;
		min-height: 360px;
		margin-top: 45px;
	}
	@media(min-width: 1280px) {
		display: flex;
		margin-top: 40px;
		width: 100%;
		//padding: 70px 105px 25px 20px;
	}
	@media(min-width: 1920px) {
		width: 100%;
		min-height: 460px;
		padding: 90px 42px 70px 35px;
	}
	@media(min-width: 2100px) {
	}
`;

const Header = styled.h3`
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	text-transform: uppercase;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		top: -10px;
		left: 0;
		width: 60px;
		height: 3px;
		background-color: ${({theme}) => theme.colors.pmmDarkestBlue};
		@media(min-width: 768px) {
			top: -15px;
			width: 80px;
			height: 4px;
		}
		@media(min-width: 1920px) {
			width: 100px;
			height: 5px;
			top: -25px;
		}
	}	
`;

const Description = styled.p`
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	font-weight: ${({theme}) => theme.fontWeight.bold};
	font-size: 13px;
	line-height: 22px;
	&.address {
		padding-top: 20px;
		font-weight: ${({theme}) => theme.fontWeight.medium};
		max-width: 90%;
		@media(min-width: 375px){
			max-width: 84%;
		}
		@media(min-width: 400px) {
			max-width: 75%;
		}
		@media(min-width: 450px) {
			max-width: 100%;
		}
		@media(min-width: 768px) {
			padding-top: 50px;
			padding-bottom: 30px;
		}
	}
	@media(min-width: 768px) {
		font-size: 16px;
		line-height: 25px;
	}
	@media(min-width: 1920px ) {
		font-size: 20px;
		line-height: 29px;
		padding: 0;
	}
`;

const TemporaryFormWrapper = styled.div`
	padding: 20px 15px 100px;
	@media(min-width: 425px) {
		padding: 20px 30px 100px;
	}
	@media(min-width: 768px) {
		display: none;
	}
`;

const HeaderTemporary = styled.h3`
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
	position: relative;
	padding: 90px 12px 0px;
	@media(min-width: 768px) {
		display: none;
	}	
`;

const DescriptionTemporary = styled.p`
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	padding: 15px 12px 20px;
	@media(min-width: 768px) {
		display: none
	}
`;

class Contact extends Component {
	state = {
		isOldBrowserEdge: false,
		currentImageIndex: 0,
	};

	componentDidMount() {
		if (typeof window !== "undefined") {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({isOldBrowserEdge: isOldBrowser});
		}
	}

	render() {
		const contactModule = this.props.data;
		const {processingPersonalData} = this.props;
		const formData = this.props.formularz;
		const contactModuleData = (contactModule.modulKontakt) || [];
		const contactFormData = [];
		let paddingTop = '';
		let paddingBottom = '';
		let contactHeader;
		let contactEmail;
		let contactAddress;
		let contactPhone;

		contactModuleData.forEach(item => {
			if(item.__typename === "Craft_modulKontakt_marginesy_BlockType") {
				paddingTop = (item.marginesGorny && item.marginesGorny[0] && item.marginesGorny[0].title) || ''
				paddingBottom = (item.marginesDolny && item.marginesDolny[0] && item.marginesDolny[0].title) || ''
			} else if (item.__typename === "Craft_modulKontakt_daneKontaktowe_BlockType") {
				contactHeader = item.naglowek || '';
				contactEmail = item.email || '';
				contactAddress = item.adres || '';
				contactPhone = item.telefon || '';

			} else if (item.__typename === "Craft_modulKontakt_formularzKontaktowy_BlockType")  {
				contactFormData.push(item)
			}
		});
		const formHeader = (contactFormData && contactFormData[0] && contactFormData[0].naglowek) || '';
		const formDescription = (contactFormData && contactFormData[0] && contactFormData[0].opis)|| '';
		const processingData = (processingPersonalData && processingPersonalData[0] && processingPersonalData[0].informacjaOPrzetwarzaniuDanychOsobowych
			&& processingPersonalData[0].informacjaOPrzetwarzaniuDanychOsobowych[0]) || '';
		return (
			<>
				<ModuleWrapper paddingTop={getPaddingTop(paddingTop)}
				               paddingBottom={getPaddingBottom(paddingBottom)}>
					<FormContainer>
						{contactFormData.map((item, key) =>
							<ContactForm processingData={processingData} key={key} formFieldsData={item} formData={formData}/>)}
					</FormContainer>
					<InfoContainer>
						<HeaderTemporary>{formHeader}</HeaderTemporary>
						<DescriptionTemporary className="custom-lh">{formDescription}</DescriptionTemporary>
						<TextBoxWrapper>
							<Header className="negative-letter-spacing">{contactHeader}</Header>
							<Description className="address subpage-paragraph">{contactAddress}</Description>
							<Description className="subpage-paragraph">{contactPhone}</Description>
							<Description className="subpage-paragraph">{contactEmail}</Description>
						</TextBoxWrapper>
					</InfoContainer>
				</ModuleWrapper>
				<TemporaryFormWrapper>
					<FormContainer className="mobile-visible">
					{contactFormData.map((item, key) =>
							<ContactForm processingData={processingData} key={key} formFieldsData={item} formData={formData}/>)}
					</FormContainer>
				</TemporaryFormWrapper>
		</>
		)
	}
}

export default Contact;
