import React, {Component} from 'react'
import styled from "styled-components"

const SinglePersonWrapper = styled.div`
	width: 100%;
	position: relative;
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	@media(min-width: 500px) {
		width: 75%;
	}
	@media(min-width: 550px) {
		width: 70%;
	}
	@media(min-width: 600px) {
		width: 48%;
		margin-top: 30px;
	}
	@media(min-width: 768px) {
		&:nth-child(2n) {
		div {
			span {
				div{
						//left: calc((100vw - 60px) * (0.52) * (-1) )
						left: 0;
					}
			}
		}
		}
	}
	@media(min-width: 1024px) {
		width: 30%;
		margin-right: 5%;
		&:nth-child(2n-1) {
			margin-right: 5%;
		}
		&:nth-child(2n) {
		div {
			span {
				div{
						left: 0;
					}
			}
		}
		}
		&:nth-child(3n) {
			margin-right: 0;
			div {
			span {
				div {
					//left: calc((100vw - 60px) * (0.3 + 0.05) * (-1) )
					left: 0;
					}
			}
		}
		}
	}
	@media(min-width: 1280px) {
		&:nth-child(3n) {
			div {
			span {
				div {
					//left: calc((100vw - 100px) * (0.3 + 0.05) * (-1) )
					left: 0;
					}
			}
		}
		}
	}
	@media(min-width: 1366px) {
		width: 23%;
		margin-top: 20px;
		margin-right: 2.5%;
		&:nth-child(2n-1) {
			margin-right: 0
		}
		&:nth-child(3n) {
			margin-right: 2.5%;
			div {
			span {
				div {
					left: 0;
					}
			}
		}
		}
		&:nth-child(4n) {
			margin-right: 0;
			div {
			span {
				div {
					//left: calc((100vw - 100px) * (0.23 + 0.025) * (-1) )
					left: 0;
					}
			}
		}
		}
		&:nth-child(4n-3) {
			margin-right: 2.5%;
		}
	}
	@media(min-width: 1920px) {
		&:nth-child(4n) {
			div {
				span {
					div{
							//left: calc(((100vw - 200px) * (0.23 + 0.025)) * (-1) )
							left: 0;
						}
				}
			}
	}
	}
`;

const PictureZoomedContainer = styled.div`
	display: flex;
	//flex-direction: row;
	//justify-content: flex-start;
	position: relative;
	background: none;
	//margin-bottom: 30px;
	overflow: hidden;
	width: 100%;
	height: 500px;
    @media(min-width: 425px) {
    	height: 600px;
    }
    @media(min-width: 500px) {
    	height: 520px;
    }
    @media(min-width: 600px) {
    	height: 420px;
    }
    @media(min-width: 700px) {
    	height: 470px;
    }
    @media(min-width: 768px) {
    	height: 550px;
    }
    @media(min-width: 900px) {
    	height: 650px;
    }
    @media(min-width: 1024px) {
    	height: 500px;
    }
    @media(min-width: 1280px) {
    	height: 600px;
    }
    @media(min-width: 1366px) {
    	height: 500px;
    }
    @media(min-width: 1500px) {
    	height: 500px;
    }
    @media(min-width: 1800px) {
    	height: 600px;
    }
    @media(min-width: 2000px) {
    	height: 750px;
    }

`;

const PictureWrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	cursor: pointer;
	transition: transform .4s;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url( ${({ bgImg }) => bgImg});
    &:hover {
    	transform: scale(1.1);
    }   
`;

const PersonDescription = styled.div`
	position: absolute;
	width: 100%;
	cursor: pointer;
	background-color: ${({theme}) => theme.colors.pmmWhite};
	border: 3px solid ${({theme}) => theme.colors.pmmOrange};
	line-height: 34px;
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	font-weight: ${({theme}) => theme.fontWeight.medium};
	display: none;
	z-index: -1;
	bottom: 0;
	left: 0;
	height: 600px;
	//overflow-y: scroll;
	::-webkit-scrollbar {
    	display: none;
	}
    @media(min-width: 768px) {
    	padding: 30px 30px 50px;
    	//font-size: 16px;
        font-size: 16px;
		line-height: 26px;
    	height: calc(550px + 59px - 15px - 15px + 5px);		
		//width: calc(100vw - 60px + 3px);
		width: calc((100vw - 60px) * (0.48*1) + 3px);
		display: ${({ isVisible }) => isVisible === true ? "block" : "none"};
    }
    @media(min-width: 900px) {
        height: calc(650px + 59px - 15px - 15px + 5px);		
		//width: calc(100vw - 60px + 3px);
    }
    @media(min-width: 1024px) {
    	padding: 20px;
    	font-size: 15px;
    	line-height: 23px;
    	// height = (wysokosc zdjecia + wysokość pola z imieniem - margin input boxa - padding pola z imieniem + szerokosc bordera)
        height: calc(500px + 59px - 15px - 15px + 5px);	
        // width = ((szerokosc ekranu - paddingi boczne wrappera) * (szerokosc jednego wrappera osoby * liczba osob które ma przykrywać) + szerokość bordera	
		//width: calc((100vw - 60px) * (0.3*2 + 0.05) + 3px);
		width: calc((100vw - 60px) * (0.3*1) + 3px);
    }
    @media(min-width: 1280px) {
        padding: 50px 30px;
        font-size: 16px;
		line-height: 26px;
        height: calc(600px + 59px - 15px - 15px + 5px);		
		//width: calc((100vw - 100px) * (0.3*2 + 0.05) + 3px);
		width: calc((100vw - 100px) * (0.3*1) + 3px);
    }
    @media(min-width: 1366px) {
		padding: 20px;
    	font-size: 15px;
    	line-height: 24px;
        height: calc(500px + 59px - 15px - 15px + 5px);		
		//width: calc((100vw - 100px) * (0.23*2 + 0.025) + 3px);
		width: calc((100vw - 100px) * (0.23*1) + 3px);
    }
    @media(min-width: 1500px) {
    	padding: 25px 30px;
    	//line-height: 34px;
    }
    @media(min-width: 1650px) {
        font-size: 16px;
    	line-height: 26px;
    }
    @media(min-width: 1800px) {
    	padding: 50px 30px;
        font-size: 18px;
		line-height: 28px;
		height: calc(600px + 59px - 15px - 15px + 5px);		
		//width: calc((100vw - 100px) * (0.23*2 + 0.025) + 3px);
		width: calc((100vw - 100px) * (0.23*1) + 3px);
	}
	@media(min-width: 1920px) {
		padding: 50px 30px 70px;
		height: calc(600px + 59px - 15px - 15px + 5px);		
		//width: calc((100vw - 200px) * (0.23*2 + 0.025) + 3px);
		width: calc((100vw - 200px) * (0.23*1) + 3px);
	}
    @media(min-width: 2000px) {
    	height: calc(750px + 59px - 15px - 15px + 5px);	
    }
`;

const InfoWrapper = styled.div`
	max-width: 100%;
	margin-top: -15px;
`;

const PersonName = styled.span`
	font-size: 16px;
	line-height: 32px;
	padding: 15px;
	position: relative;
	background-color: ${({theme}) => theme.colors.pmmOrange};
	color: ${({theme}) => theme.colors.pmmWhite};
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	font-weight: ${({theme}) => theme.fontWeight.bold};
	display: inline;
	@media(min-width: 768px) {
		&.show {
			z-index: 501;
			background-color: ${({theme}) => theme.colors.pmmDarkestBlue};
		}
	}
	@media(min-width: 1280px) {
		font-size: 18px;
	}
	@media(min-width: 1920px) {
		font-size: 24px;
		line-height: 32px;
	}
`;

const PersonPosition = styled.p`
	font-size: 20px;
	line-height: 34px;
	padding-left: 15px;
	margin-top: 20px;
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	font-weight: ${({theme}) => theme.fontWeight.regular};
`;


class SingleManagementPerson extends Component {
	state = {
		isOldBrowserEdge: false,
		currentImageIndex: 0,
		isDescriptionVisible: false,
	};

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickIfOutsideSelectWindow, false)
		if (typeof window !== "undefined") {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({isOldBrowserEdge: isOldBrowser});
		}
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickIfOutsideSelectWindow, false)
	}

	handleClickIfOutsideSelectWindow = (e) => {
		if (this.node.contains(e.target)) {
			return;
		}
		this.setState({isDescriptionVisible: false});
	};

	toggleDescriptionVisibility() {
		this.setState({isDescriptionVisible : !this.state.isDescriptionVisible })
	}

	render() {
		const {personImage, personName, personPosition, personDescription, key} = this.props;
		const descriptionVisibilityClass = (this.state.isDescriptionVisible) ? "show" : '';

		return (

			<SinglePersonWrapper key={key} onClick={this.toggleDescriptionVisibility.bind(this)}>
				<PictureZoomedContainer>
					<PictureWrapper key={key} bgImg={personImage}/>
				</PictureZoomedContainer>
				<InfoWrapper key={key}>
					<PersonName key={key} className={descriptionVisibilityClass}>{personName}
						<PersonDescription key={key} isVisible={this.state.isDescriptionVisible} ref={node => this.node = node} >
							{personDescription}
						</PersonDescription>
					</PersonName>
					<PersonPosition>{personPosition}</PersonPosition>
				</InfoWrapper>
			</SinglePersonWrapper>
		)
	}
}


export default SingleManagementPerson;
