import React, {Component} from 'react';
import Button from "../common/ButtonsSet";
import getSlug from "../../utils/getSlug";

class ContentBtn extends Component {
	render() {
		const { data, activeSite } = this.props

		const btnType = (data && data.przyciskRodzaj && data.przyciskRodzaj[0] && data.przyciskRodzaj[0].title) || ""
		const btnTo = (data && data.przyciskWpis && data.przyciskWpis[0] && data.przyciskWpis[0].slug) || ""
		const btnHref = (data && data.przyciskLink) || ""

		return (
			<div>
				<Button href={btnHref} btnType={btnType} to={`/${getSlug(btnTo, activeSite)}`} textBtn={data.przyciskNapis}/>
			</div>
		);
	}
}


export default ContentBtn;
