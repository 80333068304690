const validationForNumbers = (e) => {
			let key = e.keyCode || e.which;
			key = String.fromCharCode(key);
			let regex = /[0-9]|\./;
			if (!regex.test(key)) {
				e.returnValue = false;
				if (e.preventDefault) e.preventDefault();
			}
		}

export default validationForNumbers
