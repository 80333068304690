import * as moment from "moment"
import "moment/locale/pl"

const getDateInProperFormat = (pageContext, createdDate) => {
const activeSite = pageContext.site

	if (activeSite === "pl") {
		moment.locale("pl")
	} else {
		moment.locale("en")
	}

	return moment(new Date(createdDate)).format("DD MMMM YYYY")
}

export default getDateInProperFormat

