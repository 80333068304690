import React from "react"
import styled from "styled-components"
import Moment from "react-moment"
import 'moment/locale/pl'
import {Link} from "gatsby";
import getSlug from "../../utils/getSlug";


const SingleNewsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin: 20px 0 40px;
	cursor: pointer;
	text-decoration: none;
	width: 100%;
	@media(min-width: 768px){
		width: 48%;
		&:nth-child(2n-1) {
			margin-right: 4%;
		}
	}
	@media(min-width: 1280px){
		width: 30%;
		margin-right: 5%;
		margin-bottom: 100px;
		&:nth-child(2n-1) {
			margin-right: 5%;
		}
		&:nth-child(3n) {
			margin-right: 0;
		}
	}
	@media(min-width: 1920px) {
		margin-bottom: 120px;
	}
`;

const PictureWrapper = styled.div`
	width: 100%;
	height: 200px;
	position: relative;
  	overflow: hidden;
  	@media(min-width: 375px){
  		height: 250px;
	}
	@media(min-width: 550px){
		height: 340px
	}
	@media(min-width: 650px){
		height: 400px;
	}
	@media(min-width: 768px){
		height: 280px;
	}
	@media(min-width: 1000px) {
		height: 330px;
	}
	@media(min-width: 1280px){
		height: 250px;
	}
	@media(min-width: 1366px){
		height: 300px;
	}
	@media(min-width: 1920px){
		height: 360px;
	}
`;

const ZoomedPicture = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	cursor: pointer;
    transition: transform .3s ease-in-out;
	background-size: cover;
  	background-position: center;
  	background-image: url( ${({ bgImg }) => bgImg});
  	&.animate {
	    transform: scale(1.1);
	    > div.blend {
	    	opacity: 0.15;
	    }
  	}
  	&:hover {
   		transform: ${({ isEdge}) => isEdge ? "scale(1)" : "scale(1.1)"};
   		> div.blend {
   			opacity: 0.15;
   		}
    }  
`;

const Blend = styled.div`
	width: 100%;
	height: 100%;
	background: ${({theme}) => theme.colors.pmmDarkViolet};
	opacity: 0;
`;

const DateWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-top: 10px;
	height: 21px;
	@media(min-width: 375px){
	}
	@media(min-width: 768px){
		margin-top: 20px;	
	}
	@media(min-width: 1280px){
	}
	@media(min-width: 1366px){
	}
	@media(min-width: 1920px){
	}
`;

const Date = styled(Moment)`
	font-size: 14px;
  	line-height: 16px;
 	color: ${({theme}) => theme.colors.pmmDarkBlue};
  	font-family: ${({ theme }) => theme.fontFamily.mainFont};
  	font-weight: ${({ theme }) => theme.fontWeight.medium};
	@media(min-width: 1920px){
		font-size: 18px;
		line-height: 21px;
	}
`;

const TitleWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-top: 20px;
	@media(min-width: 375px){
	}
	@media(min-width: 768px){
		margin-top: 27px;	
	}
	@media(min-width: 1280px){
	}
	@media(min-width: 1366px){
	}
	@media(min-width: 1920px){
	}
`;

const Title = styled.h3`
  	transition: color .4s ease;
 	color: ${({theme}) => theme.colors.pmmDarkBlue};
  	&.animate {
  		@media(min-width: 1024px) {
    		color: white;
    	}
    }
    &:hover {
    	@media(min-width: 1024px) {
    		color: white;
    	}
    }
`;

const Underline = styled.span`
    background-image: linear-gradient(180deg,transparent 7%,#FF4124 0);
    background-size: 0 100%;
    padding-right: 10px;
    background-repeat: no-repeat;
    text-decoration: none;
    transition: background-size .4s ease;
    &.animate {
    	background-size: 100% 100%;
	    cursor: pointer;
    }
`;


class SingleCard extends React.Component {
	state = {
		isNewsHovered: false,
		isOldBrowserEdge: false,
	};
	componentDidMount() {
		if (typeof window !== "undefined") {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({isOldBrowserEdge: isOldBrowser});
		}
	}
	triggerNewsAnimation() {
		this.setState({ isNewsHovered: !this.state.isNewsHovered })
	}

	render() {
		const {index, lang, data, activeSite} = this.props;
		const isNewsHovered = this.state.isNewsHovered;
		const isOldBrowserEdge = this.state.isOldBrowserEdge;
		const newsSlug = data.slug;

		const additionalClassName = (isNewsHovered && !isOldBrowserEdge) ? 'animate' : '';
		const additionalClassNameTitle = (isNewsHovered) ? 'animate' : '';
		const momentLocale = lang;

		return (
			<>
				<SingleNewsWrapper as={Link} to={`/${getSlug(newsSlug, activeSite)}`} key={index} onMouseOver={this.triggerNewsAnimation.bind(this)}
				                   onMouseOut={ this.triggerNewsAnimation.bind(this)}>
					<PictureWrapper>
						<ZoomedPicture className={additionalClassName}
						               isEdge={this.state.isOldBrowserEdge}
						               bgImg={data.imageUrl}>
							<Blend className="blend"/>
						</ZoomedPicture>
					</PictureWrapper>
					<DateWrapper>
						<Date format="DD MMM. YYYY" locale={momentLocale} date={data.dateCreate}/>
					</DateWrapper>
					<TitleWrapper>
						<Title className={additionalClassNameTitle}>
							<Underline className={additionalClassNameTitle}>
								{data.title}
							</Underline>
						</Title>
					</TitleWrapper>
				</SingleNewsWrapper>
			</>
		)
	}
}
export default SingleCard
