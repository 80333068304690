import React, {Component} from 'react'
import styled from "styled-components"

const ModuleWrapper = styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    background: none;
    padding-top: 150px;
    background-size: cover;
    &:before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: 1;
		//background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 10%,transparent 30%,transparent 70%, rgba(0, 0, 0, 0.4) 100%);
	}
    @media(orientation: landscape) and (max-width: 1024px) {
    	background-position: center;
  		background-image:url( ${({ bgImgMobileLandscape }) => bgImgMobileLandscape});
  	}
    @media(min-width: 1025px) {
		background-position: center;
    	background-image: url( ${({ bgImg }) => bgImg});
    }
    @media(min-width: 1280px) {
	    &:before {
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 11;
			//background-image: linear-gradient(to right, rgba(0, 0, 0, .4) 10%,transparent 40%);
		}
  	}
`;

const ImageWrappper = styled.img`
	height: 320px;
	@media(orientation: portrait) and (max-width: 1024px) and (min-width: 768px) {
  		height: 650px;
  	}
    @media(orientation: landscape) and (max-width: 1024px) and (min-width: 768px) {
  		display: none;
  	}
	@media(min-width: 1025px) {
		display: none;
	}
`;

const TextBoxWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: calc(100% - 30px);
	min-height: 240px;
	//min-height: 150px;
	position: absolute;
	left: 15px;
	bottom: 15px;
	z-index: 100;
	border: 3px solid ${({theme}) => theme.colors.pmmOrange};
	background-color: transparent;
	padding: 45px 20px 20px;
	@media(min-width: 450px) {
		left: 30px;
		width: 260px;
	}
	@media(min-width: 768px) {
		min-height: 360px;
		width: 280px;
		left: 30px;
		bottom: 30px;
		padding: 60px 30px 25px;
	}
	@media(min-width: 1280px) {
		left: 50px;
		padding-top: 70px;
		width: 252px;
	}
	@media(min-width: 1366px) {
		padding: 70px 20px 30px;
	}
	@media(min-width: 1920px) {
		width: 18%;
		left: 100px;
		padding: 90px 30px 65px;
		min-height: 460px; 
	}
	@media(min-width: 2100px) {
		//min-height: 40%;
	}
`;

const Header = styled.h3`
	color: ${({theme}) => theme.colors.pmmWhite};
	text-transform: uppercase;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		top: -10px;
		left: 0;
		width: 60px;
		height: 3px;
		background-color: ${({theme}) => theme.colors.pmmWhite};
		@media(min-width: 768px) {
			top: -15px;
			width: 80px;
			height: 4px;
		}
		@media(min-width: 1920px) {
			width: 100px;
			height: 5px;
			top: -25px;
		}
	}	
`;

const Description = styled.p`
	color: ${({theme}) => theme.colors.pmmWhite};
	margin: 20px 0 0;
	min-height: 119px;
	@media(min-width: 768px) {
		min-height: 190px;
		margin-top: 40px;
	}
	@media(min-width: 1280px) {
		min-height: 180px;
		margin-top: 30px;
	}
	@media(min-width: 1366px) {
		min-height: 189px;
	}
	@media(min-width: 1920px ) {
		min-height: 200px;
		margin-top: 60px;
	}

`;

class SubpageBannerMultipleImages extends Component {
	state = {
		currentImageIndex: 0,
		numberOfPhotos: 0,
	};

	componentDidMount() {
		this.setState({numberOfPhotos: 4});
	}

	render() {
		const SubpageBannerMultipleImages = this.props.data;
		const { currentImageIndex } = this.state;

		const header = (SubpageBannerMultipleImages.modulBanerZmianaZdjec && SubpageBannerMultipleImages.modulBanerZmianaZdjec[0] &&
			SubpageBannerMultipleImages.modulBanerZmianaZdjec[0].naglowek) || "";
		const description = (SubpageBannerMultipleImages.modulBanerZmianaZdjec && SubpageBannerMultipleImages.modulBanerZmianaZdjec[0] &&
			SubpageBannerMultipleImages.modulBanerZmianaZdjec[0].opis) || "";
		const backgroundPhoto = (SubpageBannerMultipleImages && SubpageBannerMultipleImages.modulBanerZmianaZdjec[0] &&
			SubpageBannerMultipleImages.modulBanerZmianaZdjec[0].zdjecieWTle[0] &&
			SubpageBannerMultipleImages.modulBanerZmianaZdjec[0].zdjecieWTle[0].url) || "";
		const backgroundPhotoMobileLandscape = (SubpageBannerMultipleImages && SubpageBannerMultipleImages.modulBanerZmianaZdjec[0] &&
			SubpageBannerMultipleImages.modulBanerZmianaZdjec[0].zdjecieWTleMobilePoziome[0] &&
			SubpageBannerMultipleImages.modulBanerZmianaZdjec[0].zdjecieWTleMobilePoziome[0].url) || "";
		const multiplePhotosArray = (SubpageBannerMultipleImages && SubpageBannerMultipleImages.modulBanerZmianaZdjec[0] &&
			SubpageBannerMultipleImages.modulBanerZmianaZdjec[0].zdjeciaZmienne) || "";


		var bgImageMultiple = multiplePhotosArray[currentImageIndex].url;

		function changePhoto() {
			if (currentImageIndex < multiplePhotosArray.length - 1) {
				bgImageMultiple = multiplePhotosArray[currentImageIndex + 1];
				this.setState({currentImageIndex : currentImageIndex + 1})
			} else {
				this.setState({currentImageIndex : 0})
			}
		}


		return (
			<ModuleWrapper onClick={changePhoto.bind(this)} bgImg={backgroundPhoto} bgMultipleImages={bgImageMultiple}
			               bgImgMobileLandscape={backgroundPhotoMobileLandscape}>
				<ImageWrappper src={bgImageMultiple}/>
				<TextBoxWrapper>
					<Header className="negative-letter-spacing">{header}</Header>
					<Description className="subpage-paragraph">{description}</Description>
				</TextBoxWrapper>
			</ModuleWrapper>
		)
	}
}

export default SubpageBannerMultipleImages;
