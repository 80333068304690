import React, {Component} from 'react';
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";
import Button from "../common/ButtonsSet";
import styled from "styled-components";
import {
	ArrowBackIcon, BackBtn,
	BackBtnWrapper, ColumnCenteredWrapper,
	CurrentSlideNumber,
	NumberOfSlides,
	SlidesCounterWrapper, StepWord,
	TabButtonWrapper
} from "./CashFlow";
import escapeDiacritics from "../../utils/escapeDacritics";

const Description = styled.div`
	font-weight: ${({theme}) => theme.fontWeight.medium};
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	font-size: 14px;
	line-height: 28px;
	margin-bottom: 50px;
	text-align: center;
	
	@media(min-width: 768px){
		text-align: left;
	}
	@media(min-width: 1280px){
		font-size: 16px;
	}
	@media(min-width: 1920px){
		font-size: 20px;
	}
`

class FirstTab extends Component {
	render() {
		const {projectNumber, bottomBtnText, bottomBtnType, allFirstTabsDataArray, nextTabMethod, isDesktop,
			   isMobileOptionSelected, backBtnIcon, slidesCounterData, stepWord, projectsPaymentIds} = this.props

		const btnTextForSeo = (bottomBtnText && bottomBtnText.replace(/\s+/g, '-').toLowerCase()) || ""
		const btnTextForSeoWithoutDiacritics = escapeDiacritics(btnTextForSeo) + '-krok-' + slidesCounterData.currentSlideNumber

		return (
			<>
				{(isDesktop || isMobileOptionSelected) &&
				<>
					<Description projectNumber={projectNumber}
								 className="paragraph text-in-border"
								 dangerouslySetInnerHTML={getHtmlFromRedactorField(allFirstTabsDataArray[projectNumber].description)}/>
					<TabButtonWrapper className="first-tab">
						<BackBtnWrapper className="hidden">
							<ArrowBackIcon src={backBtnIcon}/>
							<BackBtn className="orange"/>
						</BackBtnWrapper>
						<Button btnType={bottomBtnType}
								textBtn={bottomBtnText}
								onClick={nextTabMethod}
								name={projectsPaymentIds[projectNumber].seoName +'-' + btnTextForSeoWithoutDiacritics}
						/>
						<ColumnCenteredWrapper>
						<SlidesCounterWrapper className="first-tab">
							<StepWord>{stepWord} </StepWord>
							<CurrentSlideNumber>{slidesCounterData.currentSlideNumber}</CurrentSlideNumber>
							<NumberOfSlides>{slidesCounterData.allSlidesCount}</NumberOfSlides>
						</SlidesCounterWrapper>
						</ColumnCenteredWrapper>
					</TabButtonWrapper>
				</>}


			</>
		);
	}
}

FirstTab.propTypes = {};

export default FirstTab;
