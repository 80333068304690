import React, {Component} from 'react';
import styled from "styled-components";
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";

const CardWrapper = styled.div`
	padding: ${({paddingTop}) => paddingTop}px 10px ${({paddingBottom}) => paddingBottom}px;
	background-color: ${({theme}) => theme.colors.pmmWhite};
	display: flex;
	flex-direction: column;
	
	@media(min-width: 768px) {
		padding: ${({paddingTop}) => paddingTop}px 100px ${({paddingBottom}) => paddingBottom}px;
	}
	
	@media(min-width: 1280px) {
		padding: ${({paddingTop}) => paddingTop}px 350px ${({paddingBottom}) => paddingBottom}px;
	}
	@media (min-width: 1366px) {
		padding: ${({paddingTop}) => paddingTop}px 370px ${({paddingBottom}) => paddingBottom}px;
	}
	@media(min-width: 1920px) {
		padding: ${({paddingTop}) => paddingTop}px 530px ${({paddingBottom}) => paddingBottom}px;
	}
`

const PhotoAndDescriptionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	
	@media(min-width: 480px) {
		flex-direction: row;
  	}
`

const ContentWrapper = styled.div`
	padding: 0 10px 40px;
`
const Description = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-items: center;
 align-items: center;
  @media(min-width: 480px) {
	margin-top: 0;
	padding-left: 30px;
  }
  @media(min-width: 1280px) {
	width: 50%;
	margin-top: 0;
  }
  @media(min-width: 1920px) {
	width: 775px;
    padding-left: 85px;
  }
`

const ContactData = styled.h5`
	margin-top: 50px;
	line-height: 30px;

	@media(min-width: 768px) {
		align-items: center;
		justify-content: center;
  	}
`

const PhotoAndContactDataWrapper = styled.div`
	display: flex;
	flex-direction: column;
	
	@media(min-width: 768px) {
		flex-direction: row;
  	}
  	@media(min-width: 1920px) {
		min-width: 345px;
	}
`

const PictureWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	position: relative;
	background: none;
	overflow: hidden;
	width: 250px;
	height: 250px;
  
	@media(min-width: 1920px) {
		width: 345px;
		height: 345px;
	}
	@media(min-width: 2200px) {
		width: 370px;
		height: 370px;
	}
`

const ZoomedPicture = styled.div`
    position: absolute;
  	width: 250px;
	height: 250px;
    transition: transform .5s;
    background-size: cover;
    background-position: center;
    background-image: url( ${({bgImg}) => bgImg});
    background-repeat: no-repeat;
    &.animate {
   		transform: ${({ isOldBrowserEdge }) => isOldBrowserEdge ? "scale(1)" : "scale(1.1)"};
    }
    &:hover {
    	transform: ${({ isOldBrowserEdge }) => isOldBrowserEdge ? "scale(1)" : "scale(1.1)"};
    }  

  	@media(min-width: 1280px) {
		margin-bottom: 30px;
  	}
	@media(min-width: 1920px) {
		width: 345px;
		height: 345px;
  }
  	@media(min-width: 2200px) {
		width: 370px;
		height: 370px;
	}
`;

const PersonInfoWrapper = styled.div`
	border: 2px solid ${({theme}) => theme.colors.pmmOrange};
	margin-top: -30px;
	position: relative;
	width: 250px;
  	 @media(min-width: 1920px) {
		margin-bottom: 0;
		width: 345px;
  	}
  	@media(min-width: 2200px) {
		width: 370px;
	}
`
const TileImgDescription = styled.p`
	padding: 10px;
	background-color: ${({theme}) => theme.colors.pmmOrange};
	display: table;
`

const Position = styled.h5`
	padding-top: 10px;
`


class PhotoContactOnLeftWithDescription extends Component {
	state = {
		isOldBrowserEdge: false,
	};

	componentDidMount() {
		if (typeof window !== "undefined") {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({isOldBrowserEdge: isOldBrowser});
		}
	}

	render() {
		const {data} = this.props
		const isOldBrowserEdge = this.state.isOldBrowserEdge;
		const contactData = (data && data.modulZdjecieIKontaktPoLewejOpis) || []

		let photo = ""
		let photoDescription = ""
		let position = ""
		let contactDataDescription = ""
		let paddingTop = ""
		let paddingBottom = ""
		let description = ""

		contactData.forEach(item => {
			if (item.__typename === "Craft_modulZdjecieIKontaktPoLewejOpis_modul_BlockType") {
				photo =item?.zdjecie?.[0]?.url || ""
				photoDescription = item?.zdjeciePodpis || ""
				position = item?.zdjecieStanowisko || ""
				contactDataDescription = item?.daneKontaktowe || ""
				description = item?.opisPoPrawej || ""
			} else if (item.__typename === "Craft_modulKontaktZdjeciePoLewej_marginesy_BlockType") {
				paddingTop =item?.marginesGorny?.[0]?.title || ""
				paddingBottom = item?.marginesDolny?.[0]?.title || ""
			}
		})

		return (
			<CardWrapper paddingTop={getPaddingTop(paddingTop)} paddingBottom={getPaddingBottom(paddingBottom)}>
				<PhotoAndDescriptionWrapper>
					<div>

							<PhotoAndContactDataWrapper>
								<PictureWrapper>
									<ZoomedPicture isOldBrowserEdge={isOldBrowserEdge} bgImg={photo}/>
								</PictureWrapper>
							</PhotoAndContactDataWrapper>
							<PersonInfoWrapper>
								<TileImgDescription className="h7 white">{photoDescription}</TileImgDescription>
								<ContentWrapper>
									{position && <Position className="regular letter-spacing-0 dark-blue">{position}</Position>}
									<ContactData className="dark-blue semi-bold"
									             dangerouslySetInnerHTML={getHtmlFromRedactorField(contactDataDescription)}/>
								</ContentWrapper>
							</PersonInfoWrapper>

					</div>
					<Description className="paragraph text-in-border" dangerouslySetInnerHTML={getHtmlFromRedactorField(
						description)}/>
				</PhotoAndDescriptionWrapper>

			</CardWrapper>
		);
	}
}

export default PhotoContactOnLeftWithDescription;
