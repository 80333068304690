import React, {Component} from 'react';
import styled from "styled-components"
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";
import setBackgroundColor from "../../utils/setBackgroundColor";
import {setMarginsAndBg} from "../whoHelps/WhoHelps";
// import {BtnWrapperForBgPreload} from "../news/SingleNewsBanner";
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";
import {ZoomedPicture} from "../whereWeHelpCard/whereWeHelpCard";
import setFontColor from "../../utils/setFontColor";

const OurHistoryWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${({paddingTop}) => paddingTop}px 10px ${({paddingBottom}) => paddingBottom}px 0;
	background-color: ${({background, theme}) => setBackgroundColor(background, theme)};
	
	@media(min-width: 768px){
		padding: ${({paddingTop}) => paddingTop}px 35px ${({paddingBottom}) => paddingBottom}px 0;
	}
	@media(min-width: 1280px){
		padding: ${({paddingTop}) => paddingTop}px 55px ${({paddingBottom}) => paddingBottom}px 0;
	}
	@media(min-width: 1920px){
		padding: ${({paddingTop}) => paddingTop}px 100px ${({paddingBottom}) => paddingBottom}px 0;
	}
`

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	
	@media(min-width: 1280px){
		flex-direction: row;
	}
`

const TimelineWrapper = styled.div`
	width: calc(100% + 10px);
	position: relative;
	margin-bottom: 40px;
	@media(min-width: 768px){
		width: calc(100% + 35px);
	}
	@media(min-width: 1280px){
		width: calc(100% + 55px);
	}
	@media(min-width: 1920px){
		width: calc(100% + 100px);
	}
	
	ul {
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-height: 140px;
		width: 100%;
		padding: 0 10px;
		
		li {
			cursor: pointer;
			z-index: 3;
			color: ${({theme}) => theme.colors.pmmWhite};
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: ${({theme}) => theme.fontSize.f10};
			line-height: ${({theme}) => theme.lineHeight.line11};
			font-weight: ${({theme}) => theme.fontWeight.semiBold};
			width: 55px;

			&::before {
				content: '';
				position: absolute;
				width: 15px;
				height: 15px;
				border-radius: 50%;
				background-color: ${({theme}) => theme.colors.pmmOrange};
				transition: .3s ease-in-out;
			}
			span {
				text-align: center;
				transition: .2s ease-in-out;

				opacity: 0;
			}
			&.active {
				&::before {
					width: 45px;
					height: 45px;
				}
				span {
					transition-delay: .1s;
					opacity: 1;
					z-index: 1;
				}
				@media(min-width: 375px){
					&::before {
						width: 55px;
						height: 55px;
					}
				}
				@media(min-width: 768px){
					&::before {
						width: 105px;
						height: 105px;
					}
				}
				@media(min-width: 950px){
					&::before {
						width: 120px;
						height: 120px;
					}
				}
				@media(min-width: 1280px){
					&::before {
						width: 140px;
						height: 140px;
					}
				}
			}
			@media(min-width: 375px){
				font-size: ${({theme}) => theme.fontSize.f14};
				line-height: ${({theme}) => theme.lineHeight.line10};
			}
			@media(min-width: 768px){
				&::before {
					content: '';
					position: absolute;
					width: 20px;
					height: 20px;
					border-radius: 50%;
					background-color: ${({theme}) => theme.colors.pmmOrange};
					transition: .3s ease-in-out;
				}
			}
			@media(min-width: 768px){
				width: 120px;
				font-size: ${({theme}) => theme.fontSize.f6};
				line-height: ${({theme}) => theme.lineHeight.line1};
			}
			@media(min-width: 1280px){
				width: 140px;
			}
			@media(min-width: 1920px){
				font-size: ${({theme}) => theme.fontSize.f6};
			}
		}
	}
`

const Line = styled.div`
	position: absolute;
	width: 100%;
	height: 1px;
	top: 50%;
	// background-color: ${({background, theme}) => setFontColor(background, theme)};
	background-color: ${({theme}) => theme.colors.pmmDarkestBlue};
	transition: .3s linear;
		
	&::before {
		content: '';
		top: -1px;
		left: 0;
		position: absolute;
		width: 30px;
		height: 2px;
		background-color: ${({theme}) => theme.colors.pmmOrange};
		z-index: 2;
		@media(min-width: 375px) {
			width: 35px;
		}
		@media(min-width: 768px) {
			width: 60px;
		}
		@media(min-width: 1024px) {
			width: 70px;
		}
		@media(min-width: 1280px) {
			width: 80px;
		}
	}
	&::after {
		content: '';
		transition: .3s linear;
		top: -1px;
		left: 0;
		transform: scaleX(${props => props.coloredLineScale});
		transform-origin: left center;
		position: absolute;
		width: 100%;
		height: 2px;
		background-color: ${({theme}) => theme.colors.pmmOrange};
		z-index: 2;
	}
`
const PictureWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	position: relative;
	background: none;
	margin-bottom: 30px;
	overflow: hidden;
	width: 100%;
	height: 300px;
	@media(min-width: 768px) {
		height: 600px;
	}
	@media(min-width: 1280px) {
		margin: 0;
	}
	@media(min-width: 1920px) {
		height: 800px;
	}
`;

// const MapPictogramWrapper = styled.div`
// 	position: absolute;
// 	left: 0;
// 	bottom: 0;
// 	padding: 20px;
// 	display: none;
// 	flex-direction: column;
// 	justify-content: center;
// 	@media(min-width: 1280px) {
// 		display: flex;
// 	}
// `;
//
// const SinglePictogramContainer = styled.div`
// 	display: flex;
// 	flex-direction: row;
// 	width: 53px;
// 	height: 53px;
// 	position: relative;
// 	transition: transform 0.2s ease-in-out, display .2s ease-in-out;
// 	&:hover	{
// 		> div.pictogram-circle {
// 			transform: scale(1.113);
// 		}
// 		> div.map-picto{
// 			background-image: url( ${({bgImgHovered}) => bgImgHovered} );
// 	 	}
// 	 	> div.camera-picto{
// 	 		background-image: url( ${({bgImgHovered}) => bgImgHovered} );
// 	 	}
// 	 	> h6.show {
// 	 		opacity: 1;
// 	 	}
// 	}
// `;
//
// const PictogramBackCircle = styled.div`
// 	width: 53px;
// 	height: 53px;
// 	background-color: ${({theme}) => theme.colors.pmmWhite};
// 	position: absolute;
// 	cursor: pointer;
// 	z-index: 1;
// 	right: 0;
// 	border-radius: 50%;
// 	transition: transform 0.15s ease-in-out;
// `;
//
// const ViewMapPictogram = styled.div`
// 	position: absolute;
// 	z-index: 100;
// 	cursor: pointer;
// 	transition: transform 0s;
// 	background-position: center;
// 	background-image: url( ${({bgImg}) => bgImg} );
// 	&.map-picto{
// 		background-size: 33px 33px;
// 		width: 33px;
// 		height: 33px;
// 		top: 10px;
// 		left: 10px;
// 	}
// 	&.camera-picto{
// 		background-size: 32px 26px;
// 		width: 32px;
// 		height: 26px;
// 		top: 13px;
// 		left: 11px;
// 	}
// `;
//
// const ViewMapText = styled.h6`
// 	display: block;
// 	opacity: 0;
// 	position: absolute;
// 	cursor: pointer;
// 	top: 18px;
// 	left: 55px;
// 	padding-left: 15px;
// 	width: 200px;
// 	color: ${({theme}) => theme.colors.pmmWhite};
// 	transition: opacity .2s ease-in-out;
// `;

const SlideWrapper = styled.div`
	display: flex;
	top: -60px;
	margin: 0 auto;
	position: relative;
	justify-content: center;
	width: 100%;
	@media(min-width: 1280px) {
		top: 0;
		margin: 0 0 0 -40px;
		flex-direction: column;
	}
	@media(min-width: 1920px) {
		margin-left: -40px;
		justify-content: flex-start;
		padding: 50px 0 0;
	}
`;
const InfoSquare = styled.div`
	border: 3px solid ${({theme}) => theme.colors.pmmOrange};
	position: relative;
	padding: 40px 15px 35px 20px;
	width: 100%;
	@media(min-width: 768px) {
		padding: 40px 40px 50px 30px; 
	}
	@media(min-width: 1280px ) {
		margin: 0;
		width: 100%;
		height: 420px;
		padding: 40px 40px 40px 80px; 
	}
	@media(min-width: 1920px) {
		height: 550px;
		padding: 60px 80px 60px 60px;
		right: 0; 	
	}
`;

const SlideTitle = styled.h4`
	position: relative;
	text-transform: uppercase;
	color: ${({background, theme}) => setFontColor(background, theme)};
	
	@media(min-width: 768px ) {
		width: 100%;
	}
	@media(min-width: 1280px) {
		width: 70%;
	}
	@media(min-width: 1920px) {
		padding-bottom: 45px;
		width: 100%;
	}
`;

const FrameCaption = styled.div`
	position: absolute;
	//top: 100%;
	bottom: -30px;
	right: -4px;
	background-color: ${({theme}) => theme.colors.pmmDarkestBlue};
	color: ${({theme}) => theme.colors.pmmWhite};
	padding: 17px 30px;
	font-size: ${({theme}) => theme.fontSize.f6};
	line-height: ${({theme}) => theme.lineHeight.line50};
	font-weight: ${({theme}) => theme.fontWeight.medium};
	text-align: right;
	
	@media(min-width: 768px) {
		font-size: ${({theme}) => theme.fontSize.f5};
		bottom: -35px;
	}
	@media(min-width: 1920px) {
		font-size: ${({theme}) => theme.fontSize.f8};
		bottom: -47px;
	}
`


const SlideDescription = styled.div`
	color: ${({background, theme}) => setFontColor(background, theme)};
`;

class OurHistory extends Component {
	state = {
		isShowMapTriggered: false,
		isPictureHovered: false,
		isOldBrowserEdge: false,
		activeDateIndex: 0
	};

	componentDidMount() {
		if (typeof window !== "undefined") {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({isOldBrowserEdge: isOldBrowser});
		}
	}

	// togglePicture() {
	// 	this.setState({isShowMapTriggered: !this.state.isShowMapTriggered})
	// };

	getImage = () => this.state.isShowMapTriggered ? "map" : "picture";

	setActiveDate(key) {
		this.setState({activeDateIndex: key})
	}

	render() {

		const {data} = this.props
		const marginsAndBg = (data && data.marginesyIBackground) || []
		const ourHistoryArray = (data && data.modulNaszaHistoria) || []
		let {moduleBg, paddingTopType, paddingBottomType} = setMarginsAndBg(marginsAndBg)
		// let {mapPictogramDefault, mapPictogramHovered, photoPictogramDefault, photoPictogramHovered} = setPictogramsData(piktogramyData);
		// const isPictureHovered = this.state.isPictureHovered;
		const activeDateIndex = this.state.activeDateIndex;

		// const textOnHover = {
		// 	picture: "ZOBACZ NA MAPIE",
		// 	map: "POKAŻ ZDJĘCIE"
		// };

		let ourHistoryData = []
		if (ourHistoryArray.length > 0) {
			ourHistoryArray.forEach(item => {
				ourHistoryData.push({
					"date": (item.data) || "",
					"title": (item.tytul) || "",
					"description": (item.opis) || "",
					"frameCaption": (item.podpisRamka) || "",
					"picture": (item.obrazek && item.obrazek[0] && item.obrazek[0].url) || "",
					"map": (item.mapka && item.mapka[0] && item.mapka[0].url) || "",
				})
			})
		}
		const imageNames = this.getImage();
		// const pictogramClassName = (imageNames === "map") ? "camera-picto" : "map-picto";
		const sliderPicture = {
			picture: ourHistoryData[activeDateIndex].picture,
			map: ourHistoryData[activeDateIndex].map
		};
		// const pictogramPicture = {
		// 	picture: mapPictogramDefault,
		// 	map: photoPictogramDefault
		// };
		// const pictogramPictureHovered = {
		// 	picture: mapPictogramHovered,
		// 	map: photoPictogramHovered
		// };

		const numOfPoints = ourHistoryData.length;

		return (
			<OurHistoryWrapper background={moduleBg} paddingTop={getPaddingTop(paddingTopType)}
							   paddingBottom={getPaddingBottom(paddingBottomType)}>
				<TimelineWrapper>
					<Line background={moduleBg} coloredLineScale={1/(numOfPoints - 1) * (activeDateIndex)}/>
					<ul>
						{ourHistoryData.map((item, key) =>
							<li key={key} onClick={this.setActiveDate.bind(this, key)}
								className={activeDateIndex === key ? 'active' : ''}>
								<span>{item.date}</span>
							</li>
						)}
					</ul>
				</TimelineWrapper>
				<ContentWrapper>
					<PictureWrapper>
						<ZoomedPicture className="non-clickable" bgImg={sliderPicture[imageNames]} isPictogram={imageNames === "picture"}/>
						{/*<MapPictogramWrapper>*/}
						{/*	<SinglePictogramContainer bgImgHovered={pictogramPictureHovered[imageNames]}*/}
						{/*							  onClick={this.togglePicture.bind(this)}>*/}
						{/*		<ViewMapPictogram className={pictogramClassName}*/}
						{/*						  bgImg={pictogramPicture[imageNames]}*/}
						{/*						  bgImgHovered={pictogramPictureHovered[imageNames]}*/}
						{/*						  isPictureHovered={isPictureHovered}/>*/}
						{/*		<BtnWrapperForBgPreload iconHoverBtn={pictogramPictureHovered['picture']}/>*/}
						{/*		<BtnWrapperForBgPreload iconHoverBtn={pictogramPictureHovered['map']}/>*/}
						{/*		<BtnWrapperForBgPreload iconHoverBtn={pictogramPicture['map']}/>*/}
						{/*		<PictogramBackCircle className="pictogram-circle"/>*/}
						{/*		<ViewMapText className="show">{textOnHover[imageNames]}</ViewMapText>*/}
						{/*	</SinglePictogramContainer>*/}
						{/*</MapPictogramWrapper>*/}
					</PictureWrapper>
					<SlideWrapper>
						<InfoSquare>
							<SlideTitle className="semi-bold"
										background={moduleBg}>{ourHistoryData[activeDateIndex].title}</SlideTitle>
							<SlideDescription background={moduleBg} className="paragraph who-we-help"
											  dangerouslySetInnerHTML={getHtmlFromRedactorField(ourHistoryData[activeDateIndex].description)}/>
							<FrameCaption>{ourHistoryData[activeDateIndex].frameCaption}</FrameCaption>
						</InfoSquare>
					</SlideWrapper>
				</ContentWrapper>
			</OurHistoryWrapper>
		);
	}
}

export default OurHistory;
