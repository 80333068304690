import React from "react"
import styled from "styled-components"
import Button from "../common/ButtonsSet";
import getSlug from "../../utils/getSlug";
import setBackgroundColor from "../../utils/setBackgroundColor";
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";
import {changeLinkToIdFromAnotherPage, setDifferentAmountFromCalculator, setMobileActiveProject} from "../../state/app";
import {fbqDonateBtnClicked} from "../../utils/fbqDonateBtnClicked";
import {navigate} from "gatsby";
import {connect} from "react-redux";
import validationForNumbers from "../../utils/validationForNumbers";

const ModuleWrapper = styled.div`
	display: flex;
	background-color: ${({background, theme}) => setBackgroundColor(background, theme)};
	padding: ${({paddingTop}) => paddingTop}px 10px ${({paddingBottom}) => paddingBottom}px;
	
	@media(min-width: 768px) {
		padding: ${({paddingTop}) => paddingTop}px 30px ${({paddingBottom}) => paddingBottom}px;
	}
	@media(min-width: 1280px) {
		padding: ${({paddingTop}) => paddingTop}px 50px ${({paddingBottom}) => paddingBottom}px;
	}
	@media(min-width: 1920px) {
		padding: ${({paddingTop}) => paddingTop}px 100px ${({paddingBottom}) => paddingBottom}px;
	}
`

const Photo = styled.div`
	display: none;
	background-size: cover;
	background-position: center;
	background-image: url( ${({bgImg}) => bgImg});
	height: 800px;
	
	@media(min-width: 1024px){
		display: flex;
		width: 58%;
	}
`

const CalculatorWrapper = styled.div`
	padding-top: calc(${({paddingTop}) => paddingTop}px + 15px);
	width: auto;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	@media(min-width: 1024px){
		width: 42%;
		padding-left: 120px;
	}
`

const ButtonWrapper = styled.div`
	margin: 80px 0 0 -10px;
`

const CustomTextStyled = styled.h4`
		line-height: 16px;
		@media(min-width: 768px) {
			line-height: 18px;
		}
		@media(min-width: 1280px) {
			line-height: 20px;
		}
		@media(min-width: 1366px) {
			line-height: 22px;
		}
		@media(min-width: 1920px) {
			line-height: 30px;
		}
`

const InputStyled = styled.input`
	margin: 15px 0 50px;
	height: 60px;
	width: 250px;
	padding: 0 20px;

	&:active, &:focus {
		border: 1px solid ${({theme}) => theme.colors.pmmOrange};
		outline: none;
	}
`
const TextWithBg = styled.h4`
	background-color: ${({theme}) => theme.colors.pmmOrange};
	width: 250px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 0;
	margin: 15px 0 50px 0;
`

const Sum = styled.h4`
	border: 2px solid ${({theme}) => theme.colors.pmmWhite};
	display: flex;
	margin-top: 15px;
	align-items: center;
	justify-content: center;
	padding: 10px 0;
	width: 250px;
`

class Calculator extends React.Component {
	state = {
		sum: 0
	};

	constructor(props) {
		super(props)
		this.calcSum = this.calcSum.bind(this)
	}

	handleBtnClick(linkToNavigate, slugForProject) {
		const {dispatch} = this.props
		dispatch(changeLinkToIdFromAnotherPage(true))
		dispatch(setDifferentAmountFromCalculator(this.state.sum))
		fbqDonateBtnClicked('kliknieto z kalkulatora');
		if ((window.innerWidth >= 768)) {
			const navigateToId = linkToNavigate + '#' + slugForProject
			navigate(navigateToId)
		} else {
			const navigateToId = linkToNavigate + '#payment-wrapper'
			navigate(navigateToId)
			dispatch(setMobileActiveProject(slugForProject))
		}
	}

	calcSum = (event, numberWithBg) => {
		this.setState({sum: event.target.value * numberWithBg})
	}

	render() {

		const calculatorData = this.props.data?.modulKalkulator || "";
		const {activeSite} = this.props;
		let photoUrl = '',
			unit = '',
			label = '',
			header = '',
			description = '',
			btnText = '',
			toBtn = '',
			btnType = '',
			sumText = '',
			numberWithBg = '',
			background = '',
			paddingTopType = '',
			paddingBottomType = '',
			slugForProject = '';

		calculatorData.forEach(item => {
				if (item.__typename === "Craft_modulKalkulator_margines_BlockType") {
					paddingTopType = (item.marginesGorny && item.marginesGorny[0] && item.marginesGorny[0].title) || ''
					paddingBottomType = (item.marginesDolny && item.marginesDolny[0] && item.marginesDolny[0].title) || ''
				} else if (item.__typename === "Craft_modulKalkulator_kalkulator_BlockType") {
					photoUrl = item?.zdjecie?.[0]?.url || "";
					unit = item?.jednostka || "";
					label = item?.label || "";
					header = item?.naglowek || "";
					description = item?.opis || "";
					btnText = item?.przyciskNapis || "";
					toBtn = item?.przyciskWpis?.[0]?.slug || "";
					btnType = item?.przyciskRodzaj?.[0]?.title || ""
					sumText = item?.tekstPodsumowujacy || "";
					numberWithBg = item?.liczbaZTlem || "";
					background = item?.background?.[0]?.title || "";
					slugForProject = item?.projekt?.[0]?.slug || ""
				}
			}
		);

		const linkToNavigate = `/${getSlug(toBtn, activeSite)}`

		return (
			<ModuleWrapper background={background} paddingTop={getPaddingTop(paddingTopType)}
						   paddingBottom={getPaddingBottom(paddingBottomType)}>
				<Photo bgImg={photoUrl}/>
				<CalculatorWrapper paddingTop={getPaddingTop(paddingTopType)}>
					<CustomTextStyled className="white bold">{header}</CustomTextStyled>
					<CustomTextStyled className="white">{description}</CustomTextStyled>
					<TextWithBg className="white bold">{numberWithBg} {unit}</TextWithBg>
					<CustomTextStyled className="white bold">{label}</CustomTextStyled>
					<InputStyled onChange={(event) => this.calcSum(event, numberWithBg)}  onKeyPress={(e) => validationForNumbers(e)}/>
					<CustomTextStyled className="white bold">{sumText}</CustomTextStyled>
					<Sum className="white bold">{this.state.sum} {unit}</Sum>
					<ButtonWrapper>
						<Button btnType={btnType}
								onClick={this.handleBtnClick.bind(this, linkToNavigate, slugForProject)}
								textBtn={btnText}/>
					</ButtonWrapper>
				</CalculatorWrapper>

			</ModuleWrapper>
		)
	}
}

export default connect(state => ({
	mobileActiveProject: state.app.mobileActiveProject
}), null)(Calculator)
