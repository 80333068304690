import React from "react"
import styled from "styled-components"
import {ChartInfoWrapper, ChartInfo} from "../../../common/Charts";

const ChartInfoDescription = styled.div`
	margin-top: 35px;
	// color: ${({theme}) => theme.colors.pmmOrange};
	color: ${({theme}) => theme.colors.pmmWhite};
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
	font-size: ${({theme}) => theme.fontSize.f5};
	line-height: ${({theme}) => theme.lineHeight.line7};
	min-height: 80px;
	
	 @media(min-width: 768px){
	 	width: 295px;
	 	margin-top: 60px;
	}
	
	 @media(min-width: 1920px){
	 	font-size: ${({theme}) => theme.fontSize.f2};
	 	width: 100%;
	}
`

class InfoDescription extends React.Component {

	render() {

		const { chartInfo } = this.props
		const chartTitle = (chartInfo && chartInfo.naglowek) || (chartInfo && chartInfo.podpis) || ""
		const chartDecription = (chartInfo && chartInfo.opis) || ""

		return (
			<>
				<ChartInfoDescription>{chartTitle}</ChartInfoDescription>
				<ChartInfoWrapper>
					<ChartInfo>{chartDecription}</ChartInfo>
				</ChartInfoWrapper>
			</>
		);
	}
}

export default InfoDescription
