import React, {Component} from 'react'
import styled from "styled-components"
import PlayButton from "../../images/videoModule/play-button.svg"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";
import ReactPlayer from 'react-player'
import setBackgroundColor from "../../utils/setBackgroundColor";
import setFontColor from "../../utils/setFontColor";
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background-color: ${({background, theme}) => setBackgroundColor(background, theme)};
	width: 100%;
	padding: ${({paddingTop}) => paddingTop}px 0 ${({paddingBottom}) => paddingBottom}px;
	@media(min-width: 1280px) {
		padding: ${({paddingTop}) => paddingTop}px 50px ${({paddingBottom}) => paddingBottom}px;
	}
	@media(min-width: 1920px) {
		padding: ${({paddingTop}) => paddingTop}px 100px  ${({paddingBottom}) => paddingBottom}px;
	}
`;

const PictureWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
    position: relative;
    &.video-on {
    	//padding-top: 75%;
    	padding-top: 56.25%;
    	@media(min-width: 769px) {
    		padding-top: 56.25%;
    	}
    }
  > div{
	    position: absolute;
	    top: 0;
	    left: 0;
  }
`;

const BackgroundImage = styled.img`
	height: 100%;
	background-size: contain;
	background-repeat: no-repeat;
	width: unset;
	//padding-top: 75%;
	padding-top: 56.25%;
    background-position: center;
    background-color: ${({theme}) => theme.colors.pmmDarkViolet};
    background-image: url( ${({ bgImg }) => bgImg});
    @media(min-width: 769px) {
    	padding-top: 56.25%;
    }
`

const PlayButtonWrapper = styled.img`
	width: 60px;
	height: 60px;
    cursor: pointer;
	position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    transition: transform 1s ease-in-out;
    &.video-on {
    	transform: scale(1.2);
    }
    @media(min-width: 1280px) {
    	width: 80px;
    	height: 80px;
    	top: calc(50% - 40px);
    	left: calc(50% - 40px);
    }
    @media(min-width: 1920px) {
    	width: 150px;
    	height: 150px;
    	top: calc(50% - 55px);
    	left: calc(50% - 55px);
    }
`;
const DescriptionWrapper = styled.div`
	padding: 10px 10px 0;
	background-color: ${({background, theme}) => setBackgroundColor(background, theme)};
	@media(min-width: 768px) {
		padding: 15px 25px 0;
	}
	@media(min-width: 1280px) {
		padding: 15px 0 0;
	}
`;

const VideoDescription = styled.h5`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	position: relative;
	width: 100%;
	color: ${({background, theme}) => setFontColor(background, theme)};
	font-weight: ${({theme}) => theme.fontWeight.regular};
	span {  
	  &.black {
	      font-weight: ${({ theme }) => theme.fontWeight.black};
	  }
	}
`;

class VideoModule extends Component {
	state = {
		ReactPlayer: null,
		isPlayPressed: false,
	};
	togglePlay() {
		this.setState({ isPlayPressed: !this.state.isPlayPressed })
	};
	componentDidMount() {
		if (typeof window !== "undefined") {
			this.setState({ ReactPlayer: require("react-player").default })
		}
	}
	render() {
		const videoModule = this.props.data;
		const {isNews} = this.props;
		const { ReactPlayer, isPlayPressed } = this.state;
		const videoModuleData = (videoModule.modulFilm && videoModule.modulFilm[0]) || '';

		const videoModuleFinalData = (isNews) ? videoModule : videoModuleData;
		const videoModuleDescription = (videoModuleFinalData && videoModuleFinalData.podpisPodFilmem) || '';
		const videoModuleVideoLink = (videoModuleFinalData && videoModuleFinalData.linkDoFilmu) || '';
		const videoModuleBackground = (videoModuleFinalData && videoModuleFinalData.background && videoModuleFinalData.background[0] &&
			videoModuleFinalData.background[0].title) || '';
		const paddingTop = (videoModuleFinalData && videoModuleFinalData.marginesGorny && videoModuleFinalData.marginesGorny[0] &&
			videoModuleFinalData.marginesGorny[0].title) || '';
		const paddingBottom = (videoModuleFinalData &&
			videoModuleFinalData.marginesDolny && videoModuleFinalData.marginesDolny[0] &&
			videoModuleFinalData.marginesDolny[0].title) || '';
		const videoModuleImage = (videoModuleFinalData && videoModuleFinalData.zdjecieWTle && videoModuleFinalData.zdjecieWTle[0] &&
			videoModuleFinalData.zdjecieWTle[0].url) || [];
		const isVideoOn = (isPlayPressed) ? "video-on" : "" ;
		return (
			<ModuleWrapper background={videoModuleBackground} paddingTop={getPaddingTop(paddingTop)} paddingBottom={getPaddingBottom(paddingBottom)}>
				<PictureWrapper className={isVideoOn} bgImg={videoModuleImage}>
					{!isPlayPressed && <BackgroundImage bgImg={videoModuleImage}/> }
					{isPlayPressed && <ReactPlayer width='100%'
					                               height='100%'
					                               controls={"false"}
					                               className={isVideoOn}
					                               playing={this.state.isPlayPressed}
					                               url={videoModuleVideoLink}/>}
					{ !isPlayPressed && <PlayButtonWrapper className={isVideoOn} onClick={this.togglePlay.bind(this)} src={PlayButton}/>}
				</PictureWrapper>
				<DescriptionWrapper background={videoModuleBackground}>
					<VideoDescription background={videoModuleBackground} dangerouslySetInnerHTML={getHtmlFromRedactorField(videoModuleDescription)}/>
				</DescriptionWrapper>
			</ModuleWrapper>
		)
	}
}

export default VideoModule;
