// import React from 'react';
// import Script from 'react-load-script'
// import * as sha256 from "sha256";
//
// const urlencode = str => encodeURIComponent(str)
//     .replace(/!/g, '%21')
//     .replace(/'/g, '%27')
//     .replace(/\(/g, '%28')
//     .replace(/\)/g, '%29')
//     .replace(/\*/g, '%2A')
//     .replace(/~/g, '%7E')
//     .replace(/%20/g, '+');
//
// const PayUWidget = (props) => {
//     console.log(props);
//     const isProduction = true;
//     const project = `Zestaw za ${(props.totalAmount).toFixed(2)} PLN dla ${props.supportedCountry}`;
//     const projectName = (props.supportedCountry) ? props.supportedCountry : "Polska Misja Medyczna";
//     // const craftId = (props.supportedCountry === 'Akcja specjalna | Razem dla polskich lekarzy') ? "18132" : (props.supportedCountry === 'Polska Misja Medyczna') ? "0" : props.craftProjectId;
//
//
//     // ID strony w Craft, która ma spięte ze sobą identyfikatory i klucze do PayU
//     // const craftId = 0;
//     // To musisz sobie skądś skołować
//     // const merchantPosId = '300746';
//     // const secondaryKey = 'b6ca15b0d1020e8094d9b5f8d163db54';
//     // const merchantPosId = '2516447';
//     // const secondaryKey = 'df356e27714e16f2616c1fe9889ac6b7';
//
//     // Testowe dane kart:
//     // https://developers.payu.com/pl/overview.html#sandbox
//
//     // Te dane poniżej pewnie powinny przyjść w propsach
//     const data = {
//         merchantPosId: props.merchantPosId,
//         shopName: 'pmm',
//         firstName: props.firstName,
//         lastName: props.lastName,
//         email: props.email,
//         amount: props.totalAmount,
//         title: project,
//         project: projectName,
//     };
//
//     const options = {
//         payButtonId: 'pay-button',
//         language: "pl",
//         currency: "PLN",
//         storeCard: 'true',
//         recurringPayment: 'true',
//         widgetMode: 'use',
//     };
//
//     const postUrl = `${process.env.PAYMENTS_POST_URL}/payu/end`;
//     console.log(postUrl, data.email);
//
//     const sigObject = {
//         'currency-code': urlencode(options.currency),
//         'customer-email': data.email,
//         'customer-language': urlencode(options.language),
//         'merchant-pos-id': urlencode(data.merchantPosId),
//         'recurring-payment': urlencode(options.recurringPayment),
//         'shop-name': urlencode(data.shopName),
//         'store-card': urlencode(options.storeCard),
//         'total-amount': urlencode(data.amount),
//         'widget-mode': urlencode(options.widgetMode),
//     };
//
//     const sig = sha256(Object.keys(sigObject).reduce((a, b) => a + sigObject[b], '') + props.secondaryKey);
//     const scriptDomain = isProduction ? 'secure' : 'secure.snd';
//
//     return (
//         <div>
//             <form action={postUrl} method="post">
//                 {/*<button id={options.payButtonId}>Płace se, a co!</button>*/}
//                 <input type="hidden" name={"craftId"} value={props.craftId}/>
//                 <input type="hidden" name={"firstName"} value={data.firstName}/>
//                 <input type="hidden" name={"lastName"} value={data.lastName}/>
//                 <input type="hidden" name={"email"} value={data.email}/>
//                 <input type="hidden" name={"currency"} value={options.currency}/>
//                 <input type="hidden" name={"language"} value={options.language}/>
//                 <input type="hidden" name={"amount"} value={data.amount}/>
//                 <input type="hidden" name={"project"} value={data.project}/>
//                 <input type="hidden" name={"title"} value={data.title}/>
//                 <Script
//                     url={`https://${scriptDomain}.payu.com/front/widget/js/payu-bootstrap.js`}
//                     attributes={{
//                         'pay-button': '#' + options.payButtonId,
//                         'merchant-pos-id': data.merchantPosId,
//                         'shop-name': data.shopName,
//                         'total-amount': data.amount,
//                         'currency-code': options.currency,
//                         'customer-language': options.language,
//                         'store-card': options.storeCard,
//                         'recurring-payment': options.recurringPayment,
//                         'customer-email': data.email,
//                         'widget-mode': options.widgetMode,
//                         'sig': sig,
//                     }}/>
//                 <Script url={`https://${scriptDomain}.payu.com/res/v2/openpayu-2.1.js`}/>
//                 <Script url={`https://${scriptDomain}.payu.com/res/v2/plugin-token-2.1.js`}/>
//             </form>
//         </div>
//     )
// };
//
// export default PayUWidget;

import React, {useEffect} from 'react';
import Script from 'react-load-script'
import * as sha256 from "sha256";

const urlencode = str => encodeURIComponent(str)
	.replace(/!/g, '%21')
	.replace(/'/g, '%27')
	.replace(/\(/g, '%28')
	.replace(/\)/g, '%29')
	.replace(/\*/g, '%2A')
	.replace(/~/g, '%7E')
	.replace(/%20/g, '+');

const PayUWidget = (props) => {
	const project = `Zestaw za ${(props.totalAmount / 100).toFixed(2)} PLN dla ${props.supportedCountry}`;
	const projectName = (props.supportedCountry) ? props.supportedCountry : "Polska Misja Medyczna";
	const merchantPosId = '2914458';
	const secondaryKey = '4108f6b2444a52605ea2f66fa264dbe9';
	const isProduction = true;
	const data = {
		merchantPosId: merchantPosId,
		shopName: 'PMM',
		firstName: props.firstName,
		lastName: props.lastName,
		email: props.email,
		amount: props.totalAmount / 100,
		title: project,
		project: projectName,
	};
	const options = {
		payButtonId: 'pay-button',
		language: "pl",
		currency: "PLN",
		storeCard: 'true',
		recurringPayment: 'true',
		widgetMode: 'use',
	};
	const sigObject = {
		'currency-code': urlencode(options.currency),
		'customer-email': data.email,
		'customer-language': urlencode(options.language),
		'merchant-pos-id': urlencode(data.merchantPosId),
		'recurring-payment': urlencode(options.recurringPayment),
		'shop-name': urlencode(data.shopName),
		'store-card': urlencode(options.storeCard),
		'total-amount': urlencode(data.amount),
		'widget-mode': urlencode(options.widgetMode),
	};
	const sig = sha256(Object.keys(sigObject).reduce((a, b) => a + sigObject[b], '') + secondaryKey);
	const postUrl = `${process.env.GATSBY_PAYMENTS_POST_URL}/payu/end`;
	const scriptDomain = isProduction ? 'secure' : 'secure.snd';

	const setAttributes = (el, attrs) => {
		for(var key in attrs) {
			el.setAttribute(key, attrs[key]);
		}
	}

	const refreshScripts = () => {
		const paymentScript = document.querySelectorAll('script.payu');
		let parent = document.querySelector('body');
		if (paymentScript.length > 0) {
			paymentScript.forEach(el => el.remove());
		}
		const frames = document.querySelectorAll('#payuFrame');
		frames.forEach(el => el.parentElement.remove());

		const mainScript = document.createElement('script');
		mainScript.classList.add('payu');
		mainScript.setAttribute('src', `https://${scriptDomain}.payu.com/front/widget/js/payu-bootstrap.js`);
		setAttributes(mainScript, {
			'pay-button': '#' + options.payButtonId,
			'merchant-pos-id': data.merchantPosId,
			'shop-name': data.shopName,
			'total-amount': data.amount,
			'currency-code': options.currency,
			'customer-language': options.language,
			'store-card': options.storeCard,
			'recurring-payment': options.recurringPayment,
			'customer-email': data.email,
			'widget-mode': options.widgetMode,
			'sig': sig,
		});

		// const openPayu = document.createElement('script');
		// openPayu.setAttribute('class', 'payu');
		// openPayu.setAttribute('src', `https://${scriptDomain}.payu.com/res/v2/openpayu-2.1.js`);
		//
		// const pluginToken = document.createElement('script');
		// pluginToken.setAttribute('class', 'payu');
		// pluginToken.setAttribute('src', `https://${scriptDomain}.payu.com/res/v2/plugin-token-2.1.js`);

		parent.appendChild(mainScript);
		// parent.appendChild(openPayu);
		// parent.appendChild(pluginToken);
	}

	useEffect(() => {
		refreshScripts();
	}, []);

	return (
		<div>
			<form action={postUrl} method="post">
				{props.children}
				<input type="hidden" name={"craftId"} value={props.craftProjectId}/>
				<input type="hidden" name={"firstName"} value={data.firstName}/>
				<input type="hidden" name={"lastName"} value={data.lastName}/>
				<input type="hidden" name={"email"} value={data.email}/>
				<input type="hidden" name={"currency"} value={options.currency}/>
				<input type="hidden" name={"language"} value={options.language}/>
				<input type="hidden" name={"amount"} value={data.amount}/>
				<input type="hidden" name={"project"} value={data.project}/>
				<input type="hidden" name={"title"} value={data.title}/>
			</form>
			{/*<script*/}
			{/*    className={}*/}
			{/*    src={`https://${scriptDomain}.payu.com/front/widget/js/payu-bootstrap.js`}*/}
			{/*    pay-button={`#${options.payButtonId}`}*/}
			{/*    merchant-pos-id={data.merchantPosId}*/}
			{/*    shop-name={data.shopName}*/}
			{/*    total-amount={data.amount}*/}
			{/*    currency-code={options.currency}*/}
			{/*    customer-language={options.language}*/}
			{/*    store-card={options.storeCard}*/}
			{/*    recurring-payment={options.recurringPayment}*/}
			{/*    customer-email={data.email}*/}
			{/*    widget-mode={options.widgetMode}*/}
			{/*    sig={sig}*/}
			{/*    async*/}
			{/*/>*/}
			<Script url={`https://${scriptDomain}.payu.com/res/v2/openpayu-2.1.js`} />
			<Script url={`https://${scriptDomain}.payu.com/res/v2/plugin-token-2.1.js`} />
		</div>
	)
};



export default PayUWidget;
