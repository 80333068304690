import React, {Component} from 'react'
import styled from "styled-components"

const ModuleWrapper = styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
	height: 100vh;
	background-size: cover;
	background-position: center;
	padding-right: 15px;
	padding-left: 15px;
	@media(min-width: 450px) {
		padding-right: 30px;
		padding-left: 30px;
	}
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
  	&:before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: 1;
		background-image: ${({isContactSubpage}) => (isContactSubpage) ? "none" : "linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 10%,transparent 30%,transparent 70%, rgba(0, 0, 0, 0.4) 100%)"};
	}
  	@media(orientation: landscape) and (max-width: 1024px) {
  		background-image:url( ${({ bgImgMobileLandscape }) => bgImgMobileLandscape});
  	}
  	@media(orientation: portrait) and (max-width: 1024px) {
  		background-image: url( ${({ bgImgMobile }) => bgImgMobile});
  	}
  	@media(min-width: 1025px) {
  		background-image: url( ${({ bgImg }) => bgImg});
  	}
  	@media(min-width: 1280px) {
  		padding-right: 50px;
		padding-left: 50px;
	    &:before {
				position: absolute;
				content: '';
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 11;
				background-image: ${({isContactSubpage}) => (isContactSubpage) ? "none" : "linear-gradient(to right, rgba(0, 0, 0, .4) 10%,transparent 40%)"};
		}
  	}
  	@media(min-width: 1920px) {
        padding-right: 100px;
		padding-left: 100px;
  	}
`;

const TextBoxWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	min-height: 240px;
	z-index: 100;
	background-color: ${({theme}) => theme.colors.pmmOrange};
	padding: 50px 20px 20px;
	margin: 80px 0 15px;
	@media(min-width: 450px) {
		width: 260px;
	}
	@media(min-width: 768px) {
		min-height: 360px;
		width: 280px;
		padding: 80px 30px 50px;
		margin-bottom: 30px;
	}
	@media(min-width: 1280px) {
		min-height: 350px;
		width: 252px;
		padding: 70px 20px 35px;
	}
	@media(min-width: 1366px) {
		padding: 70px 20px 45px;
		min-height: 360px;
	}
	@media(min-width: 1920px) {
		width: 20%;
		padding: 90px 30px 65px;
		margin-bottom: 40px;
		min-height: 460px;
	}
`;

const Header = styled.h3`
	color: ${({theme}) => theme.colors.pmmWhite};
	text-transform: uppercase;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		top: -15px;
		left: 0;
		width: 60px;
		height: 3px;
		background-color: ${({theme}) => theme.colors.pmmWhite};
		@media(min-width: 768px) {
			top: -20px;
			width: 80px;
			height: 4px;
		}
		@media(min-width: 1920px) {
			width: 100px;
			height: 5px;
			top: -25px;
		}
	}	
`;

const Description = styled.p`
	color: ${({theme}) => theme.colors.pmmWhite};
	margin: 20px 0 0;
	min-height: 119px;
	@media(min-width: 768px) {
		min-height: 153px;
		margin-top: 40px;
	}
	@media(min-width: 1280px) {
		min-height: 180px;
		margin-top: 30px;
	}
	@media(min-width: 1366px) {
		min-height: 169px;
	}
	@media(min-width: 1920px) {
		margin-top: 60px;
		min-height: 200px;
	}
`;

class SubpageBanner extends Component {

	render() {
		const SubpageBanner = this.props.data;
		const pageContext = this.props.pageContext;
		const isContactSubpage = (pageContext.remoteId === 25);
		const header = (SubpageBanner.modulBanerNaPodstronach && SubpageBanner.modulBanerNaPodstronach[0] &&
			SubpageBanner.modulBanerNaPodstronach[0].naglowek) || "";
		const description = (SubpageBanner.modulBanerNaPodstronach && SubpageBanner.modulBanerNaPodstronach[0] &&
			SubpageBanner.modulBanerNaPodstronach[0].opis) || "";
		const backgroundPhotoMobile = (SubpageBanner && SubpageBanner.modulBanerNaPodstronach[0] &&
			SubpageBanner.modulBanerNaPodstronach[0].zdjecieWTleMobile[0] &&
			SubpageBanner.modulBanerNaPodstronach[0].zdjecieWTleMobile[0].url) || "";
		const backgroundPhoto = (SubpageBanner && SubpageBanner.modulBanerNaPodstronach[0] &&
			SubpageBanner.modulBanerNaPodstronach[0].zdjecieWTle[0] &&
			SubpageBanner.modulBanerNaPodstronach[0].zdjecieWTle[0].url) || "";
		const backgroundPhotoMobileLandscape = (SubpageBanner && SubpageBanner.modulBanerNaPodstronach[0] &&
			SubpageBanner.modulBanerNaPodstronach[0].zdjecieWTleMobilePoziomo[0] &&
			SubpageBanner.modulBanerNaPodstronach[0].zdjecieWTleMobilePoziomo[0].url) || "";

		return (
			<ModuleWrapper bgImg={backgroundPhoto} bgImgMobile={backgroundPhotoMobile} isContactSubpage={isContactSubpage}
			               bgImgMobileLandscape={backgroundPhotoMobileLandscape}>
				<TextBoxWrapper>
					<Header className="negative-letter-spacing">{header}</Header>
					<Description className="custom-lh">{description}</Description>
				</TextBoxWrapper>

			</ModuleWrapper>
		)
	}
}

export default SubpageBanner;
