import {sendData} from "./sendEmailMethod";

export async function sendEmail(data) {

	const rawResponse = await sendData(data);
	const responseData = await rawResponse.json();
	const fbq = window.fbq;

	if (responseData.status === "OK") {
		if (fbq) {
				fbq('trackCustom', 'AddToNewsletter', {
					value: data.freshmailEmail,
					info: 'dolaczono z podstrony platnosci',
				});
			}
	} else if (responseData.status === "ERROR") {
	}

}

