import React, {Component} from 'react';
import styled from "styled-components"
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";

const ModuleWrapper = styled.div`
	background-color: ${({theme}) => theme.colors.pmmWhite};
	padding: ${({paddingTop}) => paddingTop}px 10px ${({paddingBottom}) => paddingBottom}px;
	
	@media(min-width: 768px) {
		padding: ${({paddingTop}) => paddingTop}px 30px ${({paddingBottom}) => paddingBottom}px;
	}
	@media(min-width: 1280px) {
		padding: ${({paddingTop}) => paddingTop}px 50px ${({paddingBottom}) => paddingBottom}px;
	}
	@media(min-width: 1920px) {
		padding: ${({paddingTop}) => paddingTop}px 100px ${({paddingBottom}) => paddingBottom}px;
	}
`
const HeaderWrapper = styled.div`
	width: 100%;
	display: flex;
	border-bottom: 2px solid ${({theme}) => theme.colors.pmmDarkestBlue};
	margin-bottom: 70px;
	
	@media(min-width: 1280px) {
		width: 38%;
	}
	@media(min-width: 1366px) {
		width: 35%;
	}
	@media(min-width: 1920px) {
		width: 35%;
	}
	@media(min-width: 2560px) {
		width: 33%;
	}
`
const Header = styled.h5`
	background-color: ${({theme}) => theme.colors.pmmWhite};
	margin-bottom: -2px;
	margin-right: 30px;
	border: 2px solid ${({theme}) => theme.colors.pmmDarkestBlue};
	color: ${({theme}) => theme.colors.pmmDarkBlue};
	padding: 8px 20px;
	position: relative;
	
	&:after {
		content: '';
		position: absolute;
		bottom: -2px;
		right: -17px;
		height: 2px;
		width: 15px;
		background-color: white;
	}
		@media(min-width: 768px) {
		&:after {
			right: -32px;
			width: 30px;
		}
	}
`

const ListsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`

const List = styled.div`
	width: 20%;
	min-width: 240px;
	
	a {
		text-decoration: none;
		color: ${({theme}) => theme.colors.pmmOrange};
	
		div {
			position: relative;
			margin-bottom: 35px;
			margin-left: 50px;
			transition: all 1s linear;
			display: table;
			line-height: 30px !important;
			
			&::before {
				content: '';
				position: absolute;
				top: 0;
				background-image: url(${props => props.back});
				background-position: center;
				background-repeat: no-repeat;
				left: -50px;
				width: 30px;
				height: 30px;
				transition: .2s ease-in-out;
			}
			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				background-color: ${({theme}) => theme.colors.pmmOrange};
				height: 2px;
				width: 0;
				transition: .2s ease-in-out;
			}
		}
		&:hover {
			div{
				&::after {
					width: 100%;
				}
				&::before {
					  transform: scale(1.113);
				}
			}
			div.edge{
				&::after {
					width: 100%;
				}
				&::before {
					  transform: scale(1);
				}
			}
		}
	}
`
const ListHeader = styled.p`
	color: ${({theme}) => theme.colors.pmmDarkViolet};
	margin-bottom: 55px;
`

class FilesToDownloadList extends Component {
	state = {
		isOldBrowserEdge: false,
	};

	componentDidMount() {
		if (typeof window !== "undefined") {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({isOldBrowserEdge: isOldBrowser});
		}
	}

	render() {
		const {data, downloadIcon} = this.props
		const header = (data && data.plikiNaglowek) || ""
		const filesListsArray = (data && data.plikiWybierzListe) || ""
		const margins = (data && data.plikiMarginesy && data.plikiMarginesy[0]) || ""
		const paddingTop = (margins.marginesGorny && margins.marginesGorny[0] && margins.marginesGorny[0].title) || ""
		const paddingBottom = (margins.marginesDolny && margins.marginesDolny[0] && margins.marginesDolny[0].title) || ""
		let listsData = []
		const downloadIconUrl = (downloadIcon && downloadIcon[0] && downloadIcon[0].ikonaPobierania && downloadIcon[0].ikonaPobierania[0] && downloadIcon[0].ikonaPobierania[0].url) || ""

		filesListsArray.forEach(item => {
			let filesArray = []

			item.remoteChildren.forEach(file => {
				filesArray.push(
					{
						'fileName': file.title || "",
						'fileUrl': (file.plikiWybierzPlik && file.plikiWybierzPlik[0] && file.plikiWybierzPlik[0].url) || ""
					}
				)
				return filesArray
			})

			listsData.push({
				'listHeader': item.title || "",
				'listFiles': filesArray,
			})
		})
		const iconClassName = (this.state.isOldBrowserEdge) ? "edge" : "";

		return (
			<ModuleWrapper paddingTop={getPaddingTop(paddingTop)} paddingBottom={getPaddingBottom(paddingBottom)}>
				<HeaderWrapper>
					<Header className="header-with-line">
						{header}
					</Header>
				</HeaderWrapper>
				<ListsWrapper>
					{listsData.map((item, key) =>
						<List className="paragraph text-in-border" key={key} back={downloadIconUrl}>
							<ListHeader className="h7" key={key}>
								{item.listHeader}
							</ListHeader>
							{item.listFiles.map((item, key) =>
								<a key={key} href={item.fileUrl} target="_blank" rel="noopener noreferrer" download>
									<div className={iconClassName}>
										{item.fileName}
									</div>
								</a>
							)}
						</List>
					)}
				</ListsWrapper>
			</ModuleWrapper>
		);
	}
}


export default FilesToDownloadList;
