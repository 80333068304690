import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Logo from '../components/logo/Logo'
import SEO from '../components/seo'
import ExtendedContent from '../components/extendedContent'
import CookieBar from '../components/cookieBar/cookieBar'
import TilesWithPictogramsGrid
	from '../components/tilesWithPictogramsGrid/TilesWithPictogramsGrid'
import Helmet from 'react-helmet'
import Cookies from 'js-cookie'

const IndexPage = ({ data, pageContext }) => {
	const { site: activeSite, hasSubmenu, isHomePage } = pageContext
	let {
		pojedyncze,
		projekty,
		aktualnosci,
		szkolenia,
		misjeSpecjalne,
		akcje,
		dzialania,
		politykaPrywatnosci,
		seoPojedyncze,
		seoProjekty,
		seoAktualnosci,
		seoSzkolenia,
		seoMisjeSpecjalne,
		seoPolitykaPrywatnosci,
		seoDzialania,
		categories,
		aktualnosciWszystkie,
		wzmiankiWMediach,
	} = data
	const {
		seoDefault,
		logo,
		cookiesBar,
		piktogramy,
		formularz,
		szkolenie,
		banerPlatnosci,
		translations,
		newsletterModal,
		newsBtns,
		actWithUsBackButton,
		specialActions,
		projectsBackBtn,
		photosToDownloadBtn,
		files,
		processingPersonalData,
		stickyBannerMainPage,
		mentionsGlobal,
	} = pageContext.globals.data

	pojedyncze = pojedyncze ? pojedyncze?.nodes : ''
	// TODO: aktualnosci wszystkie na głównej - na razie pusta tablica
	aktualnosciWszystkie = aktualnosciWszystkie?.nodes || ''
	wzmiankiWMediach = wzmiankiWMediach?.nodes || ''
	projekty = projekty ? projekty?.nodes : ''
	szkolenia = szkolenia ? szkolenia?.nodes : ''
	misjeSpecjalne = misjeSpecjalne ? misjeSpecjalne?.nodes : ''
	akcje = akcje ? akcje?.nodes : ''
	dzialania = dzialania ? dzialania?.nodes : ''
	aktualnosci = aktualnosci ? aktualnosci?.nodes : ''
	politykaPrywatnosci = politykaPrywatnosci ? politykaPrywatnosci?.nodes : ''
	seoPojedyncze = seoPojedyncze ? seoPojedyncze?.nodes : ''
	seoProjekty = seoProjekty ? seoProjekty?.nodes : ''
	seoSzkolenia = seoSzkolenia ? seoSzkolenia?.nodes : ''
	seoMisjeSpecjalne = seoMisjeSpecjalne ? seoMisjeSpecjalne?.nodes : ''
	seoAktualnosci = seoAktualnosci ? seoAktualnosci?.nodes : ''
	seoPolitykaPrywatnosci = seoPolitykaPrywatnosci
		? seoPolitykaPrywatnosci?.nodes
		: ''
	seoDzialania = seoDzialania ? seoDzialania?.nodes : ''

	const entry =
		pojedyncze.length > 0
			? pojedyncze[0].wybierzModul
			: projekty.length > 0
				? projekty[0].wybierzModul
				: szkolenia.length > 0
					? szkolenia[0].wybierzModul
					: misjeSpecjalne.length > 0
						? misjeSpecjalne[0].wybierzModul
						: akcje.length > 0
							? akcje[0].wybierzModul
							: aktualnosci.length > 0
								? aktualnosci[0]
								: politykaPrywatnosci.length > 0
									? politykaPrywatnosci[0]
									: politykaPrywatnosci.length > 0
										? politykaPrywatnosci[1]
										: dzialania.length > 0
											? dzialania[0]
											: ''
	const logoType =
		pojedyncze.length > 0
			? pojedyncze[0].wybierzLogo
			: projekty.length > 0
				? projekty[0].wybierzLogo
				: szkolenia.length > 0
					? szkolenia[0].wybierzLogo
					: misjeSpecjalne.length > 0
						? misjeSpecjalne[0].wybierzLogo
						: aktualnosci.length > 0
							? aktualnosci[0].wybierzLogo
							: politykaPrywatnosci.length > 0
								? politykaPrywatnosci[0].wybierzLogo
								: dzialania.length > 0
									? dzialania[0].wybierzLogo
									: ''
	const logoData = logo?.nodes?.[0] || []
	const piktogramyData = piktogramy?.nodes?.[0] || []
	const cookiesBarData = cookiesBar?.nodes?.[0] || []
	const formularzData = formularz?.nodes || []
	const szkolenieData = szkolenie?.nodes || []
	const banerPlatnosciData = banerPlatnosci?.nodes || []
	const newsBtnsData = newsBtns?.nodes || []
	const actWithUsBackButtonData = actWithUsBackButton?.nodes || []
	const specialActionsData = specialActions?.nodes || []
	const projectsBackBtnData = projectsBackBtn?.nodes || []
	const photosToDownloadBtnData = photosToDownloadBtn?.nodes || []
	const filesData = files?.nodes || []
	const processingPersonalDataNodes = processingPersonalData?.nodes || []
	const stickyBannerMainPageData = stickyBannerMainPage?.nodes || []
	const mentionsGlobalData = mentionsGlobal?.nodes || []
	const categoriesData = categories?.nodes || []

	const newsletterModalData = newsletterModal?.nodes?.[0] || []

	const seoData =
		seoPojedyncze.length > 0
			? seoPojedyncze[0]
			: seoProjekty.length > 0
				? seoProjekty[0]
				: seoSzkolenia.length > 0
					? seoSzkolenia[0]
					: seoMisjeSpecjalne.length > 0
						? seoMisjeSpecjalne[0]
						: seoAktualnosci.length > 0
							? seoAktualnosci[0]
							: seoPolitykaPrywatnosci.length > 0
								? seoPolitykaPrywatnosci[0]
								: seoDzialania.length > 0
									? seoDzialania[0]
									: ''
	const seoDefaultData = seoDefault?.nodes?.[0] || ''
	const translationsData = translations?.nodes?.[0] || ''
	const countryCategories =
		categoriesData &&
		categoriesData.filter(item => {
			return item.__typename === 'Craft_panstwo_Category'
		})
	const yearCategories =
		categoriesData &&
		categoriesData.filter(item => {
			return item.__typename === 'Craft_rok_Category'
		})

	useEffect(() => {
		if (pageContext.remoteId === 36092) {
			const fbq = window.fbq
			if (fbq) {
				const valueFromCookies = Cookies.get('valueForFbq') || 0;
				// fbq('track', 'Purchase')
				fbq('track', 'Purchase', { currency: "PLN", value: valueFromCookies })

			}
			return () => {
				Cookies.remove('valueForFbq')
			};
		}
	}, [])

	const isHomeOnProd = isHomePage && process.env.GATSBY_ENVIRONMENT === 'production'
	const isProd = process.env.GATSBY_ENVIRONMENT === 'production'
	return (
		<>
			{isHomeOnProd && <Helmet
				meta={[
					{
						name: `facebook-domain-verification`,
						content: `ojvraxqfor0llc3b7y3urtp6xbttq7`,
					}]}
			/>}
			{isProd && pageContext.remoteId === 1371 && <Helmet>
				<script>{`gtag('event', 'conversion', {'send_to': 'AW-920528065/KOaPCPnNvKUDEMHJ-LYD'})`}
				</script>
			</Helmet>}
			{isProd && pageContext.remoteId === 36092 && <Helmet>
				<script>{`gtag('event', 'conversion', {'send_to': 'AW-920528065/RGXQCPzNvKUDEMHJ-LYD', 'value': ${Cookies.get(
					'valueForFbq')}, 'currency': 'PLN', 'transaction_id': ''})`}
				</script>
				</Helmet>
			}
			<SEO seoData={seoData} seoDefault={seoDefaultData}/>
			<Logo logoData={logoData} activeSite={activeSite}
				  logoType={logoType}/>
			<ExtendedContent
				data={entry}
				piktogramyData={piktogramyData}
				newsletterModalData={newsletterModalData}
				pageContext={pageContext}
				activeSite={activeSite}
				translations={translationsData}
				newsBtns={newsBtnsData}
				actWithUsBackButton={actWithUsBackButtonData}
				specialActions={specialActionsData}
				projectsBackBtn={projectsBackBtnData}
				downloadIcon={filesData}
				photosToDownloadBtn={photosToDownloadBtnData}
				countryCategories={countryCategories}
				yearCategories={yearCategories}
				formularz={formularzData}
				szkolenie={szkolenieData}
				hasSubmenu={hasSubmenu}
				aktualnosciWszystkie={aktualnosciWszystkie}
				banerPlatnosci={banerPlatnosciData}
				processingPersonalData={processingPersonalDataNodes}
				stickyBannerMainPage={stickyBannerMainPageData}
				wzmiankiWMediach={wzmiankiWMediach}
				mentionsGlobal={mentionsGlobalData}
			/>
			<CookieBar cookiesBarData={cookiesBarData} activeSite={activeSite}/>
		</>
	)
}
export default IndexPage

export const query = graphql`
    query($remoteId: ID, $site: String, $firstOtherLangSite: String) {
        pojedynczeOtherLang: allCraftPojedynczePojedynczeEntry(filter: { language: { eq: $firstOtherLangSite } }) {
            nodes {
                slug
                id: remoteId
            }
        }
        projektyOtherLang: allCraftProjektyProjektyEntry(filter: { language: { eq: $firstOtherLangSite } }) {
            nodes {
                slug
                id: remoteId
            }
        }
        aktualnosciOtherLang: allCraftAktualnosciAktualnosciEntry(filter: { language: { eq: $firstOtherLangSite } }) {
            nodes {
                slug
                id: remoteId
            }
        }
        politykaPrywatnosciOtherLang: allCraftPolitykaPrywatnosciPolitykaPrywatnosciEntry(
            filter: { language: { eq: $firstOtherLangSite } }
        ) {
            nodes {
                slug
                id: remoteId
            }
        }
        dzialaniaOtherLang: allCraftDzialaniaDzialaniaEntry(filter: { language: { eq: $firstOtherLangSite } }) {
            nodes {
                slug
                id: remoteId
            }
        }
        misjeSpecjalneOtherLang: allCraftMisjeSpecjalneMisjeSpecjalneEntry(filter: { language: { eq: $firstOtherLangSite } }) {
            nodes {
                slug
                id: remoteId
            }
        }
        categories: allCraftCategoryInterface(filter: { language: { eq: $site } }) {
            nodes {
                __typename
                ... on Craft_panstwo_Category {
                    title
                }
                ... on Craft_rok_Category {
                    title
                }
            }
        }
        seoPojedyncze: allCraftPojedynczePojedynczeEntry(filter: { remoteId: { eq: $remoteId }, language: { eq: $site } }) {
            nodes {
                __typename
                ...seoPojedyncze
            }
        }
        seoProjekty: allCraftProjektyProjektyEntry(filter: { remoteId: { eq: $remoteId }, language: { eq: $site } }) {
            nodes {
                __typename
                ...seoProjekty
            }
        }
        seoAktualnosci: allCraftAktualnosciAktualnosciEntry(filter: { remoteId: { eq: $remoteId }, language: { eq: $site } }) {
            nodes {
                __typename
                ...seoAktualnosci
            }
        }
        seoSzkolenia: allCraftSzkoleniaSzkoleniaEntry(filter: { remoteId: { eq: $remoteId }, language: { eq: $site } }) {
            nodes {
                __typename
                ...seoSzkolenia
            }
        }
        seoMisjeSpecjalne: allCraftMisjeSpecjalneMisjeSpecjalneEntry(filter: { remoteId: { eq: $remoteId }, language: { eq: $site } }) {
            nodes {
                __typename
                ...seoMisjeSpecjalne
            }
        }
        seoPolitykaPrywatnosci: allCraftPolitykaPrywatnosciPolitykaPrywatnosciEntry(
            filter: { remoteId: { eq: $remoteId }, language: { eq: $site } }
        ) {
            nodes {
                __typename
                ...seoPolitykaPrywatnosci
            }
        }
        seoDzialania: allCraftDzialaniaDzialaniaEntry(filter: { remoteId: { eq: $remoteId }, language: { eq: $site } }) {
            nodes {
                __typename
                ...seoDzialania
            }
        }
        projekty: allCraftProjektyProjektyEntry(filter: { remoteId: { eq: $remoteId }, language: { eq: $site } }) {
            nodes {
                wybierzMenu
                wybierzLogo
                wybierzModul {
                    __typename
                    ...slider
                    ...buildingHelp
                    ...whereWeHelp
                    #                    ...headerDescriptionBackgroundNew
                    ...projectCard
                    ...sectionName
                    #                    ...sectionNameNew
                    ...wizytowkaAktualnosci
                    ...newsletter
                    ...historieOsob
                    ...dolnyBaner
                    ...modulJakBudujemyPomocKroki
                    ...textInBorder
                    ...banner
                    ...bannerZmienneZdjecia
                    ...bannerPodstronaProjektu
                    ...bannerFilm
                    ...cytatNaZdjeciu
                    ...zdjeciePoPrawej
                    ...pictograms
                    ...listaDzialan
                    ...modulFilm
                    ...modulGaleria
                    ...ktoPomaga
                    ...komuPomagamy
                    ...nastepnyProjekt
                    ...zobaczRowniez
                    ...jestesmyWdzieczni
                    ...opisZTlem
                    ...naglowekZTlem
                    ...modulKalkulator
                    ...modulKalkulatorRozbudowany
					...zakres
                    __typename
                }
            }
        }
        pojedyncze: allCraftPojedynczePojedynczeEntry(filter: { remoteId: { eq: $remoteId }, language: { eq: $site } }) {
            nodes {
                wybierzMenu
                wybierzLogo
                wybierzModul {
                    ...slider
                    ...buildingHelp
                    ...whereWeHelp
                    ...projectCard
                    ...sectionName
                    ...wizytowkaAktualnosci
                    ...newsletter
                    ...historieOsob
                    ...dolnyBaner
                    ...modulJakBudujemyPomocKroki
                    ...textInBorder
                    ...banner
                    ...bannerZmienneZdjecia
                    ...obecniePomagamy
                    ...wizytowkiNaPodstronieAktualnosci
                    ...modulZarzad
                    ...zdjeciePoPrawej
                    ...piktogramOpisWysrodkowane
                    ...plikiDoPobraniaKafelki
                    ...plikiDoPobraniaLista
                    ...modulKontakt
                    ...zdjeciaDoPobraniaKafelki
                    ...modulMapa
                    ...kontaktZdjeciePoLewej
                    ...banerPrzyciskPowrotNaglowek
                    ...zdjeciaDoPobrania
                    ...kontaktInformacje
                    ...naszaHistoria
                    ...wizytowkiSzkolen
                    ...zobaczRowniez
                    ...bannerFilm
                    ...jestesmyWdzieczni
                    ...wplaty
                    ...opisZTlem
                    ...naglowekZTlem
                    ...modulNajnowszeWzmiankiOPmm
                    ...modulWszystkieWzmiankiWMediach
                    ...modulSocialMedia
                    ...modul404
                    ...modulStartSub
                    ...modulCancelSub
                    ...modulThanksPage
                    ...modulKafelkiPiktogramOpisLink
                    ...modulBanerCzteryPrzyciski
                    ...modulZdjecieNaglowekOpisPrzycisk
                    __typename
                }
            }
        }
        aktualnosci: allCraftAktualnosciAktualnosciEntry(filter: { remoteId: { eq: $remoteId }, language: { eq: $site } }) {
            nodes {
                postDate
                ...modulBanerNaAktualnosci
                ... on Craft_aktualnosci_aktualnosci_Entry {
                    __typename
                    wybierzLogo
                    wybierzMenu
                    ...trescPodstrony
                }
                __typename
            }
        }
        aktualnosciWszystkie: allCraftAktualnosciAktualnosciEntry(
            filter: { language: { eq: $site } }
            sort: { fields: postDate, order: DESC }
        ) {
            nodes {
                __typename
                ...aktualnosci
            }
        }
        wzmiankiWMediach: allCraftWzmiankiWMediachWzmiankiWMediachEntry(filter: { language: { eq: $site } }) {
            nodes {
                __typename
                postDate
                ...wzmianki
            }
        }   wzmiankiWMediach: allCraftWzmiankiWMediachWzmiankiWMediachEntry(filter: { language: { eq: $site } }) {
            nodes {
                __typename
                postDate
                ...wzmianki
            }
        }
        szkolenia: allCraftSzkoleniaSzkoleniaEntry(filter: { remoteId: { eq: $remoteId }, language: { eq: $site } }) {
            nodes {
                __typename
                ... on Craft_szkolenia_szkolenia_Entry {
                    wybierzModul {
                        ...slider
                        ...buildingHelp
                        ...whereWeHelp
                        ...projectCard
                        ...sectionName
                        ...wizytowkaAktualnosci
                        ...newsletter
                        ...historieOsob
                        ...dolnyBaner
                        ...modulJakBudujemyPomocKroki
                        ...textInBorder
                        ...banner
                        ...bannerZmienneZdjecia
                        ...obecniePomagamy
                        ...wizytowkiNaPodstronieAktualnosci
                        ...modulZarzad
                        ...zdjeciePoPrawej
                        ...piktogramOpisWysrodkowane
                        ...plikiDoPobraniaKafelki
                        ...plikiDoPobraniaLista
                        ...modulKontakt
                        ...zdjeciaDoPobraniaKafelki
                        ...modulMapa
                        ...kontaktZdjeciePoLewej
                        ...banerPrzyciskPowrotNaglowek
                        ...zdjeciaDoPobrania
                        ...kontaktInformacje
                        ...naszaHistoria
                        ...wizytowkiSzkolen
                        ...oKursie
                        ...trener
                        ...zakres
                        ...zapisy
                        ...opinie
                        ...bannerPodstronaProjektu
                        ...zobaczRowniez
                        ...opisZTlem
                        ...naglowekZTlem
                        ...modulFilm
                        __typename
                    }
                }
            }
        }
        misjeSpecjalne: allCraftMisjeSpecjalneMisjeSpecjalneEntry(filter: { remoteId: { eq: $remoteId }, language: { eq: $site } }) {
            nodes {
                __typename
                ... on Craft_misjeSpecjalne_misjeSpecjalne_Entry {
                    wybierzMenu
                    wybierzLogo
                    wybierzModul {
                        ...slider
                        ...buildingHelp
                        ...whereWeHelp
                        ...projectCard
                        ...sectionName
                        ...wizytowkaAktualnosci
                        ...newsletter
                        ...historieOsob
                        ...dolnyBaner
                        ...modulJakBudujemyPomocKroki
                        ...textInBorder
                        ...banner
                        ...bannerZmienneZdjecia
                        ...obecniePomagamy
                        ...wizytowkiNaPodstronieAktualnosci
                        ...modulZarzad
                        ...zdjeciePoPrawej
                        ...piktogramOpisWysrodkowane
                        ...plikiDoPobraniaKafelki
                        ...plikiDoPobraniaLista
                        ...modulKontakt
                        ...zdjeciaDoPobraniaKafelki
                        ...modulMapa
                        ...kontaktZdjeciePoLewej
                        ...banerPrzyciskPowrotNaglowek
                        ...zdjeciaDoPobrania
                        ...kontaktInformacje
                        ...naszaHistoria
                        ...wizytowkiSzkolen
                        ...modulFilm
                        ...banerAkcjeSpecjalne
                        ...opisZTlem
                        ...naglowekZTlem
                        ...modulKalkulator
                        ...modulKalkulatorRozbudowany
                        ...zobaczRowniez
                        __typename
                    }
                }
            }
        }
        akcje: allCraftAkcjeAkcjeEntry(filter: { remoteId: { eq: $remoteId }, language: { eq: $site } }) {
            nodes {
                __typename
                ... on Craft_akcje_akcje_Entry {
                    wybierzMenu
                    wybierzLogo
                    wybierzModul {
                        ...slider
                        ...buildingHelp
                        ...whereWeHelp
                        ...projectCard
                        ...sectionName
                        ...wizytowkaAktualnosci
                        ...newsletter
                        ...historieOsob
                        ...dolnyBaner
                        ...modulJakBudujemyPomocKroki
                        ...textInBorder
                        ...banner
                        ...bannerZmienneZdjecia
                        ...obecniePomagamy
                        ...wizytowkiNaPodstronieAktualnosci
                        ...modulZarzad
                        ...zdjeciePoPrawej
                        ...piktogramOpisWysrodkowane
                        ...plikiDoPobraniaKafelki
                        ...plikiDoPobraniaLista
                        ...modulKontakt
                        ...zdjeciaDoPobraniaKafelki
                        ...modulMapa
                        ...kontaktZdjeciePoLewej
                        ...banerPrzyciskPowrotNaglowek
                        ...zdjeciaDoPobrania
                        ...kontaktInformacje
                        ...naszaHistoria
                        ...wizytowkiSzkolen
                        ...modulFilm
                        ...banerAkcjeSpecjalne
                        ...opisZTlem
                        ...naglowekZTlem
                        ...modulKalkulator
                        ...modulKalkulatorRozbudowany
                        __typename
                    }
                }
            }
        }
        dzialania: allCraftDzialaniaDzialaniaEntry(filter: { remoteId: { eq: $remoteId }, language: { eq: $site } }) {
            nodes {
                ...modulBanerNaDzialaniach
                ... on Craft_dzialania_dzialania_Entry {
                    wybierzLogo
                    wybierzMenu
                    __typename
                    ...trescPodstronyDzialania
                }
                __typename
            }
        }
        politykaPrywatnosci: allCraftPolitykaPrywatnosciPolitykaPrywatnosciEntry(
            filter: { remoteId: { eq: $remoteId }, language: { eq: $site } }
        ) {
            nodes {
                ... on Craft_politykaPrywatnosci_politykaPrywatnosci_Entry {
                    wybierzMenu
                    wybierzLogo
                    modulBanerNaAktualnosci {
                        __typename
                        ... on Craft_modulBanerNaAktualnosci_baner_BlockType {
                            __typename
                            naglowek
                            opis
                        }
                    }
                    trescPodstrony {
                        __typename
                        ... on Craft_trescPodstrony_naglowek_BlockType {
                            __typename
                            naglowek
                        }
                        ... on Craft_trescPodstrony_opis_BlockType {
                            __typename
                            opis
                        }
                        ... on Craft_trescPodstrony_zdjecieDuze_BlockType {
                            __typename
                            zdjecie {
                                url
                            }
                            zdjecieMobile {
                                url
                            }
                        }
                        ... on Craft_trescPodstrony_zdjecie_BlockType {
                            __typename
                            zdjecie {
                                url
                            }
                            zdjecieMobile {
                                url
                            }
                        }
                        ... on Craft_trescPodstrony_przycisk_BlockType {
                            __typename
                            przyciskNapis
                            przyciskLink
                            przyciskWpis {
                                slug
                            }
                            przyciskRodzaj {
                                __typename
                                ... on Craft_rodzajePrzyciskow_rodzajePrzyciskow_Entry {
                                    title
                                }
                            }
                        }
                        ... on Craft_trescPodstrony_modul_BlockType {
                            __typename
                            modul {
                                __typename
                                ...modulFilm
                                ...modulGaleria
                                ...dolnyBaner
                            }
                        }
                        ... on Craft_trescPodstrony_margines_BlockType {
                            __typename
                            margines {
                                title
                            }
                        }
                    }
                }
                __typename
            }
        }
    }
`
