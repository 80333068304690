import React, { Component } from 'react';
import VideoModule from '../videoModule/VideoModule';
import Gallery from '../gallery/Gallery';
import BottomBaner from '../bottomBaner/BottomBaner';
import ShareBtns from './ShareBtns';
import Calculator from '../calculator/Calculator';
import HeaderDescriptionBackground from '../common/headerDescriptionBackground';
import Faq from '../faq/Faq';
import FilesToDownloadTiles from '../filesToDownloadTiles/filesToDownloadTiles';
import PhotoContactOnLeftWithDescription from '../photoContactOnLeftWithDescription/PhotoContactOnLeftWithDescription';
import SeeAlso from '../seeAlso/SeeAlso';
import CalculatorExtended from '../calculatorExtended/CalculatorExtended';
import HeaderWithBackground from '../headerWithBackground/HeaderWithBackground';
import DescriptionWithBackground from '../descriptionWithBackground/DescriptionWithBackground';
import SectionName from '../sectionName/sectionName';

class ContentModules extends Component {
	render() {
		const { data, activeSite } = this.props;
		const moduleData = (data && data.modul) || '';

		if (Array.isArray(moduleData)) {
			return moduleData.map((item, key) => {
				switch (item.__typename) {
					case 'Craft_moduly_modulFilm_Entry':
						return <VideoModule data={item} />;
					case 'Craft_moduly_modulGaleria_Entry':
						return <Gallery data={item} />;
					case 'Craft_moduly_modulDolnyBaner_Entry':
						return <BottomBaner data={item} activeSite={activeSite} />;
					case 'Craft_moduly_modulAktualnoscPojedynczaPodzielSie_Entry':
						return <ShareBtns data={item} activeSite={activeSite} />;
					case 'Craft_moduly_modulKalkulator_Entry':
						return <Calculator activeSite={activeSite} data={item} />;
					case 'Craft_moduly_modulNaglowekOpisBackground_Entry':
						return <HeaderDescriptionBackground data={item} activeSite={activeSite} />;
					case 'Craft_moduly_modulFaq_Entry':
						return <Faq data={item} activeSite={activeSite} />;
					case 'Craft_moduly_modulPlikiDoPobraniaKafelki_Entry':
						return <FilesToDownloadTiles data={item} activeSite={activeSite} />;
					case 'Craft_moduly_modulZdjecieIKontaktPoLewejOpis_Entry':
						return <PhotoContactOnLeftWithDescription data={item} activeSite={activeSite} />;
					case 'Craft_moduly_modulZobaczRowniez_Entry':
						return <SeeAlso activeSite={activeSite} data={item} />;
					case 'Craft_moduly_modulKalkulatorRozbudowany_Entry':
						return <CalculatorExtended activeSite={activeSite} data={item} />;
					case 'Craft_moduly_modulNaglowekBackground_Entry':
						return <HeaderWithBackground activeSite={activeSite} data={item} />;
					case 'Craft_moduly_modulOpisBackground_Entry':
						return <DescriptionWithBackground activeSite={activeSite} data={item} />;
					case 'Craft_moduly_modulNazwaSekcji_Entry':
						return <SectionName data={item} activeSite={activeSite} />;
					default:
						return <h1 key={key}>Brak obsługi dla {item?.__typename}</h1>;
				}
			});
		} else {
			return null;
		}
	}
}

export default ContentModules;
