import React from "react"
import styled from "styled-components"
import setBackgroundColor from "../../utils/setBackgroundColor";
import setFontColor from "../../utils/setFontColor";
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;	
	z-index: 100;
	&.is-narrow {
		justify-content: flex-end;
	}
`;

const ModuleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-start;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 10px;
  background-color: ${({background, theme}) => setBackgroundColor(background, theme)};
  width: 100%;
  z-index: 100;

	&.is-narrow {
		width: 100%;
		justify-content: flex-start;
		padding-left: 10px;
		padding-top: ${({paddingTop}) => paddingTop}px;
		padding-bottom: ${({paddingBottom}) => paddingBottom}px;
		@media(min-width: 768px) {
			width: 60%;
			padding-left: 35px;
			position: absolute;
		}
		@media(min-width: 1280px) {
			width: 50%;
			padding-left: 60px;
		}
	}
	&.center {
		justify-content: center;
	}
  
  @media(min-width: 768px){
  	padding-left: 35px;
  	padding-right: 35px;
  	padding-top: ${({paddingTop}) => paddingTop}px;
  padding-bottom: ${({paddingBottom}) => paddingBottom}px;
  }
  @media(min-width: 1280px){
 	padding-left: 50px;
 	padding-right: 50px;
  }
  @media(min-width: 1920px){
 	padding-left: 100px;
 	padding-right: 100px;
  }
`;

const Title = styled.h5`
	text-transform: uppercase;
	color: ${({background, theme}) => setFontColor(background, theme)};
`;

class SectionName extends React.Component {

	render() {
		const {data} = this.props;
		// const {data, isNew} = this.props;
		// const sectionNameData = isNew ? (data && data.modulNazwaSekcjiNowa && data.modulNazwaSekcjiNowa[0])  || "" : (data && data.modulNazwaSekcji && data.modulNazwaSekcji[0]) || ""
		const sectionNameData = (data && data.modulNazwaSekcji && data.modulNazwaSekcji[0]) || ""
		const sectionNameText = (sectionNameData.tekst)
		const narrowContainerClass = (sectionNameText === "newsletter") ? "is-narrow" : "";
		const title = (sectionNameData && sectionNameData.tekst) || '';
		const anchor = (sectionNameData && sectionNameData.kotwica) || '';
		const centerClass = (anchor === "kotwica_następny_projekt" || anchor === "kotwica_zobacz_rowniez") ? " center" : "";
		const background = (sectionNameData && sectionNameData.background && sectionNameData.background[0] &&
			sectionNameData.background[0].title) || '';
		const paddingTopType = (sectionNameData && sectionNameData.marginesGorny && sectionNameData.marginesGorny[0] &&
			sectionNameData.marginesGorny[0].title) || '';
		const paddingBottomType = (sectionNameData &&
			sectionNameData.marginesDolny && sectionNameData.marginesDolny[0] &&
			sectionNameData.marginesDolny[0].title) || '';

		return (
			<Wrapper className={narrowContainerClass} id={anchor}>
				<ModuleWrapper className={narrowContainerClass + centerClass} background={background} paddingTop={getPaddingTop(paddingTopType)}
							   paddingBottom={getPaddingBottom(paddingBottomType)}
							   anchor={anchor}>
					<Title background={background}>{title}</Title>
				</ModuleWrapper>
			</Wrapper>
		)
	}
}

export default SectionName
