import React from "react"
import styled from "styled-components"
import {ChartInfoWrapper, ChartInfo} from "../../../common/Charts";

const PictogramPercentageChartWrapper = styled.div`
	margin-top: 0;
	
	@media(min-width: 1920px){
		margin-top: 60px;
	}
`

const PictogramChartWrapper = styled.div`
	position: relative;
`

const Pictogram = styled.img`
	position: absolute;
	height: 60px;
	
	@media(min-width: 1920px){
		height: unset;
	}
`


const PictogramPercentage = styled.div`
	position: absolute;
	top: 4px;
	left: 5px;
	min-width: 75px;
	text-align: center;
	letter-spacing: 8px;
	color: ${({theme}) => theme.colors.pmmWhite};
	font-weight: ${({theme}) => theme.fontWeight.bold};
	font-size: 40px;
	line-height: 45px;
	
	&:before {
		content: '%';
		position: absolute;
		right: -50px;
		color: ${({theme}) => theme.colors.pmmWhite};
		font-weight: ${({theme}) => theme.fontWeight.bold};
		font-size: 35px;
	}
	
	&.small-percentage {
		text-align: end;
	}
	
	@media(min-width: 1920px){
		left: 14px;
		font-size: 50px;
		line-height: 50px;
		&:before {
			right: -65px;
			font-size: 45px;
		}
	}
`


const CirclePercentageChartWrapper = styled.div`
	//margin-left: 10px;
	
	
	@media(min-width: 768px){
	 	margin-left: 0;
	}
	@media(min-width: 1920px){
		margin-top: 60px;
	 	margin-left: 0;
	}
`

const CircleChartWrapper = styled.div`
	position: relative;
`

const CirclePercentage = styled.div`
	position: absolute;
	color: ${({theme}) => theme.colors.pmmOrange};
	font-weight: ${({theme}) => theme.fontWeight.bold};
	font-size: 40px;
	line-height: 45px;
	text-align: center;
	min-width: 60px;
	
	&:before {
		content: '%';
		position: absolute;
		right: -45px;
		color: ${({theme}) => theme.colors.pmmWhite};
		font-weight: ${({theme}) => theme.fontWeight.bold};
		font-size: 35px;
		line-height: 43px;
	}
	&:after {
		content: '';
		position: absolute;
		background-color: ${({theme}) => theme.colors.pmmWhite};
		top: -12px;
		left: -5px;
		z-index: -1;
		width: 70px;
		height: 70px;
		border-radius: 100%;
	}
	
	@media(min-width: 1920px){
		font-size: 50px;
		line-height: 50px;
		&:before {
			font-size: 45px;
			right: -60px;
		}
		&:after {
			top: -15px;
			left: -10px;
			width: 80px;
			height: 80px;
		}
	}
`

const PercentageText = styled.div`
	color: ${({theme}) => theme.colors.pmmWhite};
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
	font-size: ${({theme}) => theme.fontSize.f5};
	min-height: 75px;
	padding-left: 120px;
	
	&.pictogram {
		color: ${({theme}) => theme.colors.pmmOrange};
		padding-left: 130px;
		line-height: 45px;
	}

	@media(min-width: 1920px){
		font-size: ${({theme}) => theme.fontSize.f2};
		padding-left: 135px;
		
		&.pictogram {
			padding-left: 160px;
			line-height: unset;
		}
	}
`


class InfoPercentage extends React.Component {

	render() {
		const {chartPercentage, chartPercentageText, chartDescription, chartInfoPictogram } = this.props

		return (
			<>
				{chartInfoPictogram === "" && <CirclePercentageChartWrapper>
					<CircleChartWrapper>
						<CirclePercentage>{chartPercentage}</CirclePercentage>
						<PercentageText>{chartPercentageText}
						</PercentageText>
					</CircleChartWrapper>
				</CirclePercentageChartWrapper>}

				{chartInfoPictogram && <PictogramPercentageChartWrapper>
					<PictogramChartWrapper>
						<Pictogram src={chartInfoPictogram}/>
						<PictogramPercentage className={chartPercentage < 10 ? "small-percentage" : ""}>{chartPercentage}</PictogramPercentage>
						<PercentageText className="pictogram">{chartPercentageText}</PercentageText>
					</PictogramChartWrapper>
				</PictogramPercentageChartWrapper>}

				<ChartInfoWrapper>
					<ChartInfo>{chartDescription}</ChartInfo>
				</ChartInfoWrapper>
			</>
		)
	}
}

export default InfoPercentage
