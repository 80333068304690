import React, {Component} from 'react'
import styled from "styled-components"

const CitationPhotoWrapper = styled.div`
	color: ${({theme}) => theme.colors.pmmWhite};
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding: 270px 0 30px;
	
	@media(min-width: 768px) {
		padding: 405px 30px 40px;
	}
	@media(min-width: 769px) {
		display: flex;
		align-items: flex-end;
		padding: 0 30px 40px;
		height: 100vh;
	}
	@media(orientation: landscape) and (max-width: 1024px) {
  		background-image:url( ${({ bgImgMobileLandscape }) => bgImgMobileLandscape});
  	}
  	@media(orientation: portrait) and (max-width: 1024px) {
  		background-image: url( ${({ bgImgMobile }) => bgImgMobile});
  	}
	@media(min-width: 1025px) {
		background-image: url( ${({bgImg}) => bgImg});
		padding: 0 50px 50px;
	}
	@media(min-width: 1280px) {
		padding: 0 50px 60px;
	}
	@media(min-width: 1920px) {
		padding: 0 100px 70px;
	}
`

const InfoWrapper = styled.div`
	padding: 30px 25px 20px;
	border: 3px solid ${({theme}) => theme.colors.pmmOrange};
	@media(min-width: 768px) {
		max-width: 60%;
		padding: 45px 25px 20px;
		overflow: visible;
	}
	@media(min-width: 1280px) {
		max-width: 40%;
	}
	@media(min-width: 1920px) {
		max-width: 30%;
		padding: 50px 30px 40px;
	}
`

const Citation = styled.div`
	font-weight: ${({theme}) => theme.fontWeight.medium};
	font-size: ${({theme}) => theme.fontSize.f6};
	line-height: ${({theme}) => theme.lineHeight.line7};
	
	@media(min-width: 768px) {
		font-size: ${({theme}) => theme.fontSize.f16};
		line-height: ${({theme}) => theme.lineHeight.line5};
		width: 120%;
	}
	@media(min-width: 1280px) {
		width: 100%;
	}
	@media(min-width: 1366px) {
		width: 110%;
	}
	@media(min-width: 1920px) {
		font-size: ${({theme}) => theme.fontSize.f5};
		line-height: ${({theme}) => theme.lineHeight.line34};
	}
`

const Underline = styled.span`
    padding: 6px 0;
    background-color: #FF4124;
    box-shadow: 10px 0 0 #FF4124 ,-10px 0 0 #FF4124 ;
`;

const AuthorWrapper = styled.div`
	padding-top: 5px;
	display: flex;
	font-weight: ${({theme}) => theme.fontWeight.regular};
	font-size: ${({theme}) => theme.fontSize.f10};
	line-height: ${({theme}) => theme.lineHeight.line18};
	font-style: italic;
	white-space: nowrap;
	
	@media(min-width: 768px) {
		font-size: ${({theme}) => theme.fontSize.f4};
	}
	@media(min-width: 1920px) {
		padding-top: 10px;
		font-size: ${({theme}) => theme.fontSize.f3};
		line-height: ${({theme}) => theme.lineHeight.line6};
	}
`

const Author = styled.div`
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
	padding-right: 5px;
`

const AuthorInfo = styled.div`
`

class CitationInPhoto extends Component {

	render() {
		const {data} = this.props
		const citationData = (data && data.modulCytatNaZdjeciu && data.modulCytatNaZdjeciu[0]) || ""
		const bgPhoto = (citationData && citationData.zdjeciePoziome && citationData.zdjeciePoziome[0] && citationData.zdjeciePoziome[0].url) || ""
		const bgImgMobile = (citationData && citationData.zdjecieMobile && citationData.zdjecieMobile[0] && citationData.zdjecieMobile[0].url) || ""
		const bgPhotoMobileLandscape = (citationData && citationData.zdjeciePoziomeMobile && citationData.zdjeciePoziomeMobile[0] && citationData.zdjeciePoziomeMobile[0].url) || ""
		const citation = (citationData && citationData.cytat) || ""
		const author = (citationData && citationData.autor) || ""
		const authorInfo = (citationData && citationData.informacjaOAutorze) || ""

		return (
			<CitationPhotoWrapper bgImg={bgPhoto} bgImgMobile={bgImgMobile} bgImgMobileLandscape={bgPhotoMobileLandscape}>
				<InfoWrapper>
					<Citation>
						<Underline>
							{citation}
						</Underline>
					</Citation>
					<AuthorWrapper>
						<Author>{author},</Author>
						<AuthorInfo>{authorInfo}</AuthorInfo>
					</AuthorWrapper>
				</InfoWrapper>
			</CitationPhotoWrapper>
		)
	}
}

export default CitationInPhoto;
