import React, {Component} from 'react'
import styled from "styled-components"
import {Link} from "gatsby";
import getSlug from "../../utils/getSlug";
import {BtnWithIcon, BtnWrapperForBgPreload} from "../news/SingleNewsBanner";

const ModuleWrapper = styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
	height: 100vh;
	background-size: cover;
	background-position: center;

    &:before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: 1;
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 10%,transparent 30%,transparent 70%, rgba(0, 0, 0, 0.4) 100%);
	}
  	@media(orientation: landscape) and (max-width: 1024px) {
  		background-image: url( ${({bgImgMobileLandscape}) => bgImgMobileLandscape});
  	}
  	@media(orientation: portrait) and (max-width: 1024px) {
  		background-image: url( ${({bgImgMobile}) => bgImgMobile});
  	}
  	@media(min-width: 1025px) {
  		background-image: url( ${({bgImg}) => bgImg});
  	}
    @media(min-width: 1280px) {
	    &:before {
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 11;
			background-image: linear-gradient(to right, rgba(0, 0, 0, .4) 10%,transparent 40%);
		}
  	}
`;

const TextBoxWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 260px;
	position: absolute;
	left: 15px;
	bottom: 10%;
	z-index: 100;
	background-color: ${({theme}) => theme.colors.pmmOrange};
	padding: 50px 20px 30px;
	@media(min-width: 768px) {
		//min-height: calc(30% + 45px);
		width: 300px;
		left: 30px;
		bottom: 12%;
		padding: 0px 25px 40px;
	}
	@media(min-width: 1280px) {
		//min-height: calc(40% + 30px);
		width: 292px;
		left: 50px;
		bottom: 8%;
	}
	@media(min-width: 1920px) {
		width: 394px;
		left: 100px;
		bottom: 60px;
		padding: 0 40px 65px;
	}
	@media(min-width: 2100px) {
		min-height: 260px;
	}
`;

const BackToAllWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding: 20px 0 50px;
	cursor: pointer;
	width: 100%;
	position: absolute;
	top: -70px;
	text-decoration: none;
	@media(min-width: 768px) {
		position: relative;
		top: 0;
	}
	@media(min-width: 1920px) {
		padding-bottom: 90px;
		padding-top: 30px;
	}
`;


const Header = styled.p`
	color: ${({theme}) => theme.colors.pmmWhite};
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
	font-size: 28px;
	line-height: 55px;
	text-transform: uppercase;
	position: relative;
	@media(min-width: 768px) {
		font-size: 30px;
		line-height: 39px;
	}
	@media(min-width: 1920px) {
		font-size: 40px;
		line-height: 49px;
	}
	&:before {
		content: '';
		position: absolute;
		top: -10px;
		left: 0;
		width: 60px;
		height: 3px;
		background-color: ${({theme}) => theme.colors.pmmWhite};
		@media(min-width: 768px) {
			top: -15px;
			width: 80px;
			height: 4px;
		}
		@media(min-width: 1920px) {
			width: 100px;
			height: 5px;
			top: -25px;
		}
	}	
`;

class BannerBackBtnAndHeader extends Component {

	render() {
		const {data, photosToDownloadBtn, activeSite} = this.props;
		const btnsData =  (photosToDownloadBtn && photosToDownloadBtn[0]) || ""
		const photosToDownloadBackBtnData = (btnsData.przyciskPowrot && btnsData.przyciskPowrot[0]) || ""
		const bannerData = (data && data.modulBanerPrzyciskPowrotuNaglowek && data.modulBanerPrzyciskPowrotuNaglowek[0]) || ""
		const header = bannerData.naglowek || "";
		const backgroundPhotoMobile = (bannerData.zdjecieWTleMobile && bannerData.zdjecieWTleMobile[0] &&
			bannerData.zdjecieWTleMobile[0].url) || "";
		const backgroundPhoto = (bannerData.zdjecieWTle && bannerData.zdjecieWTle[0] &&
			bannerData.zdjecieWTle[0].url) || "";
		const backgroundPhotoMobileLandscape = (bannerData.zdjecieWTleMobilePoziome && bannerData.zdjecieWTleMobilePoziome[0] &&
			bannerData.zdjecieWTleMobilePoziome[0].url) || "";

		const textBtn = (photosToDownloadBackBtnData && photosToDownloadBackBtnData.przyciskNapis) || ""
		const toBtn = (photosToDownloadBackBtnData && photosToDownloadBackBtnData.przyciskWpis && photosToDownloadBackBtnData.przyciskWpis[0] && photosToDownloadBackBtnData.przyciskWpis[0].slug) || ""
		const hrefBtn = (photosToDownloadBackBtnData && photosToDownloadBackBtnData.przyciskLink) || ""
		const iconBtn = (photosToDownloadBackBtnData && photosToDownloadBackBtnData.przyciskIkona && photosToDownloadBackBtnData.przyciskIkona[0] && photosToDownloadBackBtnData.przyciskIkona[0].url) || ""
		const iconHoverBtn = (photosToDownloadBackBtnData && photosToDownloadBackBtnData.przyciskIkonaPoNajechaniu && photosToDownloadBackBtnData.przyciskIkonaPoNajechaniu[0] && photosToDownloadBackBtnData.przyciskIkonaPoNajechaniu[0].url) || ""
		return (
			<ModuleWrapper bgImg={backgroundPhoto} bgImgMobile={backgroundPhotoMobile}
						   bgImgMobileLandscape={backgroundPhotoMobileLandscape}>
				<TextBoxWrapper>
					<BackToAllWrapper>
						<BtnWrapperForBgPreload iconHoverBtn={iconHoverBtn}>
							{hrefBtn && <BtnWithIcon href={hrefBtn} iconBtn={iconBtn} iconHoverBtn={iconHoverBtn}
													 as={"a"} className='dark-violet'>{textBtn}</BtnWithIcon>}
							{toBtn && <BtnWithIcon as={Link} iconBtn={iconBtn} iconHoverBtn={iconHoverBtn}
												   to={`/${getSlug(toBtn, activeSite)}`}
												   className='dark-violet'>{textBtn}</BtnWithIcon>}
							{!hrefBtn && !toBtn &&
							<BtnWithIcon iconBtn={iconBtn} iconHoverBtn={iconHoverBtn}
										 className='dark-violet'>{textBtn}</BtnWithIcon>}
						</BtnWrapperForBgPreload>
					</BackToAllWrapper>
					<Header>{header}</Header>
				</TextBoxWrapper>
			</ModuleWrapper>
		)
	}
}

export default BannerBackBtnAndHeader;
