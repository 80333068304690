import React, {Component} from 'react'
import lottie from "lottie-web";
import styled from "styled-components";

const AnimationWrapper = styled.div`
	height: 235px;
	transform: scale(1.4);
	@media(min-width: 768px) {
		height: 500px;
		transform: none;
	}
	@media(min-width: 1920px) {
		height: 600px;
	}
`;

class GeneralAnimationLoop extends Component {
    animation;


    componentDidMount() {
        const {animationJson} = this.props
        this.animation = lottie.loadAnimation({
            wrapper: this.animationEl,
            animType: 'svg',
            autoplay: true,
            prerender: true,
            loop: true,
            path: animationJson
        });
    }

    render() {
        return (
            <AnimationWrapper className="animation-loop" ref={node => this.animationEl = node}/>
        )
    }
}

export default GeneralAnimationLoop;
