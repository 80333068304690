import React from 'react';
import HomeSlider from './homeSlider/HomeSlider';
import BuildingHelp from './buildingHelp/BuildingHelp';
import HeaderDescriptionBackground from './common/headerDescriptionBackground';
import SectionName from './sectionName/sectionName';
import News from './news/news';
import ProjectCard from './projectCard/ProjectCard';
import Newsletter from './newsletter/Newsletter';
import StoriesOfPeople from './storiesOfPeople/StoriesOfPeople';
import BottomBaner from './bottomBaner/BottomBaner';
import HowWeBuildHelp from './howWeBuildHelp/HowWeBuildHelp';
import TextInBorder from './textInBorder/TextInBorder';
import SubpageBanner from './subpageBanner/SubpageBanner';
import SubpageBannerMultipleImages from './subpageBannerMultipleImages/SubpageBannerMultipleImages';
import SubpageBannerWithBack from './subpageBannerWithBack/SubpageBannerWithBack';
import NowWeAreHelping from './nowWeAreHelping/NowWeAreHelping';
import CitationInPhoto from './citationInPhoto/CitationInPhoto';
import WhyWeHelp from './whyWeHelp/WhyWeHelp';
import Pictograms from './pictograms/Pictograms';
import ListOfActions from './listOfActions/ListOfActions';
import VideoModule from './videoModule/VideoModule';
import Gallery from './gallery/Gallery';
import WhoHelps from './whoHelps/WhoHelps';
import SingleNews from './news/SingleNews';
import NewsCardsOnSubpage from './newsCardsOnSubpage/newsCardsOnSubpage';
import WhoWeHelp from './whoWeHelp/WhoWeHelp';
import ManagementModule from './managamentModule/ManagementModule';
import PictogramDescriptionCentered from './pictogramDescriptionCentered/PictogramDescriptionCentered';
import FilesToDownloadTiles from './filesToDownloadTiles/filesToDownloadTiles';
import FilesToDownloadList from './filesToDownloadList/FilesToDownloadList';
import Contact from './contactModule/Contact';
import MapModule from './mapModule/MapModule';
import ContactPhotoOnLeft from './contactPhotoOnLeft/ContactPhotoOnLeft';
import BannerBackBtnAndHeader from './bannerBackBtnAndHeader/BannerBackBtnAndHeader';
import PhotosToDownload from './photosToDownload/PhotosToDownload';
import ContactInfo from './contactInfo/ContactInfo';
import OurHistory from './ourHistory/OurHistory';
import CoursesCards from './courses/CoursesCards';
import NextProject from './nextProject/NextProject';
import AboutCourse from './courses/AboutCourse';
import Coach from './courses/Coach';
import Scope from './scope/Scope';
import Opinions from './courses/Opinions';
import Enrollment from './enrollment/Enrollment';
import SeeAlso from './seeAlso/SeeAlso';
import BannerVideo from './bannerVideo/BannerVideo';
import WeAreGrateful from './weAreGrateful/WeAreGrateful';
import CashFlow from './cashFlow/CashFlow';
import BannerSpecialActions from './bannerSpecialActions/BannerSpecialActions';
import HeaderWithBackground from './headerWithBackground/HeaderWithBackground';
import DescriptionWithBackground from './descriptionWithBackground/DescriptionWithBackground';
import MentionsAboutPmm from './mentionsAboutPmm/MentionsAboutPmm';
import SocialMediaPosts from './socialMediaPosts/SocialMediaPosts';
import Component404 from './component404/Component404';
import Calculator from './calculator/Calculator';
import SubscriptionInfo from './subscriptionInfo/SubscriptionInfo';
import ThankYou from './thankYou/ThankYou';
import TilesWithPictogramsGrid from './tilesWithPictogramsGrid/TilesWithPictogramsGrid';
import BannerWithButtons from './bannerWithButtons/BannerWithButtons';
// import Faq from './faq/Faq'
import PhotoHeaderDescriptionBtn from './photoHeaderDescriptionBtn/PhotoHeaderDescriptionBtn';
import CalculatorExtended from './calculatorExtended/CalculatorExtended';

const ExtendedContent = props => {
	const {
		data,
		activeSite,
		piktogramyData,
		newsletterModalData,
		pageContext,
		newsBtns,
		actWithUsBackButton,
		specialActions,
		projectsBackBtn,
		downloadIcon,
		photosToDownloadBtn,
		countryCategories,
		yearCategories,
		formularz,
		szkolenie,
		hasSubmenu,
		aktualnosciWszystkie,
		banerPlatnosci,
		processingPersonalData,
		stickyBannerMainPage,
		wzmiankiWMediach,
		mentionsGlobal,
	} = props || [];

	if (Array.isArray(data)) {
		return data.map((block, i) => {
			switch (block.__typename) {
				case 'Craft_moduly_slider_Entry':
					return <HomeSlider key={i} data={block} activeSite={activeSite} />;
				case 'Craft_moduly_budujemyPomocPodSliderem_Entry':
					return <BuildingHelp key={i} data={block} activeSite={activeSite} stickyBannerMainPage={stickyBannerMainPage} />;
				case 'Craft_moduly_modulNaglowekOpisBackground_Entry':
					return <HeaderDescriptionBackground key={i} data={block} activeSite={activeSite} />;
				// case "Craft_moduly_modulNaglowekOpisBackgroundNowy_Entry":
				// 	return <HeaderDescriptionBackground isNew key={i} data={block} activeSite={activeSite}/>
				case 'Craft_moduly_projektWizytowka_Entry':
					return <ProjectCard key={i} {...props} data={block} piktogramyData={piktogramyData} activeSite={activeSite} />;
				case 'Craft_moduly_modulNazwaSekcji_Entry':
					return <SectionName key={i} {...props} data={block} activeSite={activeSite} />;
				// case "Craft_moduly_modulNazwaSekcjiNowa_Entry":
				// 	return <SectionName isNew key={i} {...props} data={block} activeSite={activeSite}/>
				case 'Craft_moduly_aktualnosciWizytowka_Entry':
					return <News key={i} data={block} aktualnosciWszystkie={aktualnosciWszystkie} activeSite={activeSite} />;
				case 'Craft_moduly_historieOsob_Entry':
					return <StoriesOfPeople key={i} data={block} activeSite={activeSite} />;
				case 'Craft_moduly_modulNewsletter_Entry':
					return (
						<Newsletter
							key={i}
							data={block}
							newsletterModalData={newsletterModalData}
							activeSite={activeSite}
							processingPersonalData={processingPersonalData}
						/>
					);
				case 'Craft_moduly_modulDolnyBaner_Entry':
					return <BottomBaner key={i} data={block} activeSite={activeSite} />;
				case 'Craft_moduly_modulJakBudujemyPomocKroki_Entry':
					return <HowWeBuildHelp key={i} data={block} activeSite={activeSite} />;
				case 'Craft_moduly_modulTekstWRamce_Entry':
					return <TextInBorder key={i} data={block} activeSite={activeSite} />;
				case 'Craft_moduly_modulBanerNaPodstronach_Entry':
					return <SubpageBanner key={i} data={block} activeSite={activeSite} pageContext={pageContext} />;
				case 'Craft_moduly_modulBanerPodstronaProjektu_Entry':
					return (
						<SubpageBannerWithBack
							key={i}
							data={block}
							activeSite={activeSite}
							projectsBackBtn={projectsBackBtn}
							szkolenie={szkolenie}
							pageContext={pageContext}
							hasSubmenu={hasSubmenu}
						/>
					);
				case 'Craft_moduly_modulBanerZmianaZdjec_Entry':
					return <SubpageBannerMultipleImages key={i} data={block} activeSite={activeSite} />;
				case 'Craft_moduly_modulObecniePomagamy_Entry':
					return (
						<NowWeAreHelping
							key={i}
							data={block}
							activeSite={activeSite}
							newsBtns={newsBtns}
							countryCategories={countryCategories}
							yearCategories={yearCategories}
							projectsBackBtn={projectsBackBtn}
						/>
					);
				case 'Craft_moduly_modulCytatNaZdjeciu_Entry':
					return <CitationInPhoto key={i} data={block} />;
				case 'Craft_moduly_modulZdjeciePoPrawej_Entry':
					return <WhyWeHelp key={i} data={block} />;
				case 'Craft_moduly_modul3Piktogramy_Entry':
					return <Pictograms key={i} data={block} />;
				case 'Craft_moduly_modulListaDzialan_Entry':
					return <ListOfActions key={i} data={block} />;
				case 'Craft_moduly_modulFilm_Entry':
					return <VideoModule key={i} data={block} />;
				case 'Craft_moduly_modulGaleria_Entry':
					return <Gallery key={i} data={block} />;
				case 'Craft_moduly_modulKtoPomaga_Entry':
					return <WhoHelps key={i} data={block} />;
				case 'Craft_moduly_modulWizytowkiNaPodstronieAktualnosci_Entry':
					return <NewsCardsOnSubpage key={i} data={block} aktualnosciWszystkie={aktualnosciWszystkie} activeSite={activeSite} />;
				case 'Craft_moduly_modulKomuPomagamySzczegoly_Entry':
					return <WhoWeHelp key={i} data={block} />;
				case 'Craft_moduly_modulZarzad_Entry':
					return <ManagementModule key={i} data={block} />;
				case 'Craft_moduly_modulPiktogramOpisWysrodkowane_Entry':
					return <PictogramDescriptionCentered key={i} data={block} />;
				case 'Craft_moduly_modulPlikiDoPobraniaKafelki_Entry':
					return <FilesToDownloadTiles activeSite={activeSite} key={i} data={block} />;
				case 'Craft_moduly_modulPlikiLista_Entry':
					return <FilesToDownloadList key={i} data={block} downloadIcon={downloadIcon} />;
				case 'Craft_moduly_modulKontakt_Entry':
					return <Contact key={i} data={block} formularz={formularz} processingPersonalData={processingPersonalData} />;
				case 'Craft_moduly_modulZdjeciaDoPobraniaKafelki_Entry':
					return <FilesToDownloadTiles activeSite={activeSite} key={i} data={block} />;
				case 'Craft_moduly_modulMapa_Entry':
					return <MapModule activeSite={activeSite} key={i} data={block} />;
				case 'Craft_moduly_modulKontaktZdjeciePoLewej_Entry':
					return <ContactPhotoOnLeft activeSite={activeSite} key={i} data={block} pageContext={pageContext} />;
				case 'Craft_moduly_modulBanerPrzyciskPowrotuNaglowek_Entry':
					return (
						<BannerBackBtnAndHeader
							activeSite={activeSite}
							key={i}
							data={block}
							pageContext={pageContext}
							photosToDownloadBtn={photosToDownloadBtn}
						/>
					);
				case 'Craft_moduly_modulZdjeciaDoPobrania_Entry':
					return (
						<PhotosToDownload
							activeSite={activeSite}
							key={i}
							data={block}
							pageContext={pageContext}
							photosToDownloadBtn={photosToDownloadBtn}
						/>
					);
				case 'Craft_moduly_modulKontaktInformacje_Entry':
					return <ContactInfo activeSite={activeSite} key={i} data={block} />;
				case 'Craft_moduly_modulNaszaHistoria_Entry':
					return <OurHistory activeSite={activeSite} key={i} data={block} piktogramyData={piktogramyData} />;
				case 'Craft_moduly_modulWizytowkiSzkolen_Entry':
					return <CoursesCards activeSite={activeSite} key={i} data={block} szkolenie={szkolenie} />;
				case 'Craft_moduly_modulNastepnyProjekt_Entry':
					return <NextProject activeSite={activeSite} key={i} data={block} />;
				case 'Craft_moduly_modulOKursie_Entry':
					return <AboutCourse activeSite={activeSite} key={i} data={block} szkolenie={szkolenie} />;
				case 'Craft_moduly_modulZakres_Entry':
					return <Scope activeSite={activeSite} key={i} data={block} />;
				case 'Craft_moduly_modulTrener_Entry':
					return <Coach activeSite={activeSite} key={i} data={block} />;
				case 'Craft_moduly_modulOpinie_Entry':
					return <Opinions activeSite={activeSite} key={i} data={block} />;
				case 'Craft_moduly_modulZapisy_Entry':
					return <Enrollment activeSite={activeSite} key={i} data={block} szkolenie={szkolenie} />;
				case 'Craft_moduly_modulZobaczRowniez_Entry':
					return <SeeAlso activeSite={activeSite} key={i} data={block} />;
				case 'Craft_moduly_modulBanerFilm_Entry':
					return (
						<BannerVideo
							activeSite={activeSite}
							key={i}
							data={block}
							banerPlatnosci={banerPlatnosci}
							pageContext={pageContext}
						/>
					);
				case 'Craft_moduly_modulPodBaneremJestesmyWdzieczni_Entry':
					return <WeAreGrateful activeSite={activeSite} key={i} data={block} />;
				case 'Craft_moduly_modulWplaty_Entry':
					return (
						<CashFlow
							activeSite={activeSite}
							key={i}
							data={block}
							processingPersonalData={processingPersonalData}
							projectsBackBtn={projectsBackBtn}
						/>
					);
				case 'Craft_moduly_modulBanerNaAkcjachSpecjalnych_Entry':
					return <BannerSpecialActions activeSite={activeSite} key={i} data={block} specialActions={specialActions} />;
				case 'Craft_moduly_modulOpisBackground_Entry':
					return <DescriptionWithBackground activeSite={activeSite} key={i} data={block} />;
				case 'Craft_moduly_modulNaglowekBackground_Entry':
					return <HeaderWithBackground activeSite={activeSite} key={i} data={block} />;
				case 'Craft_moduly_modulNajnowszeWzmiankiOPmm_Entry':
					return (
						<MentionsAboutPmm
							activeSite={activeSite}
							key={i}
							data={block}
							wzmiankiWMediach={wzmiankiWMediach}
							mentionsGlobal={mentionsGlobal}
						/>
					);
				case 'Craft_moduly_modulWszystkieWzmiankiWMediach_Entry':
					return (
						<MentionsAboutPmm
							activeSite={activeSite}
							key={i}
							data={block}
							wzmiankiWMediach={wzmiankiWMediach}
							mentionsGlobal={mentionsGlobal}
						/>
					);
				case 'Craft_moduly_modulSocialMedia_Entry':
					return <SocialMediaPosts activeSite={activeSite} key={i} data={block} />;
				case 'Craft_moduly_modul404_Entry':
					return <Component404 activeSite={activeSite} key={i} data={block} />;
				case 'Craft_moduly_modulStartSub_Entry':
					return <SubscriptionInfo activeSite={activeSite} key={i} data={block} />;
				case 'Craft_moduly_modulCancelSub_Entry':
					return <SubscriptionInfo activeSite={activeSite} key={i} data={block} />;
				case 'Craft_moduly_modulThanksPage_Entry':
					return <ThankYou activeSite={activeSite} key={i} data={block} />;
				case 'Craft_moduly_modulKalkulator_Entry':
					return <Calculator activeSite={activeSite} key={i} data={block} />;
				case 'Craft_moduly_modulKafelkiPiktogramOpisLink_Entry':
					return <TilesWithPictogramsGrid activeSite={activeSite} key={i} data={block} />;
				case 'Craft_moduly_modulBanerCzteryPrzyciski_Entry':
					return <BannerWithButtons activeSite={activeSite} key={i} data={block} />;
				case 'Craft_moduly_modulZdjecieNaglowekOpisPrzycisk_Entry':
					return <PhotoHeaderDescriptionBtn activeSite={activeSite} key={i} data={block} />;
				case 'Craft_moduly_modulKalkulatorRozbudowany_Entry':
					return <CalculatorExtended activeSite={activeSite} key={i} data={block} />;
				default:
					return <h1 key={i}>Brak obsługi dla {block?.__typename}</h1>;
			}
		});
	} else {
		if (data?.__typename === 'Craft_aktualnosci_aktualnosci_Entry') {
			return <SingleNews data={data} isPrivacyPolicy={false} activeSite={activeSite} pageContext={pageContext} newsBtns={newsBtns} />;
		} else if (data.__typename === 'Craft_politykaPrywatnosci_politykaPrywatnosci_Entry') {
			return (
				<SingleNews data={data} isPrivacyPolicy={true} activeSite={activeSite} pageContext={pageContext} newsBackBtn={newsBtns} />
			);
		} else if (data.__typename === 'Craft_dzialania_dzialania_Entry') {
			return (
				<SingleNews
					data={data}
					actWithUsBackButton={actWithUsBackButton}
					isActions={true}
					isPrivacyPolicy={false}
					activeSite={activeSite}
					pageContext={pageContext}
					newsBackBtn={newsBtns}
				/>
			);
		} else {
			return <h1>Brak obsługi dla {data?.__typename}</h1>;
		}
	}

	// if(hasSubmenu) {
	// 	return <SingleCard/>
	// }
};

export default ExtendedContent;
