import React from 'react';
import styled from 'styled-components';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import { getPaddingBottom, getPaddingTop } from '../../utils/setModulePadding';
import GeneralAnimationLoop from '../animations/GeneralAnimationLoop';
import setBackgroundColor from '../../utils/setBackgroundColor';

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	padding-top: ${({ paddingTop }) => paddingTop}px;
	padding-bottom: ${({ paddingBottom }) => paddingBottom}px;
	@media (min-width: 768px) {
		flex-direction: row;
		justify-content: space-between;
		max-height: 500px;
	}
	@media (min-width: 1920px) {
		max-height: 800px;
	}
	@media (min-width: 2560px) {
		max-height: 1080px;
		height: 1080px;
	}
`;

// const GifItem = styled.div`
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: center;
// 	width: 100%;
// 	//min-height: 235px;
// 	background-color: ${({theme}) => theme.colors.pmmWhite};
// 	padding: 60px 0;
// 	@media(min-width: 768px){
// 		width: 50%;
// 	}
// `;

const AnimationContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	background-color: ${({ theme }) => theme.colors.pmmWhite};
	width: 100%;
	@media (min-width: 768px) {
		width: 50%;
	}

	&.with-pictogram {
		padding: 50px 0;
	}
	img {
		height: 130px;
		margin-bottom: 40px;
	}

	span {
		font-size: 20px;
		display: flex;
		align-self: center;
		color: ${({ theme }) => theme.colors.pmmWhite};
		background-color: ${({ theme }) => theme.colors.pmmDarkestBlue};
		text-transform: uppercase;
		line-height: 24px;
		padding: 15px 10px;
		font-weight: bold;
		@media (min-width: 1024px) {
			padding: 15px 10px;
		}

		@media (min-width: 1280px) {
			font-size: 30px;
			line-height: 34px;
			padding: 20px 10px;
		}

		@media (min-width: 1920px) {
			font-size: 40px;
			line-height: 44px;
			padding: 20px 10px;
		}
	}
`;

const GifImage = styled.img`
	width: 240px;
	height: 51px;
	margin: 0 auto;
	@media (min-width: 768px) {
		width: 270px;
		height: 57px;
	}
	@media (min-width: 1280px) {
		width: 332px;
		height: auto;
	}
	@media (min-width: 1920px) {
		width: 455px;
	}
`;

const StickyBannerButtonWrapper = styled.div`
	position: fixed;
	display: none;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
	bottom: 80px;
	right: 0;
	z-index: 999;
	text-decoration: none;
	background-color: ${({ theme }) => theme.colors.pmmDarkestBlue};
	height: 60px;
	color: ${({ theme }) => theme.colors.pmmWhite};
	padding: 18px 20px 15px;
	@media (min-width: 768px) {
		display: flex;
	}
	@media (min-width: 1920px) {
		padding-top: 15px;
	}
`;

const ModuleContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 200px;
  background-color: ${({ background, theme }) => setBackgroundColor(background, theme)};
    // background-color: ${({ theme }) => theme.colors.pmmOrange};
  padding: 80px 20px 60px;
  @media (min-width: 768px) {
    width: 50%;
    padding: 120px 35px 80px;
  }
  @media (min-width: 1280px) {
    padding: 150px 55px 120px;
  }
  @media (min-width: 1366px) {
    padding: 160px 55px 140px;
  }
  @media (min-width: 1920px) {
    padding: 260px 80px 230px;
  }
`;

const RedactorParagraph = styled.div`
	position: relative;
	color: ${({ theme }) => theme.colors.pmmWhite};

	span {
		&.black {
			font-weight: ${({ theme }) => theme.fontWeight.black};
		}
	}

	&:before {
		content: '';
		position: absolute;
		top: -35px;
		left: 0;
		height: 4px;
		width: 70px;
		background-color: ${({ theme }) => theme.colors.pmmWhite};
		@media (min-width: 768px) {
			width: 100px;
		}
		@media (min-width: 1920px) {
			height: 5px;
			width: 120px;
		}
	}
`;

class BuildingHelp extends React.Component {
	render() {
		const buildingHelp = this.props.data;
		const { stickyBannerMainPage } = this.props;
		const stickyBannerData =
			(stickyBannerMainPage &&
				stickyBannerMainPage[0] &&
				stickyBannerMainPage[0].przyklejonyBanerStronaGlowna &&
				stickyBannerMainPage[0].przyklejonyBanerStronaGlowna[0]) ||
			'';
		const stickyBannerText = stickyBannerData.tekst || '';
		// const stickyBannerTextBtn = stickyBannerData.przyciskNapis || ""
		// const stickyBannerLinkBtn = stickyBannerData.przyciskLink || ""
		// const stickyBannerToBtn = (stickyBannerData.przyciskWpis && stickyBannerData.przyciskWpis[0] && stickyBannerData.przyciskWpis[0].slug) || ""
		// const stickyBannerTypeBtn = (stickyBannerData.przyciskRodzaj && stickyBannerData.przyciskRodzaj[0] && stickyBannerData.przyciskRodzaj[0].title) || ""

		const animationSource =
			(buildingHelp &&
				buildingHelp.budujemyPomocPodSliderem[0] &&
				buildingHelp.budujemyPomocPodSliderem[0].gif[0] &&
				buildingHelp.budujemyPomocPodSliderem[0].gif[0].url) ||
			'';
		const pictureSource =
			(buildingHelp &&
				buildingHelp.budujemyPomocPodSliderem[0] &&
				buildingHelp.budujemyPomocPodSliderem[0].obrazek[0] &&
				buildingHelp.budujemyPomocPodSliderem[0].obrazek[0].url) ||
			'';
		const buildingHelpParagraph =
			(buildingHelp && buildingHelp.budujemyPomocPodSliderem[0] && buildingHelp.budujemyPomocPodSliderem[0].opis) || '';
		const paragraphDescriptionBg =
			(buildingHelp &&
				buildingHelp.budujemyPomocPodSliderem[0] &&
				buildingHelp.budujemyPomocPodSliderem[0].opisBackground?.[0]?.title) ||
			'background-orange';
		const paddingTopType =
			(buildingHelp &&
				buildingHelp.budujemyPomocPodSliderem[0].marginesGorny &&
				buildingHelp.budujemyPomocPodSliderem[0].marginesGorny[0] &&
				buildingHelp.budujemyPomocPodSliderem[0].marginesGorny[0].title) ||
			'';
		const paddingBottomType =
			(buildingHelp &&
				buildingHelp.budujemyPomocPodSliderem[0].marginesDolny &&
				buildingHelp.budujemyPomocPodSliderem[0].marginesDolny[0] &&
				buildingHelp.budujemyPomocPodSliderem[0].marginesDolny[0].title) ||
			'';

		const pictogram = buildingHelp?.budujemyPomocPodSliderem?.[0]?.piktogram?.[0]?.url || '';
		const pictogtamDescription = buildingHelp?.budujemyPomocPodSliderem?.[0]?.piktogramOpis || '';
		const idForScrollOnPage = buildingHelp?.budujemyPomocPodSliderem?.[0]?.piktogramIdDlaScrolla || '';

		// const toBtnSlug = stickyBannerToBtn ? `/${getSlug(stickyBannerToBtn, activeSite)}` : ''

		return (
			<ModuleWrapper
				paddingTop={getPaddingTop(paddingTopType)}
				paddingBottom={getPaddingBottom(paddingBottomType)}
				id={idForScrollOnPage}
			>
				<AnimationContainer className={pictogram ? 'with-pictogram' : ''}>
					{pictureSource && <GifImage src={pictureSource} />}
					{animationSource && <GeneralAnimationLoop animationJson={animationSource} />}
					{pictogram && <img src={pictogram} />}
					{pictogtamDescription && <span>{pictogtamDescription}</span>}
				</AnimationContainer>
				<ModuleContainer background={paragraphDescriptionBg}>
					{/*<StickyBannerButtonWrapper>*/}
					{/*	<p className="h7 black">{stickyBannerText}</p>*/}
					{/*</StickyBannerButtonWrapper>*/}
					<RedactorParagraph className="header-h4" dangerouslySetInnerHTML={getHtmlFromRedactorField(buildingHelpParagraph)} />
				</ModuleContainer>
			</ModuleWrapper>
		);
	}
}

export default BuildingHelp;
