import React from "react"
import styled from "styled-components"
import posed, {PoseGroup} from "react-pose";
import pose from "react-pose"
import getSlug from "../../utils/getSlug";
import {navigate} from "gatsby";
import {changeLinkToIdFromAnotherPage} from "../../state/app";
import {connect} from "react-redux";

const SingleStoryWrapper = styled.div`
	height: 500px;
	min-width: 300px;
	margin-right: 10px;
	background-image: url(${props => props.back});
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
	cursor: none;

	&:hover {
		.info-box{
			background-color: ${({theme}) => theme.colors.pmmOrange};
		}
	}
	
	@media(min-width: 768px){
		height: 600px;
		margin-right: 20px;
		min-width: 420px;
	}
	@media(min-width: 1280px){
	}
	@media(min-width: 1366px){
		min-width: 440px;
	}
	@media(min-width: 1920px){
		margin-right: 40px;
		height: 800px;
		min-width: 600px;
	}
	@media(min-width: 2560px){
		height: 1100px;
		min-width: 840px;
		background-size: cover;
	}
`

const PoseInfoBox = pose.div({
	open: {
		height: ({height}) => height, transition: {duration: 200}
	},
	close: {
		height: ({closedHeight}) => closedHeight, transition: {duration: 200}
	},
})

const InfoBox = styled(PoseInfoBox)`
	position: absolute;
	bottom: 10px;
	left: 10px;
	width: 70%;
	border: 3px solid ${({theme}) => theme.colors.pmmOrange};
	color: ${({theme}) => theme.colors.pmmWhite};
	overflow: hidden;
	height: ${({closedHeight}) => closedHeight};
	
	  @media(min-width: 768px){
		width: 50%;
		bottom: 20px;
		left: 20px;
	  }
	  @media(min-width: 1920px){
		border: 4px solid ${({theme}) => theme.colors.pmmOrange};
	  }
`

const InfoBoxContent = styled.div`
	margin-bottom: 14px;
	overflow: ${props => props.isStoryHover ? 'hidden' : ''};
	height: calc(100% - 14px);
	display: flex;
	flex-direction: column;
	//align-items: flex-end;
	
	@media(min-width: 1920px){
		height: calc(100% - 20px);
	}
`

const Title = styled.h3`
	//max-width: 260px;
	display: flex;
	text-align: left;
	padding: 15px 14px;
	
	@media(min-width: 1920px){
		//padding: 20px 20px 19px;
		padding: 20px 30px 20px;
	}
`

export const PosedShortDescription = posed.div({
	enter: {
		opacity: 1,
		transition: {duration: 400}
	},
	exit: {
		opacity: 0,
		transition: {duration: 400}
	}
})

const ShortDescription = styled(PosedShortDescription)`
	position: unset !important;
	text-align: left;
	font-size: ${({theme}) => theme.fontSize.f12};
	font-weight: ${({theme}) => theme.fontWeight.regular};
	line-height: ${({theme}) => theme.lineHeight.line16};
	padding: 0 14px;
    @media(min-width: 768px){
		font-size: ${({theme}) => theme.fontSize.f9};
		//line-height: ${({theme}) => theme.lineHeight.line16};
		line-height: ${({theme}) => theme.lineHeight.line4};
    }
   	@media(min-width: 1920px){
   		padding: 0 30px;
		font-size: ${({theme}) => theme.fontSize.f9};
		line-height: ${({theme}) => theme.lineHeight.line4};
	}
`

class SingleStory extends React.Component {

	state = {
		hovering: false,
		isStoryHover: false,
		tempIsNavigate: false,
		infoHeight: '',
		infoClosedHeight: ''
	}

	constructor() {
		super();
		this.updateInfoHeight = this.updateInfoHeight.bind(this);
		this.updateInfoClosedHeight = this.updateInfoClosedHeight.bind(this);
	}

	updateInfoHeight() {
		(window.innerWidth < 1366) ? this.setState({infoHeight: '55%'}) : (window.innerWidth >= 1366 && window.innerWidth < 1920) ? this.setState({infoHeight: '50%'}) : this.setState({infoHeight: '40%'});
	}

	updateInfoClosedHeight() {
		window.innerWidth < 1920 ? this.setState({infoClosedHeight: '105px'}) : this.setState({infoClosedHeight: '140px'});
	}

	componentDidMount() {
		if (typeof window !== "undefined") {
			this.updateInfoHeight();
			this.updateInfoClosedHeight();
			window.addEventListener("resize", this.updateInfoHeight)
			window.addEventListener("resize", this.updateInfoClosedHeight)
		}
	}

	componentWillUnmount() {
		if (typeof window !== "undefined") {
			window.removeEventListener("resize", this.updateInfoHeight)
			window.removeEventListener("resize", this.updateInfoClosedHeight)
		}
	}

	onMouseIn() {
		this.setState({hovering: true}, () => {
			setTimeout(() => {
				if (this.state.hovering && window.innerWidth >= 768) {
					this.setState({isStoryHover: true});
				}
			}, 0);
		});
	}

	onMouseOut() {
		this.setState({hovering: false}, () => {
			setTimeout(() => {
				if (!this.state.hovering) {
					this.setState({isStoryHover: false});
				}
			}, 0);
		})
	}

	handleSingleStoryClick(dispatch, wasContentScrolled, linkToNavigate, storyAnchor) {
		if (!wasContentScrolled) {
			dispatch(changeLinkToIdFromAnotherPage(true))
			const navigateToId = linkToNavigate + '#' + storyAnchor
			navigate(navigateToId)
		}
	}

	render() {
		const {dispatch, photoUrl, storyTitle, storyDescription, storyProject, storyAnchor, indexOfSingleStory, activeSite, wasContentScrolled} = this.props
		const {isStoryHover} = this.state
		const linkToNavigate = `/${getSlug(storyProject, activeSite)}`
		const closedHeight = this.state.infoClosedHeight
		const infoHeight = this.state.infoHeight

		return (
			<SingleStoryWrapper onClick={this.handleSingleStoryClick.bind(this, dispatch, wasContentScrolled, linkToNavigate, storyAnchor)}
								back={photoUrl}
								key={indexOfSingleStory} onMouseOver={this.onMouseIn.bind(this)}
								onMouseOut={this.onMouseOut.bind(this)}>
				<InfoBox className="info-box" pose={isStoryHover ? "open" : "close"} height={infoHeight}
						 closedHeight={closedHeight} poseKey={closedHeight}>
					<InfoBoxContent isStoryHover={isStoryHover}>
						<Title>
							{storyTitle}
						</Title>
						<PoseGroup>
							{isStoryHover &&
							<ShortDescription key={'short-description-' + indexOfSingleStory} className="description">
								{storyDescription}
							</ShortDescription>
							}
						</PoseGroup>
					</InfoBoxContent>
				</InfoBox>
			</SingleStoryWrapper>
		)
	}
}

export default connect(state => ({
	linkToIdFromAnotherPage: state.app.linkToIdFromAnotherPage
}), null)(SingleStory)
