import React, {Component} from 'react';
import styled from "styled-components"
import {setMobileActiveProject} from "../../state/app";
import {connect} from "react-redux";

const VideoWrapper = styled.div`
	height: 100vh; 
	width: 100%;
	//background-image: linear-gradient(to top, rgba(16, 7, 31, 1) 0%, rgba(16, 7, 31, 0) 33%, transparent 33%, transparent 100%);
	//z-index: 10;
	background-color: ${({theme}) => theme.colors.pmmWhite};
	position:relative;
	@media(min-width: 768px) {
		height: 80vh;
		//background-image: linear-gradient(to top, rgba(16, 7, 31, 1) 17%, rgba(16, 7, 31, .0) 33%, transparent 33%, transparent 100%);
		//background-image: linear-gradient(to top, white 17%, transparent 17%, transparent 33%, transparent 100%);
	}
	@media(min-width: 1280px) {
		//height: 83vh;
		height: calc(100vh - 120px);
	}
	@media(min-width: 1366px) {
		height: calc(100vh - 100px);
	}
	@media(min-width: 1920px) {
		height: 86vh;
	}
	
	.video-container{
		position: absolute;
		//z-index: -1;
		width: 100%;
		height: 100vh;
		overflow: hidden;
		@media(min-width: 768px) {
			height: calc(100vh - 220px);
		}
		@media(min-width: 1280px) {
			//height: 83vh;
			height: calc(100vh - 120px);
		}
		@media(min-width: 1366px) {
			height: calc(100vh - 100px);
		}
		@media(min-width: 1920px) {
			height: calc(100vh - 180px);
		}
	
		video {
		  object-fit: cover;
		  width: 100vw;
		  height: 100vh;
		  position: absolute;
		  top: 0;
		  left: 0;
		  z-index: 10;
          @media(min-width: 768px) {
          	//height: auto;
			//height: calc(100vh - 220px);
		  }
	      @media(min-width: 1280px) {
			//height: calc(100vh - 120px);
		  }
		  @media(min-width: 1366px) {
			//height: calc(100vh - 100px);
		  }
		  @media(min-width: 1920px) {
			//height: calc(100vh - 180px);
		  }
		}
	}
`;

const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 20;
	@media(min-width: 1280px) {
		flex-direction: row;
		justify-content: space-between;
		padding-bottom: 0;
	}
`;

const HeaderDescriptionWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 25px;
	padding-right: 15px;
	margin-bottom: 0;
	@media(min-height: 550px) {
		margin-bottom: 30px;
	}
	@media(min-height: 650px) {
		margin-bottom: 80px;
	}
	@media(min-height: 900px){
		margin-bottom: 40px;
	}
	@media(min-width: 768px) {
		padding-left: 35px;
		padding-right: 0;
		margin-bottom: 0px;
		width: 65%;
	}
	@media(min-width: 1280px) {
		width: 65%;
		height: 100%;
		padding-left: 13%;
		margin-bottom: 0;
	}
	@media(min-width: 1920px) {
		width: 50%;
		padding-left: 14%;
	}
`;

const TitleWrapper = styled.div`
	width: 80%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 20px;
	@media(min-width: 375px){
	}
	@media(min-width: 768px){
		margin-top: 27px;
		width: 80%;	
	}
	@media(min-width: 1280px){
	}
	@media(min-width: 1366px){
	}
	@media(min-width: 1920px){
	}
`;

const Title = styled.h1`
 	color: ${({theme}) => theme.colors.pmmWhite};
 	line-height: 50px;
    @media(min-width: 768px) {
 		line-height: 88px;
 	}
    @media(min-width: 1366px) {
 		line-height: 90px;
 	}
 	@media(min-width: 1920px) {
 		line-height: 110px;
 	}
`;

const Underline = styled.span`
    background-image: linear-gradient(180deg,transparent 7%,#FF4124 0);
    background-size: 100% 100%;
    //padding-right: 20px;
    background-repeat: no-repeat;
    text-decoration: none;
    padding: 6px 20px 6px 0;
`;
const DescriptionWrapper = styled.p`
	color: ${({theme}) => theme.colors.pmmWhite};
	font-weight: ${({theme}) => theme.fontWeight.medium} !important;
	position: relative;
	padding-bottom: 50px;
	margin-top: 20px;
	padding-top: 20px;
	@media(min-width: 768px) {
		margin-top: 30px;
		padding-top: 10px;
		padding-bottom: 10px;
		@media(min-height: 900px){
			padding-top: 30px;
			padding-bottom: 50px;
		}
	}
	@media(min-width: 1024px) {
		@media(min-height: 800px){
			padding-top: 20px;
			padding-bottom: 30px;
		}
	}
	@media(min-width: 1366px) {
		margin-top: 35px;
		padding-top: 35px;
	}
	@media(min-width: 1920px) {
		margin-top: 45px;
		padding-top: 40px;
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 60px;
		height: 4px;
		display: block;
		background-color: ${({theme}) => theme.colors.pmmWhite};	
	}
`;

const ImportantForUsWrapper = styled.div`
	width: 100%;
	//height: 20%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 25px;
	//padding-top: 25%;
	text-align: left;
	padding-bottom: 30px;
	@media(min-width: 768px) {
		padding-bottom: 115px;
		text-align: left;
		padding-right: 35px;
		padding-left: 35px;
	}
	@media(min-width: 1280px) {
		padding-right: 50px;
		width: 50%;
		height: 100%;
		padding-bottom: 4%;
		justify-content: flex-end;
		text-align: right;
	}
	@media(min-width: 1366px) {
		padding-bottom: 2%;
	}
	@media(min-width: 1920px) {
		padding-right: 100px;
		padding-top: 0;
		padding-bottom: 4%;
	}
	@media(min-width: 2100px) {
		padding-bottom: 4%;
	}
`;

const ImportantDescription = styled.p`
	color: ${({theme}) => theme.colors.pmmWhite};
	margin-bottom: 5px;
	// @media(min-width: 768px) {
	// 	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	// }
	@media(min-width: 1280px) {
		color: ${({theme}) => theme.colors.pmmWhite};
	}
`;
const FrameWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	@media(min-width: 1280px) {
		justify-content: flex-end;
	}
`
const ProjectFrame = styled.div`
	cursor: pointer;
	padding: 10px;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: space-between;
	border: 2px solid ${({theme}) => theme.colors.pmmOrange};
	@media(min-width: 768px) {
		padding: 12px 10px;
		flex-direction: row;
		justify-content: space-between;
	}
	@media(min-width: 1280px) {
		padding: 11px 10px;
	}
	@media(min-width: 1366px) {
		padding: 10px;
	}
	@media(min-width: 1920px) {
		padding: 15px 20px;
	}
`;

const ArrowDownImg = styled.img`
	width: 32px;
	cursor: pointer;
	margin-left: 20px;
	@media(min-width: 768px) {
		margin-right: 50px;
		margin-left: 0;
		width: 32px
	}
	@media(min-width: 1920px) {
		margin-right: 90px;
		width: 47px;
	}
`;

const NameOfProject = styled.h4`
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
	color: ${({theme}) => theme.colors.pmmWhite};
	// @media(min-width: 768px) {
	// 	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	// }
	@media(min-width: 1280px) {
		color: ${({theme}) => theme.colors.pmmWhite};
	}
`;

class BannerVideo extends Component {

	getYPosition(element) {
		let yPosition = 0;

		while (element) {
			yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
			element = element.offsetParent;
		}

		return yPosition;
	}

	handleBtnClick(siteUrl, storyAnchor) {
		const {dispatch} = this.props
		if ((window.innerWidth >= 768)) {
			const element = document.getElementById(storyAnchor)
			window.scroll({
				behavior: "smooth",
				top: this.getYPosition(element) - 115
			})
		} else {
			dispatch(setMobileActiveProject(storyAnchor))
			const element = document.getElementById('payment-wrapper')
			window.scroll({
				behavior: "smooth",
				top: this.getYPosition(element)
			})
		}
	}

	render() {
		const {data, banerPlatnosci } = this.props;
		const bannerData = (data && data.modulBanerFilm && data.modulBanerFilm[0]) || ""
		const film = (bannerData && bannerData.film && bannerData.film[0] && bannerData.film[0].url) || ""
		const header = (bannerData && bannerData.naglowek) || '';
		const description = (bannerData && bannerData.opis) || '';
		const importantForUsText = (bannerData && bannerData.wazneDlaNasPodpis) || '';
		const importantForUsProjectSlug = (bannerData && bannerData.wazneDlaNasWybierzProjekt && bannerData.wazneDlaNasWybierzProjekt[0] &&
			bannerData.wazneDlaNasWybierzProjekt[0].slug) || '';
		const importantForUsProjectTitle = (bannerData && bannerData.wazneDlaNasWybierzProjekt && bannerData.wazneDlaNasWybierzProjekt[0] &&
			bannerData.wazneDlaNasWybierzProjekt[0].title) || '';
		const banerPlatnosciImg = (banerPlatnosci && banerPlatnosci[0] && banerPlatnosci[0].banerPlatnosciPiktogramStrzalkaWDol &&
			banerPlatnosci[0].banerPlatnosciPiktogramStrzalkaWDol[0] && banerPlatnosci[0].banerPlatnosciPiktogramStrzalkaWDol[0].url) || '';

		let siteUrl = ''

		return (
			<VideoWrapper>
				<div className="video-container">
					<video playsInline autoPlay muted loop poster="" id="bgvid">
						<source src={film}/>
					</video>
				</div>

				<InfoWrapper>
					<HeaderDescriptionWrapper>
						<TitleWrapper>
							<Title>
								<Underline>
									{header}
								</Underline>
							</Title>
						</TitleWrapper>
						<DescriptionWrapper className="h7">
							{description}
						</DescriptionWrapper>
					</HeaderDescriptionWrapper>
					<ImportantForUsWrapper>
						<ImportantDescription>{importantForUsText}</ImportantDescription>
						<FrameWrapper>
							<ProjectFrame onClick={this.handleBtnClick.bind(this, siteUrl, importantForUsProjectSlug)}>
								<ArrowDownImg src={banerPlatnosciImg}/>
								<NameOfProject>{importantForUsProjectTitle}</NameOfProject>
							</ProjectFrame>
						</FrameWrapper>
					</ImportantForUsWrapper>
				</InfoWrapper>
			</VideoWrapper>
		);
	}
}

export default connect(state => ({
	mobileActiveProject: state.app.mobileActiveProject
}), null)(BannerVideo)
