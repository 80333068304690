import React from 'react';
import styled from 'styled-components';
import { keyframes } from 'styled-components';
import getSingleImage from '../../utils/getSingleImage';
import getSlug from '../../utils/getSlug';
import Button from '../common/ButtonsSet';
import { Link } from 'gatsby';
import { fbqDonateBtnClicked } from '../../utils/fbqDonateBtnClicked';

const SideBanner = styled.div`
	background-color: ${({ theme }) => theme.colors.pmmDarkViolet};
	box-sizing: border-box;
	position: absolute;
	right: 0;
	bottom: 0;
	color: white;
	z-index: 16;
	padding: 50px 50px 0;
	min-height: 210px;
	display: none;

	@media (min-width: 1024px) {
		height: 300px;
		margin: 0 0 20px;
		display: block;
		max-width: 50%;
	}
	@media (min-width: 1280px) {
		height: 350px;
		max-width: 45%;
	}
	@media (min-width: 1920px) {
		margin: 0 0 25px;
		width: 100%;
		height: 500px;
		max-width: 30%;
	}
`;

const SideBannerDiv = styled.div`
	width: 100%;

	&:before {
		content: '';
		position: absolute;
		height: 4px;
		width: 100px;
		background-color: ${({ theme }) => theme.colors.pmmWhite};
	}
	p {
		font-weight: 700;
		-webkit-user-select: all; /* Safari */
		-ms-user-select: all; /* IE 10 and IE 11 */
		user-select: all; /* Standard syntax */
		&.KRSBannerText {
			color: ${({ theme }) => theme.colors.pmmOrange};
		}
	}
	span {
		display: flex;
		p:first-child {
			font-weight: 300;
			margin-right: 5px;
		}
	}

	@media (min-width: 1024px) {
		margin-top: 15px;
		p {
			font-size: 1rem;
			&.KRSBannerText {
				font-size: 2rem;
				padding-top: 10px;
				padding-bottom: 5px;
			}
		}
	}
	@media (min-width: 1280px) {
		margin-top: 25px;
		p {
			font-size: 1.2rem;
			&.KRSBannerText {
				font-size: 2rem;
				padding-top: 10px;
				padding-bottom: 5px;
			}
		}
	}

	@media (min-width: 1920px) {
		margin-top: 50px;
		p {
			font-size: 1.5rem;
			&.KRSBannerText {
				font-size: 3rem;
				padding-top: 30px;
				padding-bottom: 15px;
			}
		}
	}
`;

const SliderWrapper = styled.div`
	position: relative;
	top: 0;
	left: 0;
	 // height: ${props => props.height + 'px'};
	height:100vh;
	width: 100vw;
	overflow: hidden;
	max-width: 100%;
	&:before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: 11;
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 10%,transparent 30%,transparent 70%, rgba(0, 0, 0, 0.4) 100%);
	}
	
	@media(min-width: 768px){
		height: 100vh;
	}
	@media(min-width: 1280px){
		flex-direction: row;
		justify-content: space-between;
		&:before {
			height: 100%;
			z-index: 11;
			background-image: linear-gradient(to right, rgba(0, 0, 0, 0.4) 10%,transparent 40%);
		}
	}
	@media(min-width: 1366px){
	}
	@media(min-width: 1920px){
		height: 100vh;
	}
`;

const SlideLinkBlend = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 15;
	opacity: 1;
`;

const SlideInfoLinkBlend = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 17;
	opacity: 1;
`;

const rotate = keyframes`
	0% {
		transform: scale(1);
	}

	100%{
		transform: scale(1.1);
	}
`;

const Slide = styled.div`
	overflow: hidden;
	position: absolute;
	top: 0;
	transition: transform 2s ease-in-out;
	width: 100%;
	height: 100vh;
	z-index: 1;
	transform: scale(1);

	&.next-active-slide {
		width: 0;
		z-index: 10;
	}
	&.hidden {
		display: none;
	}

	&.active {
		animation-delay: 3s;
		animation: ${rotate} 2s ease-in-out forwards;
	}
`;

const StyledPhotoWrapper = styled.div`
	display: none;

	@media (min-width: 1025px) {
		display: inline;
		width: 100%;
		z-index: 10;
	}
`;

const PhotoBg = styled.div`
	background-image: url(${props => props.back});
	background-size: cover;
	background-position: center;
	width: 100vw;
	height: 100vh;
`;

const StyledPhotoWrapperMobile = styled.div`
	@media (orientation: landscape) {
		display: none;
	}
	@media (orientation: portrait) {
		display: inline;
	}
	@media (min-width: 1025px) {
		display: none;
	}
`;

const StyledPhotoWrapperHorizontal = styled.div`
	@media (orientation: landscape) {
		display: inline;
	}
	@media (orientation: portrait) {
		display: none;
	}
	@media (min-width: 1025px) {
		display: none;
	}
`;

const SlideInfoWrapper = styled.div`
	box-sizing: border-box;
	position: absolute;
	bottom: 0;
	z-index: 16;
	border: 3px solid ${({ theme }) => theme.colors.pmmWhite};
	display: flex;
	flex-direction: column;
	width: calc(100% - 20px);
	margin: 0 10px 10px;
	//padding: 35px 20px 0;
	padding: 25px 20px 0;
	min-height: 210px;

	&.hidden {
		display: none;
	}

	@media (min-width: 768px) {
		border: 2px solid ${({ theme }) => theme.colors.pmmWhite};
		width: calc(100% - 40px);
		margin: 0 20px 20px;
		//padding: 60px 25px;
		padding: 35px 25px;
		min-height: 300px;
	}
	@media (min-width: 1024px) {
		padding: 60px 25px;
	}
	@media (min-width: 1280px) {
		padding: 80px 25px;
		min-height: 350px;
	}
	@media (min-width: 1366px) {
		padding: 80px 35px;
	}
	@media (min-width: 1920px) {
		width: calc(100% - 50px);
		margin: 0 25px 25px;
		padding: 0 85px;
		//height: 45%;
		height: 500px;
		justify-content: center;
	}
`;

const SlideInfo = styled.h1`
	color: ${({ theme }) => theme.colors.pmmWhite};
	min-height: 72px;
	width: 100%;
	//max-width: 830px;
	max-width: 1220px;

	@media (max-width: 374px) {
		font-size: 22px;
	}
	@media (min-width: 768px) {
		min-height: 128px;
		width: 65%;
	}
	@media (min-width: 1920px) {
		min-height: 176px;
		margin-bottom: 95px;
		font-weight: ${({ theme }) => theme.fontWeight.bold};
		width: 100%;
	}
`;

const BtnWrapper = styled.div`
	position: absolute;
	//bottom: 35px;
	bottom: 17px;
	z-index: 20;

	&.active {
		display: block;
	}
	&.hidden {
		display: none;
	}

	@media (min-width: 768px) {
		//bottom: 60px;
		bottom: 35px;
	}
	@media (min-width: 1024px) {
		bottom: 60px;
	}
	@media (min-width: 1280px) {
		bottom: 80px;
	}
	@media (min-width: 1366px) {
	}
	@media (min-width: 1920px) {
		bottom: 120px;
	}
`;

class HomeSlider extends React.Component {
	intervalID = 0;
	changeSliderPhotoTimeoutID = 0;
	writeTimeoutID = 0;

	constructor(props) {
		super(props);
		const { slider } = this.props.data;
		let slideDescriptionArray = [];
		slider.forEach(item => {
			slideDescriptionArray.push([item.opis]);
		});

		this.state = {
			activeSlide: 0,
			nextActiveSlide: 1,
			sliderHeight: '100vh',
			iTextPos: 0,
			slidesInfo: slideDescriptionArray,
			isBrowserTabActive: true,
		};

		this.slideShow = this.slideShow.bind(this);
		this.checkSliderHeight = this.checkSliderHeight.bind(this);
		this.typewriter = this.typewriter.bind(this);
		this.handleTabChange = this.handleTabChange.bind(this);
	}

	hideUnusedSlides() {
		const slidesToHide = document.getElementsByClassName('hidden');
		Array.from(slidesToHide).forEach(slide => {
			slide.style.cssText = '';
		});
	}

	slideShow() {
		const { activeSlide, nextActiveSlide, slidesInfo } = this.state;
		const { slider } = this.props.data;
		const numberOfSlides = slider.length;

		const changeSlideInfo = () => {
			activeSlide < numberOfSlides - 1 ? this.typewriter(slidesInfo[activeSlide + 1]) : this.typewriter(slidesInfo[0]);
		};

		const changeSlidePhoto = () => {
			const nextActiveSlideElement = document.getElementsByClassName('next-active-slide')[0];
			for (let i = 0; i < 101; i++) {
				this.changeSliderPhotoTimeoutID = setTimeout(
					function() {
						nextActiveSlideElement.style.width = i + 'vw';
						if (i === 100) {
							if (activeSlide < numberOfSlides - 1) {
								this.setState({ activeSlide: activeSlide + 1 });
							} else this.setState({ activeSlide: 0 });

							if (nextActiveSlide < numberOfSlides - 1) {
								this.setState({ nextActiveSlide: nextActiveSlide + 1 });
							} else this.setState({ nextActiveSlide: 0 });
						}
					}.bind(this),
					i * 5,
				);
			}
		};

		this.hideUnusedSlides();
		changeSlidePhoto();
		changeSlideInfo();
	}

	checkSliderHeight() {
		const slideImgHeight = document.getElementsByClassName('slide-image').item(0).clientHeight;

		// const {sliderHeight} = this.state
		if (typeof window !== 'undefined' && slideImgHeight < window.innerHeight) {
			this.setState({ sliderHeight: slideImgHeight });
		} else this.setState({ sliderHeight: window.innerHeight });
	}

	typewriter(infoArray) {
		// set up text to print, each item in array is new line
		let aText = infoArray;
		let iSpeed = 50; // time delay of print out
		let iIndex = 0; // start printing array at this posision
		let iArrLength = aText[0].length; // the length of the text array
		let iScrollAt = 20; // start scrolling up at this many lines
		let sContents = ''; // initialise contents variable
		let iRow; // initialise current row
		const destination = document.getElementById('info');

		let write = () => {
			const iTextPos = this.state.iTextPos;
			sContents = ' ';
			iRow = Math.max(0, iIndex - iScrollAt);
			while (iRow < iIndex) {
				sContents += aText[iRow++] + '<br />';
			}

			destination.innerHTML = sContents + aText[iIndex].substring(0, iTextPos);

			if (iTextPos + 1 === iArrLength + 1 || !this.state.isBrowserTabActive) {
				this.setState({ iTextPos: 0 });
				iIndex++;
				clearTimeout(this.writeTimeoutID);
				if (iIndex !== aText.length) {
					iArrLength = aText[iIndex].length;
					this.writeTimeoutID = setTimeout(write, iSpeed);
				}
			} else {
				this.setState({ iTextPos: iTextPos + 1 });
				this.writeTimeoutID = setTimeout(write, iSpeed);
			}
		};
		write();
	}

	handleTabChange() {
		document.hidden ? this.setState({ isBrowserTabActive: false }) : this.setState({ isBrowserTabActive: true });
	}

	componentDidMount() {
		this.checkSliderHeight();
		window.addEventListener('resize', this.checkSliderHeight);
		document.addEventListener('visibilitychange', this.handleTabChange);
		this.typewriter(this.state.slidesInfo[this.state.activeSlide]);

		if (this.state.isBrowserTabActive) {
			this.intervalID = setInterval(this.slideShow, 4000);
		}
	}

	resetToFirstSlide() {
		this.checkSliderHeight();
		const nextActiveSlideElement = document.getElementsByClassName('next-active-slide')[0];
		const activeSlideElement = document.getElementsByClassName('active')[0];
		nextActiveSlideElement.style.width = 0;
		nextActiveSlideElement.style.zIndex = 1;
		// for first slide animation zoom trigger when user back from different browser tab
		activeSlideElement.classList.remove('active');
		void activeSlideElement.offsetWidth;
		activeSlideElement.classList.add('active');

		this.hideUnusedSlides();
		this.typewriter(this.state.slidesInfo[this.state.activeSlide]);

		this.intervalID = setInterval(this.slideShow, 4000);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { isBrowserTabActive } = this.state;

		if (prevState.isBrowserTabActive !== isBrowserTabActive) {
			if (!isBrowserTabActive) {
				clearInterval(this.intervalID);
				clearTimeout(this.changeSliderPhotoTimeoutID);
				clearTimeout(this.writeTimeoutID);
			} else if (isBrowserTabActive) {
				this.setState(
					{
						activeSlide: 0,
						nextActiveSlide: 1,
						sliderHeight: '100vh',
						iTextPos: 0,
					},
					this.resetToFirstSlide,
				);
			}
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.checkSliderHeight);
		document.removeEventListener('visibilitychange', this.handleTabChange);
		clearTimeout(this.writeTimeoutID);
		clearTimeout(this.changeSliderPhotoTimeoutID);
		clearInterval(this.intervalID);
	}

	render() {
		const { activeSlide, nextActiveSlide, sliderHeight } = this.state;
		const { slider } = this.props.data;
		const { activeSite } = this.props;
		const sliderBtnsTextAndSlug = [];
		slider.forEach(item => {
			sliderBtnsTextAndSlug.push({
				text: item.przyciskNapis || '',
				to: (item.przyciskWpis && item.przyciskWpis[0] && item.przyciskWpis[0].slug) || '',
				href: item.przyciskLink || '',
				type: (item.przyciskRodzaj && item.przyciskRodzaj[0] && item.przyciskRodzaj[0].title) || '',
			});
		});

		const nrKRS = activeSite === 'pl' ? '1,5% DLA PMM' : '1,5% FOR PMM';
		const nrKonta = activeSite === 'pl' ? 'NR KONTA' : 'ACCOUNT NR';

		return (
			<>
				<SliderWrapper heightSet={sliderHeight}>
					{slider.map((slide, key) => (
						<React.Fragment key={key}>
							<SlideLinkBlend as={Link} to={`/${getSlug(sliderBtnsTextAndSlug[activeSlide].to, activeSite)}`} />
							<Slide className={nextActiveSlide === key ? 'next-active-slide' : activeSlide === key ? 'active' : 'hidden'}>
								<StyledPhotoWrapper className="slide-image">
									<PhotoBg back={getSingleImage(slide.zdjecie)}></PhotoBg>
								</StyledPhotoWrapper>
								<StyledPhotoWrapperMobile className="slide-image">
									<PhotoBg back={getSingleImage(slide.zdjecieMobile)}></PhotoBg>
								</StyledPhotoWrapperMobile>
								<StyledPhotoWrapperHorizontal className="slide-image">
									<PhotoBg back={getSingleImage(slide.zdjecieMobilePoziome)}></PhotoBg>
								</StyledPhotoWrapperHorizontal>
							</Slide>
						</React.Fragment>
					))}
					)}
					<SlideInfoWrapper>
						<SlideInfo id="info"></SlideInfo>
						{sliderBtnsTextAndSlug.map((slide, key) => (
							<React.Fragment key={key}>
								<SlideInfoLinkBlend as={Link} to={`/${getSlug(sliderBtnsTextAndSlug[activeSlide].to, activeSite)}`} />
								<BtnWrapper key={key} className={activeSlide === key ? 'active' : 'hidden'}>
									<Button
										onClick={() => fbqDonateBtnClicked('kliknieto ze slidera')}
										href={slide.href}
										btnType={slide.type}
										to={`/${getSlug(slide.to, activeSite)}`}
										textBtn={slide.text}
									/>
								</BtnWrapper>
							</React.Fragment>
						))}
					</SlideInfoWrapper>
					<SideBanner>
						<SideBannerDiv>
							<p className="KRSBannerText">{nrKRS}</p>
							<span>
								<p>KRS:</p>
								<p>0000162022</p>
							</span>
						</SideBannerDiv>
						<SideBannerDiv>
							<p className="KRSBannerText">{nrKonta}</p>
							<p>62 1240 2294 1111 0000 3718 5444</p>
						</SideBannerDiv>
					</SideBanner>
				</SliderWrapper>
			</>
		);
	}
}

export default HomeSlider;
