import React from "react"
import styled from "styled-components"
import ArrowRightHovered from "../../icons/arrows/arrow-right-orange-bold.svg"
import ArrowLeftHovered from "../../icons/arrows/arrow-left-orange-bold.svg"
import Slider from "react-slick/lib"
import SingleNews from "./SingleNewsCard";
import getSlug from "../../utils/getSlug";
import Button from "../common/ButtonsSet";
import {Link} from "gatsby";
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";

const ModuleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.colors.pmmWhite};
  width: 100%;
  padding-top: ${({paddingTop}) => paddingTop}px;
  padding-bottom: ${({paddingBottom}) => paddingBottom}px;
  padding-left: 12px;
  padding-right: 12px;
  @media(min-width: 768px){
	padding-right: 35px;
	padding-left: 35px;
  }
  @media(min-width: 1280px){
    padding-right: 50px;
    padding-left: 50px;
  }
  @media(min-width: 1920px){
	padding-right: 100px;
	padding-left: 100px;
  }
`;

const ContainerSliderVisible = styled.div`
  display: block;
`;

const ArrowsWrapper = styled.div`
	width: 100%;
	height: 59px;
	padding: 3px 0;
	display: none;
	flex-direction: row;
	justify-content: flex-end;
	margin-bottom: 0;
	background: ${({theme}) => theme.colors.pmmWhite};
	@media(min-width: 768px) {
		display: flex;
	}
	@media(min-width: 1280px) {
		padding: 3px 5px;
	}
	@media(min-width: 1920px) {
		margin-bottom: 10px;
	}
`;

const SingleNavigationButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-left: 20px;
	width: 53px;
	height: 53px;
	position: relative;
`;

const NavigationArrow = styled.div`
	width: 53px;
	height: 53px;
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-position: center;
	background-image: url( ${({bgImg}) => bgImg} );
	transition: transform 0.3s ease-in-out;
	
	&:hover  {
		transform: ${({isEdge}) => isEdge ? "scale(1)" : "scale(1.113)"};
	}
`;

const StyledWrapper = styled.div`
	max-height: 600px;
	width: 94%;
	
	a {
	text-decoration: none;
	}
	@media(min-width: 768px) {
		width: 105%;
	}
	.slick-list {
		overflow: visible;
		@media(min-width: 768px) {
			overflow: hidden;
	}
	.slick-slider {
	    margin: 0 auto;	
	    width: 100%;
	}
	.slick-slide {
	}
	.slick-track {
		margin-left: 0;
	}	
	}	
	@media(min-width: 1280px) {
		width: calc(100% + 3% );
	}
	@media(min-width: 1920px) {
	}
`;

const ButtonWrapper = styled.div`
	min-height: 70px;
	margin-top: 30px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	@media(min-width: 1280px) {
		margin-top: 70px;
	}
	@media(min-width: 1920px) {
		margin-top: 100px;
	}	
`;

class News extends React.Component {
	state = {
		isOldBrowserEdge: false,
	};

	constructor(props) {
		super(props);
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
	}

	componentDidMount() {
		if (typeof window !== "undefined") {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({isOldBrowserEdge: isOldBrowser});
		}
	}

	next() {
		this.slider.slickNext();
	}

	previous() {
		this.slider.slickPrev();
	}

	render() {
		const newsWizytowkaData = this.props.data;
		const {activeSite, aktualnosciWszystkie} = this.props;
		const {isOldBrowserEdge} = this.state;
		const newsData = (newsWizytowkaData.wizytowkaAktualnosci && newsWizytowkaData.wizytowkaAktualnosci[0] && newsWizytowkaData.wizytowkaAktualnosci[0].aktualnosciWybierz) || [];
		const newsDataTable = [];
		const addCardsAutomaticaly = (newsWizytowkaData.wizytowkaAktualnosci && newsWizytowkaData.wizytowkaAktualnosci[0] && newsWizytowkaData.wizytowkaAktualnosci[0].dodawajWizytowkiAutomatycznie) || '';

		if (addCardsAutomaticaly) {
			aktualnosciWszystkie.forEach(card => {
				newsDataTable.push({
					"imageUrl": (card.aktualnoscWizytowka && card.aktualnoscWizytowka[0] && card.aktualnoscWizytowka[0].zdjecie &&
						card.aktualnoscWizytowka[0].zdjecie[0] && card.aktualnoscWizytowka[0].zdjecie[0].url) || "",
					"title": (card.title) || "",
					"dateCreate": card.postDate || "",
					"slug": card.slug || "",
				})
			})
		} else {
			newsData.forEach(item => {
				newsDataTable.push({
					"imageUrl": (item.aktualnoscWizytowka && item.aktualnoscWizytowka[0] && item.aktualnoscWizytowka[0].zdjecie &&
						item.aktualnoscWizytowka[0].zdjecie[0] && item.aktualnoscWizytowka[0].zdjecie[0].url) || "",
					"title": (item.aktualnoscWizytowka && item.aktualnoscWizytowka[0] && item.aktualnoscWizytowka[0].tytul) || "",
					"dateCreate": item.postDate || "",
					"slug": (item.slug && item.slug) || "",

				})
			});
		}

		const NewsAllDataArray = (newsWizytowkaData.wizytowkaAktualnosci) || [];
		let textBtn = '';
		let typeBtn = '';
		let toBtn = '';
		let hrefBtn = '';

		NewsAllDataArray.forEach(item => {
			if (item.__typename === 'Craft_wizytowkaAktualnosci_przyciskPodSliderem_BlockType') {
				textBtn = item.przyciskNapis || ""
				typeBtn = (item.przyciskRodzaj && item.przyciskRodzaj[0] && item.przyciskRodzaj[0].title) || ""
				toBtn = (item.przyciskWpis && item.przyciskWpis[0] && item.przyciskWpis[0].slug) || ""
				hrefBtn = item.przyciskLink || ""
			}
		})


		const settings = (slidesOver1279, slidesOver767) => {
			return {
				dots: false,
				infinite: true,
				arrows: false,
				draggable: false,
				swipe: true,
				lazyLoad: true,
				slidesToShow: 3,
				initialSlide: 0,
				slidesToScroll: 3,
				centerPadding: 0,
				responsive: [{
					breakpoint: 5200,
					settings: {
						slidesToShow: slidesOver1279,
						slidesToScroll: 3,
						initialSlide: 0,
					},
				}, {
					breakpoint: 1279,
					settings: {
						slidesToShow: slidesOver767,
						slidesToScroll: 2,
						initialSlide: 0,
					},
				}, {
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						variableWidth: false,
						initialSlide: 0,
					},
				}],
			}
		};

		const slidesOver1279 = 3;
		const slidesOver767 = 2;

		// if (newsDataTable.length >= 3) {
		// 	var newsOnMobile = newsDataTable.slice(0, 3);
		// } else {
		// 	newsOnMobile = newsDataTable.slice(0, newsDataTable.length)
		// }

		const paddingTopType = (newsWizytowkaData && newsWizytowkaData.wizytowkaAktualnosci[1].marginesGorny && newsWizytowkaData.wizytowkaAktualnosci[1].marginesGorny[0] &&
			newsWizytowkaData.wizytowkaAktualnosci[1].marginesGorny[0].title) || '';
		const paddingBottomType = (newsWizytowkaData &&
			newsWizytowkaData.wizytowkaAktualnosci[1].marginesDolny && newsWizytowkaData.wizytowkaAktualnosci[1].marginesDolny[0] &&
			newsWizytowkaData.wizytowkaAktualnosci[1].marginesDolny[0].title) || '';

		return (
			<>
				<ModuleWrapper paddingTop={getPaddingTop(paddingTopType)} paddingBottom={getPaddingBottom(paddingBottomType)}>
					<ContainerSliderVisible>
						<ArrowsWrapper>
							<SingleNavigationButtonContainer>
								<NavigationArrow onClick={this.previous.bind(this)} isEdge={this.state.isOldBrowserEdge} className="arrow-left" bgImg={ArrowLeftHovered}/>
							</SingleNavigationButtonContainer>
							<SingleNavigationButtonContainer>
								<NavigationArrow onClick={this.next.bind(this)} isEdge={this.state.isOldBrowserEdge} className="arrow-right" bgImg={ArrowRightHovered}/>
							</SingleNavigationButtonContainer>
						</ArrowsWrapper>
						<StyledWrapper>
							<Slider ref={slide => (this.slider = slide)} {...settings(slidesOver1279, slidesOver767)}>
								{newsDataTable.map((news, key) =>
									<Link key={key} to={`/${getSlug(news.slug, activeSite)}`}>
										<SingleNews index={key} data={news}/>
									</Link>
								)}
							</Slider>
						</StyledWrapper>
					</ContainerSliderVisible>
					<ButtonWrapper>
						<Button isOldBrowserEdge={isOldBrowserEdge} btnType={typeBtn} to={`/${getSlug(toBtn, activeSite)}`} href={hrefBtn}
								textBtn={textBtn}/>
					</ButtonWrapper>
				</ModuleWrapper>
			</>
		)
	}
}

export default News
