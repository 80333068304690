import React, {Component} from "react"
import styled from "styled-components"
import Button from "../common/ButtonsSet";

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	background-color: ${({theme}) => theme.colors.pmmDarkViolet};
`;

const Header = styled.h4`
	color: ${({theme}) => theme.colors.pmmWhite};
	position: relative;
	text-align: center;
	padding: 30px 25px 20px;
	width: 100%;
	@media(min-width: 768px){
		padding: 30px 25px 15px;
	}
	@media(min-width: 1920px){
		padding: 40px 10% 20px;
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 40px;
	@media(min-width: 1280px){

	}
	@media(min-width: 1920px){
		margin-top: 45px;	
	}
`;

const Pictogram = styled.img`
	width: 200px;
	margin: 0 auto;
	@media(min-width: 1280px) {
		width: 296px;
	}
`

class Component404 extends Component {

	render() {
		const component404 = this.props.data;
		const component404Data = (component404.modul404 && component404.modul404[0]) || [];
		const component404Pictogram = (component404Data && component404Data.piktogram && component404Data.piktogram[0] && component404Data.piktogram[0].url) || "";
		const component404Text = (component404Data && component404Data.tekst) || "";
		const component404ButtonText = (component404Data && component404Data.przyciskTekst) || "";
		// const component404ButtonLink = (component404Data && component404Data.przyciskLink && component404Data.przyciskLink[0] && component404Data.przyciskLink[0].slug) || "";

		const btnType = 'pomarańczowy z serduszkiem';
		return (
				<ModuleWrapper>
					<Pictogram src={component404Pictogram}/>
					<Header className="medium">{component404Text}</Header>
					<ButtonWrapper>
						<Button href="/" btnType={btnType} textBtn={component404ButtonText}/>
					</ButtonWrapper>
				</ModuleWrapper>

		)
	}
}

export default Component404
