import React from "react"
import styled from "styled-components"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";
import ArrowRight from "../../icons/arrows/arrow-right.svg"
import ArrowLeft from "../../icons/arrows/arrow-left.svg"
import ArrowRightHovered from "../../icons/arrows/arrow-right-orange.svg"
import ArrowLeftHovered from "../../icons/arrows/arrow-left-orange.svg"
import {Link} from "gatsby";
import Button from "../common/ButtonsSet";
import getSlug from "../../utils/getSlug";
import {BtnWrapperForBgPreload} from "../news/SingleNewsBanner";

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0;
	background-color: ${({theme}) => theme.colors.pmmDarkViolet};
	width: 100%;
	@media(min-width: 768px) {
		flex-direction: column-reverse;
	}
	@media(min-width: 1280px){
		flex-direction: row;
		padding: 0 100px 0 50px;
	}
	@media(min-width: 1920px) {
		padding: 0 180px 140px 100px;
	}
`;

const PictureWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	position: relative;
	background: none;
	margin-bottom: 30px;
	overflow: hidden;
	width: 100%;
	height: 300px;
	@media(min-width: 768px) {
		height: 600px;
	}
	@media(min-width: 1280px) {
		width: 67vw;
		margin: 0;
	}
	@media(min-width: 1920px) {
		width: 75vw;
		height: 800px;
	}
	@media(min-width: 2560px) {
		max-height: 1080px;
		height: 1080px;
	}	
`;

export const ZoomedPicture = styled.div`
	position: absolute;
	height: 100%;
	width: 100%;
	cursor: pointer;
	transition: transform .5s;
	background-size: cover;
	background-position: center;
	background-image: url( ${({bgImg}) => bgImg});
	&.animate {
		transform: ${({isPictogram}) => isPictogram ? "scale(1.1)" : "scale(1)"};
	}
	&:hover {
		transform: ${({isPictogram}) => isPictogram ? "scale(1.1)" : "scale(1)"};
	}	
	&.non-clickable {
		cursor: unset;
	}
`;

const MapPictogramWrapper = styled.div`
	position: absolute;
	left: 0;
	bottom: 0;
	padding: 20px;
	display: none;
	flex-direction: column;
	justify-content: center;
	@media(min-width: 1280px) {
		display: flex;
	}
`;

const SinglePictogramContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 53px;
	height: 53px;
	position: relative;
	transition: transform 0.2s ease-in-out, display .2s ease-in-out;
	&:hover	{
		> div.pictogram-circle {
			transform: scale(1.113);
		}
		> div.map-picto{
			background-image: url( ${({bgImgHovered}) => bgImgHovered} );
	 	}
	 	> div.camera-picto{
	 		background-image: url( ${({bgImgHovered}) => bgImgHovered} );
	 	}
	 	> h6.show {
	 		opacity: 1;
	 	}
	}
`;

const PictogramBackCircle = styled.div`
	width: 53px;
	height: 53px;
	background-color: ${({theme}) => theme.colors.pmmWhite};
	position: absolute;
	cursor: pointer;
	z-index: 1;
	right: 0;
	border-radius: 50%;
	transition: transform 0.15s ease-in-out;
`;

const ViewMapPictogram = styled.div`
	position: absolute;
	z-index: 100;
	cursor: pointer;
	transition: transform 0s;
	background-position: center;
	background-image: url( ${({bgImg}) => bgImg} );
	&.map-picto{
		background-size: 33px 33px;
		width: 33px;
		height: 33px;
		top: 10px;
		left: 10px;
	}
	&.camera-picto{
		background-size: 32px 26px;
		width: 32px;
		height: 26px;
		top: 13px;
		left: 11px;
	}
`;

const ViewMapText = styled.h6`
	display: block;
	opacity: 0;
	position: absolute;
	cursor: pointer;
	top: 18px;
	left: 55px;
	padding-left: 15px;
	width: 200px;
	color: ${({theme}) => theme.colors.pmmWhite};
	transition: opacity .2s ease-in-out;
`;

const SlideWrapper = styled.div`
	display: flex;
	top: -60px;
	margin: 0 auto;
	position: relative;
	justify-content: center;
	@media(min-width: 768px) {
		top: 0;
	}
	@media(min-width: 1280px) {
		//width: calc((100% - 100px)*0.48);
		margin: 0 0 0 -36px;
		flex-direction: column;
	}
	@media(min-width: 1366px) {
		//width: calc((100% - 100px)*0.44);
	}
	@media(min-width: 1920px) {
		margin-left: -88px;
		justify-content: flex-end;
		padding: 75px 0;
	}
	@media(min-width: 2560px) {
		justify-content: center;
	}

`;

const NavigationArrowsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	position: absolute;
	right: 20px;
	top: 25px;
	width: 31%;
	height: 75px;
	@media(min-width: 500px) {
		right: 30px;
	}
	@media(min-width: 768px) {
		right: 35px;
		top: -60px;
	}
	@media(min-width: 1280px) {
		right: 0;
		top: -20px;
	}
	@media(min-width: 1920px) {
	right: 0;
	top: 0;
	width: 30%;
	height: 75px;
	justify-content: flex-end;
	}
	  
	  &.hide {
	   display: none;
	  }
`;

const SingleNavigationButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-left: 10px;
	width: 43px;
	height: 43px;
	position: relative;
	transition: transform 0.15s ease-in-out;
	@media(min-width: 1920px) {
		width: 53px;
		height: 53px;
		margin-left: 20px;
	}
	@media(min-width: 1024px) {
		&.container-right{
			 &:hover	{
				 > div.arrow-right{
					background-image: url( ${({bgImgHovered}) => bgImgHovered} );
				 }
			 }
		}
		&.container-left{
			 &:hover	{
				 > div.arrow-left{
					background-image: url( ${({bgImgHovered}) => bgImgHovered} );
				 }
			 }
		}
	}
`;

const NavigationArrow = styled.div`
	width: 42px;
	height: 42px;
	position: absolute;
	top: 5px;
	right: 0;
	cursor: pointer;
	z-index: 1;
	transition: transform .3s ease-in-out;
	background-size: cover;
	background-position: center;
	background-image: url( ${({bgImg}) => bgImg} );
	
	&:hover {
		&.zoom {
			transform: scale(1.113);
		}
		&.no-zoom {
			transform: scale(1);
		}
	} 
	
	@media(min-width: 1920px) {
		top: 10px;
		width: 53px;
		height: 53px;
	}
	@media(min-width: 1920px) {
		top: 0;
		right: 0;
		width: 53px;
		height: 53px;
	}
`;

const InfoSquare = styled.div`
	width: 95%;
	border: 3px solid ${({theme}) => theme.colors.pmmOrange};
	position: relative;
	padding: 20px 15px 30px 20px; 
	@media(min-width: 768px) {
		width: 100%;
		margin: 0 35px -30px;
		padding: 40px 40px 50px 30px; 
	}
	@media(min-width: 1280px ) {
		margin: 0;
		width: 535px;
		height: 520px;
		padding-left: 20px;
	}
	@media(min-width: 1366px) {
		width: 565px;
		height: 520px;
	
	}
	@media(min-width: 1920px) {
		width: 735px;
		height: 650px;
		padding: 55px 150px 120px 40px;
		right: 0; 	
	}
`;

const SlideTitle = styled.p`
	font-size: 30px;
	line-height: 60px;
	position: relative;
	width: 60%;
	margin-left: 10px;
	margin-top: 20px;
	color: ${({theme}) => theme.colors.pmmWhite};
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	font-weight: ${({theme}) => theme.fontWeight.medium};
	padding-bottom: 30px;
	@media(min-width: 768px ) {
		font-size: 50px;
		line-height: 80px;
		width: 100%;
	}
	@media(min-width: 1280px) {
		width: 70%;
	}
	@media(min-width: 1920px) {
		font-size: 70px;
		line-height: 105px;
		padding-bottom: 60px;
		width: 100%;
	}
`;

const AnimatedSpanBackground = styled.span`
	//background-color: #FF4124;
	////background-image: linear-gradient(180deg,transparent 7%,#FF4124 0);
		//background-size: 100% 100%;
		////padding-right: 10px;
		////padding: 15px 20px 15px 10px;
		//background-repeat: no-repeat;
		//text-decoration: none;
		display: inline;
		background: #FF4124;
		padding: 10px 0;
		box-shadow: 10px 0 0 #FF4124, -10px 0 0 #FF4124;
	@media(min-width: 1280px) {
		padding: 6px 0;
	}
`;

const SlideDescription = styled.div`
	font-size: 14px;
	line-height: 28px;
	color: ${({theme}) => theme.colors.pmmWhite};
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	font-weight: ${({theme}) => theme.fontWeight.medium};
	padding-bottom: 30px;
	@media(min-width: 768px) {
		font-size: 16px;
		line-height: 30px;
	}
	@media(min-width: 1920px) {
		font-size: 20px;
		line-height: 34px;		
	}
`;

const SlideCounter = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: absolute;
	bottom: 15px;
	right: 15px;
	@media(min-width: 768px ) {
		bottom: 40px;
	}
	@media(min-width: 1280px) {
		bottom: 10px;
	}
	@media(min-width: 1920px) {
		bottom: 20px;
		right: 20px;
	}
	
	&.hide {
		display: none;
	}
`;

const CurrentSlideNumber = styled.p`
	font-size: 16px;
	line-height: 19px;
	color: ${({theme}) => theme.colors.pmmWhite};
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	font-weight: ${({theme}) => theme.fontWeight.bold};
	margin-right: 35px;
	@media(min-width: 1920px) {
		font-size: 20px;
		line-height: 25px;
		margin-right: 50px; 	
	}
`;

const NumberOfSlides = styled.p`
  font-size: 16px;
  line-height: 19px;
  position: relative;
  color: ${({theme}) => theme.colors.pmmWhite};
  font-family: ${({theme}) => theme.fontFamily.mainFont};
  font-weight: ${({theme}) => theme.fontWeight.bold};
  &:before{
    content: '';
    position: absolute;
    left: -27px;
    bottom: 5px;
    height: 1px;
    width: 20px;
    background-color: ${({theme}) => theme.colors.pmmWhite};    
  }
  @media(min-width: 1920px) {
	font-size: 20px;
	line-height: 25px; 
	&:before {
		width: 30px;
		left: -39px;
	}	
  }
`;

export const setPictogramsData = (pictograms) => {
	const mapPictogramDefault = (pictograms && pictograms.mapPictogramDefault && pictograms.mapPictogramDefault[0] && pictograms.mapPictogramDefault[0].url) || '';
	const mapPictogramHovered = (pictograms && pictograms.mapPictogramHovered && pictograms.mapPictogramHovered[0] && pictograms.mapPictogramHovered[0].url) || '';
	const photoPictogramDefault = (pictograms && pictograms.photoPictogramDefault && pictograms.photoPictogramDefault[0] && pictograms.photoPictogramDefault[0].url) || '';
	const photoPictogramHovered = (pictograms && pictograms.photoPictogramHovered && pictograms.photoPictogramHovered[0] && pictograms.photoPictogramHovered[0].url) || '';

	return {
		'mapPictogramDefault': mapPictogramDefault,
		'mapPictogramHovered': mapPictogramHovered,
		'photoPictogramDefault': photoPictogramDefault,
		'photoPictogramHovered': photoPictogramHovered
	}
}

class WhereWeHelpCard extends React.Component {
	state = {
		isShowMapTriggered: false,
		isPictureHovered: false,
		isButtonHovered: false,
		isOldBrowserEdge: false,
	};

	componentDidMount() {
		if (typeof window !== "undefined") {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({isOldBrowserEdge: isOldBrowser});
		}
	}

	togglePicture() {
		this.setState({isShowMapTriggered: !this.state.isShowMapTriggered})
	};

	getImage = () => this.state.isShowMapTriggered ? "map" : "picture";

	triggerButtonAnimation() {
		this.setState({isPictureHovered: !this.state.isPictureHovered})
	}

	triggerPictureAnimation() {
		this.setState({isButtonHovered: !this.state.isButtonHovered})
	}

	render() {
		const whereWeHelpCard = this.props.data;
		const {currentProjectIndex, changeProject, activeSite} = this.props
		const pictograms = this.props.piktogramyData;

		let {mapPictogramDefault, mapPictogramHovered, photoPictogramDefault, photoPictogramHovered} = setPictogramsData(pictograms);
		const isPictureHovered = this.state.isPictureHovered;
		const isButtonHovered = this.state.isButtonHovered;

		const cardData = (whereWeHelpCard.projektWizytowka && whereWeHelpCard.projektWizytowka) || '';
		const cardDataSingleValues = [];
		const numberOfSlides = (cardData.length < 10) ? '0' + cardData.length : cardData;
		const imageNames = this.getImage();
		if (cardData.length > 0) {
			cardData.forEach(item => {
				cardDataSingleValues.push({
					"title": (item.gdziePomagamyTytul) || "",
					"description": (item.gdziePomagamyOpis) || "",
					"buttonText": (item.gdziePomagamyNapisNaPrzycisku) || "",
					"buttonType": (item.gdziePomagamyPrzyciskRodzaj && item.gdziePomagamyPrzyciskRodzaj[0] && item.gdziePomagamyPrzyciskRodzaj[0].title) || "",
					"picture": (item.gdziePomagamyObrazek && item.gdziePomagamyObrazek[0] && item.gdziePomagamyObrazek[0].url) || "",
					"map": (item.gdziePomagamyMapka && item.gdziePomagamyMapka[0] && item.gdziePomagamyMapka[0].url) || "",
					"slug": (item.slug) || "",
				})
			})
		}

		function slideRight() {
			if (currentProjectIndex < cardDataSingleValues.length - 1) {
				changeProject(currentProjectIndex + 1)
			} else changeProject(0)
		}

		function slideLeft() {
			if (currentProjectIndex > 0) {
				changeProject(currentProjectIndex - 1)
			} else changeProject(cardDataSingleValues.length - 1)
		}

		const currentSlideNumber = (currentProjectIndex < 9) ? "0" + (currentProjectIndex + 1) : (currentProjectIndex + 1);
		const title = cardDataSingleValues[currentProjectIndex]?.title;
		const description = cardDataSingleValues[currentProjectIndex].description;
		const buttonText = cardDataSingleValues[currentProjectIndex].buttonText;
		const buttonType = cardDataSingleValues[currentProjectIndex].buttonType;

		const btnSlug = cardDataSingleValues[currentProjectIndex].slug;
		const sliderPicture = {
			picture: cardDataSingleValues[currentProjectIndex].picture,
			map: cardDataSingleValues[currentProjectIndex].map
		};
		const pictogramPicture = {
			picture: mapPictogramDefault,
			map: photoPictogramDefault
		};
		const pictogramPictureHovered = {
			picture: mapPictogramHovered,
			map: photoPictogramHovered
		};
		// TODO: tlumaczenia z crafta
		const textOnHover = {
			picture: "ZOBACZ NA MAPIE",
			map: "POKAŻ ZDJĘCIE"
		};
		const textOnHoverEn = {
			picture: "SEE ON MAP",
			map: "SHOW PICTURE"
		}
		const currentLangTextOnHover = (activeSite === "pl") ? textOnHover : textOnHoverEn;
		const isPictureZoomed = (imageNames === "picture");
		const additionalClassName = (isButtonHovered) ? 'animate' : '';
		const pictogramClassName = (imageNames === "map") ? "camera-picto" : "map-picto";

		const arrowClassName = (this.state.isOldBrowserEdge) ? "no-zoom" : "zoom";
		const additionalClassNameForBtn = (isPictureHovered) ? 'animate' : '';

		return (
			<>
				<ModuleWrapper>
					<PictureWrapper>
						<ZoomedPicture className={additionalClassName}
									   onFocus={this.triggerButtonAnimation.bind(this)} onBlur={this.triggerButtonAnimation.bind(this)}
									   onMouseOver={this.triggerButtonAnimation.bind(this)}
									   onMouseOut={this.triggerButtonAnimation.bind(this)}
									   isPictogram={isPictureZoomed}
									   as={Link} to={`/${getSlug(btnSlug, activeSite)}`}
									   bgImg={sliderPicture[imageNames]}/>
						<MapPictogramWrapper>
							<SinglePictogramContainer bgImgHovered={pictogramPictureHovered[imageNames]}
													  onClick={this.togglePicture.bind(this)}>
								<ViewMapPictogram className={pictogramClassName}
												  bgImg={pictogramPicture[imageNames]}
												  bgImgHovered={pictogramPictureHovered[imageNames]}
												  isPictureHovered={isPictureHovered}/>
								<BtnWrapperForBgPreload iconHoverBtn={pictogramPictureHovered['picture']}/>
								<BtnWrapperForBgPreload iconHoverBtn={pictogramPictureHovered['map']}/>
								<BtnWrapperForBgPreload iconHoverBtn={pictogramPicture['map']}/>
								<PictogramBackCircle className="pictogram-circle"/>
								<ViewMapText className="show">{currentLangTextOnHover[imageNames]}</ViewMapText>
							</SinglePictogramContainer>
						</MapPictogramWrapper>
					</PictureWrapper>
					<SlideWrapper>
						<NavigationArrowsWrapper className={cardData.length === 1 ? 'hide' : ''}>
							<SingleNavigationButtonContainer isEdge={this.state.isOldBrowserEdge}
															 className="container-left" bgImgHovered={ArrowLeftHovered}>
								<NavigationArrow onClick={slideLeft.bind(this)}
												 className={`${arrowClassName} arrow-left`} bgImg={ArrowLeft}
												 bgImgHovered={ArrowLeftHovered}/>
							</SingleNavigationButtonContainer>
							<SingleNavigationButtonContainer isEdge={this.state.isOldBrowserEdge}
															 className="container-right"
															 bgImgHovered={ArrowRightHovered}>
								<NavigationArrow onClick={slideRight.bind(this)}
												 className={`${arrowClassName} arrow-right`}
												 bgImg={ArrowRight} bgImgHovered={ArrowRightHovered}/>
							</SingleNavigationButtonContainer>
						</NavigationArrowsWrapper>
						<InfoSquare>
							<SlideTitle><AnimatedSpanBackground>{title}</AnimatedSpanBackground></SlideTitle>
							<SlideDescription className="paragraph who-we-help"
											  dangerouslySetInnerHTML={getHtmlFromRedactorField(description)}/>
							<Button onMouseOver={this.triggerPictureAnimation.bind(this)}
									onMouseOut={this.triggerPictureAnimation.bind(this)}
									additionalClassName={additionalClassNameForBtn} btnType={buttonType}
									to={`/${getSlug(btnSlug, activeSite)}`} textBtn={buttonText}/>
							<SlideCounter className={cardData.length === 1 ? 'hide' : ''}>
								<CurrentSlideNumber>{currentSlideNumber}</CurrentSlideNumber>
								<NumberOfSlides>{numberOfSlides}</NumberOfSlides>
							</SlideCounter>
						</InfoSquare>
					</SlideWrapper>
				</ModuleWrapper>

			</>
		)
	}
}

export default WhereWeHelpCard
