import React, {Component} from 'react';
import styled from "styled-components";
import ArrowLeftHovered from "../../images/navigationArrows/arrow-left-hover.svg";
import ArrowRightHovered from "../../images/navigationArrows/arrow-right-hovered.svg";
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";
import getSingleImage from "../../utils/getSingleImage";
import PlayButton from "../../images/videoModule/play-button.svg"
import ReactPlayer from 'react-player'


const ModuleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  position: relative;
  background-color: ${({theme}) => theme.colors.pmmWhite};
  width: 100%;

  @media(min-width: 1280px){
  	flex-direction: row;
  	padding: 0 50px 0 0;
  }
  @media(min-width: 1920px) {
  	padding: 0 100px 0 0;
  }
`;

const PictureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  background: none;
  margin-bottom: 30px;
  overflow: hidden;
  width: 100%;
  height: 360px;
  @media(min-width: 768px) {
  	height: 800px;
  	margin: 0;
  }
  @media(min-width: 1280px) {
	height: 650px;
	margin: 0;
	
	&::before {
		content: '';
		position: absolute;
		background: transparent linear-gradient(90deg, transparent 85%, #FFFFFFC9 100%) 0 0 no-repeat padding-box;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;
	}
  }
  @media(min-width: 1920px) {
  	height: 900px;
  }
`;

const Picture = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-image: url( ${({bgImgMobile}) => bgImgMobile});
    
     @media(min-width: 1025px) {
		 background-image: url( ${({bgImg}) => bgImg});
  	}
	&.non-clickable {
		cursor: unset;
	}
`;

const VideoWrapper = styled.div`
	position: absolute;
	width: 100vw;
	height: 100vh;
	margin-left: 25px;
	z-index: 101;
	margin-top: -25px;
	background-color: transparent;
	&.desktop {
		display: none;
		@media(min-width: 1280px) {
			display: block;
		}
	}
	&.mobile {
		display: block;
		height: 100%;
		margin-left: 0;
		margin-top: 0;
		@media(min-width: 1280px) {
			display: none;
		}
	}
	@media(min-width: 1920px) {
		//height: calc(100vh + 25px);
		margin-left: 50px;
	}
	
`

const PlayButtonWrapper = styled.img`
	width: 100px;
	height: 100px;
    cursor: pointer;
	position: absolute;
	z-index: 1;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    transition: transform 1s ease-in-out;
    &.video-on {
    	transform: scale(1.2);
    }
    @media(min-width: 1280px) {
    	width: 80px;
    	height: 80px;
    	top: calc(50% - 40px);
    	left: calc(50% - 40px);
    }
    @media(min-width: 1920px) {
    	width: 150px;
    	height: 150px;
    	top: calc(50% - 55px);
    	left: calc(50% - 55px);
    }
`;

const SlideWrapper = styled.div`
  display: flex;
  top: -60px;
  margin: 0 auto;
  position: relative;
  justify-content: center;
  @media(min-width: 768px) {
  	top: 0;
  }
  @media(min-width: 1280px) {
  	//width: calc((100% - 100px)*0.48);
  	margin: 0 0 0 -36px;
  	flex-direction: column;
  }
  @media(min-width: 1920px) {
  	margin-left: -88px;
  	justify-content: flex-end;
  	padding: 35px 0;
  }
`;

const NavigationArrowsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  right: 20px;
  top: 25px;
  width: 31%;
  height: 75px;
  @media(min-width: 500px) {
  	right: 30px;
  }
  @media(min-width: 768px) {
  	right: 55px;
  	top: -10px;
  }
  @media(min-width: 1280px) {
  	right: 0;
  	top: -25px;
  }
  @media(min-width: 1920px) {
	right: 0;
	top: 0;
	width: 30%;
	height: 75px;
	justify-content: flex-end;
  }
  
  &.hide {
   display: none;
  }
`;

const SingleNavigationButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  width: 43px;
  height: 43px;
  position: relative;
  transition: transform 0.15s ease-in-out;
  @media(min-width: 1920px) {
	width: 53px;
	height: 53px;
	margin-left: 20px;
	top: -25px;
  }
  @media(min-width: 1024px) {
	  &.container-right{
	     &:hover  {
	        > div.circle {
	            transform: scale(1.113);
		        border: 1px solid ${({theme}) => theme.colors.pmmOrange};
		        box-shadow: 0 0 1px 0 ${({theme}) => theme.colors.pmmOrange} inset, 0 0 1px 0px ${({theme}) => theme.colors.pmmOrange};
	        }
	        > div.no-zoom {
	            transform: scale(1);
	            border: 1px solid ${({theme}) => theme.colors.pmmOrange};
				box-shadow: 0 0 1px 0 ${({theme}) => theme.colors.pmmOrange} inset, 0 0 1px 0px ${({theme}) => theme.colors.pmmOrange};
	        }
	       > div.arrow-right{
	            background-image: url( ${({bgImgHovered}) => bgImgHovered} );
	       }
	     }
	  }
	  &.container-left{
	     &:hover  {
	        > div.circle {
	            transform: scale(1.113);
	            border: 1px solid ${({theme}) => theme.colors.pmmOrange};
				box-shadow: 0 0 1px 0 ${({theme}) => theme.colors.pmmOrange} inset, 0 0 1px 0px ${({theme}) => theme.colors.pmmOrange};
	        }
	        > div.no-zoom {
	            transform: scale(1);
	            border: 1px solid ${({theme}) => theme.colors.pmmOrange};
				box-shadow: 0 0 1px 0 ${({theme}) => theme.colors.pmmOrange} inset, 0 0 1px 0px ${({theme}) => theme.colors.pmmOrange};
	        }
	       > div.arrow-left{
	            background-image: url( ${({bgImgHovered}) => bgImgHovered} );
	       }
	     }
	  }
  }
`;

const NavigationArrow = styled.div`
  width: 17px;
  height: 7px;
  position: absolute;
  top: 18px;
  right: 12px;
  cursor: pointer;
  z-index: 1;
  transition: transform 0s;
  background-size: cover;
  background-position: center;
  background-image: url( ${({bgImg}) => bgImg} );
  @media(min-width: 1920px) {
	top: 23px;
	right: 15px;
	width: 21px;
	height: 8px;
  }
`;

const Circle = styled.div`
  width: 43px;
  height: 43px;
  position: absolute;
  cursor: pointer;
  z-index: 1;
  right: 0;
  border-radius: 50%;
  border: 1px solid ${({theme}) => theme.colors.pmmOrange};
  box-shadow: 0 0 1px 0 ${({theme}) => theme.colors.pmmOrange} inset, 0 0 1px 0px ${({theme}) => theme.colors.pmmOrange};
  background: none;
  transition: transform 0.15s ease-in-out;
  @media(min-width: 1920px) {
	width: 53px;
	height: 53px;
  }
`;

const InfoSquare = styled.div`
  width: 100%;
  border: 3px solid ${({theme}) => theme.colors.pmmOrange};
  position: relative;
  padding: 20px 15px 30px 20px;
  z-index: 2;
  @media(min-width: 768px) {
  	width: 100%;
  	margin: -40px 35px 0;
  	padding: 40px 40px 50px 30px; 
  }
  @media(min-width: 1280px ) {
  	margin: 0;
	width: 965px;
	height: 600px;
	padding: 30px 40px 50px;
  }
  @media(min-width: 1366px) {
	width: 945px;
	height: 600px;
	
  }
  @media(min-width: 1920px) {
  	width: 1100px;
  	height: 840px;
  	padding: 60px 60px 70px;
  	right: 0; 	
  }
`;

const SlideTitle = styled.p`
  font-size: 30px;
  line-height: 60px;
  position: relative;
  width: 60%;
  margin-left: 10px;
  color: ${({theme}) => theme.colors.pmmWhite};
  font-family: ${({theme}) => theme.fontFamily.mainFont};
  font-weight: ${({theme}) => theme.fontWeight.medium};
  padding-bottom: 30px;
  @media(min-width: 768px ) {
	font-size: 50px;
	line-height: 80px;
	width: 100%;
	margin-top: -10px;
  }
  @media(min-width: 1280px) {
  	width: 70%;
  	margin-top: 0;
  }
  @media(min-width: 1920px) {
    margin-top: 20px;
	font-size: 70px;
	line-height: 105px;
	margin-top: 0;
	padding-bottom: 60px;
	width: 100%;
  }
`;

const AnimatedSpanBackground = styled.span`
	//background-color: #FF4124;
	////background-image: linear-gradient(180deg,transparent 7%,#FF4124 0);
    //background-size: 100% 100%;
    ////padding-right: 10px;
    ////padding: 15px 20px 15px 10px;
    //background-repeat: no-repeat;
    //text-decoration: none;
	display: inline;
	background: #FF4124;
	padding: 10px 0;
	box-shadow: 10px 0 0 #FF4124, -10px 0 0 #FF4124;
	@media(min-width: 1280px) {
		padding: 6px 0;
	}
`;

const SlideDescription = styled.div`
	color: ${({theme}) => theme.colors.pmmDarkViolet};
	padding-bottom: 30px;
	@media(min-width: 1280px) {
		div {
			display: block;
			column-count: 2;
			column-gap: 60px;
		}
	}
	@media(min-width: 1920px) {
		padding-bottom: 0;
	}
`;

const SlideCounter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  bottom: 15px;
  right: 15px;
  @media(min-width: 768px ) {
  	bottom: 40px;
  }
  @media(min-width: 1280px) {
  	bottom: 10px;
  }
  @media(min-width: 1920px) {
	bottom: 20px;
	right: 20px;
  }
  
  &.hide {
  	display: none;
  }
`;

const CurrentSlideNumber = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: ${({theme}) => theme.colors.pmmDarkViolet};
  font-weight: ${({theme}) => theme.fontWeight.bold};
  margin-right: 35px;
  @media(min-width: 1920px) {
	font-size: 20px;
	line-height: 25px;
	margin-right: 35px; 	
  }
`;

const NumberOfSlides = styled.p`
  font-size: 16px;
  line-height: 19px;
  position: relative;
  color: ${({theme}) => theme.colors.pmmDarkViolet};
  font-weight: ${({theme}) => theme.fontWeight.bold};
  @media(min-width: 1920px) {
	font-size: 20px;
	line-height: 25px; 
	&:before {
		width: 30px;
		left: -38px;
	}	
  }
  &:before{
    content: '';
    position: absolute;
    left: -27px;
    bottom: 5px;
    height: 1px;
    width: 20px;
    background-color: ${({theme}) => theme.colors.pmmDarkViolet};    
  }
`;

class WhoWeHelp extends Component {

	state = {
		currentProjectIndex: 0,
		isOldBrowserEdge: false,
		ReactPlayer: null,
		isPlayPressed: false,
		isDesktop: false,
	};

	componentDidMount() {
		if (typeof window !== "undefined") {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({isOldBrowserEdge: isOldBrowser});
			this.setState({ ReactPlayer: require("react-player").default })
			if (window.innerWidth >= 1280 && !this.state.isDesktop) {
				this.setState({
					isDesktop: true,
				})
			} else if (window.innerWidth < 1280 && this.state.isDesktop) {
				this.setState({isDesktop: false})
			}
		}
	}
	togglePlay() {
		this.setState({ isPlayPressed: !this.state.isPlayPressed })
	};

	render() {
		const {data} = this.props
		const {currentProjectIndex, ReactPlayer, isPlayPressed, isDesktop } = this.state
		const whoWeHelpData = (data && data.modulKomuPomagamySzczegoly) || []
		const numberOfSlides = whoWeHelpData.length || 0;
		const numberOfSlidesToShow = (numberOfSlides < 10) ? '0' + numberOfSlides : numberOfSlides;
		const currentSlideData = (whoWeHelpData[currentProjectIndex].historiaOsobySzczegoloweInformacje && whoWeHelpData[currentProjectIndex].historiaOsobySzczegoloweInformacje[0]) || "";

		const title = currentSlideData.naglowek;
		const description = currentSlideData.opis || "";
		const bgImg = currentSlideData.zdjeciePionowe || ""
		const bgImgMobile = currentSlideData.zdjeciePionoweMobile || ""
		const videoUrl = currentSlideData.linkdofilmu || "";
		const circleClassName = (this.state.isOldBrowserEdge) ? "no-zoom" : "circle";
		const currentSlideNumber = (currentProjectIndex < 9) ? "0" + (currentProjectIndex + 1) : (currentProjectIndex + 1);

		function slideRight() {
			if (currentProjectIndex < numberOfSlides - 1) {
				this.setState({currentProjectIndex: currentProjectIndex + 1})
			} else this.setState({currentProjectIndex: 0})
		}

		function slideLeft() {
			if (currentProjectIndex > 0) {
				this.setState({currentProjectIndex: currentProjectIndex - 1})
			} else this.setState({currentProjectIndex: numberOfSlides - 1})
		}
		const isVideoOn = (isPlayPressed) ? "video-on" : "" ;
		const isVideoAdded = (videoUrl);

		return (
			<ModuleWrapper>
				{isPlayPressed && isVideoAdded && <VideoWrapper className="desktop">
					{isDesktop && <ReactPlayer width='100%'
								             height='100%'
								             controls={"false"}
								             className={isVideoOn}
								             playing={this.state.isPlayPressed}
								             url={videoUrl}/>}
				</VideoWrapper>}
				<PictureWrapper>
					<Picture className="non-clickable" bgImgMobile={getSingleImage(bgImgMobile)} bgImg={getSingleImage(bgImg)}/>
					{ !isPlayPressed && isVideoAdded && <PlayButtonWrapper className={isVideoOn} onClick={this.togglePlay.bind(this)} src={PlayButton}/>}
					{isPlayPressed && isVideoAdded && <VideoWrapper className="mobile">
						{!isDesktop && <ReactPlayer width='100%'
						             height='100%'
						             controls={"false"}
						             className={isVideoOn}
						             playing={this.state.isPlayPressed }
						             url={videoUrl}/>}
					</VideoWrapper>}
				</PictureWrapper>
				<SlideWrapper>
					<NavigationArrowsWrapper className={numberOfSlides === 1 ? 'hide' : ''}>
						<SingleNavigationButtonContainer isEdge={this.state.isOldBrowserEdge}
														 className="container-left" bgImgHovered={ArrowLeftHovered}>
							<NavigationArrow onClick={slideLeft.bind(this)} className="arrow-left"
											 bgImg={ArrowLeftHovered}
											 bgImgHovered={ArrowLeftHovered}/>
							<Circle className={circleClassName} onClick={slideLeft.bind(this)}/>
						</SingleNavigationButtonContainer>
						<SingleNavigationButtonContainer isEdge={this.state.isOldBrowserEdge}
														 className="container-right"
														 bgImgHovered={ArrowRightHovered}>
							<NavigationArrow onClick={slideRight.bind(this)} className="arrow-right"
											 bgImg={ArrowRightHovered} bgImgHovered={ArrowRightHovered}/>
							<Circle className={circleClassName} onClick={slideRight.bind(this)}/>
						</SingleNavigationButtonContainer>
					</NavigationArrowsWrapper>
					<InfoSquare>
						<SlideTitle><AnimatedSpanBackground>{title}</AnimatedSpanBackground></SlideTitle>
						<SlideDescription className="paragraph who-we-help"
										  dangerouslySetInnerHTML={getHtmlFromRedactorField(description)}/>
						<SlideCounter className={numberOfSlides === 1 ? 'hide' : ''}>
							<CurrentSlideNumber>{currentSlideNumber}</CurrentSlideNumber>
							<NumberOfSlides>{numberOfSlidesToShow}</NumberOfSlides>
						</SlideCounter>
					</InfoSquare>
				</SlideWrapper>
			</ModuleWrapper>
		);
	}
}

export default WhoWeHelp
