import React, {Component} from 'react'
import styled from "styled-components"
import { sendData } from "../../utils/sendEmailMethod"
import Button from "../common/ButtonsSet";
import Checkbox from "./Checkbox";
import Popup from "../common/popup/Popup";
import WholeInfoProcessingPersonalData from "../common/WholeInfoProcessingPersonalData";

const ContactFormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	position: relative;
	background-color: ${({theme}) => theme.colors.pmmWhite};
	//padding-top: 40px;
	@media(min-width: 1280px) {
	}
	@media(min-width: 1920px) {
		//padding-top: 100px;
	}
`;

const StyledInput = styled.input`
	border: 0;
	border-bottom: 1px solid #BCBDC0;
	background-color: ${({ theme }) => "2px solid" + theme.colors.pmmWhite};
	outline: none;
	display: block;
	padding: 10px 15px;
	margin-top: 10px;
	width: 100%;
	position: relative;
	height: 50px;
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	color: #707070;
	font-size: 16px;
	cursor: pointer;
	transition: border .25s ease-in-out, background-color .25s ease-in-out;
	box-shadow: none;
	@media(min-width: 768px) {
		margin-top: 0;
	}
	&.two-columns {
		width: 100%;
		@media(min-width: 768px) {
			width: 45%;
		}
	}
	@media(min-width: 1280px) {

  	}
	@media(min-width: 1920px) {
	    padding: 10px 15px;
		width: 100%;
		height: 50px;
  	}
	&.active {
		border: ${({ theme }) => "1px solid" + theme.colors.pmmInputBorderOrange};	
	}
	&.wrong-email {
		border: ${({ theme }) => "3px solid" + theme.colors.pmmInputBorderOrange};
		background-color: ${({ theme }) => theme.colors.pmmInputErrorOrange}
	}
  
`;

const CheckboxInfoVisibilityWrapper = styled.div`
	display: block;
	position: relative;
`;

const ButtonWrapper = styled.div`
	//height: 120px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-top: 120px;
	margin-left: -10px;
	@media(min-width: 768px) {
		justify-content: flex-end;
		margin-top: 60px;
	}
	@media(min-width: 1280px) {
		justify-content: flex-start;
	}
	@media(min-width: 1366px) {
		margin-top: 45px;
	}
  	@media(min-width: 1920px) {
        margin-top: 35px;
        margin-left: -10px;
  	}	
`;

const Header = styled.h3`
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
	position: relative;
	display: none;
	@media(min-width: 768px) {
		display: block;
	}	
`;

const Description = styled.p`
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	padding: 15px 0 20px;
	display: none;
	@media(min-width: 768px) {
		max-width: 100%;
		display: block;
		padding: 15px 0 20px;
	}
	@media(min-width: 1280px) {
		padding-bottom: 45px;
	}
	@media(min-width: 1920px ) {
		padding: 20px 0 55px;
		max-width: 70%;
	}
`;

const TwoColumnsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	@media(min-width: 768px) {
		flex-direction: row;
		justify-content: space-between;
		padding-bottom: 20px;
	}
	@media(min-width: 1280px) {
		padding-bottom: 35px;
	}
	@media(min-width: 1920px) {
		padding-bottom: 45px;
	}
`;

class ContactForm extends Component {
	state = {
		firstName: "",
		email: "",
		message: "",
		isEmailPending: false,
		privacyPolicyAgree: false,
		isPopupVisible: false,
		isWholeInfoShown: false,
		firstNameInvalid: false,
		phoneInvalid: false,
		emailInvalid: false,
		messageInvalid: false,
		firstNameInvalidMsg: "",
		emailInvalidMsg: "",
		phoneInvalidMsg: "",
		messageInvalidMsg: "",
		isOldBrowserEdge: false,
	};

	initialState = {
		firstName: "",
		email: "",
		message: "",
		privacyPolicyAgree: false,
	};

	componentDidMount() {
		if (typeof window !== "undefined") {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({isOldBrowserEdge: isOldBrowser});
		}
	}

	markCheckbox() {
		this.setState({ privacyPolicyAgree: !this.state.privacyPolicyAgree })
	}
	togglePopup() {
		this.setState({ isPopupVisible: !this.state.isPopupVisible })
	};
	toggleWholeInfoShown() {
		this.setState({ isWholeInfoShown: !this.state.isWholeInfoShown })
	};
	handleInputChange = event => {
		const target = event.target
		const value = target.type === "checkbox" ? target.checked : target.value
		const name = target.name
		this.setState({ [name]: value, [name + "Invalid"]: false, [name + "InvalidMsg"]: "" })
	}

	handleSubmit = event => {
		event.preventDefault()
		try {
			this.sendEmail()
		} catch (err) {
			console.log(err)
		}
	}

	async sendEmail() {
		this.setState({ isEmailPending: true })
		const data = new FormData();
		data.append("fromEmail", this.state.email);
		data.append("fromName", this.state.firstName);
		data.append("action", "contact-form/send");
		data.append("message[body]", this.state.message);

		const rawResponse = await sendData(data)
		const responseData = await rawResponse.json()
		const getInvalidInput = {
			"fromEmail": "emailInvalid",
			"fromName": "firstNameInvalid",
			"message[body]": "messageInvalid",
		}
		if (responseData.success) {
			this.togglePopup()
			this.setState(this.initialState)
			this.setState({ isEmailPending: false })
		} else if (responseData.errors) {
			Object.keys(responseData.errors).forEach((key) => {
				this.setState({ isEmailPending: false })
				this.setState({ [getInvalidInput[key]]: true })
				const errorMessagesArray = responseData.errors[key]
				errorMessagesArray.forEach((errorMsg) => {
					this.setState({ [getInvalidInput[key] + "Msg"]: errorMsg })
				})
			})
		}
	}

	render() {
		const {formFieldsData, formData, processingData} = this.props;
		const {isWholeInfoShown, isOldBrowserEdge} = this.state;
		const formHeader = formFieldsData.naglowek || "";
		const formDescription = formFieldsData.opis || "";
		const formFirstNamePlaceholder = formFieldsData.imiePlaceholder || "";
		const formEmailPlaceholder = formFieldsData.emailPlaceholder || "";
		const formMessagePlaceholder = formFieldsData.wiadomoscPlaceholder || "";
		const formCheckboxText = formFieldsData.checkboxTekst || "";
		// const formCheckboxLink = (formFieldsData.checkboxTekst && formFieldsData.checkboxTekst[0] && formFieldsData.checkboxTekst[0].slug) || "";
		const formCheckboxLinkText = formFieldsData.checkboxTekstLinku || "";
		const formBtnText = formFieldsData.przyciskTekst || "";
		const formBtnVariant = (formFieldsData.przyciskRodzaj && formFieldsData.przyciskRodzaj[0] && formFieldsData.przyciskRodzaj[0].title) || "";
		const contactFormPopupData = [];
		contactFormPopupData.push({
			"newsletterModalNaglowekOk": (formData && formData[0] && formData[0].formularzModalNaglowek) || "",
			"newsletterModalNaglowekBlad": "",
			"newsletterModalOpisOk": (formData && formData[0] && formData[0].formularzModalOpis) || "",
			"newsletterModalOpisBlad": "",
			"newsletterModalNapisNaPrzyciskuOk": (formData && formData[0] && formData[0].formularzModalNapisNaPrzycisku) || "",
			"newsletterModalNapisNaPrzyciskuBlad": "",
		});

		return (
			<ContactFormWrapper>
				<Header>{formHeader}</Header>
				<Description className="custom-lh">{formDescription}</Description>
				<form onSubmit={this.handleSubmit.bind(this)}>
					<TwoColumnsWrapper>
						<StyledInput
								   placeholder={formFirstNamePlaceholder}
						           type="text"
						           name="firstName"
						           className="two-columns"
						           errorMessage={this.state.firstNameInvalidMsg}
						           required
						           value={this.state.firstName}
						           onChange={this.handleInputChange}
						/>
						<StyledInput
						           placeholder={formEmailPlaceholder}
						           type="email"
						           name="email"
						           className="two-columns"
						           errorMessage={this.state.emailInvalidMsg}
						           required
						           value={this.state.email}
						           onChange={this.handleInputChange}
						/>
					</TwoColumnsWrapper>
						 <StyledInput
                                  placeholder={formMessagePlaceholder}
                                  type="text"
                                  name="message"
                                  className={`${this.state.messageInvalid && "invalid"}`}
                                  errorMessage={this.state.messageInvalidMsg}
                                  isTextarea="true"
                                  required
                                  value={this.state.message}
                                  onChange={this.handleInputChange}
						/>
						<CheckboxInfoVisibilityWrapper>
							<Checkbox name="privacyPolicyAgree"
							              type="checkbox"
							              required
							              isCheckboxClicked={this.markCheckbox.bind(this)}
							              checked={this.state.privacyPolicyAgree}
							              isSpanClicked={this.toggleWholeInfoShown.bind(this)}
							              onChange={this.handleInputChange}
							              paragraphText={formCheckboxText}
							              spanText={formCheckboxLinkText}
							/>
							{isWholeInfoShown &&
							<WholeInfoProcessingPersonalData isFooter={false} isContact={true} isPayment={false} processingData={processingData}
							                                 isSpanClicked={this.toggleWholeInfoShown.bind(this)} /> }
						</CheckboxInfoVisibilityWrapper>
					<ButtonWrapper>
						<Button isOldBrowserEdge={isOldBrowserEdge} type="submit" btnType={formBtnVariant} textBtn={formBtnText}/>
					</ButtonWrapper>
				</form>
				<Popup newsletterModalData={contactFormPopupData[0]}
				       isVisible={this.state.isPopupVisible}
				       hide={this.togglePopup.bind(this)}
				       isSubscriberAdded={true}
				       />
			</ContactFormWrapper>
		)
	}
}

export default ContactForm;
