import React, {Component} from 'react'
import lottie from "lottie-web";
import styled from "styled-components"

const AnimationWrapper = styled.div`
	width: 100%;
	height: 100%;
`

class GeneralAnimation extends Component {
	pictogram;
	animation;

	constructor() {
		super();
		this.animationPlay = this.animationPlay.bind(this);
		this.animationStop = this.animationStop.bind(this);
	}

	animationPlay() {
		this.animation.play();
		this.animation.setDirection(1);
	}

	animationStop() {
		this.animation.stop();
		// this.animation.play();
		// this.animation.setDirection(-1);
	}

	componentDidMount() {
		const {animationJson, isAnimationTriggerFromParent} = this.props

		this.animation = lottie.loadAnimation({
			wrapper: this.animationEl,
			animType: 'svg',
			loop: false,
			autoplay: false,
			prerender: true,
			path: animationJson
		});

		if (!isAnimationTriggerFromParent) {
			this.animationEl.addEventListener("mouseenter", this.animationPlay);
			this.animationEl.addEventListener("mouseleave", this.animationStop);
		}

	}

	componentWillUnmount() {
		this.animation.destroy();
		if (!this.props.isAnimationTriggerFromParent) {
			this.animationEl.removeEventListener('mouseenter', this.animationPlay)
			this.animationEl.removeEventListener('mouseleave', this.animationStop)
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.isAnimationTriggerFromParent) {
			this.props.isAnimationActive ? this.animationPlay() : this.animationStop();
		}
	}

	render() {
		return (
			<AnimationWrapper className="animation" ref={node => this.animationEl = node}/>
		)
	}
}

export default GeneralAnimation;
