import React, {Component} from 'react';
import styled from "styled-components";
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";

const CardWrapper = styled.div`
	padding: ${({paddingTop}) => paddingTop}px 10px ${({paddingBottom}) => paddingBottom}px;
	background-color: ${({theme}) => theme.colors.pmmWhite};
	display: flex;
	flex-direction: column;
	
	@media(min-width: 768px) {
		padding: ${({paddingTop}) => paddingTop}px 35px ${({paddingBottom}) => paddingBottom}px;
	}
	
	@media(min-width: 1280px) {
		padding: ${({paddingTop}) => paddingTop}px 50px ${({paddingBottom}) => paddingBottom}px;
	}
	@media(min-width: 1920px) {
		padding: ${({paddingTop}) => paddingTop}px 100px ${({paddingBottom}) => paddingBottom}px;
	}
`

const Header = styled.h3`
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	margin-bottom: 70px;
`;

const TileImgWithDescription = styled.div`
	position: relative;
`;

const Position = styled.div`
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	font-size:  ${({theme}) => theme.fontSize.f6};
	line-height: ${({theme}) => theme.lineHeight.line14};
	font-weight: ${({theme}) => theme.fontWeight.regular};
	margin-top: 40px;
	margin-bottom: 50px;
	position: relative;
	
	&::before {
		content: '';
		position: absolute;
		width: 20px;
		height: 2px;
		background-color: ${({theme}) => theme.colors.pmmOrange};
		bottom: -20px;
	}
	
	@media(min-width: 768px) {
		margin-top: 15px;
	}
`
const PictureWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	position: relative;
	background: none;
	overflow: hidden;
	width: 200px;
	height: 200px;
  @media(min-width: 768px) {
  	width: 260px;
	height: 260px;
  }
  @media(min-width: 1280px) {
  }
  @media(min-width: 1920px) {
  }
`;

const ZoomedPicture = styled.div`
    position: absolute;
  	width: 200px;
	height: 200px;
    cursor: pointer;
    transition: transform .5s;
    background-size: cover;
    background-position: center;
    background-image: url( ${({bgImg}) => bgImg});
    &.animate {
   		transform: ${({ isOldBrowserEdge }) => isOldBrowserEdge ? "scale(1)" : "scale(1.1)"};
    }
    &:hover {
    	transform: ${({ isOldBrowserEdge }) => isOldBrowserEdge ? "scale(1)" : "scale(1.1)"};
    }  
    
    @media(min-width: 768px) {
		width: 260px;
		height: 260px;
  	}
`;

const TileImgDescription = styled.p`
	position: absolute;
	top: 100%;
	transform:translateY(-50%);
	padding: 10px 20px;
	background-color: ${({theme}) => theme.colors.pmmOrange};
	color: ${({theme}) => theme.colors.pmmWhite};
	width: 100%;
	// TODO: maybe mac fix for description - not tested
	display: table;
`

const PhotoAndContactDataWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	
	@media(min-width: 420px) {
		flex-direction: row;
		align-items: center;
  	}
`
const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	@media(min-width: 420px) {
		margin-left: 15px;
  	}
  	@media(min-width: 475px) {
		margin-left: 45px;
  	}
`
const ContactData = styled.label`

div {
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	font-weight: ${({theme}) => theme.fontWeight.bold};
	display: flex;
	text-decoration: none;
	
	 	
  	&.with-border {
  		margin: 0 0 125px 0;
  		border: 2px solid ${({theme}) => theme.colors.pmmOrange};
  		padding: 20px;
  		width: 355px;
  	}

	@media(min-width: 768px) {
		align-items: center;
		justify-content: center;
  	}
}
`

const PhotoAndBannerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	
	@media(min-width: 1280px) {
		flex-direction: row;
  	}
`
const BannerWrapper = styled.div`
	border: 2px solid ${({theme}) => theme.colors.pmmOrange};
	position: relative;
	width: 100%;
	margin-top: 100px;
	@media(min-width: 1280px) {
		width: 50%;
		margin-top: 26px;
  	}
	@media(min-width: 1920px) {
		width: 775px;
  	}
	
`
const BannerTitle = styled.p`
	position: absolute;
	bottom: 100%;
	left: -2px;
	transform: translateY(50%);
	background-color: ${({theme}) => theme.colors.pmmDarkestBlue};
	height: 56px;
	display: flex;
	align-items: center;
	padding: 0 20px;
`
const BannerDescription = styled.div`
	padding: 80px 40px 75px;
	div {
		font-weight: ${({theme}) => theme.fontWeight.medium} !important;
	}

`

class ContactPhotoOnLeft extends Component {
	state = {
		isOldBrowserEdge: false,
	};

	componentDidMount() {
		if (typeof window !== "undefined") {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({isOldBrowserEdge: isOldBrowser});
		}
	}

	render() {
		const {data} = this.props
		const isOldBrowserEdge = this.state.isOldBrowserEdge;
		const contactData = (data && data.modulKontaktZdjeciePoLewej) || []

		let contactHeader = ""
		let photo = ""
		let photoDescription = ""
		let position = ""
		let contactDataDescription = ""
		let paddingTop = ""
		let paddingBottom = ""
		let bannerTitle = ""
		let bannerDescription = ""

		contactData.forEach(item => {
			if (item.__typename === "Craft_modulKontaktZdjeciePoLewej_kontakt_BlockType") {
				contactHeader = item.naglowek || ""
				photo = (item.zdjecie && item.zdjecie[0] && item.zdjecie[0].url) || ""
				photoDescription = item.zdjeciePodpis || ""
				position = item.zdjecieStanowisko || ""
				contactDataDescription = item.daneKontaktowe || ""
			} else if (item.__typename === "Craft_modulKontaktZdjeciePoLewej_marginesy_BlockType") {
				paddingTop = (item.marginesGorny && item.marginesGorny[0] && item.marginesGorny[0].title) || ""
				paddingBottom = (item.marginesDolny && item.marginesDolny[0] && item.marginesDolny[0].title) || ""
			} else if (item.__typename === "Craft_modulKontaktZdjeciePoLewej_baner_BlockType") {
				bannerTitle = item.tytul || ""
				bannerDescription = item.opis || ""
			}
		})

		return (
			<CardWrapper paddingTop={getPaddingTop(paddingTop)} paddingBottom={getPaddingBottom(paddingBottom)}>
				<Header>{contactHeader}</Header>
				<PhotoAndBannerWrapper>
					<div>
						<PhotoAndContactDataWrapper>
							<TileImgWithDescription>
								<PictureWrapper>
									<ZoomedPicture isOldBrowserEdge={isOldBrowserEdge} bgImg={photo}/>
								</PictureWrapper>
								<TileImgDescription className="h7">{photoDescription}</TileImgDescription>
							</TileImgWithDescription>
							<InfoWrapper>
								<Position>{position}</Position>
								<ContactData className="paragraph"
											 dangerouslySetInnerHTML={getHtmlFromRedactorField(contactDataDescription)}/>
							</InfoWrapper>
						</PhotoAndContactDataWrapper>
					</div>
					<BannerWrapper>
						<BannerTitle className="h7 white">{bannerTitle}</BannerTitle>
						<BannerDescription className="paragraph darkest-blue who-we-help"
										   dangerouslySetInnerHTML={getHtmlFromRedactorField(bannerDescription)}/>
					</BannerWrapper>
				</PhotoAndBannerWrapper>

			</CardWrapper>
		);
	}
}

export default ContactPhotoOnLeft;
