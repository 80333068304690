import React from 'react'
import styled from 'styled-components'
import Button from "../common/ButtonsSet";
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";

const ModuleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-items: center;
  background-color: ${({theme}) => theme.colors.pmmWhite};
  padding: 0 10px;

  @media (min-width: 768px) {
	flex-direction: row;
	padding: 0;
	height: 700px;
  }
  @media (min-width: 1280px) {
	padding: 0;
	height: 800px;
  }
  @media (min-width: 1920px) {
	height: 900px;
  }
  @media (min-width: 2560px) {
	height: 1280px;
  }
`

const ImageWrapper = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  height: 380px;
  background-size: cover;
  background-position: center;
  @media (min-width: 768px) {
	width: 50%;
	height: inherit;
	margin: 0;
	position: initial;
  }
  @media (orientation: landscape) and (max-width: 1024px) {
	background-image: url(${({bgImgMobileLandscape}) => bgImgMobileLandscape});
  }
  @media (orientation: portrait) and (max-width: 1024px) {
	background-image: url(${({bgImgMobile}) => bgImgMobile});
  }
  @media (min-width: 1025px) {
	background-image: url(${({bgImg}) => bgImg});
  }
`

const HeaderDescriptionBtnWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 200px;
  padding: 60px 0;

  h3 {
	margin-bottom: 30px;
  }

  @media (min-width: 768px) {
	width: 50%;
	padding: 80px 35px;
  }
  @media (min-width: 1280px) {
	padding: 80px 55px;
  }
  @media (min-width: 1920px) {
	padding: 80px;
  }
`

const ButtonWrapper = styled.div`
  margin-top: 70px;
  margin-left: -10px;
  display: flex;
`

class PhotoHeaderDescriptionBtn extends React.Component {
	render() {
		const {data} = this.props
		const moduleData = data?.modulZdjecieNaglowekOpisPrzycisk?.[0] || ''
		const header = moduleData?.naglowek || ''
		const description = moduleData?.opis || ''
		const hrefBtn = moduleData?.przyciskLink || ''
		const textBtn = moduleData?.przyciskNapis || ''
		const photo = moduleData?.zdjecie?.[0]?.url || ''
		const photoMobile = moduleData?.zdjecieMobile?.[0]?.url || ''
		const photoMobileLandscape = moduleData?.zdjecieMobilePoziome?.[0]?.url || ''

		return (
			<ModuleWrapper>
				<ImageWrapper bgImg={photo} bgImgMobile={photoMobile} bgImgMobileLandscape={photoMobileLandscape}/>
				<HeaderDescriptionBtnWrapper>
					<div>
						<h3>{header}</h3>
						<div className="paragraph" dangerouslySetInnerHTML={getHtmlFromRedactorField(
							description)}/>
						<ButtonWrapper>
							<Button href={hrefBtn} btnType="pomarańczowy" textBtn={textBtn}/>
						</ButtonWrapper>
					</div>
				</HeaderDescriptionBtnWrapper>
			</ModuleWrapper>
		)
	}
}

export default PhotoHeaderDescriptionBtn
