import React, {Component} from 'react';
import styled from "styled-components"
import {Link} from "gatsby";
import getSlug from "../../utils/getSlug";
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";
import setBackgroundColor from "../../utils/setBackgroundColor";
import setFontColor from "../../utils/setFontColor";

const BannerWrapper = styled.div`
	padding: 190px 10px 10px;
	height: 100vh;
	color: ${({background, theme}) => setFontColor(background, theme)};
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: ${({background, theme}) => setBackgroundColor(background, theme)};
			
	@media(min-width: 768px) {
		padding: 230px 100px 100px;
		min-height: 760px;
		height: auto;
	}
	@media(min-width: 1280px) {
		padding: 205px 50px 50px;
		flex-direction: row;
		min-height: 550px;
	}
	@media(min-width: 1920px) {
		padding: 205px 100px 100px;
		min-height: 800px;
	}
`

const BtnWrapper = styled.div`
	@media(min-width: 1280px) {
		width: 300px;
		padding-top: 55px;
	}
	@media(min-width: 1366px) {
		width: 320px;
	}
	@media(min-width: 1920px) {
		width: 430px;
		padding-top: 75px;
	}
`
const InfoWrapper = styled.div`
	@media(min-width: 1280px) {
		width: auto;
	}
`

const BtnWrapperForBgPreload = styled.div`
	// to preload image on hover - fixes flash for first hover
	background: url(${props => props.iconHoverBtn}) no-repeat -9999px -9999px;
`

const Header = styled.h3`
	position: relative;
	margin-top: 30px;
	color: ${({background, theme}) => setFontColor(background, theme)};
	&::after {
		content: '';
		position: absolute;
		height: 3px;
		width: 60px;
		background-color: ${({background, theme}) => setFontColor(background, theme)};
		bottom: -20px;
		left: 0;
	}
	@media(min-width: 1280px) {
		width: 590px;
	}
	@media(min-width: 1366px) {
		width: 630px;
	}
	@media(min-width: 1920px) {
		width: 860px;
		&::after {
			width: 85px;
			bottom: -30px;
		}
	}
`

const Description = styled.div`
	color: ${({background, theme}) => setFontColor(background, theme)};
	padding-top: 60px;
		
	@media(min-width: 768px) {
		padding-top: 70px;
	}
	@media(min-width: 1280px) {
		width: 590px;
	}
	@media(min-width: 1366px) {
		width: 630px;
	}
	@media(min-width: 1920px) {
		padding-top: 75px;
		width: 860px;
	}
`

const BtnWithIcon = styled.p`
	color: ${({background, theme}) => setFontColor(background, theme)};
	font-weight: ${({theme}) => theme.fontWeight.medium};
	text-decoration: none;
	position: relative;
	padding-left: 40px;
	user-select: none;
	font-size: 12px;
	line-height: 14px;
	@media(min-width: 768px) {
		font-size: 14px;
		line-height: 16px;
	}
	@media(min-width: 1280px) {
		font-size: 12px;
		line-height: 14px;
	}
	@media(min-width: 1920px) {
		font-size: 14px;
		line-height: 16px;
	}
	&:before {
		content: '';
		background-position: center;
		width: 34px;
		height: 34px;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
    	background-image: url( ${({iconBtn}) => iconBtn});
    	background-repeat: no-repeat;
		position: absolute;
		border-radius: 100%;
	}
	
	&:hover {
		color: ${({theme}) => theme.colors.pmmOrange};

		&:before {
			background-image: url( ${({iconHoverBtn}) => iconHoverBtn});
		}
	}
	
	&.dark-violet {
		color: ${({theme}) => theme.colors.pmmDarkViolet};

		&:hover {
			color: ${({theme}) => theme.colors.pmmWhite};
		}
	}
	@media(min-width: 768px) {
		padding-left: 50px;
		&:before {
			width: 34px;
			height: 34px;
		}
	}
`


class BannerSpecialActions extends Component {
	render() {
		const {data, activeSite, specialActions} = this.props;
		const banner = data;
		const bannerData = (banner && banner.modulBanerNaAkcjachSpecjalnych && banner.modulBanerNaAkcjachSpecjalnych[0]) || '';
		const bannerHeader = bannerData.naglowek || ""
		const bannerDescription = bannerData.opis || ""
		const background = (bannerData && bannerData.wybierzTlo && bannerData.wybierzTlo[0] &&
			bannerData.wybierzTlo[0].title) || '';

		const globalData = (specialActions && specialActions[0] && specialActions[0].akcjeSpecjalnePrzyciskPowrot && specialActions[0].akcjeSpecjalnePrzyciskPowrot[0]) || '';
		const textBtn = (globalData && globalData.przyciskNapis) || ""
		const toBtn = (globalData && globalData.przyciskWpis && globalData.przyciskWpis[0] && globalData.przyciskWpis[0].slug) || ""
		const hrefBtn = (globalData && globalData.przyciskLink) || ""
		const iconBtn = (globalData && globalData.przyciskIkona && globalData.przyciskIkona[0] && globalData.przyciskIkona[0].url) || ""
		const iconHoverBtn = (globalData && globalData.przyciskIkonaPoNajechaniu && globalData.przyciskIkonaPoNajechaniu[0] && globalData.przyciskIkonaPoNajechaniu[0].url) || ""

		return (
			<BannerWrapper background={background}>
				<BtnWrapper>
					<BtnWrapperForBgPreload iconHoverBtn={iconHoverBtn}>
						{hrefBtn && <BtnWithIcon background={background} className="h8" href={hrefBtn} iconBtn={iconBtn} iconHoverBtn={iconHoverBtn}
						                         as={"a"}>{textBtn}</BtnWithIcon>}
						{toBtn && <BtnWithIcon background={background} className="h8" as={Link} iconBtn={iconBtn} iconHoverBtn={iconHoverBtn}
						                       to={`/${getSlug(toBtn, activeSite)}`}>{textBtn}</BtnWithIcon>}
						{!hrefBtn && !toBtn &&
						<BtnWithIcon background={background} iconBtn={iconBtn} iconHoverBtn={iconHoverBtn}>{textBtn}</BtnWithIcon>}
					</BtnWrapperForBgPreload>
				</BtnWrapper>
				<InfoWrapper>
					<Header background={background}>{bannerHeader}</Header>
					<Description background={background} className="paragraph" dangerouslySetInnerHTML={getHtmlFromRedactorField(bannerDescription)}/>
				</InfoWrapper>
			</BannerWrapper>
		);
	}
}

export default BannerSpecialActions;
