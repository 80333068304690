import React from "react"
import styled from "styled-components"
import {Link} from "gatsby";
import getSlug from "../../utils/getSlug";
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";

const SingleProjectWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 40px 0;
	cursor: pointer;
	text-decoration: none;
	@media(min-width: 1280px){
		width: 48%;
	}
`;

const PictureWrapper = styled.div`
    width: unset;
	height: 300px;
	padding-top: 56.25%;
	background-size: cover;
    background-position: center;
    background-image: url( ${({ bgImg }) => bgImg});
    position: relative;
    @media(min-width: 768px) {
    	height: 425px;
    }
    @media(min-width: 1920px) {
    	height: 625px
    }
    &:before {
    	content: '';
    	width: 94%;
    	height: 105%;
    	margin-left: 12px;
    	border: ${({theme}) => theme.colors.pmmDarkBlue} 2px solid;
    	background-color: transparent;
    	position: absolute;
    	top: 12px;
    	z-index: 0;
    	transition: border .2s ease-in-out;
    	@media(min-width: 768px) {
    		top: 22px;
    		margin-left: 20px;
    	}
    	@media(min-width: 1280px) {
    		height: 110%;
    	}
    	@media(min-width: 1920px) {
    		margin-left: 30px;
    		top: 24px;
    		height: 110%;
    	}
    }
    &.animate {
    	@media(min-width: 1024px) {
    	&:before {
    		border: ${({theme}) => theme.colors.pmmTextOrange} 2px solid;
    	}
    }	
    }
`;

const TitleDescriptionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 4.5% 10px 0 35px;
	@media(min-width: 425px) {
		padding: 4% 10px 0 35px;
	}
	@media(min-width: 600px) {
		padding: 3.5% 10px 0 35px;
	}
	@media(min-width: 768px) {
		padding: 4.5% 80px 50px 55px;
	}
	@media(min-width: 1024px) {
		padding: 4% 80px 50px 55px;
	}
	@media(min-width: 1280px) {
		padding: 10% 80px 50px 60px;
	}
	@media(min-width: 1366px) {	
		padding: 9% 80px 50px 60px;
	}
	@media(min-width: 1500px) {
		padding: 8% 80px 50px 60px;
	}
	@media(min-width: 1700px) {
		padding: 7% 80px 50px 60px;
	}
	@media(min-width: 1920px) {
		padding: 8.5% 80px 50px 80px;
	}
	@media(min-width: 2100px) {
		padding: 7.5% 80px 50px 80px;
	}
	@media(min-width: 2300px) {
		padding: 6.5% 80px 50px 80px;
	}
`;

const Title = styled.p`
	font-size: 12px;
  	line-height: 14px;
  	text-align: left;
  	text-transform: uppercase;
  	letter-spacing: 2px;
  	padding-bottom: 15px;
  	margin-left: -10px; 
 	color: ${({theme}) => theme.colors.pmmDarkBlue};
  	font-family: ${({ theme }) => theme.fontFamily.mainFont};
  	font-weight: ${({ theme }) => theme.fontWeight.black};
  	z-index: 10;
  	font-style: italic;
  	transition: color .2s ease-in-out;
  	@media(min-width: 768px) {
  		font-size: 14px;
  		line-height: 16px;
  	}
	@media(min-width: 1920px) {
		font-size: 18px;
		line-height: 30px;
		margin-left: -20px;
	}
	&.animate {
		color: ${({theme}) => theme.colors.pmmOrange};
	}
`;

const TitleBackground = styled.span`
    background-color: ${({theme}) => theme.colors.pmmWhite};
    background-size: 100% 100%;
    padding: 0 10px;
    background-repeat: no-repeat;
    text-decoration: none;
    @media(min-width: 768px) {
    	padding: 0 20px;
    }
`;

const Description = styled.div`
	position: relative;
	text-align: left;
	width: 100%;
	color: ${({theme}) => theme.colors.pmmDarkBlue};
	div {
		font-weight: ${({theme}) => theme.fontWeight.medium} !important;
	}
`;

class SingleProjectPast extends React.Component {
	state = {
		isPictureHovered: false,
	};

	triggerAnimation() {
		this.setState({isPictureHovered: !this.state.isPictureHovered})
	}

	render() {
		const {index, data, activeSite} = this.props;
		const image = data.picture;
		const title = data.title;
		const description = data.description;
		const slug = data.slug;
		const isPictureHovered = this.state.isPictureHovered;
		const classNameAnimation = (isPictureHovered) ? "animate" : "";

		return (
			<>
				<SingleProjectWrapper key={index} as={Link} to={`/${getSlug(slug, activeSite)}`} >
					<PictureWrapper bgImg={image} className={classNameAnimation}
					                onMouseOver={this.triggerAnimation.bind(this)}
					                onMouseOut={this.triggerAnimation.bind(this)}/>
					<TitleDescriptionWrapper>
						<Title className={classNameAnimation}>
							<TitleBackground>{title}</TitleBackground></Title>
						<Description className="paragraph who-we-help" dangerouslySetInnerHTML={getHtmlFromRedactorField(description)}/>
					</TitleDescriptionWrapper>
				</SingleProjectWrapper>
			</>
		)
	}
}
export default SingleProjectPast
