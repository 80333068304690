import React from 'react'
import styled from 'styled-components'
import Button from '../common/ButtonsSet'
import getSlug from '../../utils/getSlug'
import { getPaddingBottom, getPaddingTop } from '../../utils/setModulePadding'
import {
	changeLinkToIdFromAnotherPage,
	setDifferentAmountFromCalculator,
	setMobileActiveProject,
} from '../../state/app'
import { fbqDonateBtnClicked } from '../../utils/fbqDonateBtnClicked'
import { navigate } from 'gatsby'
import { connect } from 'react-redux'
import validationForNumbers from '../../utils/validationForNumbers'
import CalculatorExtendedRange
	from '../calculatorExtendedRange/CalculatorExtendedRange'

const ModuleWrapper = styled.div`
	display: flex;
	background: linear-gradient(to bottom, ${({ theme }) => theme.colors.pmmOrangeBeige} 325px, ${({ theme }) => theme.colors.pmmWhite} 325px);
	padding: 0 10px;
		margin: ${({ paddingTop }) => paddingTop}px 0 ${({ paddingBottom }) => paddingBottom}px;
	
	@media(min-width: 768px) {
		padding: 0 30px;
		margin: ${({ paddingTop }) => paddingTop}px 0 ${({ paddingBottom }) => paddingBottom}px;
	}
	@media(min-width: 1280px) {
		padding: 0 50px;
		margin: ${({ paddingTop }) => paddingTop}px 0 ${({ paddingBottom }) => paddingBottom}px;
	}
	@media(min-width: 1920px) {
		padding: 0 100px;
		margin: ${({ paddingTop }) => paddingTop}px 0 ${({ paddingBottom }) => paddingBottom}px;
	  background: linear-gradient(to bottom, ${({ theme }) => theme.colors.pmmOrangeBeige} 340px, ${({ theme }) => theme.colors.pmmWhite} 340px);

	}
`

const PhotoSliderWrapper = styled.div`
	display: none;
	height: 700px;
	
	@media(min-width: 1024px){
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40%;
	}
`

const Photo = styled.div`
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url( ${({ bgImg }) => bgImg});
	height: 450px;
	width: 360px;
	@media(min-width: 1024px){
		display: flex;
	}
`

const CalculatorWrapper = styled.div`
	padding-top: 45px;
	width: auto;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	@media(min-width: 1024px){
		width: 60%;
		padding-left: 120px;
	}
`

const ButtonWrapper = styled.div`
	margin: 50px 0 0 -10px;
`

const CustomTextStyled = styled.h4`
	line-height: 16px;
	margin-bottom: 20px;

	span {
	color:  ${({ theme }) => theme.colors.pmmOrange};
	}
	&.above-range {
		margin-bottom: 30px;
	}
  
	&.above-sum {
		margin-top: 30px;
	}
	@media(min-width: 768px) {
		line-height: 18px;
	}
	@media(min-width: 1280px) {
		line-height: 20px;
	}
	@media(min-width: 1366px) {
		line-height: 22px;
	}
	@media(min-width: 1920px) {
		line-height: 30px;
	}
`

const AmountInputWrapper = styled.div`
	position: relative;
	height: 60px;
	margin-bottom: 40px;
`
const AmountInput = styled.input`
	width: 250px;
	height: 60px;
	border: 2px solid ${({ theme }) => theme.colors.pmmOrange};
	background-color: ${({ theme }) => theme.colors.pmmWhite};
	color: ${({ theme }) => theme.colors.pmmDarkestBlue};
	font-weight: ${({ theme }) => theme.fontWeight.semiBold};
	text-align: center;
	outline: none;
	-moz-appearance: textfield;
  	font-size: ${({ theme }) => theme.fontSize.h4_360};
		
		@media(min-width: 375px) {
			font-size: ${({ theme }) => theme.fontSize.h4_375};
			line-height: ${({ theme }) => theme.lineHeight.h4_375};
			&::placeholder {
				font-size: ${({ theme }) => theme.fontSize.h4_375};
				line-height: ${({ theme }) => theme.lineHeight.h4_375};
			}
		}
		@media(min-width: 768px) {
			font-size: ${({ theme }) => theme.fontSize.h4_768};
			line-height: ${({ theme }) => theme.lineHeight.h4_768};
			&::placeholder {
				font-size: ${({theme}) => theme.fontSize.h4_768};
				line-height: ${({theme}) => theme.lineHeight.h4_768};
			}
		}
		@media(min-width: 1280px) {
			font-size: ${({ theme }) => theme.fontSize.h4_1280};
			line-height: ${({ theme }) => theme.lineHeight.h4_1280};
			&::placeholder {
				font-size: ${({ theme }) => theme.fontSize.h4_1280};
				line-height: ${({ theme }) => theme.lineHeight.h4_1280};
			}
		}
		@media(min-width: 1366px) {
			font-size: ${({ theme }) => theme.fontSize.h4_1366};
			line-height: ${({ theme }) => theme.lineHeight.h4_1366};
			&::placeholder {
				font-size: ${({ theme }) => theme.fontSize.h4_1366};
				line-height: ${({ theme }) => theme.lineHeight.h4_1366};
			}
		} 
		@media(min-width: 1920px) {
			font-size: ${({ theme }) => theme.fontSize.h4_1920};
			line-height: ${({ theme }) => theme.lineHeight.h4_1920};
			&::placeholder {
				font-size: ${({ theme }) => theme.fontSize.h4_1920};
				line-height: ${({ theme }) => theme.lineHeight.h4_1920};
			}
		}
	&::placeholder {
		color: ${({ theme }) => theme.colors.pmmDarkestBlue};
		font-weight: ${({ theme }) => theme.fontWeight.semiBold};
		text-align: center;
		opacity: 1;
	}
	
	&:focus, &:active {
		background-color: ${({ theme }) => theme.colors.pmmWhite};
		color: ${({ theme }) => theme.colors.pmmDarkestBlue};
		&::placeholder{
			color: transparent !important;
		}
	}
	
	&.input-padding {
		padding-right: 30px;
	}
	
	&::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	@media(min-width: 768px){
		font-size: ${({ theme }) => theme.fontSize.f5};
	 	font-weight: ${({ theme }) => theme.fontWeight.bold};
		&::placeholder{
			font-size: ${({ theme }) => theme.fontSize.f5};
		  	font-weight: ${({ theme }) => theme.fontWeight.bold};
		}
	}
`

const InvisibleDivForUnit = styled.div`
	position: absolute;
	color: ${({ theme }) => theme.colors.pmmDarkestBlue};
	background-color: transparent;
	z-index: 50;
	top: 0;
	width: 100%;
	max-width: calc(100% - 15px);
	height: 100%;
	pointer-events: none;
	font-size: ${({ theme }) => theme.fontSize.h4_360};

	span {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: -5px;
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	}
	
	&.hidden{
		visibility: hidden;
		pointer-events: none;
	}
	@media(min-width: 375px) {
		span {
			font-size: ${({theme}) => theme.fontSize.h4_375};
			line-height: ${({theme}) => theme.lineHeight.h4_375};
		}
	}
	@media(min-width: 768px) {
		span {
			font-size: ${({theme}) => theme.fontSize.h4_768};
			line-height: ${({theme}) => theme.lineHeight.h4_768};
		}
	}
	@media(min-width: 1280px) {
		span {
			top: calc(50% - 1px);
			font-size: ${({theme}) => theme.fontSize.h4_1280};
			line-height: ${({theme}) => theme.lineHeight.h4_1280};
		}
	}
	@media(min-width: 1366px) {
		span {
			font-size: ${({theme}) => theme.fontSize.h4_1366};
			line-height: ${({theme}) => theme.lineHeight.h4_1366};
		}
	}
	@media(min-width: 1920px) {
		span {
			font-size: ${({theme}) => theme.fontSize.h4_1920};
			line-height: ${({theme}) => theme.lineHeight.h4_1920};
		}
	}
`

const TextWithBg = styled.h4`
	background-color: ${({ theme }) => theme.colors.pmmDarkestBlue};
	width: 250px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 0;
	margin: 0 0 85px;
  	height: 60px;
  @media(min-width: 1366px) {
	margin: 0 0 60px;
  }
`

const Sum = styled.h4`
	border: 2px solid ${({ theme }) => theme.colors.pmmOrange};
	color: ${({ theme }) => theme.colors.pmmOrange};
	display: flex;
	margin-top: 15px;
	align-items: center;
	justify-content: center;
	padding: 10px 0;
	width: 250px;
  	height: 60px;
`

class ExtendedCalculator extends React.Component {
	state = {
		inputValue: 0,
		inputFocus: false,
		valuePerHour: 0,
		rangeFactor: [1],
		sum: 0,
	}

	handleBtnClick(linkToNavigate, slugForProject) {
		const { dispatch } = this.props
		dispatch(changeLinkToIdFromAnotherPage(true))
		dispatch(setDifferentAmountFromCalculator(this.state.sum))
		fbqDonateBtnClicked('kliknieto z kalkulatora rozszerzonego')
		if ((window.innerWidth >= 768)) {
			const navigateToId = linkToNavigate + '#' + slugForProject
			navigate(navigateToId)
		} else {
			const navigateToId = linkToNavigate + '#payment-wrapper'
			navigate(navigateToId)
			dispatch(setMobileActiveProject(slugForProject))
		}
	}

	handleAmountChange = (e) => {
		this.setState({ inputValue: e.target.value })
		const valuePerHour = Math.round(e.target.value / 160)
		this.setState({ valuePerHour: valuePerHour })
		this.setState({ sum: valuePerHour * this.state.rangeFactor[0] })
	}

	handleInputFocus = () => {
		this.setState({ inputFocus: true })
	}

	handleInputBlur = () => {
		this.setState({ differentAmountFocus: false })
	}

	handleSumChange = (values) => {
		this.setState({ rangeFactor: [values], sum: this.state.valuePerHour * values })
	}

	render () {
		const calculatorData = this.props.data?.modulKalkulatorRozbudowany || ''

		const { activeSite } = this.props
		const { inputValue, inputFocus, valuePerHour, rangeFactor } = this.state

		let photoFirstUrl = '',
			photoSecondUrl = '',
			photoThirdUrl = '',
			photoFourthUrl = '',
			photoFifthUrl = '',
			inputLabel = '',
			computedValueLabel = '',
			rangeLabelStart = '',
			rangeLabelAfterNumber = '',
			rangeLabelEnd = '',
			textAboveSum = '',
			toBtn = '',
			btnText = '',
			btnType = '',
			paddingTopType = '',
			paddingBottomType = '',
			slugForProject = '',
			rangeStep = 10

		calculatorData.forEach(item => {
				if (item.__typename ===
					'Craft_modulKalkulatorRozbudowany_margines_BlockType') {
					paddingTopType = (item.marginesGorny && item.marginesGorny[0] &&
						item.marginesGorny[0].title) || ''
					paddingBottomType = (item.marginesDolny &&
						item.marginesDolny[0] && item.marginesDolny[0].title) || ''
				} else if (item.__typename ===
					'Craft_modulKalkulatorRozbudowany_kalkulator_BlockType') {
					photoFirstUrl = item?.zdjecie1?.[0]?.url || ''
					photoSecondUrl = item?.zdjecie2?.[0]?.url || ''
					photoThirdUrl = item?.zdjecie3?.[0]?.url || ''
					photoFourthUrl = item?.zdjecie4?.[0]?.url || ''
					photoFifthUrl = item?.zdjecie5?.[0]?.url || ''
					inputLabel = item?.tekstNadPolemDoWpisania || ''
					computedValueLabel = item?.tekstNadPolemZKwota || ''
					rangeLabelStart = item?.tekstNadOsiaPoczatek || ''
					rangeLabelAfterNumber = item?.tekstNadOsiaPoLiczbie || ''
					rangeLabelEnd = item?.tekstNadOsiaKoniec || ''
					textAboveSum = item?.tekstNadKoncowaKwota || ''
					btnText = item?.przyciskNapis || ''
					toBtn = item?.przyciskWpis?.[0]?.slug || ''
					btnType = item?.przyciskRodzaj?.[0]?.title || ''
					slugForProject = item?.projekt?.[0]?.slug || ''
				}
			},
		)

		const linkToNavigate = `/${getSlug(toBtn, activeSite)}`
		let rangeLabelAfterNumberVariety = ''

		if(activeSite === 'pl') {
			if ([2,3,4, 22, 23, 24, 32, 33, 34].indexOf(rangeFactor[0]) > -1) {
				rangeLabelAfterNumberVariety = 'godziny'
			}
			else if(rangeFactor[0] === 1) {
				rangeLabelAfterNumberVariety = 'godzinę'
			}
			else {
				rangeLabelAfterNumberVariety = rangeLabelAfterNumber
			}
		}
		 else {
			rangeLabelAfterNumberVariety = rangeLabelAfterNumber
		}

		return (
			<ModuleWrapper
				paddingTop={getPaddingTop(paddingTopType)}
				paddingBottom={getPaddingBottom(paddingBottomType)}>
				<PhotoSliderWrapper>
					{rangeFactor[0] === 1 &&
					<Photo bgImg={photoFirstUrl}/>}
					{rangeFactor[0] > 1 && rangeFactor[0] <= rangeStep &&
					<Photo bgImg={photoSecondUrl}/>}
					{rangeFactor[0] > rangeStep && rangeFactor[0] <=
					rangeStep * 2 &&
					<Photo bgImg={photoThirdUrl}/>}
					{rangeFactor[0] > 2 * rangeStep && rangeFactor[0] <=
					rangeStep * 3 &&
					<Photo bgImg={photoFourthUrl}/>}
					{rangeFactor[0] > 3 * rangeStep &&
					<Photo bgImg={photoFifthUrl}/>}
				</PhotoSliderWrapper>

				<CalculatorWrapper paddingTop={getPaddingTop(paddingTopType)}>
					<CustomTextStyled
						className="blue bold">{inputLabel}</CustomTextStyled>

					<AmountInputWrapper>
						<AmountInput id="amount-input"
									 autoComplete="off"
									 className={((inputValue !== 0 &&
										 inputValue !== '') ||
										 inputFocus)
										 ? 'input-padding'
										 : ''}

									 value={inputValue !== 0
										 ? inputValue
										 : ''}
									 placeholder='0 zł'
									 onFocus={() => this.handleInputFocus()}
									 onBlur={() => this.handleInputBlur()}
									 onChange={(e) => this.handleAmountChange(
										 e)}
									 onKeyPress={(e) => validationForNumbers(e)}
						/>
						<InvisibleDivForUnit id="invisible-div"
											 className={((inputValue !==
												 0 && inputValue !== '') ||
												 inputValue)
												 ? ''
												 : 'hidden'}>
							<span id="amount-unit">zł</span>
						</InvisibleDivForUnit>
					</AmountInputWrapper>

					<CustomTextStyled
						className="blue bold">{computedValueLabel}</CustomTextStyled>
					<TextWithBg
						className="white bold"> {valuePerHour} zł</TextWithBg>
					<CustomTextStyled
						className="blue bold above-range">{rangeLabelStart} <span>{rangeFactor} {rangeLabelAfterNumberVariety}</span> {rangeLabelEnd}</CustomTextStyled>
					<CalculatorExtendedRange rangeFactor={this.state.rangeFactor} sum={this.state.sum}
											 handleSumChange={this.handleSumChange}
					/>
					<CustomTextStyled
						className="blue bold above-sum">{textAboveSum}</CustomTextStyled>
					<Sum className="bold">{this.state.sum} zł</Sum>
					<ButtonWrapper>
						<Button btnType={btnType}
								onClick={this.handleBtnClick.bind(this,
									linkToNavigate, slugForProject)}
								textBtn={btnText}/>
					</ButtonWrapper>
				</CalculatorWrapper>
			</ModuleWrapper>
		)
	}
}

export default connect(state => ({
	mobileActiveProject: state.app.mobileActiveProject,
}), null)(ExtendedCalculator)
