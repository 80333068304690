import React from 'react';
import styled from 'styled-components';
import SingleQuestionWithAnswer from './SingleQuestionWithAnswer';

const StyledModuleWrapper = styled.div`
	width: 100%;
	background-color: ${({ theme }) => theme.colors.pmmDarkestBlue};
	padding: 50px 10px;
	@media (min-width: 768px) {
		padding: 85px 100px 45px;
	}
	@media (min-width: 1280px) {
		padding: 85px 350px 45px;
	}
	@media (min-width: 1366px) {
		padding: 85px 370px 45px;
	}
	@media (min-width: 1920px) {
		padding: 85px 530px 45px;
	}
`;

const StyledHeaderWrapper = styled.div`
	font-size: 40px;
	line-height: 48px;
	font-weight: ${({ theme }) => theme.fontWeight.semiBold};
	color: ${({ theme }) => theme.colors.pmmWhite};
`;

const StyledQuestionsAndAnswersWrapper = styled.div`
	margin-top: 40px;
	padding-left: 40px;
	@media (min-width: 768px) {
		padding-left: 0;
	}
`;

const Faq = ({ data }) => {
	const moduleData = data?.modulFaq || [];
	let headerBlockArray = [];
	let questionWithAnswerBlockArray = [];

	moduleData.forEach(item => {
		if (item.__typename === 'Craft_modulFaq_naglowek_BlockType') {
			headerBlockArray.push(item);
		} else if (item.__typename === 'Craft_modulFaq_pytanieZOdpowiedzia_BlockType') {
			questionWithAnswerBlockArray.push(item);
		}
	});
	const header = headerBlockArray?.[0]?.naglowek || '';
	return (
		<StyledModuleWrapper>
			<StyledHeaderWrapper>{header}</StyledHeaderWrapper>
			<StyledQuestionsAndAnswersWrapper>
				{questionWithAnswerBlockArray.map((item, key) => {
					const question = item?.pytanie || '';
					const answer = item?.odpowiedz || '';
					return <SingleQuestionWithAnswer isFirstQuestion={key === 0} key={question} question={question} answer={answer} />;
				})}
			</StyledQuestionsAndAnswersWrapper>
		</StyledModuleWrapper>
	);
};
export default Faq;
