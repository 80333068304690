import React from "react"
import styled from "styled-components"
import ChartWithInfoWrapper from "../../common/Charts";
import InfoPercentage from "../chartsDescription/infoPercentage/InfoPercentage";


const ChartContent = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: normal;
	height: 140px;
	margin-bottom: 25px;
	
	.break {
		flex-basis: 100%;
	}
	
	//padding-right: 90px;
	padding-right: 24%;
	
	@media(min-width: 768px){
		padding-right: 0;
		margin-top: 60px;
		margin-bottom: 60px;
	}
	
	@media(min-width: 1920px){
		height: 200px;
		margin-bottom: 0;
		margin-top: 0;
	}
	@media(min-width: 2560px) {
		height: 350px;
	}
`

const ImgElementWrapper = styled.div`
 	//display: inline-block;
 	height: 100%;
 	
 	@media(min-width: 1920px){
 		height: unset;
 	}
 	@media(min-width: 2560px){
 		height: 100%;
 	}
`

const ChartElementWrapper = styled.div`
	position: relative;
	height: 42%;
	
	img {
		height: 100%;
		width: auto;
	}
	
	@media(min-width: 1920px){
		img {
			height: auto;
		}
		
		&.unitSet {
			&:before {
				background-size: auto;
			}
		}
	}
	
	@media(min-width: 2560px){
		img {
			height: 100%;
		}
	}
		
	&.bottom {
		align-self: flex-end;
	}
	&.unitSet {
		&:before {
			content: '';
			z-index: -1;
			position: absolute;
			top: 0;
			left:0;
			background-image: url(${props => props.emptyChartElementSrc});
			background-repeat: no-repeat;
			background-size: contain;
			width: 100%;
			height: 100%;
			
			@media(min-width: 1920px){
				background-size: auto;
			}
			@media(min-width: 2560px){
				background-size: contain;
			}
		}
	}
`

const ImgWrapper = styled.div`
	overflow: hidden;
	width: ${props => props.lastOfDigitChartElementWidth + "%"};
	height: 100%;
`

class PercentageTenElementsCharts extends React.Component {

	render() {

		const {data} = this.props;
		const chartData = (data && data.dziesiecElementow && data.dziesiecElementow[0]) || ""
		const fullChartElementSrc = (chartData.piktogramPelny && chartData.piktogramPelny[0] && chartData.piktogramPelny[0].url) || ""
		const emptyChartElementSrc = (chartData.piktogramPusty && chartData.piktogramPusty[0] && chartData.piktogramPusty[0].url) || ""
		const percentage = chartData.procent|| ""
		const chartPercentageText = chartData.procentTekst || ""
		const chartDescription = chartData.opis || ""
		const chartInfoPictogram = (chartData.procentPiktogram &&chartData.procentPiktogram[0] && chartData.procentPiktogram[0].url) || ""

		let lastFullPercentage;
		let isLastDigitOfPercentage = false;
		const lastOfDigitChartElementWidth = percentage.toString().split('').pop() * 10;
		let elementsOfChartArray = []

		for (let i = 1; i <= 10; i++) {
			if (percentage >= 10 * i) {
				lastFullPercentage = 10 * i;
				elementsOfChartArray.push({src: fullChartElementSrc, isLastDigitOfPercentage: false})

			} else if (!isLastDigitOfPercentage && percentage < lastFullPercentage + 10) {
				isLastDigitOfPercentage = true;
				elementsOfChartArray.push({src: fullChartElementSrc, isLastDigitOfPercentage: true})

			} else {
				if (!isLastDigitOfPercentage && percentage < 10) {
					isLastDigitOfPercentage = true;
					elementsOfChartArray.push({src: fullChartElementSrc, isLastDigitOfPercentage: true})
				} else {
					elementsOfChartArray.push({src: emptyChartElementSrc, isLastDigitOfPercentage: false})
				}
			}
		}

		return (
			<ChartWithInfoWrapper>
			<ChartContent>
					{elementsOfChartArray.map((element, key) =>
						<React.Fragment key={key}>
							{key === 5 && <div className="break"/>}
							{!element.isLastDigitOfPercentage &&
							<ChartElementWrapper className={key >= 5 ? "bottom" : ""}>
								<img alt="" src={element.src}/>
							</ChartElementWrapper>
							}
							{element.isLastDigitOfPercentage &&
							<ChartElementWrapper className={key >= 5 ? "bottom unitSet" : "unitSet"} emptyChartElementSrc={emptyChartElementSrc}>
								<ImgElementWrapper>
									<ImgWrapper lastOfDigitChartElementWidth={lastOfDigitChartElementWidth}>
										<img alt="" src={fullChartElementSrc}/>
									</ImgWrapper>
								</ImgElementWrapper>
							</ChartElementWrapper>
							}
						</React.Fragment>
					)}
			</ChartContent>
				<InfoPercentage chartPercentage={percentage} chartPercentageText={chartPercentageText} chartDescription={chartDescription} chartInfoPictogram={chartInfoPictogram} />
			</ChartWithInfoWrapper>

		)
	}
}

export default PercentageTenElementsCharts
