import React from "react"
import styled from "styled-components"
import ChartsExtendedContent from "../charts/ChartsExtendedContent";
import {PoseGroup} from "react-pose"
import {Option, OptionList, SelectedOption, SelectWindow, SelectWrapper} from "../common/CustomSelector";

const DoYouKnowThatWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	
	@media(min-width: 768px){
		padding-bottom: 60px;
	}
	
	@media(min-width: 1280px){
		margin-top: 50px;
	}
	@media(min-width: 1920px){
		padding-bottom: 100px;
		margin-top: 0;
	}
`

const Title = styled.h3`
	display: flex;
	margin: 0 10px 80px;
	align-self: flex-start;
	color: ${({theme}) => theme.colors.pmmWhite};
	flex-wrap: wrap;
	position: relative;
	
	@media(min-width: 768px){
	 	margin: 0 35px;
	 	justify-content: flex-start;
	 	position: unset;
	}
	@media(min-width: 1280px){
	 	margin: 0 50px;
	}
	
	@media(min-width: 1920px){
		margin: 0 100px;
	}
`

const TitleText = styled.div`
	white-space: nowrap;
	margin-right: 7px;
	@media(min-width: 768px){
		margin-right: 0;
	}
`

const ChartsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding: 0 10px;
	width: 100%;
	
	@media(min-width: 768px){
	 	padding: 0 35px;
	 	margin-top: 90px;
	 		//margin-top: 170px;
	}
	@media(min-width: 1280px){
		padding: 0 50px;
	}
	@media(min-width: 1366px){
	}
	@media(min-width: 1920px){
		padding: 0 100px;
	}
	
`

class DoYouKnowThat extends React.Component {

	state = {
		isSelectWindowOpen: false
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickIfOutsideSelectWindow, false)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickIfOutsideSelectWindow, false)
	}

	handleClickIfOutsideSelectWindow = (e) => {
		if (this.node.contains(e.target)) {
			return;
		}

		this.closeSelectWindow();
	}

	closeSelectWindow() {
		if (this.state.isSelectWindowOpen) {
			this.setState({isSelectWindowOpen: false})
		}
	}

	toggleSelectWindow() {
		this.setState({isSelectWindowOpen: !this.state.isSelectWindowOpen})
	}

	render() {
		const {currentProjectData, projectCountriesList, currentProjectIndex, changeProject} = this.props
		const {isSelectWindowOpen} = this.state
		const currentProjectCountry = projectCountriesList[currentProjectIndex]
		const title = (currentProjectData && currentProjectData.czyWieszZe && currentProjectData.czyWieszZe[0] && currentProjectData.czyWieszZe[0].nazwa) || ""
		const statsData = (currentProjectData && currentProjectData.statystyki) || []

		return (
			<DoYouKnowThatWrapper>
				<Title>
					<TitleText>{title}</TitleText>
					<SelectWrapper className="display-always">
						<SelectedOption
							onClick={this.toggleSelectWindow.bind(this)}>{currentProjectCountry}</SelectedOption>
						<div ref={node => this.node = node}>
							<PoseGroup>
								{isSelectWindowOpen &&
								<SelectWindow pose={isSelectWindowOpen ? "enter" : "exit"} key="select-animation-window"
								              onClick={this.toggleSelectWindow.bind(this)}>
									<OptionList>
										{projectCountriesList.map((project, key) =>
											<Option key={key} pose={isSelectWindowOpen ? "opened" : "closed"}
											        onClick={() => changeProject(projectCountriesList.indexOf(project))}>{project}</Option>
										)}
									</OptionList>
								</SelectWindow>
								}
							</PoseGroup>
						</div>
					</SelectWrapper>
				</Title>
				<ChartsWrapper>
					<ChartsExtendedContent data={statsData}/>
				</ChartsWrapper>
			</DoYouKnowThatWrapper>
		)
	}
}

export default DoYouKnowThat
