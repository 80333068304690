import React, {Component} from 'react'
import styled from "styled-components"

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
  	background-color: ${({theme}) => theme.colors.pmmWhite};
	padding: 40px 30px;
	@media(min-width: 1280px) {
		padding: 30px 50px;
	}
	@media(min-width: 1920px) {
		padding: 40px 100px;
	}
`;

const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 45%;
`;

const Header = styled.h3`
	position: relative;
	text-align: left;
	width: 100%;
	padding-bottom: 60px;
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
`;

const PhotoAndDescriptionWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	min-height: 600px; 
	width: 85%;
	padding-bottom: 50px;
`;

const PictureWrapper = styled.div`
	width: 390px;
	height: 600px;
	cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url( ${({ bgImg }) => bgImg});
`;

const PersonDescription = styled.div`
	width: calc(100% - 390px);
	cursor: pointer;
	background-color: ${({theme}) => theme.colors.transparent};
	border: 3px solid ${({theme}) => theme.colors.pmmOrange};
	height: 540px;
	margin: 30px 0 30px -40px;
	padding: 40px 85px 55px; 
	overflow: hidden;
`;

const PersonName = styled.span`
	font-size: 16px;
	line-height: 32px;
	padding: 15px;
	margin-bottom: 40px;
	position: relative;
	background-color: ${({theme}) => theme.colors.pmmOrange};
	color: ${({theme}) => theme.colors.pmmWhite};
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	font-weight: ${({theme}) => theme.fontWeight.bold};
	@media(min-width: 1280px) {
		font-size: 18px;
	}
	@media(min-width: 1920px) {
		font-size: 24px;
		line-height: 32px;
	}
`;

const Description = styled.p`
	position: relative;
	text-align: left;
    margin-top: 30px;
	width: 100%;
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	font-family: ${({theme}) => theme.fontFamily.mainFont};
`;

class Coach extends Component {

	render() {
		const Coach = this.props.data;
		const CoachData = (Coach && Coach.modulTrener && Coach.modulTrener[0]) || "";
		const CoachHeader = (CoachData && CoachData.naglowek) || "";
		const CoachDescription = (CoachData && CoachData.opis) || "";
		const CoachName = (CoachData && CoachData.imieINazwisko) || "";
		const CoachPhoto = (CoachData && CoachData.zdjecie && CoachData.zdjecie[0] && CoachData.zdjecie[0].url) || "";

		return (
			<ModuleWrapper>
				<HeaderWrapper>
					<Header>{CoachHeader}</Header>
				</HeaderWrapper>
				<PhotoAndDescriptionWrapper>
					<PictureWrapper bgImg={CoachPhoto} />
					<PersonDescription>
						<PersonName>{CoachName}</PersonName>
						<Description>{CoachDescription}</Description>
					</PersonDescription>
				</PhotoAndDescriptionWrapper>
			</ModuleWrapper>
		)
	}
}

export default Coach;
