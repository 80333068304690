import React, {Component} from 'react';
import styled from "styled-components";
import Moment from "react-moment";
import {connect} from "react-redux";
import PopupVideoAndAudio from "../common/popupVideoAndAudio/PopupVideoAndAudio";

const SingleMentionStyled = styled.div`
	width: 100%;
	padding-right: 10px;
	display: flex;
	flex-direction: column;
	margin-bottom: 60px;
	cursor: pointer;
	@media(min-width: 768px) {
		width: 25%;
		margin-bottom: 0;
	}
	@media(min-width: 1280px) {
		padding-right: 30px;
	}
	&.margin-bottom{
		margin-bottom: 100px;
	}
	
		&:hover {
		.icon {
			transform: scale(1.1);
		}  
		.text {
			padding: 0 7px 2px;
			margin-left: -7px;
		}
	}
`
const LogoMediaWrapper = styled.div`
	height: 90px;
	margin-bottom: 40px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
`
const LogoMedia = styled.img`
	height: 90px;
	max-width: 100%;
`
const Date = styled(Moment)`
	font-size: 14px;
  	line-height: 16px;
 	color: ${({theme}) => theme.colors.pmmDarkestBlue};
  	font-weight: ${({theme}) => theme.fontWeight.regular};
  	margin-bottom: 5px;
  	
	@media(min-width: 1920px){
		font-size: 18px;
		line-height: 21px;
	}
`
const Title = styled.p`
	padding-bottom: 20px;
`
const Description = styled.h5`
	height: 90px;
	
	@media(min-width: 768px) {
		height: 155px;
	}
	@media(min-width: 1280px) {
		height: 140px;
	}
`
const ActionBtnWrapper = styled.div`
	display: flex;
	align-items: center;
	overflow: visible;
`
const Icon = styled.div`
	background-image: url(${props => props.back});
	background-position: center;
	background-size: contain;
	width: 30px;
	height: 30px;
	margin-right: 10px; 
	transition: .25s ease-out;
`
const Text = styled.h5`
	padding-bottom: 2px;
	border-bottom: 2px solid ${({theme}) => theme.colors.pmmOrange};
	transition: .25s ease-out;
`

class SingleMention extends Component {

	state = {
		isPopupVisible: false,
		isHovered: false,
		isOldBrowserEdge: false,
	};

	componentDidMount() {
		if (typeof window !== "undefined") {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({isOldBrowserEdge: isOldBrowser});
		}
	}

	togglePopup() {
		this.setState({isPopupVisible: !this.state.isPopupVisible})
	};

	actionOnClick(link, file, film, audio) {
		if (link !== "") {
			window.open(
				link,
				'_blank'
			);
		} else if (file !== "") {
			window.open(
				file,
				'download'
			);
		} else if (film !== "") {
			this.togglePopup()
		} else if (audio !== "") {
			this.togglePopup()
		}
	}

	render() {
		const {item, fileBtn, videoBtn, audioBtn, showOnPageBtn, lang, additionalClass} = this.props
		const momentLocale = lang;
		const iconClassName = (this.state.isOldBrowserEdge) ? "icon-edge" : "icon";
		return (
			<SingleMentionStyled className={additionalClass}
								 onClick={this.actionOnClick.bind(this, item.link, item.file, item.film, item.audio)}>
				<LogoMediaWrapper>
					<LogoMedia src={item.logo}/>
				</LogoMediaWrapper>
				<Date format="DD MMM. YYYY" locale={momentLocale} date={item.date}/>
				<Title className="h7 dark-blue">{item.title}</Title>
				<Description className="medium dark-blue line-height-32">{item.desctiption}</Description>
				{item.link !== "" &&
				<ActionBtnWrapper>
					<Icon className={iconClassName} back={showOnPageBtn.icon}/>
					<Text className="medium orange text">{showOnPageBtn.text}</Text>
				</ActionBtnWrapper>
				}
				{item.file !== "" &&
				<ActionBtnWrapper>
					<Icon className={iconClassName} back={fileBtn.icon}/>
					<Text className="medium orange text">{fileBtn.text}</Text>
				</ActionBtnWrapper>
				}
				{item.film !== "" &&
				<ActionBtnWrapper>
					<Icon className={iconClassName} back={videoBtn.icon}/>
					<Text className="medium orange text">{videoBtn.text}</Text>
				</ActionBtnWrapper>
				}
				{item.audio !== "" &&
				<ActionBtnWrapper>
					<Icon className={iconClassName} back={audioBtn.icon}/>
					<Text className="medium orange text">{audioBtn.text}</Text>
				</ActionBtnWrapper>
				}
				<PopupVideoAndAudio
					videoSrc={item.film}
					audioSrc={item.audio}
					isVisible={this.state.isPopupVisible}
					hide={this.togglePopup.bind(this)}
					isSubscriberAdded={true}
				/>
			</SingleMentionStyled>
		);
	}
}

export default connect(state => ({
	lang: state.app.lang,
}), null)(SingleMention)
