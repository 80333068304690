export const getPaddingTop = (paddingTop) => {
	switch (paddingTop) {
		case "extra-small":
			return 20;
		case "small":
			return 40;
		case "medium":
			return 60;
		case "extra-medium":
			return 80;
		case "large":
			return 100;
		case "extra-large":
			return 120;
		default:
			return 0;
	}
};

export const getPaddingBottom = (paddingBottom) => {
	switch (paddingBottom) {
		case "extra-small":
			return 20;
		case "small":
			return 40;
		case "medium":
			return 60;
		case "extra-medium":
			return 80;
		case "large":
			return 100;
		case "extra-large":
			return 120;
		default:
			return 0;
	}
};
