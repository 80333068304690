export async function addNewPaymentToListPayPal(data) {
	const url = `${process.env.GATSBY_PAYMENTS_POST_URL}/payments`;

	const response = fetch( url, {
		method: "POST",
		headers: {
			"Accept": "application/json",
		},
		body: data,
	}).then(res => res.json())
		.then((data)=> {document.location.href=data})
	typeof window !== "undefined" && typeof window.gtag === 'function' && window.gtag("event", "click", {eventLabel: 'PayPal payment', transport: 'beacon'})
	// .then(data => console.log(data))

	return response
}

