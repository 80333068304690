import React, {Component} from 'react';
import styled from "styled-components"
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";

const PhotosWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	background-color: ${({theme}) => theme.colors.pmmWhite};
	padding: ${({paddingTop}) => paddingTop}px 10px ${({paddingBottom}) => paddingBottom}px;
	@media(min-width: 768px) {
		padding-right: 30px;
		padding-left: 30px;
	}
	@media(min-width: 1280px) {
		padding-right: 50px;
		padding-left: 50px;
	}
	@media(min-width: 1920px) {
		padding-right: 100px;
		padding-left: 100px;
	}
	
	
`
const PhotoWrapper = styled.div`
	width: 25%;
	min-width: 380px;
	padding-bottom: 20px;
	a {
		text-decoration: none;
	}
`

const DownloadBtn = styled.div`
	position: absolute;
	bottom: 20px;
	right: 30px;
	background-color: ${({theme}) => theme.colors.pmmOrange};
	border-radius: 100%;
	width: 45px;
	height: 45px;
	z-index: 1;
	
	&:before {
		content: '';
		position: absolute;
		z-index: 0;
		background-image: url( ${({back}) => back});
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		width: 45px;
		height: 45px;
	}
`

const PictureWrapper = styled.div`
	width: 360px;
	height: 360px;
	position: relative;
  	overflow: hidden;
`;

const ZoomedPicture = styled.div`
	width: 360px;
	height: 360px;
	position: absolute;
	cursor: pointer;
    transition: transform .3s ease-in-out;
	background-size: cover;
  	background-position: center;
  	background-image: url( ${({bgImg}) => bgImg});
  	
  	&:hover {
   		transform: scale(1.1);
    }  
     &.animate {
   		transform: scale(1.1);
    }
`;

const PhotoData = styled.div`
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	font-size: ${({theme}) => theme.fontSize.f3};
	line-height: ${({theme}) => theme.lineHeight.line4};
	font-weight: ${({theme}) => theme.fontWeight.light};
	margin-top: 20px;
`

const Description = styled.div`
	padding-right: 10px;
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	font-size: ${({theme}) => theme.fontSize.f6};
	line-height: ${({theme}) => theme.lineHeight.line6};
	font-weight: ${({theme}) => theme.fontWeight.medium};
	margin-top: 20px;
`

class PhotosToDownload extends Component {
	state = {
		zoomedPictureKey: -1
	};

	triggerAnimation(number) {
		number !== -1 ? this.setState({zoomedPictureKey: number}) : this.setState({zoomedPictureKey: -1})
	}

	render() {
		const {data, photosToDownloadBtn} = this.props
		const photoData = (data && data.modulZdjeciaDoPobrania) || []
		const btnsData = (photosToDownloadBtn && photosToDownloadBtn[0]) || ""
		const downloadBtnIcon = (btnsData.ikonaPobierania && btnsData.ikonaPobierania[0] && btnsData.ikonaPobierania[0].url) || ""
		let photoArray = []
		let paddingTop = '';
		let paddingBottom = '';

		photoData.forEach(item => {
			if(item.__typename === "Craft_modulZdjeciaDoPobrania_marginesy_BlockType") {
				paddingTop = (item.marginesGorny && item.marginesGorny[0] && item.marginesGorny[0].title) || ''
				paddingBottom = (item.marginesDolny && item.marginesDolny[0] && item.marginesDolny[0].title) || ''
			} else if (item.__typename === "Craft_modulZdjeciaDoPobrania_zdjecie_BlockType") {
				const photoData = (item.zdjecie && item.zdjecie[0]) || ""
				const sizeKb = photoData.size ? photoData.size * 0.0009765625 : 0;
				const sizeKbFixed = sizeKb.toFixed(0)
				photoArray.push(
					{
						'photoUrl': (photoData && photoData.url) || "",
						'photoDescription': item.podpis || "",
						'size': sizeKbFixed,
						'extension': photoData.extension || ""
					}
				)
			}
		})

		return (
			<PhotosWrapper paddingTop={getPaddingTop(paddingTop)}
				               paddingBottom={getPaddingBottom(paddingBottom)}>
				{photoArray.map((item, key) =>
					<PhotoWrapper key={key} onMouseOver={this.triggerAnimation.bind(this, key)}
								  onMouseOut={this.triggerAnimation.bind(this)}>
						<a href={item.photoUrl} target="_blank" rel="noopener noreferrer" download>
							<PictureWrapper>
								<ZoomedPicture bgImg={item.photoUrl}
											   className={this.state.zoomedPictureKey === key ? 'animate' : ''}/>
								<DownloadBtn back={downloadBtnIcon}/>
							</PictureWrapper>
							<PhotoData>{item.extension} - {item.size} kB</PhotoData>
							<Description>{item.photoDescription}</Description>

						</a>
					</PhotoWrapper>)}
			</PhotosWrapper>
		);
	}
}


export default PhotosToDownload;
