import React from "react"
import WhereWeHelpCard from "../whereWeHelpCard/whereWeHelpCard";
import DoYouKnowThat from "../doYouKnowThat/DoYouKnowThat";

class ProjectCard extends React.Component {

	state = {
		currentProjectIndex: 0,
	};

	changeProject(index) {
		this.setState({currentProjectIndex: index})
	}

	render() {
		const { data, activeSite, piktogramyData} = this.props
		const { currentProjectIndex } = this.state
		const projectsData = (data && data.projektWizytowka) || ""
		const currentProjectData = (projectsData && projectsData[currentProjectIndex]) || ""
		const isDoYouKnowThatVisible = (currentProjectData && currentProjectData.czyWieszZe && currentProjectData.czyWieszZe[0] && currentProjectData.czyWieszZe[0].widocznoscStatystyk) || ""
		let projectCountriesList = []

		projectsData && projectsData.forEach((project) => {
			const projectTitleVariety = project.tytulOdmianaDlaStatystyk || ""
			projectCountriesList.push(projectTitleVariety)
		})

		return (
			<>
				<WhereWeHelpCard data={data} activeSite={activeSite} piktogramyData={piktogramyData} currentProjectIndex={currentProjectIndex} changeProject={this.changeProject.bind(this)}/>
				{isDoYouKnowThatVisible && <DoYouKnowThat changeProject={this.changeProject.bind(this)} projectCountriesList={projectCountriesList} currentProjectIndex={currentProjectIndex} currentProjectData={currentProjectData}/>}
			</>
		)
	}
}

export default ProjectCard
