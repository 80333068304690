import React from "react"
import styled from "styled-components"
import InfoPercentage from "../chartsDescription/infoPercentage/InfoPercentage"
import ChartWithInfoWrapper from "../../common/Charts";

const ChartContent = styled.div`
	// height: 150px;
	padding-bottom: 40px;
	display: flex;
	align-items: center;
	width: 100%;
	// padding-right: 90px;
	padding-right: 24%;

	@media(min-width: 768px){
		padding-right: 0;
		margin-top: 60px;
		height: 200px;
		padding-bottom: 0;
	}
	@media(min-width: 1920px){
	//	margin-top: 45px;
		margin-top: 0;
	}
	@media(min-width: 2560px) {
		height: 350px;
	}
`

const HorizontalRectangleChart = styled.div`
	background-color: ${({theme}) => theme.colors.pmmViolet};
	width: 100%;
	height: 80px;

	@media(min-width: 1920px){
		height: 110px;
	}
	@media(min-width: 2560px) {
		height: 150px;
	}
`

const RectangleWithPictogram = styled.div`
	background-color: ${({theme}) => theme.colors.pmmWhite};
	width: ${props => props.percentage + "%"};
	height: 100%;
	position: relative;
`

const ChartPictogram = styled.img`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 30px;
	width: 70px;

	&.small-percentage-value {
		left: unset;
		right: -105px;
	}

	@media(min-width: 1920px){
		width: auto;
	}
`

class HorizontalColumnChart extends React.Component {

	render() {
		const { data } = this.props
		const chartData = (data && data.kolumnaPozioma && data.kolumnaPozioma[0]) || []
		const chartPercentage = chartData.procent
		const chartPercentageText = chartData.procentTekst
		const chartDescription = chartData.opis || ""
		const chartPictogram = (chartData.piktogram && chartData.piktogram[0] && chartData.piktogram[0].url) || ""
		const chartInfoPictogram = (chartData.procentPiktogram && chartData.procentPiktogram[0] && chartData.procentPiktogram[0].url) || ""

		return (
			<ChartWithInfoWrapper>
						<ChartContent>
							<HorizontalRectangleChart>
								<RectangleWithPictogram percentage={chartPercentage}>
									<ChartPictogram className={chartPercentage < 40 ? "small-percentage-value" : ""} src={chartPictogram}/>
								</RectangleWithPictogram>
							</HorizontalRectangleChart>
						</ChartContent>
				   <InfoPercentage chartPercentage={chartPercentage} chartPercentageText={chartPercentageText} chartDescription={chartDescription} chartInfoPictogram={chartInfoPictogram}/>
			</ChartWithInfoWrapper>
		)
	}
}

export default HorizontalColumnChart
