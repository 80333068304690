import React, {Component} from 'react'
import styled from "styled-components"
import TickPicture from '../../images/newsletter/checkbox-tick-orange.svg'

const StyledCheckboxInput = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	align-items: flex-start;
	width: 100%;
	height: 50px;
	cursor: pointer;
	z-index: 0;
	margin-top: 20px;
	transition: opacity .3s;
	outline: none;
	@media(min-width: 768px) {
		width: 100%;
	    margin-top: 15px;
	}
	@media(min-width: 1280px) {
		margin-top: 25px;
	}
	input{
		margin: 0;
		width: 42px !important;
		height: 30px !important;
		z-index: 20;
		cursor: pointer;
	}
	p {
	    padding-left: 5px;
	    font-size: 12px;
	    line-height: 22px;
	    color: ${({theme}) => theme.colors.pmmPlaceHolderGrey};
	    @media(min-width: 768px) {
	    	//padding: 10px;
	    }
	    span {
		    text-decoration: underline;
			color: ${({theme}) => theme.colors.pmmOrange};
			padding-left: 5px;
	    }
    }
	.custom-checkbox {
		opacity: 0;
	}
`;

const CheckboxLabel = styled.label`
		position: absolute;
		background-color: ${({theme}) => theme.colors.pmmWhite};
		border: 2px solid ${({theme}) => theme.colors.pmmOrange};
		border-radius: 4px;
		//border: none;
		opacity: 1;
		z-index: 4;
		width: 20px;
		height: 20px;
	    top: 5px;
    	left: -1px;
		cursor: pointer;
		outline: none;
		padding: 10px;
  }	
`;
const TickPictureWrapper = styled.div`
		position: absolute;
		z-index: 5;
		left: 5px;
		bottom: 28px;
		width: 11px;
		height: 9px;
		background-size: cover;
    	background-position: center;
    	background-image: url( ${({ bgImg }) => bgImg});	
`;

class Checkbox extends Component {

	render() {
		const { required, checked, isCheckboxClicked, isSpanClicked, paragraphText, spanText} = this.props;
		const photo = (checked) ? TickPicture : '';

		return (
			<StyledCheckboxInput isCheckboxVisible={true} onClick={isCheckboxClicked}>
				<input type="checkbox" className="custom-checkbox" onClick={isCheckboxClicked} id="contact-form" checked={checked} required={required} />
				<CheckboxLabel htmlFor="contact-form" onClick={isCheckboxClicked}/>
				<TickPictureWrapper checked={checked} bgImg={photo}/>
				<div style={{marginLeft: "10px"}}>
					<p style={{marginLeft: "7px"}} className="regular">{paragraphText}
						<span onClick={isSpanClicked}>{spanText}</span>
					</p>
				</div>
			</StyledCheckboxInput>
		)
	}
}

export default Checkbox;
