import React, {Component} from 'react'
import styled from "styled-components"
import Button from "../common/ButtonsSet";
import getSlug from "../../utils/getSlug";
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";

const SingleCardWrapper = styled.div`
   //height: 595px;
   height: 468px;
   width: 100%;
   position: relative;
   background-size: cover;
   background-position: center;
   cursor: pointer;
   background-image: url( ${({ bgImg }) => bgImg});
   padding: 35px 27px;
   margin-bottom: 45px;
   @media(min-width: 768px) {
   	  width: 48%;
	  height: 500px;
   }
   @media(min-width: 1280px) {
   	margin: 0 40px;
   }
   @media(min-width: 1920px) {
   	height: 650px;
   }
   &:before {
        content: '';
        width: 100%;
        height: 100%;
        background-color: ${({theme}) => theme.colors.pmmDarkestBlue};
        position: absolute;
        top: 0;
        left: 0;
        opacity: .3;
   }
   &:last-of-type {
    margin: 0;
   }
`;
const BlendOnHover = styled.div`
   height: 100%;
   width: 100%;
   display: none;
   flex-direction: column;
   justify-content: center;
   position: absolute;
   top: 0;
   left: 0;
   &.show {
   	display: flex;
   }
`;
const BackgroundOfBlend = styled.div`
   width: 100%;
   height: 100%;
   background-color: ${({theme}) => theme.colors.pmmDarkestBlue};
   z-index: 0;
   opacity: 0.85;
`
const RedactorDescription = styled.div`
   z-index: 100;
   opacity: 1;
   position: absolute;
   left: 0;
   padding: 0 45px;
   width: 100%;
   color: ${({theme}) => theme.colors.pmmWhite};
   font-weight: ${({theme}) => theme.fontWeight.medium};
   span.orange {
      color: ${({theme}) => theme.colors.pmmOrange};
   }
`;
const CardTitle = styled.span`
   font-size: 18px;
   line-height: 32px;
   display: flex;
   align-items: center;
   padding: 0 25px;
   min-height: 50px;
   position: absolute;
   top: 0;
   left: 0;
   z-index: 100;
   background-color: ${({theme}) => theme.colors.pmmDarkestBlue};
   color: ${({theme}) => theme.colors.pmmWhite};
   font-weight: ${({theme}) => theme.fontWeight.regular};
    span.black {
		font-weight: ${({theme}) => theme.fontWeight.black};
    }
   @media(min-width: 768px) {
      font-size: 21px;
      line-height: 28px;
   }
   @media(min-width: 1366px) {
      font-size: 20px;
   }
   @media(min-width: 1920px) {
    //padding: 21px 25px;
      min-height: 70px;
      font-size: 24px;
      line-height: 32px;
   }
`;
const InnerFrame = styled.div`
   width: 100%;
   height: 100%;
   border: 2px solid ${({theme}) => theme.colors.pmmWhite};
   position: relative;
   z-index: 99;
`;
const ButtonWrapper = styled.div`
   height: 120px;
   display: flex;
   position: relative;
   z-index: 101;
   flex-direction: column;
   justify-content: center;
   margin-top: -25px;
   @media(min-width: 768px) {
      height: 70px;
      margin-top: -35px;
   }
`;
class SingleCardAdditionalPayments extends Component {
   state = {
      isPictureHovered: false,
   };
   showBlendWithDescription() {
		   this.setState({isPictureHovered: !this.state.isPictureHovered})
   }

   render() {
      const SingleCardData = this.props.data;
      const {index, activeSite} = this.props;
      const { isPictureHovered } = this.state;
      const header = (SingleCardData && SingleCardData.header) || ""
      const descriptionOnHover = (SingleCardData && SingleCardData.descriptionOnHover) || "";
      const image = (SingleCardData && SingleCardData.image) || "";
      const btnText = (SingleCardData && SingleCardData.btnText) || "";
      const btnType = (SingleCardData && SingleCardData.btnType) || "";
      const btnTo = (SingleCardData && SingleCardData.btnTo) || "";
      const btnHref = (SingleCardData && SingleCardData.btnHref) || "";
      const classNameShowBlend = (isPictureHovered) ? "show" : "";

       const btnToIfExist = btnTo ? `/${getSlug(btnTo, activeSite)}` : ""

      return (
            <SingleCardWrapper key={index} bgImg={image}
                               onMouseOver={this.showBlendWithDescription.bind(this)}
                               onMouseOut={this.showBlendWithDescription.bind(this)}>
               <CardTitle dangerouslySetInnerHTML={getHtmlFromRedactorField(header)}/>
               <InnerFrame/>
               <ButtonWrapper>
                  <Button href={btnHref} btnType={btnType} textBtn={btnText} to={btnToIfExist} />
               </ButtonWrapper>
               <BlendOnHover className={classNameShowBlend}>
                  <BackgroundOfBlend/>
                  <RedactorDescription className="paragraph" dangerouslySetInnerHTML={getHtmlFromRedactorField(descriptionOnHover)}/>
               </BlendOnHover>
            </SingleCardWrapper>
      )
   }
}
export default SingleCardAdditionalPayments;
