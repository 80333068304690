import React, { useState } from 'react';
import styled from 'styled-components';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import NavyBlueLine from '../../images/faq/navy-blue-minus.svg';
import OrangePlus from '../../images/faq/orange-plus.svg';

const StyledSingleQAndAWrapper = styled.div`
	margin-bottom: 20px;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		width: 30px;
		height: 30px;
		background-color: ${({ theme }) => theme.colors.pmmWhite};
		left: -40px;
		border-radius: 50%;
		@media (min-width: 768px) {
			width: 36px;
			height: 36px;
			left: -50px;
		}
	}
	@media (min-width: 768px) {
		margin-bottom: 40px;
	}
`;

const StyledQuestion = styled.div`
	font-size: 16px;
	line-height: 27px;
	font-weight: ${({ theme }) => theme.fontWeight.semiBold};
	color: ${({ theme }) => theme.colors.pmmWhite};
	cursor: pointer;
	@media (min-width: 768px) {
		font-size: 20px;
		line-height: 38px;
		font-weight: ${({ theme }) => theme.fontWeight.black};
	}
`;

const StyledAnswer = styled.div`
	font-size: 15px;
	line-height: 26px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	color: ${({ theme }) => theme.colors.pmmWhite};
	margin-top: 20px;
	padding-bottom: 15px;
	max-height: 1px;
	opacity: 0;
	overflow-y: hidden;
	transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
	@media (min-width: 768px) {
		font-size: 20px;
		line-height: 38px;
		margin-top: 35px;
		padding-bottom: 25px;
	}
	&.open {
		max-height: 500px;
		opacity: 1;
		transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
	}
`;

const StyledIcon = styled.img`
	position: absolute;
	cursor: pointer;
	height: 48px;
	max-width: 48px;
	transform: rotate(0deg);
	transition: transform 0.3s ease-in-out;
	left: -49px;
	top: -9px;
	padding: 18px;
	@media (min-width: 768px) {
		padding: 20px;
		left: -59px;
		height: 54px;
		max-width: 54px;
		top: -9px;
	}
	&.plus {
		transform: rotate(180deg);
	}
`;

const SingleQuestionWithAnswer = ({ question, answer, isFirstQuestion }) => {
	const [isExpanded, setIsExpanded] = useState(isFirstQuestion);
	const toggleQuestionExpand = () => {
		setIsExpanded(!isExpanded);
	};
	return (
		<StyledSingleQAndAWrapper>
			<StyledIcon
				onClick={() => toggleQuestionExpand()}
				className={isExpanded ? '' : 'plus'}
				src={isExpanded ? NavyBlueLine : OrangePlus}
			/>
			<StyledQuestion onClick={() => toggleQuestionExpand()}>{question}</StyledQuestion>
			<StyledAnswer className={isExpanded ? 'open' : ''} dangerouslySetInnerHTML={getHtmlFromRedactorField(answer)} />
		</StyledSingleQAndAWrapper>
	);
};

export default SingleQuestionWithAnswer;
