import React, {Component} from 'react'
import styled from "styled-components"
import setBackgroundColor from "../../utils/setBackgroundColor";
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";
import getSlug from "../../utils/getSlug";
import Button from "../common/ButtonsSet";
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";
import {changeLinkToIdFromAnotherPage, setMobileActiveProject} from "../../state/app";
import {navigate} from "gatsby";
import {connect} from "react-redux";
import {fbqDonateBtnClicked} from "../../utils/fbqDonateBtnClicked";

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	//min-height: 400px;
	width: 100%;
  	background-color: ${({background, theme}) => setBackgroundColor(background, theme)};
	padding-top: ${({paddingTop}) => paddingTop}px;
	padding-bottom: ${({paddingBottom}) => paddingBottom}px;
	@media(min-width: 2560px) {
		padding-top: 120px;
		padding-bottom: 120px;
	}
`;

const PictureWrapper = styled.div`
	margin: 40px auto 0;
	width: 70px;
	height: 88px;
	background-size: 71px 88px;
    background-position: center;
    background-image: url( ${({bgImg}) => bgImg});
    position: relative;
    &.pictogram-empty {
    	margin: 0 auto;
    }
	&:before {
		content: '';
		position: absolute;
		width: 140%;
		height: 4px;
		background-color: ${({theme}) => theme.colors.pmmWhite};
		top: 120px;
		left: -20%;			
	}
	@media(min-width: 768px) {
		margin: 0 auto;
	}	
`;

const RedactorHeader = styled.div`
	font-size: 30px;
	line-height: 50px;
	position: relative;
	text-align: center;
	padding: 100px 10% 0;
	width: 100%;
	color: ${({theme}) => theme.colors.pmmWhite};
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
	@media(min-width: 768px){
		font-size: 36px;
		line-height: 50px;
		padding: 100px 10% 10px;
	}
	@media(min-width: 1280px){
		font-size: 50px;
		line-height: 75px;
		padding-bottom: 0;
	}
	@media(min-width: 1366px){
	}
	@media(min-width: 1920px){
	}
	span {  
	  &.black {
	      font-weight: ${({theme}) => theme.fontWeight.black};
	  }
	}
	&.header-empty {
		padding: 0;
	}
`;

const RedactorDescription = styled.div`
	position: relative;
	text-align: center;
	padding: 30px 10% 40px;
	width: 100%;
	color: ${({theme}) => theme.colors.pmmWhite};
	span {  
	  &.black {
	      font-weight: ${({theme}) => theme.fontWeight.black};
	  }
	}
	&.header-empty {
		font-size: 28px;
		line-height: 36px;	
		padding: 35px 20px 40px;
		margin-top: 40px;
		@media(min-width: 768px) {
			padding: 35px 50px 40px;
		}
		@media(min-width: 1280px) {
			padding: 35px 120px 40px;
		}
		@media(min-width: 1920px) {
			padding: 50px 15% 60px;	
		}
	}
	&.header-not-empty {
		font-size: 20px;
		line-height: 32px;
		font-weight: ${({theme}) => theme.fontWeight.regular};
		padding: 10px 10% 30px;
		@media(min-width: 768px) {
			font-size: 28px;
			line-height: 52px;
		}	
	}
`;
const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-bottom: 40px;
	@media(min-width: 768px){
		margin-bottom: 50px;
	}
`;

class BottomBaner extends Component {

	handleBannerBtnClick(linkToNavigate, slugForProject) {
		const { dispatch } = this.props
		dispatch(changeLinkToIdFromAnotherPage(true))
		fbqDonateBtnClicked('kliknieto z baneru');
		if((window.innerWidth >= 768) ) {
			const navigateToId = linkToNavigate + '#' + slugForProject
			navigate(navigateToId)
		} else {
			const navigateToId = linkToNavigate + '#payment-wrapper'
			navigate(navigateToId)
			dispatch(setMobileActiveProject(slugForProject))
		}
	}

	render() {
		const bottomBaner = this.props.data;
		const { isNews, activeSite } = this.props;
		const bottomBanerData = (bottomBaner.modulDolnyBaner && bottomBaner.modulDolnyBaner[0]) || '';

		const bottomBanerFinalData = (isNews) ? bottomBaner : bottomBanerData;
		const bottomBanerHeader = (bottomBanerFinalData && bottomBanerFinalData.naglowek) || '';
		const bottomBanerDescription = (bottomBanerFinalData && bottomBanerFinalData.opis) || '';
		const bottomBanerLogo = (bottomBanerFinalData && bottomBanerFinalData.logo && bottomBanerFinalData.logo[0] &&
			bottomBanerFinalData.logo[0].url) || [];
		const background = (bottomBanerFinalData && bottomBanerFinalData.background && bottomBanerFinalData.background[0] &&
			bottomBanerFinalData.background[0].title) || '';
		const bottomBanerBtnText = (bottomBanerFinalData && bottomBanerFinalData.buttonTekst) || '';
		const bottomBanerBtnLink = (bottomBanerFinalData && bottomBanerFinalData.buttonLink) || '';
		const bottomBanerBtnType = (bottomBanerFinalData && bottomBanerFinalData.buttonRodzaj && bottomBanerFinalData.buttonRodzaj[0] && bottomBanerFinalData.buttonRodzaj[0].title) || '';
		const bottomBanerTo = (bottomBanerFinalData && bottomBanerFinalData.buttonWpis && bottomBanerFinalData.buttonWpis[0] && bottomBanerFinalData.buttonWpis[0].slug) || '';
		const isHeaderEmptyClass = (bottomBanerHeader === "") ? "header-empty header-h2 light" : "header-not-empty";
		const isPictogramEmptyClass = (bottomBanerLogo.length === 0) ? "pictogram-empty" : "";
		const paddingTopType = (bottomBanerFinalData && bottomBanerFinalData.marginesGorny && bottomBanerFinalData.marginesGorny[0] &&
			bottomBanerFinalData.marginesGorny[0].title) || '';
		const paddingBottomType = (bottomBanerFinalData &&
			bottomBanerFinalData.marginesDolny && bottomBanerFinalData.marginesDolny[0] &&
			bottomBanerFinalData.marginesDolny[0].title) || '';

		const linkToNavigate = `/${getSlug(bottomBanerTo, activeSite)}`
		const slugForProject = (bottomBanerFinalData.projekt && bottomBanerFinalData.projekt[0] && bottomBanerFinalData.projekt[0].slug) || ""

		return (
			<ModuleWrapper background={background} paddingTop={getPaddingTop(paddingTopType)} paddingBottom={getPaddingBottom(paddingBottomType)}>
				<PictureWrapper bgImg={bottomBanerLogo} className={isPictogramEmptyClass}/>
				<RedactorHeader className={isHeaderEmptyClass}
								dangerouslySetInnerHTML={getHtmlFromRedactorField(bottomBanerHeader)}/>
				<RedactorDescription className={isHeaderEmptyClass}
									 dangerouslySetInnerHTML={getHtmlFromRedactorField(bottomBanerDescription)}/>
				<ButtonWrapper>
					<Button href={bottomBanerBtnLink} btnType={bottomBanerBtnType} onClick={this.handleBannerBtnClick.bind(this,linkToNavigate, slugForProject)} textBtn={bottomBanerBtnText}/>
				</ButtonWrapper>
			</ModuleWrapper>
		)
	}
}

export default connect(state => ({
	mobileActiveProject: state.app.mobileActiveProject
}), null)(BottomBaner)
