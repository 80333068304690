import React from "react"
import styled from "styled-components"
import getSlug from "../../utils/getSlug";
import {Link} from "gatsby"
import {connect} from "react-redux"

export const LogoStyled = styled.img`
	position: absolute;
	top: 25px;
	left: 10px;
	width: auto;
	height: 80px;
	z-index: 30;
	
	@media(min-width: 768px){
		top: 40px;
		left: 35px;
	}
	@media(min-width: 1280px){
		top: 30px;
		left: 45px;
	}
	@media(min-width: 1366px){
		top: 30px;
		left: 60px;
	}
	@media(min-width: 1920px){
		top: 40px;
		left: 105px;
		width: auto;
		height: 100px;
	}
`

class Logo extends React.Component {


	render() {
		const { logoData, activeSite, logoType} = this.props

		const logo = (logoData && logoData.logo && logoData.logo[0] && logoData.logo[0].url) || ""
		const logoWhiteBg = (logoData && logoData.logoBialeTlo && logoData.logoBialeTlo[0] && logoData.logoBialeTlo[0].url) || ""
		const logoSlug = (logoData && logoData.logoLink && logoData.logoLink[0] && logoData.logoLink[0].slug) || ""
		const isLogoBlue = logoType === "pomaranczowoGranatowe"

		return (
			<Link to={`/${getSlug(logoSlug, activeSite)}`}>
				{!isLogoBlue && <LogoStyled src={logo}/>}
				{isLogoBlue && <LogoStyled src={logoWhiteBg}/>}
			</Link>
		)
	}
}

export default connect(state => ({
	isMenuVisible: state.app.isMenuVisible,
}), null)(Logo)
