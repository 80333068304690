import React from "react"
import GoogleMapReact from "google-map-react"
import styled from "styled-components"
import CustomMarkerImage from "./../../images/map/marker-map-orange.svg"
import ArrowRight from "./../../images/map/arrow-right-orange.svg"

const CustomMarker = styled.div`
	background-size: cover;
	background-position: 30%, left;
	background-image: url( ${({ bgImg }) => bgImg});
	width: 41px;
	height: 62px;
	position: relative;
	top: -62px;
	left: -20px;
	cursor: pointer;
`;

const TextBoxWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 100;
	position: absolute;
	top: 75px;
	width: 215px;
	min-height: 150px;
	border: 3px solid ${({theme}) => theme.colors.pmmOrange};
	padding: 30px 35px 30px 20px;
	background-color: white;
	@media(min-width: 768px) {
		justify-content: flex-start;
		left: 60px;
		top: 0;
		width: 275px;
		padding: 30px 65px 20px 25px;
	}
	@media(min-width: 1366px) {
		padding-right: 40px;
	}
	@media(min-width: 1920px) {
		width: 335px;
		padding-right: 80px;
	}
`;

const Description = styled.p`
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	font-weight: ${({theme}) => theme.fontWeight.bold};
	&.address {
		padding-bottom: 20px;
		font-weight: ${({theme}) => theme.fontWeight.medium};
		@media(min-width: 1280px) {
			padding-right: 20px;
		}
		@media(min-width: 1920px) {
			padding-right: 0;
			padding-bottom: 30px;
		}
	}
	@media(min-width: 768px) {
	}
`;

const GotoGoogleMap = styled.a`
	font-size: 14px;
	line-height: 17px;
	position: relative;
	font-weight: ${({theme}) => theme.fontWeight.medium};
	text-decoration: underline;
	color:  ${({theme}) => theme.colors.pmmOrange};
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	@media(min-width: 1920px) {
		font-size: 18px;
		line-height: 34px;
	}	
`;

const ArrowRightWrapper = styled.img`
	width: 30px;
	height: 30px;
	position: relative;
	top: 7px;
	left: 10px;
`

class SimpleMap extends React.Component {
	state = {
		isBoxVisible: false
	};

	toggleVisibility() {
		this.setState({isBoxVisible: !this.state.isBoxVisible})
	}

	render() {
		const lat = parseFloat(this.props.lat) || "";
		const lng = parseFloat(this.props.lng) || "";
		// const address = this.props.address || "";
		const center = { lat: lat-0.0025, lng: lng+0.003 };

		return (
			// Important! Always set the container height explicitly
				<GoogleMapReact
					bootstrapURLKeys={{ key: "AIzaSyAAnN5Uleg968EgKgwP2dcCxg3QbzK2oSQ" }}
					defaultCenter={center}
					defaultZoom={15}
					options={{
						styles: require(`./MapStyles.json`)
					}}
					center={center}>
						<CustomMarker lat={lat}
						              lng={lng}
									  bgImg={CustomMarkerImage}
									  onClick={this.toggleVisibility.bind(this)}>
							{ this.state.isBoxVisible &&
							<TextBoxWrapper>
								<Description className="address subpage-paragraph">Plac Sikorskiego 11/2 31-115c <b>Kraków</b></Description>
								<GotoGoogleMap href={"https://www.google.com/maps/place/Polska+Misja+Medyczna.+Stowarzyszenie/@50.060956,19.9267206,17z/data=!3m1!4b1!4m5!3m4!1s0x47165b432ca51bd5:0x9f1589498febd7ad!8m2!3d50.060956!4d19.9289093"}
								               target="_blank">prowadź do nas <ArrowRightWrapper src={ArrowRight}/></GotoGoogleMap>
							</TextBoxWrapper> }
						</CustomMarker>

				</GoogleMapReact>
		)
	}
}

export default SimpleMap
