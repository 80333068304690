import React from 'react';
import styled from "styled-components";
import {getPaddingTop} from "../../utils/setModulePadding";

const Margin = styled.div`
 	height: ${({height}) => height}px;
 	width: 100vw;
`

const ContentMargin = props => {
	const marginType = (props && props.data && props.data.margines && props.data.margines[0] && props.data.margines[0].title) || ""
	return (
		<Margin height={getPaddingTop(marginType)}/>

	);
};

export default ContentMargin;
