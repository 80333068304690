import React, {Component} from 'react'
import styled from "styled-components"
import SingleStep from "./SingleStep";

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
	padding: 50px 20px;
  	background-color: ${({theme}) => theme.colors.pmmWhite};
  	@media(min-width: 768px) {
  		padding: 50px 30px;
  		flex-direction: column;
  		justify-content: flex-start;
  	}
    @media(min-width: 1280px) {
    	flex-direction: row;
    	justify-content: space-between;
  	}
  	@media(min-width: 1920px) {
  		padding: 50px 85px 150px;
  	}
`;

class HowWeBuildHelp extends Component {

	render() {
		const howWeBuildHelpSteps = this.props.data.modulJakBudujemyPomocKroki;
		const numberOfSteps = howWeBuildHelpSteps.length;
		const singleStep = [];

		howWeBuildHelpSteps.forEach(item => {
			singleStep.push({
				"title": item.nazwaKroku || "",
				"description": item.opisKroku || "",
				"pictogram": (item.piktogram && item.piktogram[0] && item.piktogram[0].url) || "",
				"animation": (item.animacja && item.animacja[0] && item.animacja[0].url) || "",
			})
		})

		return (
			<ModuleWrapper>
				{singleStep.map((step, key) =>
					<SingleStep stepNumber={key} key={key} numberOfSteps={numberOfSteps} step={step}/>
				)}
			</ModuleWrapper>
		)
	}
}

export default HowWeBuildHelp;
