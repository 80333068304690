import React from 'react';
import ContentHeader from './news/ContentHeader';
import styled from 'styled-components';
import ContentDescription from './news/ContentDescription';
import ContentPhotoLarge from './news/ContentPhotoLarge';
import ContentPhoto from './news/ContentPhoto';
import ContentBtn from './news/ContentBtn';
import ContentModules from './news/ContentModules';
import ContentMargin from './news/ContentMargin';
import VideoModule from './videoModule/VideoModule';
import BottomBaner from './bottomBaner/BottomBaner';
import ContentGallery from './news/ContentGallery';
// import PhotoContactOnLeftWithDescription from "./photoContactOnLeftWithDescription/PhotoContactOnLeftWithDescription";

const ContentWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.pmmWhite};
	color: ${({ theme }) => theme.colors.pmmDarkViolet};
`;

const SingleNewsExtendedContent = props => {
	const { data, activeSite } = props || [];

	return (
		<ContentWrapper>
			{data.map((block, i) => {
				switch (block.__typename) {
					case 'Craft_trescPodstrony_naglowek_BlockType':
						return <ContentHeader key={i} data={block} activeSite={activeSite} />;
					case 'Craft_trescPodstrony_opis_BlockType':
						return <ContentDescription key={i} data={block} activeSite={activeSite} />;
					case 'Craft_trescPodstrony_zdjecieDuze_BlockType':
						return <ContentPhotoLarge key={i} data={block} activeSite={activeSite} />;
					case 'Craft_trescPodstrony_zdjecie_BlockType':
						return <ContentPhoto key={i} data={block} activeSite={activeSite} />;
					case 'Craft_trescPodstrony_przycisk_BlockType':
						return <ContentBtn key={i} data={block} activeSite={activeSite} />;
					case 'Craft_trescPodstrony_modul_BlockType':
						return <ContentModules key={i} data={block} activeSite={activeSite} />;
					case 'Craft_trescPodstrony_margines_BlockType':
						return <ContentMargin key={i} data={block} activeSite={activeSite} />;
					case 'Craft_trescPodstrony_film_BlockType':
						return <VideoModule isNews={true} key={i} data={block} activeSite={activeSite} />;
					case 'Craft_trescPodstrony_dolnyBaner_BlockType':
						return <BottomBaner isNews={true} key={i} data={block} activeSite={activeSite} />;
					case 'Craft_trescPodstrony_galeria_BlockType':
						return <ContentGallery isNews={true} key={i} data={block} activeSite={activeSite} />;
					default:
						return <h1 key={i}>Brak obsługi dla tresci {block.__typename}</h1>;
				}
			})}
		</ContentWrapper>
	);
};

export default SingleNewsExtendedContent;
