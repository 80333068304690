import React from 'react';
import Button from '../common/ButtonsSet';
import styled from 'styled-components';
import { Link } from 'gatsby';
import getSlug from '../../utils/getSlug';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const TileWrapper = styled.div`
	width: 50%;
	min-width: 320px;
	margin-bottom: 30px;

	@media (min-width: 768px) {
		width: 25%;
		min-width: 420px;
	}
	a {
		text-decoration: none;
	}
`;
const TileImgWithDescription = styled.div`
	position: relative;
	width: 300px;
	height: 300px;
	@media (min-width: 768px) {
		width: 390px;
		height: 390px;
	}
`;

const PictureWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	position: relative;
	background: none;
	margin-bottom: 30px;
	overflow: hidden;
	width: 300px;
	height: 300px;
	@media (min-width: 768px) {
		width: 390px;
		height: 390px;
	}
`;

const ZoomedPicture = styled.div`
	position: absolute;
	width: 300px;
	height: 300px;
	cursor: pointer;
	transition: transform 0.5s;
	background-size: cover;
	background-position: center;
	background-image: url(${({ bgImg }) => bgImg});

	@media (min-width: 768px) {
		width: 390px;
		height: 390px;
	}
	&.animate {
		transform: ${({ isOldBrowserEdge }) => (isOldBrowserEdge ? 'scale(1)' : 'scale(1.1)')};
	}
	&:hover {
		transform: ${({ isOldBrowserEdge }) => (isOldBrowserEdge ? 'scale(1)' : 'scale(1.1)')};
	}
`;

const TileImgDescription = styled.p`
	position: absolute;
	bottom: -23px;
	//top: 100%;
	//transform:translateY(-50%);
	padding: 10px;
	background-color: ${({ theme }) => theme.colors.pmmDarkestBlue};
	color: ${({ theme }) => theme.colors.pmmWhite};
	max-width: 80%;
	display: table;
	@media (min-width: 1920px) {
		bottom: -26px;
	}
`;

const Description = styled.div`
	color: ${({ theme }) => theme.colors.pmmDarkestBlue};
	max-width: 370px;
`;

const ButtonWrapper = styled.div`
	margin-top: 20px;
	display: flex;
	margin-left: -10px;
`;

const DescriptionWithBtnWrapper = styled.div`
	margin-top: 60px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media (min-width: 1920px) {
		min-height: 180px;
	}
`;

class Tile extends React.Component {
	state = {
		isPictureHovered: false,
		isButtonHovered: false,
		isOldBrowserEdge: false,
	};

	componentDidMount() {
		if (typeof window !== 'undefined') {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({ isOldBrowserEdge: isOldBrowser });
		}
	}

	triggerButtonAnimation() {
		this.setState({ isPictureHovered: !this.state.isPictureHovered });
	}

	triggerPictureAnimation() {
		this.setState({ isButtonHovered: !this.state.isButtonHovered });
	}

	render() {
		const { tileData, activeSite } = this.props;
		const isPictureHovered = this.state.isPictureHovered;
		const isButtonHovered = this.state.isButtonHovered;
		const isOldBrowserEdge = this.state.isOldBrowserEdge;
		const additionalClassName = isButtonHovered ? 'animate' : '';
		const additionalClassNameForBtn = isPictureHovered ? 'animate' : '';

		return (
			<TileWrapper>
				{tileData.to && (
					<Link to={`/${getSlug(tileData.to, activeSite)}`}>
						<TileImgWithDescription>
							<PictureWrapper>
								<a href={tileData.fileToDownload} target="_blank" rel="noopener noreferrer" download>
									<ZoomedPicture
										className={additionalClassName}
										onMouseOver={this.triggerButtonAnimation.bind(this)}
										onMouseOut={this.triggerButtonAnimation.bind(this)}
										isPictogram={isPictureHovered}
										isOldBrowserEdge={isOldBrowserEdge}
										bgImg={tileData.photoUrl}
									/>
								</a>
							</PictureWrapper>
							<TileImgDescription className="h7">{tileData.photoDescription}</TileImgDescription>
						</TileImgWithDescription>
					</Link>
				)}
				{!tileData.to && (
					<TileImgWithDescription>
						<PictureWrapper>
							<a href={tileData.fileToDownload} target="_blank" rel="noopener noreferrer" download>
								<ZoomedPicture
									className={additionalClassName}
									onMouseOver={this.triggerButtonAnimation.bind(this)}
									onMouseOut={this.triggerButtonAnimation.bind(this)}
									isPictogram={isPictureHovered}
									isOldBrowserEdge={isOldBrowserEdge}
									bgImg={tileData.photoUrl}
								/>
							</a>
						</PictureWrapper>
						<TileImgDescription className="h7">{tileData.photoDescription}</TileImgDescription>
					</TileImgWithDescription>
				)}
				<DescriptionWithBtnWrapper>
					{tileData.description && (
						<Description
							className="paragraph"
							dangerouslySetInnerHTML={getHtmlFromRedactorField(tileData.description)}
						></Description>
					)}
					{tileData.fileToDownload && (
						<ButtonWrapper>
							<Button
								href={tileData.fileToDownload}
								onMouseOver={this.triggerPictureAnimation.bind(this)}
								onMouseOut={this.triggerPictureAnimation.bind(this)}
								btnClassName={additionalClassNameForBtn}
								btnType={tileData.btnType}
								isOldBrowserEdge={isOldBrowserEdge}
								textBtn={tileData.btnText}
							/>
						</ButtonWrapper>
					)}
				</DescriptionWithBtnWrapper>
			</TileWrapper>
		);
	}
}

export default Tile;
