import React, {Component} from 'react'
import styled from "styled-components"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 0 auto;
	width: 100%;
	min-height: 270px;
	background-color: ${({theme}) => theme.colors.pmmDarkViolet};
	padding: 30px;
	@media(min-width: 1280px) {
		padding-right: 50px;
		padding-left: 50px;
		min-height: 330px;
	}
	@media(min-width: 1920px) {
		padding-right: 100px;
		padding-left: 100px;
		min-height: 356px;
	}
`;

const TextModule = styled.div`
	width: 100%;
	margin: 0 auto;
	text-align: center;
	color: ${({theme}) => theme.colors.pmmWhite};
	@media(min-width: 768px) {
		width: 90%;
	}
	div {
			font-size: 26px;
			line-height: 50px;
		@media(min-width: 768px) {
			font-size: 30px;
			font-weight: ${({theme}) => theme.fontWeight.regular};
			line-height: 55px;
		}
		@media(min-width: 1920px) {
			font-size: 40px;
			line-height: 65px;
		}
	}
	@media(min-width: 1280px) {
		width: 60%;
	}
`;

class WeAreGrateful extends Component {

	render() {
		const weAreGratefulModule = this.props.data;
		const weAreGratefulModuleData = (weAreGratefulModule.modulPodBaneremJestesmyWdzieczni) || [];
		const moduleText = (weAreGratefulModuleData && weAreGratefulModuleData[0] && weAreGratefulModuleData[0].tekst) || '';

		return (
			<ModuleWrapper>
				<TextModule dangerouslySetInnerHTML={getHtmlFromRedactorField(moduleText)}/>
			</ModuleWrapper>
		)
	}
}

export default WeAreGrateful;
