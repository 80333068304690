import React, {Component} from 'react'
import styled from "styled-components"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";
import setBackgroundColor from "../../utils/setBackgroundColor";
import setFontColor from "../../utils/setFontColor";
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";

const ModuleWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column-reverse;
	background-color: ${({theme}) => theme.colors.pmmWhite};
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	padding-top: calc(${({paddingTop}) => paddingTop}px /2);
	padding-bottom: calc(${({paddingBottom}) => paddingBottom}px /2);
	padding-left: 10px;
	padding-right: 10px;
	&.backgroundIsImage {
		background-size: cover;
		background-position: center;
		background-image: url( ${({bgImg}) => bgImg});
	}
	&.backgroundIsColor {
		background-color: ${({background, theme}) => setBackgroundColor(background, theme)};
		color: ${({background, theme}) => setFontColor(background, theme)};
	}
	@media(min-width: 768px) {
		padding-left: 30px;
		padding-right: 30px;
	}
	@media(min-width: 1280px) {
		flex-direction: row;
		justify-content: space-between;
		padding-left: 50px;
		padding-right: 50px;
	}
	@media(min-width: 1920px) {
		flex-direction: row;
		justify-content: space-between;
		padding-top: ${({paddingTop}) => paddingTop}px;
		padding-bottom: ${({paddingBottom}) => paddingBottom}px;
		padding-left: 100px;
		padding-right: 100px;
	}
`;

const HeaderDescriptionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	@media(min-width: 1280px) {
		width: 47%;
	}
	@media(min-width: 1920px) {
		flex-direction: column;
		justify-content: flex-start;
		padding-bottom: 100px;
	}
`;
const SectionTitle = styled.h5`
	text-transform: uppercase;
  	padding-bottom: 30px;
  	&.noSectionName {
  		padding: 0;
  	}
	@media(min-width: 1280px){
		padding-bottom: 100px;
	}
	@media(min-width: 1366px) {
		padding-bottom: 120px;
	}
`;

const ModuleHeader = styled.h3`
	position: relative;
	padding-bottom: 20px;
	@media(min-width: 1280px){
		padding-bottom: 60px;
	}
`;

const RedactorDescription = styled.div`
	span {  
	  &.black {
	      font-weight: ${({theme}) => theme.fontWeight.black};
	  }
	}
	a {
		color: ${({theme}) => theme.colors.pmmOrange};
		text-decoration: underline;
		cursor: pointer;	
	}
`;

const PictureWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-bottom: 60px;
	@media(min-width: 768px) {
		padding-bottom: 50px;
	}
	@media(min-width: 1280px) {
		padding-top: 121px;
        flex-direction: column;
		justify-content: flex-start;
	}
	@media(min-width: 1366px) {
		padding-top: 141px;
	}
	@media(min-width: 1920px) {
		width: 40%;
	}
	&.noImage {
		padding: 0;
	}
	
	&.small {
		@media(min-width: 1280px) {
			align-items: flex-end;
		    padding-top: 220px;
	        flex-direction: column;
			justify-content: flex-start;
		}
		@media(min-width: 1366px) {
			padding-top: 235px;
		}
		@media(min-width: 1920px) {
			justify-content: flex-start;
			padding-top: 260px;
		}
	}
`;

const Picture = styled.img`
	width: 264px;
	height: 264px;
    position: relative;
    &.noImage {
    	display: none;
    }
	@media(min-width: 768px) {
		width: 469px;
    	height: 475px;
	}	
	@media(min-width: 1366px) {
		width: 504px;
		height: 510px;
	}	
	@media(min-width: 1920px) {
		width: 670px;
		height: 670px;
	}
	
	&.small {
		width: calc(100% - 80px);
		margin: 0 40px;
		
		@media(min-width: 768px) {
			width: calc(100% - 260px);
			margin: 0 70px;
		}	
		@media(min-width: 1280px) {
			padding-top: 0;
			padding-bottom:0;
			align-items: center;
		}	
	
		@media(min-width: 1920px) {
			width: auto;
			height: auto;
		}
	}
`;

class WhyWeHelp extends Component {

	render() {
		const WhyWeHelp = this.props.data;
		const WhyWeHelpData = (WhyWeHelp.modulZdjeciePoPrawej && WhyWeHelp.modulZdjeciePoPrawej[0]) || '';
		const SectionName = (WhyWeHelpData && WhyWeHelpData.nazwaSekcji) || '';
		const Header = (WhyWeHelpData && WhyWeHelpData.naglowek) || '';
		const Description = (WhyWeHelpData && WhyWeHelpData.opis) || '';
		const BackgroundImage = (WhyWeHelpData && WhyWeHelpData.zdjecieWTle && WhyWeHelpData.zdjecieWTle[0] &&
			WhyWeHelpData.zdjecieWTle[0].url) || [];
		const GlobeImage = (WhyWeHelpData && WhyWeHelpData.zdjeciePoPrawej && WhyWeHelpData.zdjeciePoPrawej[0] &&
			WhyWeHelpData.zdjeciePoPrawej[0].url) || '';
		const smallPhoto = (WhyWeHelpData && WhyWeHelpData.zdjeciePoPrawejMale && WhyWeHelpData.zdjeciePoPrawejMale[0] &&
			WhyWeHelpData.zdjeciePoPrawejMale[0].url) || '';
		const paddingTop = (WhyWeHelpData && WhyWeHelpData.marginesGorny && WhyWeHelpData.marginesGorny[0] &&
			WhyWeHelpData.marginesGorny[0].title) || '';
		const paddingBottom = (WhyWeHelpData &&
			WhyWeHelpData.marginesDolny && WhyWeHelpData.marginesDolny[0] &&
			WhyWeHelpData.marginesDolny[0].title) || '';
		const background = (WhyWeHelpData &&
			WhyWeHelpData.marginesDolny && WhyWeHelpData.background[0] &&
			WhyWeHelpData.background[0].title) || '';

		const classNameIsBackground = (BackgroundImage.length > 0) ? "backgroundIsImage" : "backgroundIsColor";
		const classNameIsPhoto = (GlobeImage.length > 0) ? '' : "noImage";
		const classNameIsSmallPhoto = (smallPhoto.length > 0) ? '' : "noImage";
		const classNameIsSectionName = (SectionName.length > 0) ? '' : "noSectionName";

		return (
			<ModuleWrapper paddingTop={getPaddingTop(paddingTop)} paddingBottom={getPaddingBottom(paddingBottom)}
						   className={classNameIsBackground} background={background} bgImg={BackgroundImage}>
				<HeaderDescriptionWrapper>
					<SectionTitle className={classNameIsSectionName}>{SectionName}</SectionTitle>
					<ModuleHeader>{Header}</ModuleHeader>
					<RedactorDescription className="paragraph" dangerouslySetInnerHTML={getHtmlFromRedactorField(Description)}/>
				</HeaderDescriptionWrapper>
				{GlobeImage !== '' && <PictureWrapper className={classNameIsPhoto}>
					<Picture className={classNameIsPhoto} src={GlobeImage}/>
				</PictureWrapper>}

				{smallPhoto !== '' && <PictureWrapper className={classNameIsSmallPhoto + ' small'}>
					<Picture className={classNameIsSmallPhoto + ' small'} src={smallPhoto}/>
				</PictureWrapper>}

			</ModuleWrapper>
		)
	}
}

export default WhyWeHelp;
