import React, {Component} from 'react';
import styled from "styled-components";
import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton
} from "react-share";
import {BtnWrapperForBgPreload} from "./SingleNewsBanner";
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";


const ShareButtonsWrapper = styled.div`
	padding: ${({paddingTop}) => paddingTop}px 10px ${({paddingBottom}) => paddingBottom}px;
	background-color: ${({theme}) => theme.colors.pmmWhite};
	display: ${({isPrivacyPolicy}) => isPrivacyPolicy ? "none" : "block"};
	@media(min-width: 768px) {
		padding: ${({paddingTop}) => paddingTop}px 100px ${({paddingBottom}) => paddingBottom}px;
	}
	@media(min-width: 1280px) {
		padding: ${({paddingTop}) => paddingTop}px 350px ${({paddingBottom}) => paddingBottom}px;
	}
	@media(min-width: 1366px) {
		padding: ${({paddingTop}) => paddingTop}px 370px ${({paddingBottom}) => paddingBottom}px;
	}
	@media(min-width: 1920px) {
		padding: ${({paddingTop}) => paddingTop}px 530px ${({paddingBottom}) => paddingBottom}px;
	}
`

const Icon = styled.div`
	cursor: pointer;
	background-image: url(${props => props.back});
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
	position: relative;
	overflow: visible;
	transition: transform 0.15s ease-in-out;
	width: 40px;
	height: 40px;
	margin-right: 15px;
	&:hover {
		background-image: url(${props => props.backHover});
		transform: scale(1.113);
		&.edge {
			transform: scale(1);
		}
	}
	
	@media(min-width: 768px) {
		width: 60px;
		height: 60px;
		margin-right: 20px;
	}
`

const CopyWrapper = styled.div`
	position: relative;
`

export const CopyTooltip = styled.h6`
	position: absolute;
	//top: -80px;
	top: 0;
	left: -60px;
	height: 50px;
	border: 1px solid ${({theme}) => theme.colors.pmmTextOrange};
	padding: 15px;
	display: flex;
	flex-wrap: nowrap;
	white-space: nowrap;
	background-color:  ${({theme}) => theme.colors.pmmWhite};
	z-index: 20;
	box-shadow: 0 6px 8px 0 rgba(16,6,31,0.27);
	opacity: 0;
	transform: translateY(0);
	pointer-events: none;
	transition: .2s ease-in;
	&.visible {
		opacity: 1;	
		transform: translateY(-80px);
	}
	&.visible-payment {
		opacity: 1;	
		left: 0;
		transform: translateY(-30px);
	}
	@media(min-width: 375px) {
		left: 0;
	}
	@media(min-width: 768px) {
		padding: 15px 35px;
	}
`
const Header = styled.p`
	text-transform: uppercase;
	position: relative;
	display: table;
	z-index: 10;
	width: 100%;
	margin-bottom: 60px;
	
	span {
		background-color: ${({theme}) => theme.colors.pmmWhite};
		padding-right: 20px;
	}
	
	&:before {
		content: '';
		position: absolute;
		bottom: 3px;
		left: 0;
		height: 2px;
		width: 100%;
		z-index: -1;
		background-color: ${({theme}) => theme.colors.pmmDarkViolet};
	}
`

const BtnsWrapper = styled.div`
	display: flex;
`

class ShareBtns extends Component {
	state = {
		isOldBrowserEdge: false,
		isTooltipVisible: false
	};

	componentDidMount() {
		if (typeof window !== "undefined") {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({isOldBrowserEdge: isOldBrowser});
		}
	}

	copyToClipboard() {
		const dummy = document.createElement('input'),
			text = window.location.href;
		document.body.appendChild(dummy);
		dummy.value = text;
		dummy.select();
		document.execCommand('copy');
		document.body.removeChild(dummy);
		this.setState({isTooltipVisible: true})

		setTimeout(() => {
			this.setState({isTooltipVisible: false})
		}, 2500);
	}

	render() {
		const {data, isPrivacyPolicy} = this.props;
		const {isTooltipVisible} = this.state;
		const shareData = (data && data.aktualnoscPojedynczaPodzielSie) || [];
		let paddingTopType = ''
		let paddingBottomType = ''
		let header = ''
		let facebookIcon = ''
		let facebookIconHover = ''
		let twitterIcon = ''
		let twitterIconHover = ''
		let linkedinIcon = ''
		let linkedinIconHover = ''
		let copyUrlIcon = ''
		let copyUrlIconHover = ''
		let copyLinkInfo = ''

		shareData.forEach(item => {
			if (item.__typename === "Craft_aktualnoscPojedynczaPodzielSie_marginesy_BlockType") {

				paddingTopType = (item.marginesGorny && item.marginesGorny[0] && item.marginesGorny[0].title) || ""
				paddingBottomType = (item.marginesDolny && item.marginesDolny[0] && item.marginesDolny[0].title) || ""
			} else if (item.__typename === "Craft_aktualnoscPojedynczaPodzielSie_podzielSie_BlockType") {
				header = item.naglowek || ""
				facebookIcon = (item.ikonaFacebook && item.ikonaFacebook[0] && item.ikonaFacebook[0].url) || ""
				facebookIconHover = (item.ikonaFacebookPoNajechaniu && item.ikonaFacebookPoNajechaniu[0] && item.ikonaFacebookPoNajechaniu[0].url) || ""
				twitterIcon = (item.ikonaTwitter && item.ikonaTwitter[0] && item.ikonaTwitter[0].url) || ""
				twitterIconHover = (item.ikonaTwitterPoNajechaniu && item.ikonaTwitterPoNajechaniu[0] && item.ikonaTwitterPoNajechaniu[0].url) || ""
				linkedinIcon = (item.ikonaLinkedin && item.ikonaLinkedin[0] && item.ikonaLinkedin[0].url) || ""
				linkedinIconHover = (item.ikonaLinkedinPoNajechaniu && item.ikonaLinkedinPoNajechaniu[0] && item.ikonaLinkedinPoNajechaniu[0].url) || ""
				copyUrlIcon = (item.ikonaKopiujLink && item.ikonaKopiujLink[0] && item.ikonaKopiujLink[0].url) || ""
				copyUrlIconHover = (item.ikonaKopiujLinkPoNajechaniu && item.ikonaKopiujLinkPoNajechaniu[0] && item.ikonaKopiujLinkPoNajechaniu[0].url) || ""
				copyLinkInfo = item.kopiujLinkInformacja || ""
			}
		})

		let siteUrl = ''
		if (typeof window !== "undefined") {
			siteUrl = window.location.href || ""
		}
		const iconClassName = (this.state.isOldBrowserEdge) ? " edge" : "";
		return (
			<ShareButtonsWrapper isPrivacyPolicy={isPrivacyPolicy}
								 paddingTop={getPaddingTop(paddingTopType)}
								 paddingBottom={getPaddingBottom(paddingBottomType)}>
				<Header className="h8 black">
					<span>{header}</span>
				</Header>
				<BtnsWrapper>
					<BtnWrapperForBgPreload iconHoverBtn={facebookIconHover}/>
					<BtnWrapperForBgPreload iconHoverBtn={twitterIconHover}/>
					<BtnWrapperForBgPreload iconHoverBtn={linkedinIconHover}/>
					<BtnWrapperForBgPreload iconHoverBtn={copyUrlIconHover}/>
					<FacebookShareButton className="icon" url={siteUrl}><Icon className={iconClassName}
																			  back={facebookIcon}
																			  backHover={facebookIconHover}/></FacebookShareButton>
					<TwitterShareButton className="icon" url={siteUrl}><Icon className={iconClassName}
																			 back={twitterIcon}
																			 backHover={twitterIconHover}/></TwitterShareButton>
					<LinkedinShareButton className="icon" url={siteUrl}><Icon className={iconClassName}
																			  back={linkedinIcon}
																			  backHover={linkedinIconHover}/></LinkedinShareButton>
					<CopyWrapper>
						<Icon className={iconClassName} onClick={this.copyToClipboard.bind(this)} back={copyUrlIcon}
							  backHover={copyUrlIconHover}/>

						<CopyTooltip
							className={isTooltipVisible ? "visible semi-bold dark-blue" : "semi-bold dark-blue"}>{copyLinkInfo}</CopyTooltip>
					</CopyWrapper>
				</BtnsWrapper>
			</ShareButtonsWrapper>
		);
	}
}

export default ShareBtns;
