import React from 'react';
import Button from "../common/ButtonsSet";
import {sendEmail} from "../../utils/addEmailToFreshmail";
import {addNewPaymentToListPayPal} from "../../utils/addNewPaymentToListPayPal";
import escapeDiacritics from "../../utils/escapeDacritics";
import Cookies from 'js-cookie'

// const payPalLink = "https://www.paypal.com/cgi-bin/webscr";

const PayWithPayPal = ({supportedCountry, btnType, textBtn, disabled, isProccesingDataAllowed, checkAllRequiredCheckboxes, smallerPaddingClass, totalAmount, person, isRecurring, craftProjectId, seoName, currentSlideNumber}) => {

	const project = `Darowizna dla PMM${supportedCountry ? ' - ' + supportedCountry : ''}`;
	const projectName = (supportedCountry) ? supportedCountry : "Polska Misja Medyczna";
	const craftId = (supportedCountry === 'Akcja specjalna | Razem dla polskich lekarzy') ? "18132" : (supportedCountry === 'Polska Misja Medyczna') ? "0" : craftProjectId;
	const btnTextForSeo = (textBtn && textBtn.replace(/\s+/g, '-').toLowerCase()) || ""
	const btnTextForSeoWithoutDiacritics = escapeDiacritics(btnTextForSeo) + '-krok-' + currentSlideNumber
	let emailFreshmail = person.email;
	const freshmailData = new FormData();
	freshmailData.append("freshmailListId", "xms79axjk1");
	freshmailData.append("freshmailEmail", emailFreshmail);
	freshmailData.append("action", "freshmail/freshmail/ajax");

	const dataEntry = new FormData();
	dataEntry.append("firstName", person.firstName);
	dataEntry.append("lastName", person.lastName);
	dataEntry.append("email", person.email);
	dataEntry.append("project", projectName);
	dataEntry.append("title", project);
	dataEntry.append("provider", "2");
	dataEntry.append("currencyCode", "PLN");
	dataEntry.append("language", "pl");
	dataEntry.append("craftId", craftId);
	dataEntry.append("isRecurring", isRecurring);
	dataEntry.append("totalAmount", (totalAmount / 100).toString());

	const onSubmit = async (event) => {

		event.preventDefault();

		if(!disabled) {
			const totalAmountForCookie = (totalAmount / 100).toString();
			Cookies.set('valueForFbq', totalAmountForCookie);
			await addNewPaymentToListPayPal(dataEntry) ;
			if(isProccesingDataAllowed) {
				await sendEmail(freshmailData);
			}
		}
	};

	return (
		<form onSubmit={onSubmit} name={seoName + '-' + btnTextForSeoWithoutDiacritics}>
			<Button type="submit" smallerPaddingClass={smallerPaddingClass} onClick={checkAllRequiredCheckboxes}
			        btnType={btnType} textBtn={textBtn}/>
		</form>
	);
};

export default PayWithPayPal;
