import React from "react"
import styled from "styled-components"
import CookieConsent from "react-cookie-consent";
import CloseIcon from "../../images/cookies/close.svg"
import LogoPmmCookies from "../../images/cookies/logo-pmm-cookies.svg"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: green;
	.cookies-bar-style {
		> div {
			max-width: 100%;
			font-size: 14px;
			line-height: 20px;
			z-index: 1000;
			@media(min-width: 768px ) {
				padding: 0px 20px;
			}
		}
	.cookies-btn-style {
		cursor: pointer;
		border: none;
		display: flex;
		margin: 0 auto 25px;
		justify-content: center;
		position: fixed;
		padding: 0 !important;
		background: none !important;
		z-index: 99999999999;
		top: calc(50% + 135px);
		left: calc(50% - 110px);
		@media(min-width: 1280px) {
			top: calc(50% + 180px);
		}
		@media(min-width: 1920px) {
			top: calc(50% + 225px);
			left: calc(50% - 110px);
		}
		span{
			padding: 10px 30px !important;
			background-color: ${({theme}) => theme.colors.pmmOrange} !important;
			color: white !important; 
			border-radius: 60px !important;
			text-transform: uppercase; 
			font-size: 16px;
			font-family: ${({theme}) => theme.fontFamily.mainFont};
			font-weight: bold;
			text-align: center;
			z-index: 12000;
			min-height: 32px;
			margin: auto 0;
			transition: margin .25s ease-out, padding .25s ease-out !important;
			&:hover {
			    padding: 10px 40px!important;
			    margin-left: -10px; 
			}
			} 
		}
	}
`;

const WholeInfoWrapper = styled.div`
	position: fixed;
	top: calc(50% - 200px);
	left: 0;
	height: 400px;
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	border: 3px solid ${({theme}) => theme.colors.pmmOrange};
	padding: 30px;
	background-color: ${({theme}) => theme.colors.pmmWhite};
	z-index: 10000;
	@media(min-width: 768px) {
		margin: 0 auto;
		width: 510px;
		left: calc(50% - 255px);
	}
	@media(min-width: 1280px) {
		width: 600px;
		height: 500px;
		top: calc(50% - 250px);
		left: calc(50% - 300px);
	}
	@media(min-width: 1920px) {
		width: 774px;
		height: 590px;
		top: calc(50% - 295px);
		left: calc(50% - 387px);
	}	

`;

const LogoWrapper = styled.img`
	width: 183px;
	margin-bottom: 30px;
`;

const ClosingIcon = styled.img`
	position: absolute;
	top: 20px;
	right: 20px;
	width: 30px;
	cursor: pointer;
	z-index: 10001;
	@media(min-width: 1920px) {
		top: 30px;
		right: 20px;
		width: 36px;
	}
`;

const ScrollingTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	overflow-y: scroll;
	z-index: 1000;
	max-height: 200px;
	@media(min-width: 1280px) {
		max-height: 285px;
	}
	&::-webkit-scrollbar {
		width: 10px;
		height: 80px;
		padding-left: 30px;
	}
	&::-webkit-scrollbar-track {   
        opacity: 0;
        
	}
	&::-webkit-scrollbar-thumb {
	    -webkit-border-radius: 10px;
	    border-radius: 10px;
	    background: ${({theme}) => theme.colors.pmmOrange};
	}
	@media(min-width: 1920px) {
		line-height: 28px !important;
		max-height: 380px;
		&::-webkit-scrollbar {
			width: 10px;
			height: 80px;
			padding-left: 30px;
		}
		&::-webkit-scrollbar-track {   
    		opacity: 0;
    		
		}
		&::-webkit-scrollbar-thumb {
		    -webkit-border-radius: 10px;
		    border-radius: 10px;
		    background: ${({theme}) => theme.colors.pmmOrange};
		}
	}
`

const StyledBlend = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(16, 7, 31, 0.79);
	z-index: 10000;
`;
const InfoTitle = styled.p`
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
	margin-bottom: 20px;
	padding-right: 25px;
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	@media(min-width: 1920px) {
		margin-bottom: 35px;
	}
`;

const InfoText = styled.h5`
		max-height: 300px;
		padding-right: 40px;
		letter-spacing: 0;
		position: relative;
		z-index: 10000;
		@media(min-width: 1920px) {
			line-height: 28px !important;
			max-height: 315px;
		}
`;


// const CookieReadMoreLink = styled(Link)`
// 	text-decoration: underline;
// 	font-family: ${({theme}) => theme.fontFamily.mainFont} !important;
// 	font-weight: ${({theme}) => theme.fontWeight.regular} !important;
// 	color: ${({theme}) => theme.colors.pmmOrange};
// `

class CookieBar extends React.Component {

	simulateClick() {
		document.getElementById("cookie-button").click()
	}

	render()
	{
		const {cookiesBarData} = this.props;
		const cookiesTitle = (cookiesBarData && cookiesBarData.cookiesNaglowekDrogiUzytkowniku ) || "";
		const cookiesText = (cookiesBarData && cookiesBarData.cookiesTresc ) || "";
		const buttonText = (cookiesBarData && cookiesBarData.cookiesNapisNaButtonie ) || "";
		return(
			<ContentWrapper>
					<CookieConsent
						disableStyles={true}
									containerClasses="cookies-bar-style"
					               // onAccept={this.toggleModalVisibility.bind(this)}
					               buttonClasses="cookies-btn-style"
					               // buttonId="cookie-button"
					               buttonText={<span id="cookie-button">{buttonText}</span>}
					               cookieName="cookies-pmm"
					               expires={150}>
						<StyledBlend/>
						<WholeInfoWrapper className="cookies-bar-style">
						<LogoWrapper src={LogoPmmCookies}/>
						<ClosingIcon onClick={this.simulateClick.bind(this)} src={CloseIcon}/>
						<ScrollingTextWrapper>
							<InfoTitle className="h7">{cookiesTitle}</InfoTitle>
							<InfoText className="regular dark-blue" dangerouslySetInnerHTML={getHtmlFromRedactorField(cookiesText)}/>
						</ScrollingTextWrapper>
						</WholeInfoWrapper>
					</CookieConsent>
			</ContentWrapper>
		)
	}};

export default CookieBar;
