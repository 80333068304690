// import React, {Component} from 'react';
// import graph from 'fb-react-sdk';
// import styled from "styled-components"
// import moment from "moment";
// import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";
// import {StaticQuery, graphql} from "gatsby"
//
// const PostsWrapper = styled.div`
// 	display: flex;
// 	flex-direction: column;
// 	width: 100%;
// 	flex-wrap: wrap;
// 	background-color: ${({theme}) => theme.colors.pmmWhite};
// 	padding: ${({paddingTop}) => paddingTop}px 10px ${({paddingBottom}) => paddingBottom}px;
//
// 	@media(min-width: 768px){
// 		flex-direction: row;
// 		justify-content: space-between;
// 		padding: ${({paddingTop}) => paddingTop}px 35px ${({paddingBottom}) => paddingBottom}px;
// 	}
// 	@media(min-width: 1280px){
// 		padding: ${({paddingTop}) => paddingTop}px 55px ${({paddingBottom}) => paddingBottom}px;
// 	}
// 	@media(min-width: 1920px){
// 		padding: ${({paddingTop}) => paddingTop}px 100px ${({paddingBottom}) => paddingBottom}px;
// 	}
// `
//
// const PostWrapper = styled.a`
// 	width: 100%;
// 	height: 420px;
// 	margin-bottom: 45px;
// 	background-color: ${({theme}) => theme.colors.pmmGreyBackground};
// 	padding: 15px 25px 20px;
// 	text-decoration: none;
//
// 	@media(min-width: 768px){
// 		width: 330px;
// 	}
// 	@media(min-width: 1280px){
// 		width: 340px;
// 	}
// 	@media(min-width: 1366px){
// 		width: 385px;
// 	}
// `
//
// const TimeAndIconWrapper = styled.div`
// 	display: flex;
// 	width: 100%;
// 	justify-content: space-between;
// 	align-items: center;
// 	margin-bottom: 80px;
//
// 	&.small-margin{
// 		margin-bottom: 15px;
// 	}
// `
// const Time = styled.h5`
//
// `
// const Icon = styled.img`
// 	width: 40px;
// 	height: 40px;
// `
// const Photo = styled.div`
// 	margin: 0 -25px 20px;
// 	height: 200px;
// 	width: auto;
// 	background-image: url(${props => props.back});
// 	background-size: cover;
// 	background-position: center;
// `
//
// const Message = styled.h6`
// `
//
// const MessageContent = styled.div`
// 	overflow: hidden;
//    text-overflow: ellipsis;
//    display: -webkit-box;
//    -webkit-line-clamp: 4;
//    -webkit-box-orient: vertical;
//
//    &.no-image{
//    	-webkit-line-clamp: 9;
//    }
// `
//
// class SocialMediaPosts extends Component {
//
// 	state = {
// 		facebookPosts: [],
// 	}
//
// 	paddingTop = '';
// 	paddingBottom = '';
//
// 	componentDidMount() {
// 		const {data} = this.props
// 		const moduleData = (data && data.modulSocialMedia) || []
//
// 		let pageId = '';
// 		let accessToken = '';
// 		let facebookPostsArray = []
//
// 		moduleData.forEach(item => {
// 			if (item.__typename === "Craft_modulSocialMedia_facebook_BlockType") {
// 				pageId = item.idStrony || ""
// 				accessToken = item.tokenDostepu || ''
// 			}
// 		})
//
// 		graph.get(pageId + '/posts?fields=created_time,full_picture,message, permalink_url', {
// 			access_token: accessToken,
// 			limit: 12
// 		}, function (err, res) {
// 			const resultData = (res && res.data) || []
// 			facebookPostsArray = [...facebookPostsArray, ...resultData]
// 			this.setState({facebookPosts: facebookPostsArray})
// 		}.bind(this));
// 	}
//
// 	render() {
// 		const {data, twitterData} = this.props
// 		const moduleData = (data && data.modulSocialMedia) || []
// 		let paddingTop = '';
// 		let paddingBottom = '';
// 		let fbIcon = ''
// 		let twitterIcon = ''
//
//
// 		moduleData.forEach(item => {
// 			if (item.__typename === "Craft_modulSocialMedia_marginesy_BlockType") {
// 				paddingTop = (item.marginesGorny && item.marginesGorny[0] && item.marginesGorny[0].title) || ''
// 				paddingBottom = (item.marginesDolny && item.marginesDolny[0] && item.marginesDolny[0].title) || ''
// 			} else if (item.__typename === "Craft_modulSocialMedia_facebook_BlockType") {
// 				fbIcon = (item.ikona && item.ikona && item.ikona[0] && item.ikona[0].url) || ''
// 			} else if (item.__typename === "Craft_modulSocialMedia_twitter_BlockType") {
// 				twitterIcon = (item.ikona && item.ikona && item.ikona[0] && item.ikona[0].url) || ''
// 			}
// 		});
//
// 		let facebookPostsToShow = []
//
// 		this.state.facebookPosts.forEach(item => {
// 			const createdTime = moment(new Date(item.created_time))
//
// 			const daysAgo = moment(moment(new Date(item.created_time))).fromNow()
//
// 			facebookPostsToShow.push({
// 				'message': item.message,
// 				'photo': item.full_picture || '',
// 				'createdTime': createdTime,
// 				'timeAgo': daysAgo,
// 				'link': item.permalink_url,
// 				'source': 'facebook'
// 			})
// 		})
//
// 		const twitterPosts = (twitterData && twitterData.allTwitterStatusesUserTimelineAllStatuses && twitterData.allTwitterStatusesUserTimelineAllStatuses.edges) || ""
// 		let twitterPostsToShow = []
//
// 		twitterPosts.forEach(item => {
// 			const createdTime =  moment(new Date(item.node.created_at))
// 			const daysAgo = moment(moment(new Date(item.node.created_at))).fromNow()
// 			const statusId = item.node.id_str || ""
// 			const linkToTweet = (statusId && `https://twitter.com/PMM_org/status/${statusId}`) || ""
//
// 			twitterPostsToShow.push({
// 				'message': item.node.full_text,
// 				'photo': (item.node.entities && item.node.entities.media && item.node.entities.media[0] && item.node.entities.media[0].media_url) || "",
// 				'createdTime': createdTime,
// 				'timeAgo': daysAgo,
// 				'link': linkToTweet,
// 				'source': 'twitter'
// 			})
// 		})
//
// 		const twitterAndFacebookPostsArray = facebookPostsToShow.concat(twitterPostsToShow)
// 		const sortedPosts = twitterAndFacebookPostsArray.sort((a, b) => (a.createdTime.diff(b.createdTime)))
// 		const sortedPostsWithLimit = sortedPosts.reverse().slice(0,12)
//
// 		return (
// 			<PostsWrapper paddingTop={getPaddingTop(paddingTop)}
// 						  paddingBottom={getPaddingBottom(paddingBottom)}>
// 					{sortedPostsWithLimit.map((item, key) =>
// 					<PostWrapper key={key} href={item.link} target={item.link ? "_blank" : ""} rel="noopener noreferrer">
// 						 <TimeAndIconWrapper className={item.photo !== "" ? 'small-margin' : ''}>
// 							<Time className="medium darkest-blue">{item.timeAgo}</Time>
// 							{item.source === 'facebook' && <Icon src={fbIcon}/>}
// 							{item.source === 'twitter' && <Icon src={twitterIcon}/>}
// 						</TimeAndIconWrapper>
// 						{item.photo !== '' && <Photo back={item.photo}/>}
// 						<Message className="regular line-height-28 darkest-blue">
// 							<MessageContent className={item.photo !== "" ? '' : 'no-image'}>
// 								{item.message}
// 							</MessageContent>
// 						</Message>
// 					</PostWrapper>
//
// 					)}
// 			</PostsWrapper>
// 		);
// 	}
// }
//
// export default props => (
// 	<StaticQuery
// 		query={graphql`
//         query {
// 			allTwitterStatusesUserTimelineAllStatuses {
// 				edges {
// 					node {
// 						full_text
// 						created_at
// 						id_str
// 						entities {
// 							media {
// 								media_url
// 							}
// 						}
// 						}
// 					}
// 				}
// 			}
//     `}
// 		render={data => <SocialMediaPosts twitterData={data} {...props} />}
// 	/>
// )

import React from 'react';

const SocialMediaPosts = () => {
	return <div></div>;
};

export default SocialMediaPosts;
