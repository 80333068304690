import React, {Component} from 'react';
import styled from "styled-components"
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";
import Button from "../common/ButtonsSet";
import getSlug from "../../utils/getSlug";
import SingleMention from "./SingleMention";

const MentionsAboutPmmWrapper = styled.div`
  background-color: ${({theme}) => theme.colors.pmmWhite};
  padding: ${({paddingTop}) => paddingTop}px 10px ${({paddingBottom}) => paddingBottom}px;

  @media(min-width: 768px) {
    padding: ${({paddingTop}) => paddingTop}px 30px ${({paddingBottom}) => paddingBottom}px;
  }
  @media(min-width: 1280px) {
    padding: ${({paddingTop}) => paddingTop}px 50px ${({paddingBottom}) => paddingBottom}px;
  }
  @media(min-width: 1920px) {
    padding: ${({paddingTop}) => paddingTop}px 100px ${({paddingBottom}) => paddingBottom}px;
  }
`
const AllMentionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  margin-bottom: 65px;
  flex-direction: column;

  @media(min-width: 768px) {
    width: 100%;
    flex-direction: row;
  }
`
const FirstLineMentionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  margin-bottom: 65px;
  flex-direction: column;

  @media(min-width: 768px) {
    width: 100%;
    flex-direction: row;
  }
`

const SecondLineMentionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  background-color: ${({theme}) => theme.colors.pmmGreyBackground};
  margin: 0 -10px;
  padding: 55px 10px 35px;

  @media(min-width: 768px) {
    margin: 0 -30px;
    padding: 55px 30px 35px;
  }
  @media(min-width: 1280px) {
    margin: 0 -50px;
    padding: 55px 50px 35px;
  }
  @media(min-width: 1920px) {
    margin: 0 -100px;
    padding: 55px 100px 35px;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 95px;
`


class MentionsAboutPmm extends Component {

	render() {

		const {data, wzmiankiWMediach, mentionsGlobal, activeSite} = this.props
		const moduleData = (data && data.modulNajnowszeWzmiankiOPmm) || []
		const moduleAllDataVisible = (data && data.modulWszystkieWzmiankiWMediach) || []

		let automaticMentionsShow = ''
		let allMentionsShow = ''
		let paddingTop = ''
		let paddingBottom = ''
		let selectedMentions = []

		moduleData.forEach(item => {
			if (item.__typename === "Craft_modulNajnowszeWzmiankiOPmm_wzmianki_BlockType") {
				selectedMentions = item.wybierzWzmianke || []
				automaticMentionsShow = item.wyswietlAutomatycznie || ""
			} else if (item.__typename === "Craft_modulNajnowszeWzmiankiOPmm_marginesy_BlockType") {
				paddingTop = (item.marginesGorny && item.marginesGorny[0] && item.marginesGorny[0].title) || ""
				paddingBottom = (item.marginesDolny && item.marginesDolny[0] && item.marginesDolny[0].title) || ""
			} else if (item.__typename === "Craft_modulWszystkieWzmiankiWMediach_wzmianki_BlockType") {
				allMentionsShow = item.wyswietlWszystkieWzmianki || ""
			}
		});

		moduleAllDataVisible.forEach(item => {
			if (item.__typename === "Craft_modulWszystkieWzmiankiWMediach_wzmianki_BlockType") {
				allMentionsShow = item.wyswietlWszystkieWzmianki || ""
			} else if (item.__typename === "Craft_modulWszystkieWzmiankiWMediach_marginesy_BlockType") {
				paddingTop = (item.marginesGorny && item.marginesGorny[0] && item.marginesGorny[0].title) || ""
				paddingBottom = (item.marginesDolny && item.marginesDolny[0] && item.marginesDolny[0].title) || ""
			}
		});

		const globalData = (mentionsGlobal && mentionsGlobal[0]) || ""
		const showOnPageData = (globalData.wzmiankaPrzyciskZobaczNaStronie && globalData.wzmiankaPrzyciskZobaczNaStronie[0]) || ""
		const showOnPageBtn = {
			"text": showOnPageData.napis || "",
			"icon": (showOnPageData.ikona && showOnPageData.ikona[0] && showOnPageData.ikona[0].url) || "",
		}

		const audioData = (globalData.wzmiankaPrzyciskAudycja && globalData.wzmiankaPrzyciskAudycja[0]) || ""
		const audioBtn = {
			"text": audioData.napis || "",
			"icon": (audioData.ikona && audioData.ikona[0] && audioData.ikona[0].url) || "",
		}

		const videoData = (globalData.wzmiankaPrzyciskFilm && globalData.wzmiankaPrzyciskFilm[0]) || ""
		const videoBtn = {
			"text": videoData.napis || "",
			"icon": (videoData.ikona && videoData.ikona[0] && videoData.ikona[0].url) || "",
		}

		const fileData = (globalData.wzmiankaPrzyciskPlik && globalData.wzmiankaPrzyciskPlik[0]) || ""
		const fileBtn = {
			"text": fileData.napis || "",
			"icon": (fileData.ikona && fileData.ikona[0] && fileData.ikona[0].url) || "",
		}
		const showaAllData = (globalData.wzmiankaPrzyciskZobaczWszystkie && globalData.wzmiankaPrzyciskZobaczWszystkie[0]) || ""
		const showAllBtn = {
			"text": showaAllData.przyciskTekst || "",
			"type": (showaAllData.przyciskRodzaj && showaAllData.przyciskRodzaj[0] && showaAllData.przyciskRodzaj[0].title) || "",
			"to": (showaAllData.przyciskWpis && showaAllData.przyciskWpis[0] && showaAllData.przyciskWpis[0].slug) || "",
		}

		let allMentionsData = []

		if((automaticMentionsShow || allMentionsShow) && wzmiankiWMediach ) {
			wzmiankiWMediach.forEach((item) => {
				const singleData = (item.wzmianka && item.wzmianka[0]) || ""
				allMentionsData.push({
						"date": item.postDate || "",
						"title": singleData.tytul || "",
						"desctiption": singleData.opis || "",
						"logo": (singleData.logo && singleData.logo[0] && singleData.logo[0].url) || "",
						"link": singleData.linkPrzekierowanieDoStrony || "",
						"film": (singleData.filmWybierz && singleData.filmWybierz[0] && singleData.filmWybierz[0].url) || "",
						"audio": (singleData.audycjaWybierz && singleData.audycjaWybierz[0] && singleData.audycjaWybierz[0].url) || "",
						"file": (singleData.plikWybierz && singleData.plikWybierz[0] && singleData.plikWybierz[0].url) || "",
					}
				)
			})
		}

		if(!automaticMentionsShow && !allMentionsShow) {
			selectedMentions.forEach((item) => {
				const singleData = (item.wzmianka && item.wzmianka[0]) || ""
				allMentionsData.push({
						"date": item.postDate || "",
						"title": singleData.tytul || "",
						"desctiption": singleData.opis || "",
						"logo": (singleData.logo && singleData.logo[0] && singleData.logo[0].url) || "",
						"link": singleData.linkPrzekierowanieDoStrony || "",
						"film": (singleData.filmWybierz && singleData.filmWybierz[0] && singleData.filmWybierz[0].url) || "",
						"audio": (singleData.audycjaWybierz && singleData.audycjaWybierz[0] && singleData.audycjaWybierz[0].url) || "",
						"file": (singleData.plikWybierz && singleData.plikWybierz[0] && singleData.plikWybierz[0].url) || "",
					}
				)
			})
		}

		const allMentionsDataaReverse = allMentionsData.reverse()
		const numberOfMentions = allMentionsDataaReverse.length
		const mentionsDataToShowOnWhiteBg = allMentionsDataaReverse.slice(0, 4);
		const mentionsDataToShowOnGrayBg = allMentionsDataaReverse.slice(4, 8);

		return (
			<MentionsAboutPmmWrapper paddingTop={getPaddingTop(paddingTop)}
									 paddingBottom={getPaddingBottom(paddingBottom)}>

				{allMentionsShow === '' &&
				<>
					<FirstLineMentionsWrapper>
						{mentionsDataToShowOnWhiteBg.map((item, key) =>
							<SingleMention item={item} key={key} audioBtn={audioBtn} videoBtn={videoBtn}
										   fileBtn={fileBtn} showOnPageBtn={showOnPageBtn}/>
						)}
					</FirstLineMentionsWrapper>
					{numberOfMentions > 4 &&
					<SecondLineMentionsWrapper>
						{mentionsDataToShowOnGrayBg.map((item, key) =>
							<SingleMention item={item} key={key} audioBtn={audioBtn} videoBtn={videoBtn}
										   fileBtn={fileBtn}
										   showOnPageBtn={showOnPageBtn}/>
						)}
						{numberOfMentions >= 8 && <ButtonWrapper>
							<Button to={`/${getSlug(showAllBtn.to, activeSite)}`} btnType={showAllBtn.type}
									textBtn={showAllBtn.text}/>
						</ButtonWrapper>}
					</SecondLineMentionsWrapper>
					}
				</>
				}
				{allMentionsShow &&
				<AllMentionsWrapper>
					{allMentionsDataaReverse.map((item, key) =>
						<SingleMention additionalClass='margin-bottom' item={item} key={key} audioBtn={audioBtn}
									   videoBtn={videoBtn}
									   fileBtn={fileBtn} showOnPageBtn={showOnPageBtn}/>
					)}
				</AllMentionsWrapper>
				}
			</MentionsAboutPmmWrapper>
		);
	}
}

export default MentionsAboutPmm
