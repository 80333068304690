import React, {Component} from 'react'
import styled from "styled-components"
import CheckboxInfo from "./CheckboxInfo";
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";
import {sendData} from "../../utils/sendEmailMethod";
import Popup from "../common/popup/Popup";
import Button from "../common/ButtonsSet";
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";
import WholeInfoProcessingPersonalData from "../common/WholeInfoProcessingPersonalData";

const NewsletterWrapper = styled.div`
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	position: relative;
	background-color: ${({theme}) => theme.colors.pmmWhite};
	padding-top: 0;
	@media(min-width: 768px) {
		flex-direction: row;
	}
	@media(min-width: 1280px) {
		top: 0;
		padding-bottom: 0;
		margin-bottom: 0;
		height: 700px;
		justify-content: space-between;		
	}
	@media(min-width: 1920px) {
		height: 1000px;	
	}
	@media(min-width: 2560px) {
		height: 1700px;
	}
`;

const PictureWrapper = styled.div`
	display: none;
	@media(min-width: 768px) {
		display: flex;
		height: 580px;
		flex-direction: row;
		justify-content: flex-start;
		position: relative;
		background: none;
		overflow: hidden;
		width: 40%;
	}
	@media(min-width: 1280px) {
		width: 50%;
		height: 1000px;
	}
	@media(min-width: 2560px) {
		height: 1700px;
	}
`;

const NewsletterPicture = styled.div`
	@media(min-width: 768px){
	    position: absolute;
		height: 100%;
		width:100%;
		background-size: cover;
		background-position: center;
		background-image: url( ${({bgImg}) => bgImg});
	}
`;

const TextContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	background: ${({theme}) => theme.colors.pmmWhite};
	//height: 100%;
	position: relative;
	padding: 0 10px;
	@media(min-width: 768px) {
		width: 60%;
		height: 100%;
		padding: 135px 35px 0;
	}
	@media(min-width: 1280px) {
		width: 50%;
		padding: 0 60px;
	}
	
	form{
		@media(min-width: 1280px) {
			padding-bottom:50px;
			margin-bottom: -50px;
		}
	}
`;

const NewsletterHeader = styled.div`
	position: relative;
	color: ${({theme}) => theme.colors.pmmDarkBlue};
	padding-bottom: 30px;
`;

const NewsletterInfo = styled.div`
	display: none;

	@media(min-width: 768px) {
		display: flex;
		padding-bottom: 30px;
		position: relative;
		width: 100%;
		color: ${({theme}) => theme.colors.pmmDarkBlue};
		font-size: ${({theme}) => theme.fontSize.f12};
		line-height: ${({theme}) => theme.lineHeight.line1};
		font-weight: ${({theme}) => theme.fontWeight.medium};
	}
	@media(min-width: 1280px) {
		width: 100%;
		padding-bottom: 60px;
		font-size: ${({theme}) => theme.fontSize.f4};
		line-height: ${({theme}) => theme.lineHeight.line15};
    }
    @media(min-width: 1600px) {
    	width: 60%;
    }
	@media(min-width: 1700px){
		width: 70%;
	}
    @media(min-width: 1920px) {
    padding-bottom: 80px;
    	width: 75%;
    }
`;
const InputAndButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	position: relative;
	@media(min-width: 768px) {
		flex-direction: column;
		justify-content: space-between;
	}
	@media(min-width: 1280px) {
  		width: 100%;
		flex-direction: row;
		justify-content: space-between;
  	}
  	@media(min-width: 2000px) {
		justify-content: flex-start;
	}
	form {
		display: flex;
		flex-direction: column;
		width: 100%;
		@media(min-width: 768px) {
			flex-direction: row;
		}
	}
`;

const InputWrapper = styled.span`
	position: relative;
	width: 100%;
	margin-right: 10px;
	margin-top: 20px;
	@media(min-width: 1024px) {
		width: 75%;
	}
	@media(min-width: 1280px) {
	    width: 62%;
	    margin-top: 0;
		margin-right: 30px;
 	}
	@media(min-width: 1366px){
		width: 65%;	
		margin-top: 20px;
	}
	@media(min-width: 1600px){
		width: 65%;	
		margin-top: 20px;
	}
	@media(min-width: 1700px){
		width: 70%;
	}
	@media(min-width: 1920px){
		width: 74%;
	}
	&:after {
		content: '${({customPlaceholder}) => customPlaceholder}';
		position: absolute;
		left: 17px;
		top: 18px;
		color: ${({theme}) => theme.colors.pmmPlaceHolderGrey};
		cursor: pointer;
		transition: top .25s ease-in-out, font-size .25s ease-in-out;
		@media(min-width: 1920px){
			top: 24px;
			//left: 20px;
		//top: 26px;
		}
	}
	&.active::after {
		top: -25px;
		font-size: 14px !important;
		@media(min-width: 768px) {
			font-size: 15px !important;
		}
    }  
`;

const ButtonWrapper = styled.div`
	height: 120px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-top: 10px;
	@media(min-width: 768px) {
		height: 70px;
		//display: block;
		//margin-left: auto;
		//margin-right: 0;
	}
	@media(min-width: 1280px) {
	//flex-direction: column;
	//	justify-content: center;
		flex-direction: row;
		justify-content: flex-start;	
  	}
  	@media(min-width: 1366px) {
        flex-direction: column;
		justify-content: center;
  	}	
  	@media(min-width: 1920px) {
        margin-top: 20px;
  	}	
`;

const StyledInput = styled.input`
	border: ${({theme}) => "1px solid" + theme.colors.pmmInputBorderDark};
	background-color: ${({theme}) => "2px solid" + theme.colors.pmmWhite};
	outline: none;
	display: block;
	padding: 10px 15px;
	width: 100%;
	position: relative;
	height: 55px;
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	color: #707070;
	font-size: 18px;
	cursor: pointer;
	transition: border .25s ease-in-out, background-color .25s ease-in-out;
	box-shadow: none;
	@media(min-width: 1920px) {
	    padding: 10px 15px;
		width: 100%;
		height: 70px;
  	}
	&.active {
		border: ${({theme}) => "1px solid" + theme.colors.pmmInputBorderOrange};	
	}
	&.wrong-email {
		border: ${({theme}) => "3px solid" + theme.colors.pmmInputBorderOrange};
		background-color: ${({theme}) => theme.colors.pmmInputErrorOrange}
	}
  
`;

const IncorrectEmailInfo = styled.p`
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	font-weight: ${({theme}) => theme.fontWeight.regular};
	color: ${({theme}) => theme.colors.pmmOrange};
	font-size: 15px;
	padding-left: 16px;
	position: absolute;
	top: -23px;
	display: ${({showIncorrectMailInfo}) => showIncorrectMailInfo === true ? "flex" : "none"};
	@media(min-width: 1280px) {
	    font-size: 15px;
		padding-left: 16px;
		position: absolute;
		top: -23px;
    }
`;

const CheckboxInfoVisibilityWrapper = styled.div`
	display: block;
	margin-top: 10px;
	@media(min-width: 1280px) {
		display: none;
	}
	@media(min-width: 1366px) {
	}
	&.big-screen {
		display: none;

		@media(min-width: 1280px) {
			display: block;
		}
		@media(min-width: 1366px) {
		}
	}
`

class Newsletter extends Component {
	state = {
		isInputActiveAndCheckboxVisible: false,
		email: "",
		privacyPolicyAgree: false,
		emailInvalid: false,
		emailInvalidMsg: "",
		isPopupVisible: false,
		isEmailCorrect: false,
		showIncorrectEmailInfo: false,
		isWholeInfoShown: false,
		isOldBrowserEdge: false,
	};

	initialState = {
		isInputActiveAndCheckboxVisible: false,
		email: "",
		emailInvalidMsg: "",
		showIncorrectEmailInfo: false,
	};

	triggerInputAnimationAndShowCheckbox() {
		if (this.state.email === "") {
			this.setState({isInputActiveAndCheckboxVisible: !this.state.isInputActiveAndCheckboxVisible})
		} else {
			this.setState({isInputActiveAndCheckboxVisible: this.state.isInputActiveAndCheckboxVisible})
		}
	}

	markCheckbox() {
		this.setState({privacyPolicyAgree: !this.state.privacyPolicyAgree})
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickIfOutsideSelectWindow, false)
		if (typeof window !== "undefined") {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({isOldBrowserEdge: isOldBrowser});
		}
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickIfOutsideSelectWindow, false)
	}

	handleClickIfOutsideSelectWindow = (e) => {
		if (this.node.contains(e.target)) {
			return;
		} else {
			this.setState({isWholeInfoShown: false})
		}
		this.setState(this.initialState);
	};

	handleInputChange = event => {
		const target = event.target;
		const value = target.type === "email" ? target.value : "";
		this.setState({email: value})
	};

	togglePopup() {
		this.setState({isPopupVisible: !this.state.isPopupVisible})
	};

	toggleWholeInfoShown() {
		this.setState({isWholeInfoShown: !this.state.isWholeInfoShown})
	};

	handleSubmit = event => {
		event.preventDefault();
		try {
			this.sendEmail();
		} catch (err) {
			console.log(err);
		}
	};

	async sendEmail() {
		const data = new FormData();
		data.append("freshmailListId", "bbmpe04a95");
		data.append("freshmailEmail", this.state.email);
		data.append("action", "freshmail/freshmail/ajax");

		const rawResponse = await sendData(data);
		const responseData = await rawResponse.json();
		const fbq = window.fbq;

		if (responseData.status === "OK") {
			this.setState({isEmailCorrect: true});
			if (fbq) {
				fbq('trackCustom', 'AddToNewsletter', {
					value: this.state.email,
					info: 'dolaczono ze strony glownej',
				});
			}
			this.togglePopup();
		} else if (responseData.status === "ERROR") {
			if (responseData.errors[0].code === 1304) {
				this.setState({isEmailCorrect: false})
				this.togglePopup();
			} else if (responseData.errors[0].code === 0) {
				this.setState({showIncorrectEmailInfo: true})
			} else {
				this.setState(this.initialState);
			}
		}
	}

	render() {
		const newsletter = this.props.data;
		const {newsletterModalData, processingPersonalData} = this.props;
		const {isWholeInfoShown, isOldBrowserEdge} = this.state;
		const newsletterData = (newsletter.modulNewsletter && newsletter.modulNewsletter[0]) || '';
		const newsletterTitle = (newsletterData && newsletterData.naglowek) || '';
		const newsletterDescription = (newsletterData && newsletterData.opis) || '';
		const newsletterPicture = (newsletterData && newsletterData.zdjecie && newsletterData.zdjecie[0] &&
			newsletterData.zdjecie[0].url) || [];
		let newsletterPlaceholder = (newsletterData && newsletterData.placeholder) || '';
		const newsletterBtnText = (newsletterData && newsletterData.przyciskNapis) || '';
		const newsletterTypeBtn = (newsletterData && newsletterData.przyciskRodzaj && newsletterData.przyciskRodzaj[0] && newsletterData.przyciskRodzaj[0].title) || ""
		const newsletterCheckboxText = (newsletterData && newsletterData.checkboxTekst) || '';
		// const newsletterCheckboxLink = (newsletterData && newsletterData.checkboxLink) || '';
		const newsletterCheckboxLinkText = (newsletterData && newsletterData.checkboxLinkTekst) || '';
		const newsletterEmailValidationInfo = (newsletterModalData && newsletterModalData.newsletterEmailValidationInfo) || '';

		const processingData = (processingPersonalData && processingPersonalData[0] && processingPersonalData[0].informacjaOPrzetwarzaniuDanychOsobowych
			&& processingPersonalData[0].informacjaOPrzetwarzaniuDanychOsobowych[0]) || '';
		const isInputActiveAndCheckboxVisible = this.state.isInputActiveAndCheckboxVisible;
		const classNameIfInputActive = (isInputActiveAndCheckboxVisible) ? "active" : "";
		if (!this.state.isInputActiveAndCheckboxVisible && this.state.email !== "") {
			this.setState({isInputActiveAndCheckboxVisible: true})
		}
		if (this.state.showIncorrectEmailInfo) {
			newsletterPlaceholder = "";
		}
		let isSubscriberAdded = this.state.isEmailCorrect;
		const showIncorrectMailInfo = this.state.showIncorrectEmailInfo;
		const classNameIfIncorrectMail = (showIncorrectMailInfo) ? "wrong-email" : "";

		const paddingTopType = (newsletter && newsletter.modulNewsletter[0].marginesGorny && newsletter.modulNewsletter[0].marginesGorny[0] &&
			newsletter.modulNewsletter[0].marginesGorny[0].title) || '';
		const paddingBottomType = (newsletter &&
			newsletter.modulNewsletter[0].marginesDolny && newsletter.modulNewsletter[0].marginesDolny[0] &&
			newsletter.modulNewsletter[0].marginesDolny[0].title) || '';

		return (
			<NewsletterWrapper paddingTop={getPaddingTop(paddingTopType)}
							   paddingBottom={getPaddingBottom(paddingBottomType)}>
				<PictureWrapper>
					<NewsletterPicture bgImg={newsletterPicture}/>
				</PictureWrapper>
				<TextContentWrapper>
					<NewsletterHeader className="header-h3"
									  dangerouslySetInnerHTML={getHtmlFromRedactorField(newsletterTitle)}/>
					<NewsletterInfo className="paragraph text-in-border"
									dangerouslySetInnerHTML={getHtmlFromRedactorField(newsletterDescription)}/>
					<form id="newsletter-form"
						  onSubmit={this.handleSubmit.bind(this)}
						  ref={node => this.node = node}>
						<InputAndButtonWrapper>
							<InputWrapper onClick={this.triggerInputAnimationAndShowCheckbox.bind(this)}
										  className={`${classNameIfInputActive} header-h5 grey`}
										  customPlaceholder={newsletterPlaceholder}>
								<StyledInput type="email"
											 className={classNameIfInputActive + ' ' + classNameIfIncorrectMail}
											 value={this.state.email}
											 onChange={this.handleInputChange}
											 required
								/>
								<IncorrectEmailInfo
									showIncorrectMailInfo={showIncorrectMailInfo}>{newsletterEmailValidationInfo}</IncorrectEmailInfo>
							</InputWrapper>
							<CheckboxInfoVisibilityWrapper>
								<CheckboxInfo isInputActiveAndCheckboxVisible={isInputActiveAndCheckboxVisible}
											  isCheckboxClicked={this.markCheckbox.bind(this)}
											  required
											  isFooter={false}
											  isSpanClicked={this.toggleWholeInfoShown.bind(this)}
											  checked={this.state.privacyPolicyAgree}
											  paragraphText={newsletterCheckboxText}
											  spanText={newsletterCheckboxLinkText}
								/>
							</CheckboxInfoVisibilityWrapper>
							<ButtonWrapper>
								<Button isOldBrowserEdge={isOldBrowserEdge} form="newsletter-form" type="submit"
										btnType={newsletterTypeBtn} textBtn={newsletterBtnText}/>
							</ButtonWrapper>
							{isWholeInfoShown && isInputActiveAndCheckboxVisible &&
							<WholeInfoProcessingPersonalData isFooter={false} isContact={false} isPayment={false}
															 processingData={processingData}
															 isSpanClicked={this.toggleWholeInfoShown.bind(this)}/>}
						</InputAndButtonWrapper>
						<CheckboxInfoVisibilityWrapper className="big-screen">
							<CheckboxInfo isInputActiveAndCheckboxVisible={isInputActiveAndCheckboxVisible}
										  isCheckboxClicked={this.markCheckbox.bind(this)}
										  required
										  isFooter={false}
										  isSpanClicked={this.toggleWholeInfoShown.bind(this)}
										  checked={this.state.privacyPolicyAgree}
										  paragraphText={newsletterCheckboxText}
										  spanText={newsletterCheckboxLinkText}
							/>
						</CheckboxInfoVisibilityWrapper>
					</form>
					<Popup newsletterModalData={newsletterModalData}
						   isVisible={this.state.isPopupVisible}
						   hide={this.togglePopup.bind(this)}
						   isSubscriberAdded={isSubscriberAdded}/>
				</TextContentWrapper>
			</NewsletterWrapper>
		)
	}
}

export default Newsletter;
