import React from "react"
import HorizontalColumnChart from "./horizontalColumnChart/HorizontalColumnChart";
import VerticalColumnsChart from "./verticalColumnsChart/VerticalColumnsChart";
import PercentageTenElementsChart from "./percentageTenElementsChart/PercentageTenElementsChart";
import NumberInCircleChart from "./numberInCircleChart/NumberInCircleChart";
import ImgChart from "./imgChart/ImgChart";

const ChartsExtendedContent = (props) => {
	const { data } = props || []

	return (
			data.map((block, i) => {
			switch (block.__typename) {
				case "Craft_wykresy_kolumnyPionowe_Entry":
					return <VerticalColumnsChart key={i} data={block}/>
				case "Craft_wykresy_kolumnaPozioma_Entry":
					return <HorizontalColumnChart key={i} data={block}/>
				case "Craft_wykresy_dziesiecElementow_Entry":
					return <PercentageTenElementsChart key={i} data={block}/>
				case "Craft_wykresy_liczbaWKolku_Entry":
					return <NumberInCircleChart key={i} data={block}/>
					case "Craft_wykresy_obrazek_Entry":
						return <ImgChart key={i} data={block}/>
				default:
					return <h1 key={i}>Brak obsługi dla wykresu {block.__typename}</h1>
			}
		})
	)
}

export default ChartsExtendedContent
