import * as React from 'react'
import { Range } from 'react-range'
import styled from 'styled-components'

const RangeWrapper = styled.div`
  margin: 40px 0;
	.marker {
		visibility: hidden;

		&:first-of-type,
		&:nth-of-type(${({ visibleMarkersStep }) => visibleMarkersStep }),
		&:nth-of-type(${({ visibleMarkersStep }) => 2 * visibleMarkersStep }),
		&:nth-of-type(${({ visibleMarkersStep }) => 3 * visibleMarkersStep}),
		&:nth-of-type(${({ markersNumber }) => markersNumber }) {
			visibility: visible;
			background-color: #251447 !important;
		}
	}
`

const ValueInfo = styled.h4`
	position: absolute;
	bottom: -40px;
	left: 50%;
	color: ${({ theme }) => theme.colors.pmmOrange};
	font-size: 18px;
	transform: translateX(-50%);
	font-weight: ${({ theme }) => theme.fontWeight.black};
	width:fit-content;
	display: flex;
	flex-wrap: nowrap;
	min-width: 100px;
	justify-content: center;
`

class CalculatorExtendedRange extends React.Component {

	render () {
		const { rangeFactor, handleSumChange } = this.props
		const maxValue = 40
		const markersNumber = 40
		const visibleMarkersStep = Math.ceil(markersNumber / 4)

		return (
			<RangeWrapper
				style={{
					display: 'flex',
					justifyContent: 'center',
					flexWrap: 'wrap',
					width: '100%',
				}}
				visibleMarkersStep={visibleMarkersStep}
				markersNumber={markersNumber}
			>
				<Range
					step={1}
					min={1}
					max={maxValue}
					values={rangeFactor}
					onChange={values => handleSumChange(values[0])}
					renderMark={({ props, children }) => (
						<div
							{...props}
							className="marker"
							style={{
								...props.style,
								height: '15px',
								width: '15px',
								backgroundColor: '#251447',
								borderRadius: '100%',
							}}
						>
							{children}
						</div>
					)}
					renderTrack={({ props, children }) => (
						<div
							{...props}
							style={{
								...props.style,
								height: '5px',
								width: '100%',
								backgroundColor: '#251447',
							}}
						>
							{children}
						</div>
					)}
					renderThumb={({ props }) => (
						<div
							{...props}
							style={{
								...props.style,
								height: '25px',
								width: '25px',
								borderRadius: '100%',
								backgroundColor: '#FD4123',
								outline: 'none',
								position: 'relative',
							}}
						>
							<ValueInfo id="output">
								{`${rangeFactor[0]}`}
							</ValueInfo>
						</div>
					)}
				/>

			</RangeWrapper>
		)
	}
}

export default CalculatorExtendedRange
