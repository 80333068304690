import React, {Component} from 'react'
import styled from "styled-components"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";
import setBackgroundColor from "../../utils/setBackgroundColor";
import setFontColor from "../../utils/setFontColor";
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";

const ModuleWrapper = styled.div`
	display: block;
	width: 100%;
	padding-right: 12px;
	padding-left: 12px;
	padding-top: ${({paddingTop}) => paddingTop}px;
  	padding-bottom: ${({paddingBottom}) => paddingBottom}px;
  	background-color: ${({background, theme}) => setBackgroundColor(background, theme)};
	color: ${({background, theme}) => setFontColor(background, theme)};
	@media(min-width: 768px) {
		padding-right: 30px;
		padding-left: 30px;
	}
	@media(min-width: 1280px) {
		padding-right: 50px;
		padding-left: 50px;
	}
`;

const BorderWithContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	padding: 40px 15px;
	border: 2px solid ${({theme}) => theme.colors.pmmOrange};
	@media(min-width: 768px) {
		padding: 55px 35px;
	}
	@media(min-width: 1280px) {
		padding: 45px 35px;
		width: 50%;
	}
	@media(min-width: 1920px) {
		padding: 40px 45px 60px;
	}
	@media(min-width: 2560px) {
		padding: 90px 45px 130px;
	}
`;

const Header = styled.h3`
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	@media(min-width: 1920px) {
		padding-bottom: 20px;
	}
`;

const Description = styled.div`
	padding: 20px 0 0;
`;

class TextInBorder extends Component {

	render() {
		const { data } = this.props
		const textInBorder = (data && data.modulTekstWRamce && data.modulTekstWRamce[0]) || ""

		const header = textInBorder.naglowek || "";
		const description = textInBorder.opis || "";
		const paddingTopType = (textInBorder.marginesGorny && textInBorder.marginesGorny[0] &&
			textInBorder.marginesGorny[0].title) || '';
		const paddingBottomType = (textInBorder.marginesDolny && textInBorder.marginesDolny[0] &&
			textInBorder.marginesDolny[0].title) || '';
		const background = (textInBorder.background &&
			textInBorder.background[0] && textInBorder.background[0] &&
			textInBorder.background[0].title) || '';


		return (
			<ModuleWrapper background={background} paddingTop={getPaddingTop(paddingTopType)} paddingBottom={getPaddingBottom(paddingBottomType)}>
				<BorderWithContentWrapper>
					<Header>{header}</Header>
					<Description className="paragraph text-in-border" dangerouslySetInnerHTML={getHtmlFromRedactorField(description)}/>
				</BorderWithContentWrapper>
			</ModuleWrapper>
		)
	}
}

export default TextInBorder;
