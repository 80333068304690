import React, { Component } from 'react';
import styled from 'styled-components';
// import setBackgroundColor from "../../utils/setBackgroundColor";
// import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";
import getSlug from '../../utils/getSlug';
import Button from '../common/ButtonsSet';
// import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";

const SingleCardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	//min-height: 400px;
	width: 100%;
	//height: 200px;
	background-color: ${({ theme }) => theme.colors.pmmWhite};
	padding: 0 0 50px;
	@media (min-width: 1920px) {
		//min-height: 700px;
		padding: 0 0 100px;
	}
`;

const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
`;

const Title = styled.span`
	font-size: 28px;
	line-height: 32px;
	color: ${({ theme }) => theme.colors.pmmWhite};
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	background-color: ${({ theme }) => theme.colors.pmmDarkestBlue};
	padding: 10px 20px;
	width: 100%;
	@media (min-width: 768px) {
		width: unset;
	}
	@media (min-width: 1280px) {
		font-size: 50px;
		line-height: 75px;
		padding: 0 20px;
	}
`;

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	border: 2px solid ${({ theme }) => theme.colors.pmmOrange};
	padding-bottom: 20px;
	@media (min-width: 1280px) {
		flex-direction: row;
		justify-content: space-between;
		height: 450px;
	}
`;

const PictureWrapper = styled.div`
	//margin: 40px auto 0;
	width: calc(100% + 4px);
	//height: calc(100% + 4px);
	height: 300px;
	margin-top: -2px;
	margin-left: -2px;
	background-position: center;
	background-size: cover;
	background-image: url(${({ bgImg }) => bgImg});
	position: relative;
	@media (min-width: 1280px) {
		height: 450px;
		//height: calc(100% + 4px);
		width: 55%;
	}
`;

const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	height: 100%;
	width: 100%;
	margin: 0 auto;
	padding: 5% 0;
	@media (min-width: 768px) {
		flex-direction: row;
		justify-content: space-between;
	}
	@media (min-width: 1280px) {
		flex-direction: column;
		justify-content: space-between;
		padding: 5% 0 5% 80px;
		width: 30%;
	}
`;

const SingleInfoWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 100%;
	padding: 10px 0;
	@media (min-width: 768px) {
		width: 33%;
	}
	@media (min-width: 1280px) {
		width: 100%;
	}
`;
const PictogramWrapper = styled.div`
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: center;
	padding: 0 20px;
`;
const Pictogram = styled.img`
	width: 32px;
	height: 32px;
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const Placeholder = styled.p`
	color: ${({ theme }) => theme.colors.pmmDarkestBlue};
	text-transform: uppercase;
`;

const Info = styled.p`
	font-size: 18px;
	line-height: 30px;
	color: ${({ theme }) => theme.colors.pmmDarkestBlue};
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	@media (min-width: 768px) {
		font-size: 15px;
	}
	@media (min-width: 1024px) {
		font-size: 20px;
	}
	@media (min-width: 1280px) {
		font-size: 24px;
		line-height: 40px;
	}
`;

const PriceWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	width: 100%;
	@media (min-width: 1280px) {
		width: 15%;
	}
`;

const Price = styled.h4`
	font-weight: ${({ theme }) => theme.fontWeight.black};
	line-height: 36px;
	text-align: center;
	padding-bottom: 15px;
`;

const ButtonWrapper = styled.div``;

class SingleCourseCard extends Component {
	state = {
		isOldBrowserEdge: false,
	};

	componentDidMount() {
		if (typeof window !== 'undefined') {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({ isOldBrowserEdge: isOldBrowser });
		}
	}

	render() {
		const { data, activeSite, globalCourses } = this.props;
		const { isOldBrowserEdge } = this.state;
		const title = data.title;
		const image = data.image;
		const date = data.date;
		const place = data.place;
		const seatsLeft = data.seatsLeft;
		const allSeats = data.allSeats;
		const price = data.price;
		const slug = data.slug;

		const datePlaceholder = globalCourses.szkolenieWizytowkaTerminPodpis || '';
		const placePlaceholder = globalCourses.szkolenieWizytowkaMiejscePodpis || '';
		const seatsLeftPlaceholder = globalCourses.szkolenieWizytowkaPozostaloPodpis || '';
		const datePictogram =
			(globalCourses.szkolenieWizytowkaTerminPiktogram &&
				globalCourses.szkolenieWizytowkaTerminPiktogram[0] &&
				globalCourses.szkolenieWizytowkaTerminPiktogram[0].url) ||
			'';
		const placePictogram =
			(globalCourses.szkolenieWizytowkaMiejscePiktogram &&
				globalCourses.szkolenieWizytowkaMiejscePiktogram[0] &&
				globalCourses.szkolenieWizytowkaMiejscePiktogram[0].url) ||
			'';
		const seatsLeftPictogram =
			(globalCourses.szkolenieWizytowkaPozostaloPiktogram &&
				globalCourses.szkolenieWizytowkaPozostaloPiktogram[0] &&
				globalCourses.szkolenieWizytowkaPozostaloPiktogram[0].url) ||
			'';
		const textBtn = globalCourses.szkolenieWizytowkaPrzycisknapis || '';
		const typeBtn =
			(globalCourses.szkolenieWizytowkaPrzyciskRodzaj &&
				globalCourses.szkolenieWizytowkaPrzyciskRodzaj[0] &&
				globalCourses.szkolenieWizytowkaPrzyciskRodzaj[0].title) ||
			'';
		const toBtn = slug;

		return (
			<SingleCardWrapper>
				<TitleWrapper>
					<Title>{title}</Title>
				</TitleWrapper>
				<ContentWrapper>
					<PictureWrapper bgImg={image} />
					<InfoWrapper>
						<SingleInfoWrapper>
							<PictogramWrapper>
								<Pictogram src={datePictogram} />
							</PictogramWrapper>
							<TextContainer>
								<Placeholder className="h8 black">{datePlaceholder}</Placeholder>
								<Info>{date}</Info>
							</TextContainer>
						</SingleInfoWrapper>
						<SingleInfoWrapper>
							<PictogramWrapper>
								<Pictogram src={placePictogram} />
							</PictogramWrapper>
							<TextContainer>
								<Placeholder className="h8 black">{placePlaceholder}</Placeholder>
								<Info>{place}</Info>
							</TextContainer>
						</SingleInfoWrapper>
						<SingleInfoWrapper>
							<PictogramWrapper>
								<Pictogram src={seatsLeftPictogram} />
							</PictogramWrapper>
							<TextContainer>
								<Placeholder className="h8 black">{seatsLeftPlaceholder}</Placeholder>
								<Info>
									{seatsLeft} / {allSeats}
								</Info>
							</TextContainer>
						</SingleInfoWrapper>
					</InfoWrapper>
					<PriceWrapper>
						<Price>{price}</Price>
						<ButtonWrapper>
							<Button
								isOldBrowserEdge={isOldBrowserEdge}
								btnType={typeBtn}
								to={`/${getSlug(toBtn, activeSite)}`}
								textBtn={textBtn}
							/>
						</ButtonWrapper>
					</PriceWrapper>
				</ContentWrapper>
			</SingleCardWrapper>
		);
	}
}

export default SingleCourseCard;
