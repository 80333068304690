import React, {Component} from 'react';
import styled from "styled-components";

const PhotoStyled = styled.div`
    width: 100%;
	@media(min-width: 768px) {
		margin: 0 100px;
		width: calc(100% - 200px);
	}
	@media(min-width: 1280px) {
		margin: 0 350px;
		width: calc(100% - 700px);
	}
	@media(min-width: 1366px) {
		margin: 0 370px;
		width: calc(100% - 740px);
	}
	@media(min-width: 1920px) {
		margin: 0 530px;
		width: calc(100% - 1060px);
	}
`;

const SingleImg = styled.img`
	width: 100%;
`;

class ContentPhoto extends Component {
	state = {
		isMobile: false,
	};

	constructor(props) {
		super(props)
		this.actionsOnResize = this.actionsOnResize.bind(this);
	}

	actionsOnResize() {
		if (typeof window !== "undefined") {
			if (window.innerWidth < 768) {
				this.setState({
					isMobile: true,
				})
			} else if (window.innerWidth >= 768) {
				this.setState({isMobile: false})
			}
		}
	}

	componentDidMount()
	{
		this.actionsOnResize();
		window.addEventListener("resize", this.actionsOnResize);
	}

	componentWillUnmount()
	{
		window.removeEventListener("resize", this.actionsOnResize);
	}


	render() {
		const {data} = this.props
		const {isMobile} = this.state;
		const photoUrl = (data && data.zdjecie && data.zdjecie[0] && data.zdjecie[0].url ) || ""
		const photoUrlMobile = (data && data.zdjecieMobile && data.zdjecieMobile[0] && data.zdjecieMobile[0].url ) || ""
		const finalSrc = (!isMobile) ? photoUrl : (photoUrlMobile !== "") ? photoUrlMobile : photoUrl;

		return (
			<PhotoStyled>
				<SingleImg src={finalSrc}/>
			</PhotoStyled>
		);
	}
}


export default ContentPhoto;
