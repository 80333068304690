import React, {Component} from 'react';
import styled from "styled-components";

const SelectTargetTabWrapper = styled.div`
	text-align: center;
	padding: ${({tabNumber}) => tabNumber === 1 ? 0 : "35px 15px 30px"}
`
const Title = styled.div`
	text-transform: uppercase;
	font-weight: ${({theme}) => theme.fontWeight.bold};
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	font-size: 18px;
	line-height: 34px;
	margin-bottom: 15px;
`
const Description = styled.div`
	font-weight: ${({theme}) => theme.fontWeight.medium};
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	font-size: 14px;
	line-height: 28px;
	margin-bottom: 50px;
	text-align: center;
	
	@media(min-width: 768px){
		text-align: left;
	}
	@media(min-width: 1280px){
		font-size: 16px;
	}
	@media(min-width: 1920px){
		font-size: 20px;
	}
`
const Pictogram = styled.img`
	width: 130px;
	height: auto;
`

class FirstTabInfoMobile extends Component {
	render() {
		const { globalData, tabNumber} = this.props
		const selectTargetTabData = (globalData.wplatyWybierzCelMobile && globalData.wplatyWybierzCelMobile[0]) || ""
		const selectTargetTabTitle = (selectTargetTabData.tytul) || ""
		const selectTargetTabDescription = (selectTargetTabData.opis) || ""
		const selectTargetTabPictogram = (selectTargetTabData.piktogram && selectTargetTabData.piktogram[0] && selectTargetTabData.piktogram[0].url) || ""

		return (
			<>
				<SelectTargetTabWrapper tabNumber={tabNumber}>
					<Title>{selectTargetTabTitle}</Title>
					<Description className="paragraph text-in-border">{selectTargetTabDescription}</Description>
					<Pictogram src={selectTargetTabPictogram}/>
				</SelectTargetTabWrapper>

			</>
		);
	}
}

export default FirstTabInfoMobile;
