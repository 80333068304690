import React, {Component} from "react"
import styled from "styled-components"
import SingleStory from "./SingleStory";

const HistoryOfPeopleWithScrollWrapper = styled.div`
	position: relative;
	touch-action: manipulation;
	overflow-x: visible !important;
`

const ScrollbarPromptWrapper = styled.div`
	color: ${({theme}) => theme.colors.pmmWhite};
	position: relative;
	padding-bottom: 30px;
	display: flex;
	justify-content: center;
	
	@media(min-width: 768px){
		padding-bottom: 100px;
	}
	@media(min-width: 1280px){
		padding-bottom: 110px;
	}
	@media(min-width: 1366px){
	}
	@media(min-width: 1920px){
		padding-bottom: 150px;
	}
`

// const ScrollbarPrompt = styled.h5`
// 	width: auto;
// 	position: relative;
// 	display: none;
// 	letter-spacing: 0 !important;
// 	&:before {
// 		content: '';
// 		position: absolute;
// 		top: 9px;
// 		left: -40px;
// 		background-image: url(${ArrowSVG});
// 		background-repeat: no-repeat;
// 		width: 20px;
// 		height: 8px;
// 	}
// 	&:after {
// 		content: '';
// 		position: absolute;
// 		top: 9px;
// 		right: -40px;
// 		background-image: url(${ArrowSVG});
// 		background-repeat: no-repeat;
// 		width: 20px;
// 		height: 8px;
// 		transform: rotate(180deg);
// 	}
// 	@media(min-width: 768px){
// 		display: flex;
// 	}
// `

const CustomScrollbar = styled.div`
	position: absolute;
	z-index: 10;
	bottom: 120px;
	height: 10px;
	width: 300px;
	background-color: ${({theme}) => theme.colors.pmmLightViolet};
	border-radius: 5px;
	left: 0;
	right: 0;
	margin: 0 auto;
	visibility: hidden;

	@media(min-width: 768px){
		visibility: visible;
		bottom: 70px;
	}
	@media(min-width: 1280px){
		width: 800px;
		bottom: 80px;
	}
	@media(min-width: 1366px){
	}
	@media(min-width: 1920px){
		bottom: 120px;
	}
`

const ScrollbarThumb = styled.div`
	z-index: 11;
	background-color: ${({theme}) => theme.colors.pmmOrange};
	width: ${props => props.width + 'px'};
	height: 100%;
	border-radius: 5px;
`

const StoriesOfPeopleWrapper = styled.div`
	display: flex;
	margin-left: 10px;
	margin-bottom: 0;
	position: relative;
	cursor: none;
	
	@media(min-width: 768px){
		margin-bottom: 40px;
		margin-left: 35px;
	}
	@media(min-width: 1280px){
		width: 800px;
		margin-bottom: 60px;
		margin-left: 50px;
	}
	@media(min-width: 1025px){
		width: auto;
		display: inline-flex;
	}
	@media(min-width: 1920px){
		margin-left: 100px;
		margin-bottom: 70px;
	}
`

const CustomCursor = styled.div`
	display: none;
	@media(min-width: 768px){
		display: none;
		position: absolute;
		z-index: 1;
		top: 0;
		left: calc(100vw - 190px);
		background-color: ${({theme}) => theme.colors.pmmWhite};
		color: ${({theme}) => theme.colors.pmmOrange};
		font-size:  ${({theme}) => theme.fontSize.f9};
		font-weight:  ${({theme}) => theme.fontWeight.black};
		letter-spacing: 1.5px;
		text-transform: uppercase;
		justify-content: center;
		align-items: center;
		width: 130px;
		height: 130px;
		border-radius: 50%;
		cursor: none;
		pointer-events: none;
		transform: translate(-50%, -50%);
		transition: width .25s ease-in-out, height .25s ease-in-out;
	
		&:before {
			content: '';
			position: absolute;
			left: 0;
			width: 0;
			height: 0;
			border-top: 7px solid transparent;
			border-bottom: 7px solid transparent;
			border-right: 7px solid transparent;
			z-index: 0;
			transition: .25s ease-in-out;
		}
	
		&:after{
			content: '';
			position: absolute;
			right: 0;
			width: 0;
			height: 0;
			border-top: 7px solid transparent;
			border-bottom: 7px solid transparent;
			border-left: 7px solid transparent;
			transition: .25s ease-in-out;
			z-index: 0;
		}
	
		&.mouse-down {
			width: 80px;
			height: 80px;
			
			span {
				display: none;
			}
	
			&:before {
				transform: translateX(-30px);
				border-right: 7px solid ${({theme}) => theme.colors.pmmWhite};;
			}
			&:after {
				transform: translateX(30px);
				border-left: 7px solid ${({theme}) => theme.colors.pmmWhite};;
			}
		}
	}
	
`

class StoriesOfPeople extends Component {

	state = {
		scrollThumbWidth: 0,
		wasContentScrolled: false
	}
	scrollbarWidth;
	scrollContentWidth;
	slidesNumber;
	slideWidth;
	mouseDown = false;
	mouseDownOnSlider = false;
	scrollContentTransition = 0;
	scrollThumbTransition = 0;
	startScrollThumbTransition = 0;
	clickedPointXPosition = 0;
	centerPointOfScrollThumb = 0;
	scrollbarElement;
	scrollContentElement;
	scrollThumbElement;
	customCursor;

	constructor(props) {
		super(props)
		this.setInitSizes = this.setInitSizes.bind(this);
		this.handleMouseMove = this.handleMouseMove.bind(this);
		this.handleMouseUp = this.handleMouseUp.bind(this);
		this.handleTouchMove = this.handleTouchMove.bind(this);
		this.handleTouchEnd = this.handleTouchEnd.bind(this);

	}

	setScrollThumbWidth() {
		this.scrollbarWidth = document.getElementById("scrollbar").offsetWidth
		this.setState({scrollThumbWidth: this.scrollbarWidth / this.slidesNumber})
	}

	setSlidePlusMarginWidth() {
		this.slideWidth = this.scrollContentWidth / this.slidesNumber
	}

	setInitSizes() {
		this.scrollContentWidth = this.scrollContentElement.scrollWidth
		this.scrollbarElement = document.getElementById("scrollbar").getBoundingClientRect();
		this.setScrollThumbWidth();
		this.setSlidePlusMarginWidth();
		this.scrollContentElement.style.transform = "translateX(0)";
		this.scrollThumbElement.style.transform = "translateX(0)";
		this.scrollThumbTransition = this.state.scrollThumbWidth;
		this.startScrollThumbTransition = this.scrollThumbTransition;
	}

	componentDidMount() {
		this.scrollContentElement = document.getElementById("scroll-content")
		this.scrollThumbElement = document.getElementById("scrollbar-thumb")
		this.customCursor = document.getElementById("custom-cursor")
		this.setInitSizes();
		window.addEventListener('resize', this.setInitSizes);
		window.addEventListener('mousemove', this.handleMouseMove);
		window.addEventListener('touchmove', this.handleTouchMove);
		window.addEventListener('click', this.handleMouseUp);
		window.addEventListener('touchend', this.handleTouchEnd);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.setInitSizes);
		window.removeEventListener('mousemove', this.handleMouseMove);
		window.removeEventListener('touchmove', this.handleTouchMove);
		window.removeEventListener('click', this.handleMouseUp);
		window.removeEventListener('touchend', this.handleTouchEnd);
	}

	transitionScrollThumb(slideToScrollTo) {
		const {scrollThumbWidth} = this.state
		slideToScrollTo < 1 ? this.scrollThumbTransition = 0 : this.scrollThumbTransition = (slideToScrollTo - 1) * scrollThumbWidth
		this.scrollThumbElement.style.transform = "translateX(" + this.scrollThumbTransition + "px)";
		this.scrollThumbElement.style.transition = "linear 200ms";

	}

	transitionScrollContent(slideToScrollTo) {
		this.scrollContentTransition = this.slideWidth * (slideToScrollTo - 1)
		this.scrollContentElement.style.transform = "translateX(-" + this.scrollContentTransition + "px)";
		this.scrollContentElement.style.transition = "linear 200ms";
	}

	handleScrollClick(e) {
		e.preventDefault()
		if (e.target.id !== 'scrollbar-thumb') {
			const {scrollThumbWidth} = this.state
			const clickedPointOnScrollbar = e.clientX - this.scrollbarElement.left
			const slideToScrollTo = Math.ceil(clickedPointOnScrollbar / scrollThumbWidth)
			this.transitionScrollThumb(slideToScrollTo);
			this.transitionScrollContent(slideToScrollTo)
		}
	}

	handleMouseDown(e) {
		e.preventDefault()
		if (e.target.id === 'scrollbar-thumb') {
			this.clickedPointXPosition = e.clientX
			this.startScrollThumbTransition = this.scrollThumbTransition
			this.mouseDown = true
		}
	}

	handleTouchStart(e) {
		if (e.target.id === 'scrollbar-thumb') {
			this.clickedPointXPosition = e.touches[0].screenX
			this.startScrollThumbTransition = this.scrollThumbTransition
			this.mouseDown = true
		}
	}

	goToNearestSlide() {
		if (this.mouseDown || this.mouseDownOnSlider) {
			const {scrollThumbWidth} = this.state
			this.mouseDown = false
			this.mouseDownOnSlider = false
			this.centerPointOfScrollThumb = this.scrollThumbElement.getBoundingClientRect().left + (Math.floor(scrollThumbWidth / 2)) - this.scrollbarElement.left
			const slideToScrollTo = Math.ceil(this.centerPointOfScrollThumb / scrollThumbWidth)
			this.scrollThumbTransition = (slideToScrollTo - 1) * scrollThumbWidth
			this.transitionScrollThumb(slideToScrollTo, scrollThumbWidth);
			this.transitionScrollContent(slideToScrollTo)
		}
	}

	handleMouseUp() {
		this.goToNearestSlide()
	}

	handleTouchEnd() {
		this.goToNearestSlide()
	}

	realTimeTransition(transition) {
		this.mouseDownOnSlider ? this.scrollContentElement.style.transform = "translateX(" + transition + "px)" : this.scrollContentElement.style.transform = "translateX(-" + transition + "px)"
		this.scrollThumbElement.style.transform = "translateX(" + this.scrollThumbTransition + "px)";
	}

	translationOnMouseMove(e) {
		const {scrollThumbWidth} = this.state
		const scrollThumbTransition = e.clientX - this.clickedPointXPosition
		const maxTransition = this.scrollbarWidth - scrollThumbWidth
		this.scrollThumbTransition = this.startScrollThumbTransition + scrollThumbTransition;
		this.scrollThumbTransition = this.scrollThumbTransition < 0 ? 0 : this.scrollThumbTransition > maxTransition ? maxTransition : this.scrollThumbTransition;
		const percentageScrollThumbShift = ((this.scrollThumbTransition) / this.scrollbarWidth).toFixed(2)
		this.newScrollContentTransition = Math.round(this.scrollContentWidth * percentageScrollThumbShift)
		this.realTimeTransition(this.newScrollContentTransition);
	}

	translationOnTouchMove(e) {
		e.preventDefault()
		const {scrollThumbWidth} = this.state
		const scrollThumbTransition = e.touches[0].screenX - this.clickedPointXPosition
		const maxTransition = this.scrollbarWidth - scrollThumbWidth
		this.scrollThumbTransition = this.startScrollThumbTransition + scrollThumbTransition;
		this.scrollThumbTransition = this.scrollThumbTransition < 0 ? 0 : this.scrollThumbTransition > maxTransition ? maxTransition : this.scrollThumbTransition;
		const percentageScrollThumbShift = ((this.scrollThumbTransition) / this.scrollbarWidth).toFixed(2)
		this.newScrollContentTransition = Math.round(this.scrollContentWidth * percentageScrollThumbShift)
		this.realTimeTransition(this.newScrollContentTransition);
	}

	handleTransition(maxTransition) {
		const {scrollThumbWidth} = this.state
		this.newScrollContentTransition = this.newScrollContentTransition > 0 ? 0 : Math.abs(this.newScrollContentTransition) > maxTransition ? -maxTransition : this.newScrollContentTransition;
		const percentageScrollContentShift = -(this.newScrollContentTransition / (this.scrollContentWidth - this.slideWidth)).toFixed(2)
		this.scrollThumbTransition = Math.round((this.scrollbarWidth - scrollThumbWidth) * percentageScrollContentShift)
		this.realTimeTransition(this.newScrollContentTransition);
	}

	translationOnMouseMoveOnSlider(e) {
		this.setState({wasContentScrolled: true})
		this.newScrollContentTransition = e.clientX - this.clickedPointXPosition - this.scrollContentTransition
		const maxTransition = this.scrollContentWidth - this.slideWidth
		this.handleTransition(maxTransition)
	}

	translationOnTouchMoveOnSlider(e) {
		this.setState({wasContentScrolled: true})
		this.newScrollContentTransition = e.touches[0].screenX - this.clickedPointXPosition - this.scrollContentTransition
		const maxTransition = this.scrollContentWidth - this.slideWidth
		this.handleTransition(maxTransition)
	}

	handleMouseMove(e) {
		e.preventDefault()
		const {scrollThumbWidth} = this.state

		this.centerPointOfScrollThumb = this.scrollThumbElement.getBoundingClientRect().left + (Math.floor(scrollThumbWidth / 2)) - this.scrollbarElement.left

		if (this.mouseDown) {
			this.scrollThumbElement.style.transition = "none";
			this.scrollContentElement.style.transition = "none";
			this.translationOnMouseMove(e)
		} else if (this.mouseDownOnSlider) {
			this.scrollThumbElement.style.transition = "none";
			this.scrollContentElement.style.transition = "none";
			this.translationOnMouseMoveOnSlider(e)
		}
	}

	handleTouchMove(e) {
		e.preventDefault()
		const {scrollThumbWidth} = this.state

		this.centerPointOfScrollThumb = this.scrollThumbElement.getBoundingClientRect().left + (Math.floor(scrollThumbWidth / 2)) - this.scrollbarElement.left

		if (this.mouseDown) {
			this.scrollThumbElement.style.transition = "none";
			this.scrollContentElement.style.transition = "none";
			this.translationOnTouchMove(e)
		} else if (this.mouseDownOnSlider) {
			this.scrollThumbElement.style.transition = "none";
			this.scrollContentElement.style.transition = "none";
			this.translationOnTouchMoveOnSlider(e)
		}
	}

	handleMouseDownOnSlider(e) {
		e.preventDefault()
		this.setState({wasContentScrolled: false})
		this.clickedPointXPosition = e.clientX
		this.mouseDownOnSlider = true;
		this.customCursor.classList.add('mouse-down');
	}

	handleMouseUpOnSlider(e) {
		e.preventDefault()
		this.customCursor.classList.remove('mouse-down');
	}

	handleMouseMoveOnSlider(e) {
		this.customCursor.style.display = 'flex';
		const rect = e.currentTarget.getBoundingClientRect(),
			offsetX = e.clientX - rect.left,
			offsetY = e.clientY - rect.top;
		this.customCursor.style.top = offsetY + 'px';
		this.customCursor.style.left = offsetX + 'px';
	}

	handleMouseOutOnSlider(e) {
		this.customCursor.style.display = 'none';
		// back to right
		// this.customCursor.style.top = '50%';
		// this.customCursor.style.left =  'calc(' + this.scrollContentTransition + 'px + 100vw - 200px)';
	}

	handleTouchStartOnSlider(e) {
		e.preventDefault()
		this.setState({wasContentScrolled: false})
		this.clickedPointXPosition = e.touches[0].screenX
		this.mouseDownOnSlider = true;
	}

	render() {
		const {data, activeSite} = this.props;
		// const scrollPropt = (translations && translations.tlumaczenieScrollPodpowiedz) || ""
		const {scrollThumbWidth} = this.state;
		const storiesOfPeopleData = (data && data.historieOsobWybierzOsobe) || "'"
		let storiesArray = []

		storiesOfPeopleData.forEach((story) => {
			const storyCard = (story.historiaOsobyWizytowka && story.historiaOsobyWizytowka[0]) || ""
			storiesArray.push(
				{
					title: storyCard.tytul || "",
					description: storyCard.krotkiOpis || "",
					photoUrl: (storyCard.zdjecie && storyCard.zdjecie[0] && storyCard.zdjecie[0].url) || "",
					project: (story.historiaOsobyWybierzProjekt && story.historiaOsobyWybierzProjekt[0] && story.historiaOsobyWybierzProjekt[0].slug) || "",
					anchor: (story.historiaOsobyWybierzModulZKotwica && story.historiaOsobyWybierzModulZKotwica[0] && story.historiaOsobyWybierzModulZKotwica[0].modulNazwaSekcji && story.historiaOsobyWybierzModulZKotwica[0].modulNazwaSekcji[0] && story.historiaOsobyWybierzModulZKotwica[0].modulNazwaSekcji[0].kotwica) || "",
				})
		})

		this.slidesNumber = storiesArray.length;

		return (
			<HistoryOfPeopleWithScrollWrapper id="gallery-content">

				<StoriesOfPeopleWrapper id="scroll-content" onMouseMove={this.handleMouseMoveOnSlider.bind(this)}
										onMouseOut={this.handleMouseOutOnSlider.bind(this)}
										onMouseDown={this.handleMouseDownOnSlider.bind(this)}
										onMouseUp={this.handleMouseUpOnSlider.bind(this)}
										onTouchStart={this.handleTouchStartOnSlider.bind(this)}>
					{/*<StoriesOfPeopleWrapper id="scroll-content" onMouseDown={this.handleMouseDownOnSlider.bind(this)} onMouseUp={this.handleMouseUpOnSlider.bind(this)} onTouchStart={this.handleTouchStartOnSlider.bind(this)}>*/}
					<CustomCursor id="custom-cursor">
						<span>
						{ activeSite === "pl" ? 'przesuń' : 'move' }
						</span>
					</CustomCursor>
					{storiesArray.map((story, key) =>
						<SingleStory wasContentScrolled={this.state.wasContentScrolled} key={'single-story-' + key}
									 indexOfSingleStory={key} photoUrl={story.photoUrl}
									 storyTitle={story.title} storyDescription={story.description}
									 storyProject={story.project} storyAnchor={story.anchor} activeSite={activeSite}/>
					)}
				</StoriesOfPeopleWrapper>
				<ScrollbarPromptWrapper>
					{/*<ScrollbarPrompt>*/}
					{/*	{scrollPropt}*/}
					{/*</ScrollbarPrompt>*/}
				</ScrollbarPromptWrapper>
				<CustomScrollbar id="scrollbar"
								 onClick={this.handleScrollClick.bind(this)}
								 onMouseDown={this.handleMouseDown.bind(this)}
								 onTouchStart={this.handleTouchStart.bind(this)}
				>
					<ScrollbarThumb width={scrollThumbWidth}
									id="scrollbar-thumb"/>
				</CustomScrollbar>
			</HistoryOfPeopleWithScrollWrapper>
		)
	}
}

export default StoriesOfPeople
