import React from "react"
import styled from "styled-components"
import ChartWithInfoWrapper from "../../common/Charts";
import InfoDescription from "../chartsDescription/infoDescription/InfoDescription";

const ChartContent = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	height: 140px;
	padding-right: 24%;
	
	@media(min-width: 768px){
		padding-right: 0;
		margin-top: 60px;
	}
	
	@media(min-width: 1920px){
		height: 200px;
		margin-top: 0;
	}
	@media(min-width: 2560px) {
		height: 350px;
	}
`

const ChartNumber = styled.div`
	color: ${({theme}) => theme.colors.pmmWhite};
	position: relative;
	width: 110px;
	height: 110px;
	
	&:before {
		content: '${props => props.textNextToNumber}';
		position: absolute;
		bottom: 50%;
		transform: translateY(50%);
		right: -100%;
		color: ${({theme}) => theme.colors.pmmWhite};
		font-weight: ${({theme}) => theme.fontWeight.bold};
		font-size: 50px;
		white-space: nowrap;
	}
	
	&:after {
		content: '${props => props.number}';
		position: absolute;
		left: 0;
		width: 110px;
		height: 110px;
		background-color: ${({theme}) => theme.colors.pmmOrange};
		border-radius: 100px;
		color: ${({theme}) => theme.colors.pmmWhite};
		font-weight: ${({theme}) => theme.fontWeight.bold};
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 50px;
		
		@media(min-width: 1920px){
			font-size: 50px;
		}
	}
	
	@media(min-width: 2560px) {
		width: 150px;
		height: 150px;
		
		&:before {
			right: -85%;
		}
		
		&:after {
			width: 150px;
			height: 150px;
	}
`

class NumberInCircleCharts extends React.Component {

	render() {
		const {data} = this.props;
		const chartInfo = data?.liczbaWKolku?.[0] || ""
		const number = chartInfo?.liczba || ""
		const textNextToNumber = chartInfo?.tekstObokLiczby || ""
		// const caption = chartInfo?.podpis || ""
		// const description = chartInfo?.opis || ""


		return (
			<ChartWithInfoWrapper>
				<ChartContent>
					<ChartNumber number={number} textNextToNumber={textNextToNumber}/>
				</ChartContent>
				<InfoDescription chartInfo={chartInfo}/>
			</ChartWithInfoWrapper>

		)
	}
}

export default NumberInCircleCharts
