import React, {Component} from 'react'
import styled from "styled-components"
import TickPicture from '../../images/newsletter/checkbox-tick.svg'
import {CopyTooltip} from "../news/ShareBtns";

const StyledCheckboxInput = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	align-items: flex-start;
	width: 100%;
	//height: 50px;
	cursor: pointer;
	z-index: 0;
	//margin-top: 20px;
	transition: opacity .3s;
	outline: none;
	@media(min-width: 768px) {
		width: 100%;
	    //margin-top: 15px;
	}
	@media(min-width: 1280px) {
		//margin-top: 25px;
		padding-left: 10px;
	}
	input{
		margin: 0;
		width: 23px !important;
		height: 30px !important;
		z-index: 20;
		cursor: pointer;
	}
	p {
	    padding-left: 15px;
	    font-size: 14px;
	    @media(min-width: 768px) {
	    	//padding: 10px;
	    }
	    a {
		    text-decoration: underline;
			color: ${({theme}) => theme.colors.pmmOrange};
			padding-left: 5px;
	    }
    }
	.custom-checkbox {
		opacity: 0;
	}
`;

const CheckboxLabel = styled.label`
		position: absolute;
		background-color: ${({theme, checked }) => checked ? theme.colors.pmmOrange : theme.colors.pmmWhite};
		border: ${({theme, required, markUncheckedCheckboxes, checked }) => (required && !checked && markUncheckedCheckboxes )? "3px solid " +  theme.colors.pmmRed : "2px solid" + theme.colors.pmmOrange};
		border-radius: 4px;
		opacity: 1;
		z-index: 4;
		width: 20px;
		height: 20px;
	    top: 30%;
    	left: -1px;
		cursor: pointer;
		outline: none;
		padding: 10px;
  }	
`;
const TickPictureWrapper = styled.div`
		position: absolute;
		z-index: 50;
		left: 5px;
		top: calc(30% + 8px);
		width: 11px;
		height: 9px;
		background-size: cover;
    	background-position: center;
    	background-image: url( ${({ bgImg }) => bgImg});	
`;

class SingleRegulationsPoint extends Component {

	render() {
		const {children, required, checked, isCheckboxClicked, markUncheckedCheckboxes, isSpanClicked, seoName, seoNumber} = this.props;
		const photo = (checked) ? TickPicture : '';

		return (
			<StyledCheckboxInput isCheckboxVisible={true} >
				<input onClick={isCheckboxClicked} type="checkbox" className="custom-checkbox" name={seoName + '-' + seoNumber}/>
				<CheckboxLabel onClick={isCheckboxClicked} required={required} markUncheckedCheckboxes={markUncheckedCheckboxes} checked={checked}/>
				<TickPictureWrapper onClick={isCheckboxClicked} checked={checked} bgImg={photo}/>
				<div onClick={isSpanClicked}>{children}</div>
				{required && !checked && <CopyTooltip className={markUncheckedCheckboxes ? "visible-payment semi-bold dark-blue" : "semi-bold dark-blue"} >pole wymagane</CopyTooltip>}
			</StyledCheckboxInput>
		)
	}
}

export default SingleRegulationsPoint;
