import React, {Component} from 'react';
import styled from "styled-components";

const HeaderStyled = styled.h3`
	color: ${({theme}) => theme.colors.pmmDarkBlue};
	padding: 0 10px;
		
	@media(min-width: 768px) {
		padding: 0 100px;
	}
	@media(min-width: 1280px) {
		padding: 0 350px;
	}
	@media(min-width: 1366px) {
		padding: 0 370px;
	}
	@media(min-width: 1920px) {
		padding: 0 530px;
	}
`

class ContentHeader extends Component {
	render() {
		const { data } = this.props

		return (
			<HeaderStyled>
				{data.naglowek}
			</HeaderStyled>
		);
	}
}


export default ContentHeader;
