import React from "react"
import styled from "styled-components"
import InfoDescription from "../chartsDescription/infoDescription/InfoDescription";
import ChartWithInfoWrapper, {ChartInfo, InfoNumber, InfoText, InfoWrapper} from "../../common/Charts";

const ChartContent = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	//padding-right: 90px;
	padding-right: 24%;
	
	@media(min-width: 768px){
		padding-right: 0;
	}
`

const ColumnWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: flex-end;
	//margin-top: -30px;
	margin-top: 80px;
	//margin-bottom: 15px;
	height: 160px;
	
	@media(min-width: 768px){
		height: 200px;
		margin-top: 0;
		padding-bottom: 30px;
	}
	@media(min-width: 2560px){
		height: 350px;
	}
	
`

const Column = styled.div`
	background-color: ${({theme}) => theme.colors.pmmWhite};
	height: ${props => props.percentage + "%"};
	width: 100%;
	margin-right: 20px;
	position: relative;
	
	&:last-child {
		margin-right: 0;
	}
	&:before {
		content: '${props => props.description}';
		position: absolute;
		top: calc(100% + 10px);
		left: 50%;
		transform: translateX(-50%);
		color: ${({theme}) => theme.colors.pmmOrange};
		font-weight: ${({theme}) => theme.fontWeight.light};
		font-size: ${({theme}) => theme.fontSize.f4};
	}
	
	&:after {
		content: '${props => props.number}';
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		background-color: ${({theme}) => theme.colors.pmmOrange};
		border-radius: 100px;
		color: ${({theme}) => theme.colors.pmmWhite};
		font-weight: ${({theme}) => theme.fontWeight.bold};
		font-size: ${props => props.columnsNumber === 3 ? '40px' : props.columnsNumber === 4 ? '30px' : props.columnsNumber === 5 ? '25px' : '40px'};
		display: flex;
		align-items: center;
		justify-content: center;
		
		@media(min-width: 375px){
			font-size: ${props => props.columnsNumber === 3 ? '45px' : props.columnsNumber === 4 ? '35px' : props.columnsNumber === 5 ? '25px' : '40px'};
		}
		
		@media(min-width: 1920px){
			font-size: ${props => props.columnsNumber === 3 ? '45px' : props.columnsNumber === 4 ? '35px' : props.columnsNumber === 5 ? '25px' : '60px'};
		}
		
	}
	
	&.small-circle {
		&:after {
			top: ${props => props.columnsNumber === 3 ? '-85px' : props.columnsNumber === 4 ? '-65px' : props.columnsNumber === 5 ? '-50px' : '-90px'};
			width: ${props => props.columnsNumber === 3 ? '70px' : props.columnsNumber === 4 ? '55px' : props.columnsNumber === 5 ? '40px' : '75px'};
			height: ${props => props.columnsNumber === 3 ? '70px' : props.columnsNumber === 4 ? '55px' : props.columnsNumber === 5 ? '40px' : '75px'};
		}
		
		@media(min-width: 375px){
			&:after {
				top: ${props => props.columnsNumber === 3 ? '-90px' : props.columnsNumber === 4 ? '-70px' : props.columnsNumber === 5 ? '-60px' : '-90px'};
				width: ${props => props.columnsNumber === 3 ? '75px' : props.columnsNumber === 4 ? '60px' : props.columnsNumber === 5 ? '50px' : '75px'};
				height: ${props => props.columnsNumber === 3 ? '75px' : props.columnsNumber === 4 ? '60px' : props.columnsNumber === 5 ? '50px' : '75px'};
			}
		}
		
		@media(min-width: 1920px){
			&:after {
				top: ${props => props.columnsNumber === 3 ? '-90px' : props.columnsNumber === 4 ? '-70px' : props.columnsNumber === 5 ? '-60px' : '-125px'};
				width: ${props => props.columnsNumber === 3 ? '75px' : props.columnsNumber === 4 ? '60px' : props.columnsNumber === 5 ? '50px' : '100px'};
				height: ${props => props.columnsNumber === 3 ? '75px' : props.columnsNumber === 4 ? '60px' : props.columnsNumber === 5 ? '50px' : '100px'};
			}
		}
	}
	
	&.big-circle {
		&:after {
			top: ${props => props.columnsNumber === 3 ? '-75px' : props.columnsNumber === 4 ? '-60px' : props.columnsNumber === 5 ? '-45px' : '-90px'};
			width: ${props => props.columnsNumber === 3 ? '90px' : props.columnsNumber === 4 ? '65px' : props.columnsNumber === 5 ? '50px' : '110px'};
			height: ${props => props.columnsNumber === 3 ? '90px' : props.columnsNumber === 4 ? '65px' : props.columnsNumber === 5 ? '50px' : '110px'};
		}
		
		@media(min-width: 375px){
			&:after {
				top: ${props => props.columnsNumber === 3 ? '-95px' : props.columnsNumber === 4 ? '-70px' : props.columnsNumber === 5 ? '-55px' : '-90px'};
				width: ${props => props.columnsNumber === 3 ? '105px' : props.columnsNumber === 4 ? '80px' : props.columnsNumber === 5 ? '60px' : '110px'};
				height: ${props => props.columnsNumber === 3 ? '105px' : props.columnsNumber === 4 ? '80px' : props.columnsNumber === 5 ? '60px' : '110px'};
			}
		}
		
		@media(min-width: 1920px){
			&:after {
				top: ${props => props.columnsNumber === 3 ? '-95px' : props.columnsNumber === 4 ? '-70px' : props.columnsNumber === 5 ? '-55px' : '-90px'};
				width: ${props => props.columnsNumber === 3 ? '105px' : props.columnsNumber === 4 ? '80px' : props.columnsNumber === 5 ? '60px' : '110px'};
				height: ${props => props.columnsNumber === 3 ? '105px' : props.columnsNumber === 4 ? '80px' : props.columnsNumber === 5 ? '60px' : '110px'};
			}
		}
		
	}
`

class VerticalColumnsChart extends React.Component {

	render() {
		const {data} = this.props;
		const chartInfo = (data && data.kolumnyPionowe && data.kolumnyPionowe[0]) || ""
		const columnMaxValue = (chartInfo && chartInfo.maksymalnaWartoscKolumny) || ""
		const isColumnMaxValueVisible = (chartInfo && chartInfo.wyswietlMaksymalnaWartoscKolumny) || ""
		const columns = (data && data.kolumnyPionowe && data.kolumnyPionowe.slice(1)) || []
		const columnsNumber = columns.length
		let columnsData = []

		const chartText = chartInfo.naglowek || ""
		const chartDescription = chartInfo.opis || ""

		columns.forEach((column) => {
			const columnPercentageWidth = (column.wartosc * 100 / columnMaxValue).toFixed(1)
			columnsData.push({
				"columnPercentageWidth": columnPercentageWidth,
				"columnValue": column.wartosc,
				"columnDescription": column.podpis
			})
		})

		return (
			<ChartWithInfoWrapper>
				<ChartContent>
					<ColumnWrapper>
						{columnsData.map((column, key) =>
							<Column columnsNumber={columnsNumber} key={key} percentage={column.columnPercentageWidth}
									number={column.columnValue}
									className={column.columnPercentageWidth < 50 ? "small-circle" : "big-circle"}
									description={column.columnDescription}/>
						)}
					</ColumnWrapper>
				</ChartContent>
				{!isColumnMaxValueVisible ? <InfoDescription isVertical chartInfo={chartInfo}/> :
					<InfoWrapper>
						<InfoNumber>{columnMaxValue}</InfoNumber>
						<InfoText>{chartText}</InfoText>
						<ChartInfo>{chartDescription}</ChartInfo>
					</InfoWrapper>
				}
			</ChartWithInfoWrapper>
		)
	}
}

export default VerticalColumnsChart
