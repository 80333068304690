import React, { Component } from 'react';
import Button from '../common/ButtonsSet';
import {
	ArrowBackIcon,
	BackBtn,
	BackBtnWrapper,
	BottomButtonsWrapper,
	ColumnCenteredWrapper,
	CurrentSlideNumber,
	CustomRadioImage,
	NumberOfSlides,
	PaymentTypeRadioButtonsWrapper,
	RadioButtonLabel,
	SingleRadioButton,
	SingleRadioButtonWrapper,
	SlidesCounterWrapper,
	StepWord,
	TabButtonWrapper,
} from './CashFlow';
import styled from 'styled-components';
import escapeDiacritics from '../../utils/escapeDacritics';
import validationForNumbers from '../../utils/validationForNumbers';

const AmountTabWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 50px;
	border-top: 1px solid ${({ theme }) => theme.colors.pmmOrange};
	border-bottom: 1px solid ${({ theme }) => theme.colors.pmmOrange};

	@media (min-width: 768px) {
		border: 1px solid ${({ theme }) => theme.colors.pmmOrange};
	}
	@media (min-width: 1920px) {
		height: 70px;
	}
`;
const AmountTab = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: ${({ theme }) => theme.colors.pmmDarkestBlue};
	font-weight: ${({ theme }) => theme.fontWeight.semiBold};
	font-size: ${({ theme }) => theme.fontSize.f10};
	border-right: 1px solid ${({ theme }) => theme.colors.pmmOrange};
	width: 33%;

	&:last-of-type {
		border-right: none;
	}
	&.active {
		background-color: ${({ theme }) => theme.colors.pmmOrange};
		color: ${({ theme }) => theme.colors.pmmWhite};

		input {
			color: ${({ theme }) => theme.colors.pmmWhite};
			&::placeholder {
				//margin-left: 20px;
				color: ${({ theme }) => theme.colors.pmmWhite};
			}
		}
	}

	@media (min-width: 768px) {
		font-size: ${({ theme }) => theme.fontSize.f3};
	}
`;
const AmountInputWrapper = styled.div`
	position: relative;
	height: 100%;
`;
const AmountInput = styled.input`
	width: 100%;
	height: 100%;
	color: ${({ theme }) => theme.colors.pmmDarkestBlue};
	font-weight: ${({ theme }) => theme.fontWeight.semiBold};
	font-size: 12px;
	text-align: center;
	border: none;
	background-color: transparent;
	outline: none;
	-moz-appearance: textfield;
	&::placeholder {
		color: ${({ theme }) => theme.colors.pmmDarkestBlue};
		font-weight: ${({ theme }) => theme.fontWeight.semiBold};
		font-size: 12px;
		text-align: center;
		opacity: 1;
	}

	&:focus,
	&:active {
		background-color: ${({ theme }) => theme.colors.pmmOrange};
		color: ${({ theme }) => theme.colors.pmmWhite};
		&::placeholder {
			color: transparent !important;
		}
	}

	&.input-padding {
		padding-right: 30px;
	}

	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	@media (min-width: 768px) {
		font-size: ${({ theme }) => theme.fontSize.f3};
		&::placeholder {
			font-size: ${({ theme }) => theme.fontSize.f3};
		}
	}
`;
const InvisibleDivForUnit = styled.div`
	position: absolute;
	background-color: transparent;
	z-index: 50;
	top: 0;
	width: 50%;
	max-width: calc(100% - 15px);
	height: 100%;
	pointer-events: none;
	
	// &.white {
	// 	span {
	// 		color: ${({ theme }) => theme.colors.pmmWhite};
	// 	}
	// }
	// &.blue {
	// 	span {
	// 		color: ${({ theme }) => theme.colors.pmmDarkestBlue};
	// 	}
	// }
	
	span {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: -5px;
	}
	
	&.hidden{
		visibility: hidden;
		pointer-events: none;
	}
	@media(min-width: 1280px){
		span {
			top: calc(50% - 1px);
		}
	}
`;

const PictogramAndDescriptionWrapper = styled.div`
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-between;
	align-items: center;
	padding-top: 35px;

	@media (min-width: 768px) {
		flex-direction: row;
	}
`;
const PackageDescription = styled.p`
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	width: 75%;
	text-align: center;
	margin: 0 0 20px;
	line-height: 28px;

	@media (min-width: 768px) {
		text-align: left;
	}
`;
const Pictogram = styled.div`
	background-image: url(${({ pictogramImg }) => pictogramImg});
	background-size: contain;
	background-repeat: no-repeat;
	width: 97px;
	height: 97px;
	@media (min-width: 768px) {
		width: 75px;
		height: 74px;
	}
	@media (min-width: 1920px) {
		width: 110px;
		height: 110px;
	}
`;

class SecondTab extends Component {
	// state = {
	// 	differentAmountFocused: false
	// }

	updateUnitSuffix = () => {
		const inputEl = document.getElementById('amount-input');
		const unitEl = document.getElementById('invisible-div');
		const shift = window.innerWidth < 768 ? (inputEl.value.length + 1) * 3 : (inputEl.value.length + 1) * 5;
		unitEl.style.width = 'calc(50% + ' + shift + 'px)';
	};

	componentDidMount() {
		const inputEl = document.getElementById('amount-input');
		this.updateUnitSuffix();
		inputEl.addEventListener('input', this.updateUnitSuffix);
	}

	componentWillUnmount() {
		const inputEl = document.getElementById('amount-input');
		inputEl.removeEventListener('input', this.updateUnitSuffix);
	}

	render() {
		const {
			amountsDataArray,
			projectNumber,
			activePack,
			packGlobalBtn,
			backBtnText,
			backBtnIcon,
			handleAmountMethod,
			setActivePackageTypeMethod,
			differentAmount,
			handleAmountChangeMethod,
			prevTabMethod,
			activeSite,
			handlePaymentType,
			paymentType,
			nextTabMethod,
			isRadioButtonClicked,
			isFirstTabVisible,
			slidesCounterData,
			stepWord,
			handleFocus,
			handleBlur,
			differentAmountFocus,
			projectsPaymentIds,
		} = this.props;

		const packGlobalBtnText = packGlobalBtn.przycisk || '';
		const btnTextForSeo = (packGlobalBtnText && packGlobalBtnText.replace(/\s+/g, '-').toLowerCase()) || '';
		const btnTextForSeoWithoutDiacritics = escapeDiacritics(btnTextForSeo) + '-krok-' + slidesCounterData.currentSlideNumber;
		const packGlobalBtnType =
			(packGlobalBtn.przyciskRodzaj && packGlobalBtn.przyciskRodzaj[0] && packGlobalBtn.przyciskRodzaj[0].title) || '';
		const singlePaymentText = activeSite === 'pl' ? 'Jednorazowa płatność' : 'Single payment';
		const cyclicPaymentText = activeSite === 'pl' ? 'Miesięczna płatność' : 'Monthly payment';
		//TODO: translates from craft
		return (
			<>
				<PaymentTypeRadioButtonsWrapper>
					<SingleRadioButtonWrapper
						onClick={() => {
							handlePaymentType(false);
						}}
					>
						<SingleRadioButton
							checked={isRadioButtonClicked}
							className="hidden"
							name="platnosc"
							type="radio"
							id="one-payment"
						/>
						<RadioButtonLabel className={`paragraph who-we-help ${paymentType ? '' : 'active'}`} htmlFor="one-payment">
							{singlePaymentText}
						</RadioButtonLabel>
						<CustomRadioImage className={paymentType ? null : 'active'} />
					</SingleRadioButtonWrapper>
					<SingleRadioButtonWrapper onClick={() => handlePaymentType(true)}>
						<SingleRadioButton className="hidden" name="platnosc" type="radio" id="cyclic-payment" />
						<RadioButtonLabel className={`paragraph who-we-help ${paymentType ? 'active' : ''}`} htmlFor="cyclic-payment">
							{cyclicPaymentText}
						</RadioButtonLabel>
						<CustomRadioImage className={paymentType ? 'active' : null} />
					</SingleRadioButtonWrapper>
				</PaymentTypeRadioButtonsWrapper>
				<AmountTabWrapper>
					{amountsDataArray[projectNumber].map((item, key) => (
						<React.Fragment key={key}>
							{item.amount && (
								<AmountTab
									onClick={() => handleAmountMethod(key, item.amount)}
									className={activePack === key ? 'active' : ''}
									key={key}
									name={projectsPaymentIds[projectNumber].seoName + '-' + (key + 1)}
								>
									{item.amount}
								</AmountTab>
							)}
							{item.label && (
								<AmountTab className={activePack === key ? 'active' : ''} key={key}>
									<AmountInputWrapper differentAmount={differentAmount}>
										<AmountInput
											id="amount-input"
											name={projectsPaymentIds[projectNumber].seoName + '-different'}
											autoComplete="off"
											className={
												(differentAmount !== 0 && differentAmount !== '') || differentAmountFocus
													? 'input-padding'
													: ''
											}
											value={differentAmount !== 0 ? differentAmount : ''}
											placeholder={item.label}
											onClick={() => setActivePackageTypeMethod(key)}
											onChange={e => handleAmountChangeMethod(e)}
											onFocus={() => handleFocus()}
											onBlur={handleBlur}
											onKeyPress={e => validationForNumbers(e)}
										/>
										<InvisibleDivForUnit
											id="invisible-div"
											className={
												(differentAmount !== 0 && differentAmount !== '') || differentAmountFocus ? '' : 'hidden'
											}
										>
											<span id="amount-unit">zł</span>
										</InvisibleDivForUnit>
									</AmountInputWrapper>
								</AmountTab>
							)}
						</React.Fragment>
					))}
				</AmountTabWrapper>
				<PictogramAndDescriptionWrapper>
					<Pictogram pictogramImg={amountsDataArray[projectNumber][activePack].pictogram} />
					<PackageDescription>{amountsDataArray[projectNumber][activePack].description}</PackageDescription>
				</PictogramAndDescriptionWrapper>
				<BottomButtonsWrapper>
					<BackBtnWrapper
						className={isFirstTabVisible ? '' : 'hidden'}
						onClick={prevTabMethod}
						name={projectsPaymentIds[projectNumber].seoName + '-powrot-krok-' + slidesCounterData.currentSlideNumber}
					>
						<ArrowBackIcon src={backBtnIcon} />
						<BackBtn className="orange">{backBtnText}</BackBtn>
					</BackBtnWrapper>
					<TabButtonWrapper
						onClick={nextTabMethod}
						name={projectsPaymentIds[projectNumber].seoName + '-' + btnTextForSeoWithoutDiacritics}
					>
						<Button btnType={packGlobalBtnType} textBtn={packGlobalBtnText} />
					</TabButtonWrapper>
					<ColumnCenteredWrapper>
						<SlidesCounterWrapper className="second-tab">
							<StepWord className="bold custom-lh">{stepWord} </StepWord>
							<CurrentSlideNumber>{slidesCounterData.currentSlideNumber}</CurrentSlideNumber>
							<NumberOfSlides>{slidesCounterData.allSlidesCount}</NumberOfSlides>
						</SlidesCounterWrapper>
					</ColumnCenteredWrapper>
				</BottomButtonsWrapper>
			</>
		);
	}
}

SecondTab.propTypes = {};

export default SecondTab;
