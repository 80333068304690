import React, {Component} from 'react';
import styled from "styled-components";
import getSingleImage from "../../utils/getSingleImage";

const PhotoStyled = styled.div`
	background-image: url(${props => props.backMobile});
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
    width: 100%;
    height: 0;
    padding-top: 66.64%; 

	@media(min-width: 1025px) {
  		background-image: url(${props => props.back});
  	}
	@media(min-width: 1280px) {
		margin: 0 50px;
		width: calc(100% - 100px);
	}
	@media(min-width: 1920px) {
		margin: 0 100px;
		width: calc(100% - 200px);
	}
`

class ContentPhotoLarge extends Component {
	render() {
		const { data } = this.props
		const photoUrl = (data && data.zdjecie) || ""
		const photoUrlMobile = (data && data.zdjecieMobile) || ""
		return (
				<PhotoStyled back={getSingleImage(photoUrl)} backMobile={getSingleImage(photoUrlMobile)}/>
		);
	}
}


export default ContentPhotoLarge;
