import React from 'react';
import styled from 'styled-components';
import { getPaddingBottom, getPaddingTop } from '../../utils/setModulePadding';
import Tile from './Tile';

const TilesModuleWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.pmmWhite};
	padding: ${({ paddingTop }) => paddingTop}px 10px ${({ paddingBottom }) => paddingBottom}px;

	@media (min-width: 768px) {
		padding: ${({ paddingTop }) => paddingTop}px 30px ${({ paddingBottom }) => paddingBottom}px;
	}
	@media (min-width: 1280px) {
		padding: ${({ paddingTop }) => paddingTop}px 50px ${({ paddingBottom }) => paddingBottom}px;
	}
	@media (min-width: 1920px) {
		padding: ${({ paddingTop }) => paddingTop}px 100px ${({ paddingBottom }) => paddingBottom}px;
	}
`;
const TilesHeaderWrapper = styled.div`
	width: 100%;
	display: flex;
	border-bottom: 2px solid ${({ theme }) => theme.colors.pmmDarkestBlue};
	margin-bottom: 70px;

	@media (min-width: 1280px) {
		width: calc(50% - 35px);
		min-width: 810px;
	}
	@media (min-width: 2560px) {
		width: calc(50% - 195px);
		min-width: 810px;
	}
`;
const TilesHeader = styled.h5`
	text-transform: lowercase;
	color: ${({ theme }) => theme.colors.pmmDarkestBlue};
	position: relative;
	margin-bottom: -2px;
	padding: 8px 20px;
	border: 2px solid ${({ theme }) => theme.colors.pmmDarkestBlue};

	&:after {
		content: '';
		position: absolute;
		bottom: -2px;
		right: -17px;
		height: 2px;
		width: 15px;
		background-color: white;
	}

	@media (min-width: 768px) {
		&:after {
			right: -32px;
			width: 30px;
		}
	}
`;

const TilesWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
`;

class FilesToDownloadTiles extends React.Component {
	render() {
		const { data, activeSite } = this.props;
		const filesData = (data && data.modulPlikiDoPobraniaKafelki) || [];
		const photosData = (data && data.modulZdjeciaDoPobraniaKafelki) || [];
		let paddingTop = '';
		let paddingBottom = '';
		let header = '';
		let fileToDownloadArray = [];

		filesData.forEach(item => {
			if (item.__typename === 'Craft_modulPlikiDoPobraniaKafelki_naglowek_BlockType') {
				header = item?.naglowek || '';
			} else if (item.__typename === 'Craft_modulPlikiDoPobraniaKafelki_marginesy_BlockType') {
				paddingTop = (item.marginesGorny && item.marginesGorny[0] && item.marginesGorny[0].title) || '';
				paddingBottom = (item.marginesDolny && item.marginesDolny[0] && item.marginesDolny[0].title) || '';
			} else if (item.__typename === 'Craft_modulPlikiDoPobraniaKafelki_plik_BlockType') {
				fileToDownloadArray.push({
					photoUrl: (item.zdjecie && item.zdjecie[0] && item.zdjecie[0].url) || '',
					photoDescription: item.zdjeciePodpis || '',
					description: item.opis || '',
					btnText: item.przyciskTekst || '',
					btnType: (item.przyciskRodzaj && item.przyciskRodzaj[0] && item.przyciskRodzaj[0].title) || '',
					fileToDownload: (item.plikDoPobrania && item.plikDoPobrania[0] && item.plikDoPobrania[0].url) || '',
				});
			}
		});

		photosData.forEach(item => {
			if (item.__typename === 'Craft_modulZdjeciaDoPobraniaKafelki_naglowek_BlockType') {
				header = item.naglowek || '';
			} else if (item.__typename === 'Craft_modulZdjeciaDoPobraniaKafelki_marginesy_BlockType') {
				paddingTop = (item.marginesGorny && item.marginesGorny[0] && item.marginesGorny[0].title) || '';
				paddingBottom = (item.marginesDolny && item.marginesDolny[0] && item.marginesDolny[0].title) || '';
			} else if (item.__typename === 'Craft_modulZdjeciaDoPobraniaKafelki_kafelek_BlockType') {
				fileToDownloadArray.push({
					photoUrl: (item.zdjecie && item.zdjecie[0] && item.zdjecie[0].url) || '',
					photoDescription: item.zdjeciePodpis || '',
					to: (item.linkDoPodstrony && item.linkDoPodstrony[0] && item.linkDoPodstrony[0].slug) || '',
				});
			}
		});

		return (
			<TilesModuleWrapper paddingTop={getPaddingTop(paddingTop)} paddingBottom={getPaddingBottom(paddingBottom)}>
				{header && (
					<TilesHeaderWrapper>
						<TilesHeader className="header-with-line">{header}</TilesHeader>
					</TilesHeaderWrapper>
				)}
				<TilesWrapper>
					{fileToDownloadArray.map((item, key) => (
						<Tile key={key} tileData={item} activeSite={activeSite} />
					))}
				</TilesWrapper>
			</TilesModuleWrapper>
		);
	}
}

export default FilesToDownloadTiles;
