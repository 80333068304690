import React, { Component } from 'react';
import styled from 'styled-components';
import SingleCardAdditionalPayments from '../payments/SingleCardAdditionalPayments';
import SingleRegulationsPoint from './SingleRegulationsPoint';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import PayWithPayU from './PayWithPayU';
import PayWithPayPal from './PayWithPayPal';
import FirstTab from './FirstTab';
import SecondTab from './SecondTab';
import TabsHeader from './TabsHeader';
import FirstTabInfoMobile from './FirstTabInfoMobile';
import WholeInfoProcessingPersonalData from '../common/WholeInfoProcessingPersonalData';
import { connect } from 'react-redux';
import { setDifferentAmountFromCalculator, setMobileActiveProject } from '../../state/app';
import PayuWidget from '../payments/PayuWidget';

const CashFlowWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	position: relative;
	padding: 45px 0;
	background-color: ${({ theme }) => theme.colors.pmmWhite};

	@media (min-width: 768px) {
		flex-direction: row;
		padding: 80px 0 100px;
	}
	@media (min-width: 1280px) {
		padding: 80px 0 100px 55px;
	}
	@media (min-width: 1920px) {
		padding: 80px 0 100px 100px;
	}
`;
const PaymentSpace = styled.div`
	width: 100%;
	@media (min-width: 768px) {
		width: 0;
	}
	@media (min-width: 1280px) {
		width: 504px;
	}
	@media (min-width: 1920px) {
		width: 690px;
	}
`;
const PaymentWrapperToScroll = styled.div`
	display: table;
	width: 100%;
	position: relative;
	@media (min-width: 768px) {
		width: auto;
		left: 35px;
		top: 545px;
		z-index: 1;
	}
	@media (min-width: 1280px) {
		left: unset;
		top: 0;
	}
`;

const SingleCardAdditionalPaymentsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	width: 100%;
	@media (min-width: 768px) {
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: nowrap;
		padding-right: 35px;
		padding-left: 35px;
	}
	@media (min-width: 1280px) {
		margin-bottom: 0;
		padding-right: 50px;
		padding-left: 0;
		width: calc(100% - 504px);
	}
	@media (min-width: 1920px) {
		width: calc(100% - 690px);
		padding-right: 100px;
	}
`;
const PaymentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	min-height: 530px;
	border: 2px solid ${({ theme }) => theme.colors.pmmOrange};
	padding: 85px 0;
	position: relative;
	margin-bottom: 40px;
	top: 0;
	width: 100%;
	&.first-tab {
		padding: 120px 15px 30px;
	}
	&.third-tab {
		padding: 100px 20px 30px;
		@media (min-width: 768px) {
			padding: 65px 50px 30px 30px;
		}
		@media (min-width: 1920px) {
			padding: 90px 120px 30px 35px;
		}
	}
	&.fourth-tab {
		padding: 80px 10px 30px;
		@media (min-width: 768px) {
			padding: 60px 50px 30px 25px;
		}
		@media (min-width: 1280px) {
			padding: 50px 40px 30px 25px;
		}
		@media (min-width: 1920px) {
			padding: 60px 140px 60px 35px;
		}
	}
	@media (min-width: 768px) {
		z-index: 3;
		width: 504px;
		margin-top: 34px;
		min-height: unset;
		height: 490px;
		background-color: rgba(255, 255, 255, 0.95);
		padding: 65px 50px 30px 25px;
		margin-bottom: 0;
		&.first-tab {
			padding: 75px 55px 30px 40px;
		}
	}
	@media (min-width: 1280px) {
		background-color: unset;
		height: 450px;
		padding: 65px 55px 30px 25px;
	}
	@media (min-width: 1920px) {
		width: 690px;
		height: 580px;
		padding: 85px 120px 30px 35px;
		margin-top: 34px;
		&.first-tab {
			padding: 115px 120px 30px 35px;
		}
	}
`;
export const TabButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	&.first-tab {
		justify-content: space-between;
		position: absolute;
		bottom: -17px;
		left: 0;
		padding: 0 10px;
		@media (min-width: 768px) {
			padding: 0 15px;
		}
		@media (min-width: 1280px) {
			padding: 0 20px;
		}
		@media (min-width: 1920px) {
			bottom: -22px;
			padding: 0 10px;
		}
	}
	button {
		padding: 0 13px;
		font-size: 12px;
		line-height: 14px;
		div.text {
			padding: 8px 0;
			@media (min-width: 1920px) {
				padding: 10px 0;
			}
		}
		@media (min-width: 768px) {
			padding: 0 25px;
		}
		@media (min-width: 1920px) {
			font-size: 16px;
			line-height: 19px;
		}
	}
`;
export const BackBtnWrapper = styled.div`
	display: flex;
	flex-direction: row;
	cursor: pointer;
	justify-content: flex-start;
	padding: 0 2px;
	margin: auto 0;
	background-color: white;
	height: 34px;
	&.hidden {
		opacity: 0;
		pointer-events: none;
	}
	@media (min-width: 768px) {
		padding: 0 15px 0 5px;
		border-radius: 17px;
		min-width: 104px;
	}
	@media (min-width: 1920px) {
		min-width: 124px;
		padding: 0 15px;
	}
`;
export const ArrowBackIcon = styled.img`
	width: 30px;
	height: 30px;
	margin-right: 5px;
	margin-top: 2px;

	@media (min-width: 1920px) {
		width: 34px;
		height: 34px;
		margin-right: 10px;
	}
`;
export const BackBtn = styled.h6`
	position: relative;
	cursor: pointer;
	margin-top: 9px;
	font-size: 12px;
	display: none;
	@media (min-width: 425px) {
		display: block;
	}
	@media (min-width: 768px) {
		font-size: 14px;
	}
`;

export const SlidesCounterWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.colors.pmmWhite};
	border-radius: 13px;
	padding: 0 5px;
	height: 25px;
	min-width: 65px;
	&.first-tab {
		//margin: auto 0 auto -26px;
		@media (min-width: 768px) {
			padding: 0 5px 0 10px;
			//margin: auto 0 auto -19px;
		}
		@media (min-width: 1280px) {
			//margin: auto 0 auto -19px;
		}
		@media (min-width: 1366px) {
			//margin: auto 0 auto -19px;
		}
		@media (min-width: 1920px) {
			padding: 0 10px 0 20px;
			//margin: auto 0 auto 42px;
		}
	}
	&.second-tab {
		margin-left: -26px;
		@media (min-width: 425px) {
			margin-left: 17px;
		}
		@media (min-width: 768px) {
			padding: 0 5px 0 10px;
			margin-left: -22px;
		}
		@media (min-width: 1280px) {
			margin-left: -22px;
		}
		@media (min-width: 1366px) {
			margin-left: -22px;
		}
		@media (min-width: 1920px) {
			padding: 0 10px 0 20px;
			margin-left: -36px;
		}
	}
	&.third-tab {
		margin-left: -18px;
		@media (min-width: 425px) {
			margin-left: 17px;
		}
		@media (min-width: 768px) {
			padding: 0 5px 0 10px;
			margin-left: -22px;
		}
		@media (min-width: 1280px) {
			margin-left: -22px;
		}
		@media (min-width: 1366px) {
			margin-left: -22px;
		}
		@media (min-width: 1920px) {
			padding: 0 10px 0 20px;
			margin-left: -36px;
		}
	}
	&.hidden {
		opacity: 0;
	}
	@media (min-width: 768px) {
		padding: 0 5px;
		min-width: 120px;
	}
	@media (min-width: 1920px) {
		padding: 0 10px 0 20px;
		min-width: 180px;
	}
`;

export const StepWord = styled.p`
	display: none;
	@media (min-width: 768px) {
		display: flex;
		padding-right: 10px;
		font-weight: ${({ theme }) => theme.fontWeight.bold};
		flex-direction: column;
		justify-content: center;
		line-height: 25px !important;
	}
	@media (min-width: 1920px) {
		padding-right: 15px;
	}
`;

export const ColumnCenteredWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const CurrentSlideNumber = styled.h6`
	color: ${({ theme }) => theme.colors.pmmOrange};
	//margin: auto 20px auto 0;
	margin-right: 20px;
	//margin-top: 2px;
	height: 25px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media (min-width: 768px) {
		//margin: auto 20px auto 0;
		margin-right: 20px;
		margin-top: 1px;
	}
	@media (min-width: 1366px) {
		margin-top: 2px;
	}
	@media (min-width: 1920px) {
		margin-right: 30px;
		margin-top: 1px;
	}
`;

export const NumberOfSlides = styled.h6`
	position: relative;
	color: ${({ theme }) => theme.colors.pmmDarkViolet};
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 25px;
	&:before {
		content: '';
		position: absolute;
		left: -10px;
		top: calc(50% - 5px);
		height: 10px;
		width: 2px;
		background-color: ${({ theme }) => theme.colors.pmmOrange};
	}

	@media (min-width: 768px) {
		margin-top: 1px;
		&:before {
			height: 10px;
			left: -10px;
		}
	}
	@media (min-width: 1366px) {
		margin-top: 2px;
	}
	@media (min-width: 1920px) {
		margin-top: 1px;
		&:before {
			left: -15px;
			height: 15px;
			top: calc(50% - 7.5px);
		}
	}
`;

export const PaymentTypeRadioButtonsWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-bottom: 30px;

	@media (min-width: 768px) {
		justify-content: flex-start;
		margin-bottom: 40px;
	}
	@media (min-width: 1920px) {
		margin-bottom: 50px;
	}
`;
export const SingleRadioButtonWrapper = styled.div`
	position: relative;
	width: 41%;
	@media (min-width: 375px) {
		//width: auto;
	}
	@media (min-width: 430px) {
		width: auto;
	}
	&.hidden {
		display: none;
	}
`;

export const SingleRadioButton = styled.input`
	color: ${({ theme }) => theme.colors.pmmWhite};
	&.hidden {
		opacity: 0;
	}
`;

export const RadioButtonLabel = styled.label`
	color: ${({ theme }) => theme.colors.pmmDarkViolet};
	margin-right: 30px;
	margin-left: 30px;
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	font-size: 12px;
	line-height: 16px !important;
	opacity: 1;
	display: block;
	&.active {
		opacity: 1;
		color: ${({ theme }) => theme.colors.pmmOrange};
		font-weight: ${({ theme }) => theme.fontWeight.bold} !important;
	}

	@media (min-width: 430px) {
		display: inline;
		margin-left: 10px;
		line-height: 30px !important;
	}
	@media (min-width: 768px) {
		font-size: 16px;
		margin-right: 15px;
		line-height: 30px !important;
	}
	@media (min-width: 1366px) {
		margin-right: 10px;
		margin-left: 5px;
	}
	@media (min-width: 1920px) {
		font-size: 20px;
		margin-right: 20px;
		line-height: 34px !important;
	}
`;

export const CustomRadioImage = styled.div`
	position: absolute;
	z-index: 5;
	left: 0;
	bottom: 8px;
	width: 18px;
	height: 18px;
	border: 2px solid red;
	border-radius: 50%;
	&.active {
		&:before {
			content: '';
			position: absolute;
			width: 8px;
			height: 8px;
			top: calc(50% - 4px);
			left: calc(50% - 4px);
			border-radius: 50%;
			background-color: red;
		}
	}
`;
const RegulationsWrapper = styled.div`
	.small {
		font-size: ${({ theme }) => theme.fontSize.f19};
		line-height: ${({ theme }) => theme.lineHeight.line16};
	}
`;
const PaymentProvidersWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	position: relative;
`;
const ValidationButtonWrapper = styled.div`
	width: 80px;
	height: 80px;
	background-color: white;
	position: relative;
	border: 2px solid ${({ theme }) => theme.colors.pmmOrange};
	border-radius: 50%;
	display: flex;
	cursor: pointer;
	flex-direction: row;
	justify-content: center;
	z-index: 5;
	&:last-child {
		margin-left: 15px;
		@media (min-width: 1920px) {
			margin-left: 20px;
		}
	}
	@media (min-width: 1280px) {
		width: 90px;
		height: 90px;
	}
	@media (min-width: 1920px) {
		width: 120px;
		height: 120px;
	}
`;
const SingleIconWrapper = styled.input`
	width: 100%;
	height: 100%;
	z-index: 20;
	opacity: 0;
	cursor: pointer;
	border: 1px solid black;
`;
const ProviderIcon = styled.img`
	width: 49px;
	height: 24px;
	margin: 0 auto;
	position: absolute;
	left: 15px;
	top: 26px;
	z-index: 0;
	&.paypal {
		width: 66px;
		height: 17px;
		left: 6px;
		top: 30px;
	}
	@media (min-width: 1280px) {
		width: 55px;
		height: 28px;
		left: 17px;
		top: 30px;
		&.paypal {
			width: 75px;
			height: 19px;
			left: 7px;
			top: 34px;
		}
	}
	@media (min-width: 1920px) {
		width: 72px;
		height: 37px;
		left: 24px;
		top: 40px;
		&.paypal {
			width: 99px;
			height: 25px;
			left: 9px;
			top: 46px;
		}
	}
`;
export const BottomButtonsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	margin: auto 0;
	position: absolute;
	//bottom: -22px;
	bottom: -18px;
	left: 0;
	padding: 0 5px;
	&.providers {
		bottom: -41px;
		//bottom: -20px;
	}
	@media (min-width: 400px) {
		//bottom: -23px;
	}
	@media (min-width: 768px) {
		bottom: -17px;
		padding: 0 20px 0 15px;
		&.providers {
			bottom: -40px;
		}
	}
	@media (min-width: 1280px) {
		&.providers {
			bottom: -45px;
		}
	}
	@media (min-width: 1920px) {
		padding: 0 10px 0 30px;
		bottom: -22px;
		&.providers {
			bottom: -60px;
		}
	}
`;
const StyledInput = styled.input`
	border: ${({ theme }) => '1px solid' + theme.colors.pmmOrange};
	background-color: ${({ theme }) => '2px solid' + theme.colors.pmmWhite};
	outline: none;
	display: block;
	margin-bottom: 14px;
	padding: 10px 15px;
	width: 100%;
	position: relative;
	height: 40px;
	color: #707070;
	font-size: 13px;
	cursor: pointer;
	transition: border 0.25s ease-in-out, background-color 0.25s ease-in-out;
	@media (min-width: 768px) {
		width: 100%;
	}
	@media (min-width: 1920px) {
		padding: 10px 15px;
		margin-bottom: 20px;
		font-size: 16px;
		height: 50px;
	}
	&.active {
		border: ${({ theme }) => '1px solid' + theme.colors.pmmInputBorderOrange};
	}
	&.wrong-email {
		border: ${({ theme }) => '3px solid' + theme.colors.pmmInputBorderOrange};
		background-color: ${({ theme }) => theme.colors.pmmInputErrorOrange};
	}
`;
const RedactorDescription = styled.div`
	margin: 3px 0 15px 25px;

	div {
		font-size: 8px !important;
		color: ${({ theme }) => theme.colors.pmmPlaceHolderGrey};
		@media (min-width: 1920px) {
			font-size: 11px !important;
		}
	}
	span.orange {
		color: ${({ theme }) => theme.colors.pmmOrange};
		text-decoration: underline;
	}
`;

const SummaryWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 25px;
	padding-left: 10px;
	@media (min-width: 1280px) {
		margin-bottom: 10px;
	}
	@media (min-width: 1920px) {
		margin-bottom: 25px;
	}
`;

const FirstColumnWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 70%;
`;

const SecondColumnWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	text-align: right;
	width: 30%;
`;

const AmountPaymentLabel = styled.p`
	color: ${({ theme }) => theme.colors.pmmLightViolet};
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	margin: 10px 0;
	text-align: left;
	span {
		color: ${({ theme }) => theme.colors.pmmOrange};
		font-weight: ${({ theme }) => theme.fontWeight.black};
	}
	@media (min-width: 1280px) {
		margin: 7px 0;
	}
	@media (min-width: 1920px) {
		margin: 12px 0;
	}
`;

const FormDescription = styled.p`
	margin-bottom: 35px;
`;
const LogoWrapper = styled.img`
	width: 60px;
	@media (min-width: 1920px) {
		width: 72px;
	}
	&.paypal {
	}
`;

const ProjectsWrapper = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-end;
	@media (min-width: 1280px) {
		margin-top: 150px;
	}
`;
const PhotoWrapper = styled.div`
	margin-bottom: 60px;
	position: relative;
	z-index: 0;
	display: none;

	overflow: hidden;

	@media (min-width: 768px) {
		display: flex;
		cursor: pointer;
	}
	@media (min-width: 1280px) {
		margin: 0 0 40px auto;
		width: calc(100% - 470px);
	}
	@media (min-width: 1366px) {
		margin-bottom: 40px;
	}
	@media (min-width: 1920px) {
		margin-bottom: 70px;
		width: calc(100% - 600px);
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: 1;
	}
	&:last-of-type {
		margin-bottom: 0;
	}

	&.tablet {
		display: none;
		@media (min-width: 768px) {
			display: flex;
		}
		@media (min-width: 1280px) {
			display: none;
		}
	}
`;
const Photo = styled.img`
	transition: transform 0.5s;

	&.active {
		transform: scale(1.1);
	}
	@media (min-width: 768px) {
		height: 650px;
		width: auto;
	}
	@media (min-width: 1280px) {
		width: 100%;
		height: 100%;
	}
`;
const Underline = styled.span`
	background-image: linear-gradient(180deg, transparent 7%, #ff4124 0);
	background-size: 0 100%;
	background-repeat: no-repeat;
	text-decoration: none;
	padding-right: 5px;
	transition: background-size 0.4s ease;
	&.active {
		background-size: 100% 100%;
		cursor: pointer;
	}
`;
//  TODO tlumaczenie wspieraj do crafta
const PhotoTitle = styled.div`
	color: ${({ theme }) => theme.colors.pmmWhite};
	font-size: 40px;
	line-height: 55px;
	font-weight: ${({ theme }) => theme.fontWeight.semiBold};
	position: absolute;
	z-index: 2;
	// TODO: temp before tablet done
	text-align: right;
	transform-origin: top right;
	transform: rotate(270deg);
	right: 140px;
	top: 50px;
	@media (min-width: 768px) {
	}
	@media (min-width: 1280px) {
		right: 50px;
		top: 30px;
		font-size: 48px;
		line-height: 63px;
		transform: none;
	}
	@media (min-width: 1366px) {
		font-size: 50px;
		line-height: 65px;
	}
	@media (min-width: 1920px) {
		top: 50px;
		right: 100px;
		font-size: 70px;
		line-height: 85px;
	}
`;

class CashFlow extends Component {
	state = {
		isDesktop: false,
		isTablet: false,
		isMobileProjectSetFromAnotherPage: false,
		isMobileOptionSelected: false,
		projectNumber: 0,
		tabNumber: 1,
		activePack: 0,
		activePackAmountPenny: 0,
		arePayURegulationsShown: false,
		arePaypalRegulationsShown: false,
		differentAmount: 0,
		differentAmountFocus: false,
		name: '',
		surname: '',
		// email: "",
		processToRegulations: false,
		goToRegulations: false,
		markRequiredCheckbox: false,
		isCheckboxClicked: false,
		isRadioButtonClicked: false,
		isWholeInfoShown: false,
		personForm: {
			firstName: '',
			lastName: '',
			email: '',
		},
		formSubmitInputValue: 0,
		isRecurring: false, // do podpiecia na froncie jak pojawia sie platnosci cykliczne
		imagesLoaded: false,
		form: {
			infoAboveForm: '',
			namePlaceholder: '',
			surnamePlaceholder: '',
			emailPlaceholder: '',
			payULogo: '',
			paypalLogo: '',
		},
		regulationsArrayPayU: [],
		regulationsArrayPayPal: [],
	};

	paymentEl;
	cashFlowWrapperTopOfPage;
	paymentElOffsetTopOfPage;
	isFirstTabVisible;

	projectsIdsAndOffsetsArray = [];
	globalProjectsArray = [];
	loadedImagesArray = [];
	resizeId = null;
	projectFromHashId = null;

	constructor(props) {
		super(props);
		this.updatePaymentEl = this.updatePaymentEl.bind(this);
		this.setProjectsIdsAndOffsetsArray = this.setProjectsIdsAndOffsetsArray.bind(this);
		this.actionsOnResize = this.actionsOnResize.bind(this);
		this.changeProject = this.changeProject.bind(this);
		this.resizeWithTimer = this.resizeWithTimer.bind(this);
	}

	getYPosition(element) {
		let yPosition = 0;
		while (element) {
			yPosition += element.offsetTop - element.scrollTop + element.clientTop;
			element = element.offsetParent;
		}
		return yPosition;
	}

	closeInfoWindow() {
		this.setState({ isWholeInfoShown: false });
	}

	toggleWholeInfoShown(key) {
		const elementId = 'regulation-point-payment ' + key;
		const elementThatWasClicked = document.getElementById(elementId);
		const findSpanInsideDiv = document.getElementsByClassName('orange');
		let isSpanInsideDiv;
		if (elementThatWasClicked && findSpanInsideDiv) {
			isSpanInsideDiv = elementThatWasClicked.contains(findSpanInsideDiv[0]);
		}
		if (isSpanInsideDiv) {
			this.setState({ isWholeInfoShown: !this.state.isWholeInfoShown });
		}
	}

	setPaymentWrapperStyleOnDesktop() {
		const indexOfLastProject = this.projectsIdsAndOffsetsArray.length - 1;
		const singleCardAdditionalWrapperEl = document.getElementById('single-card-additional-wrapper');
		const singleCardAdditionalWrapperElOffsetTop = this.getYPosition(singleCardAdditionalWrapperEl);
		const additionalOffsetTopForLastProject = window.innerWidth < 1440 ? 10 : 115;
		const stopPoint = 115 - additionalOffsetTopForLastProject;
		if (window.scrollY + stopPoint >= this.projectsIdsAndOffsetsArray[indexOfLastProject].offsetTopOfPage) {
			let lastProjectElementTarget = document.getElementById(this.projectsIdsAndOffsetsArray[indexOfLastProject].elementId);
			const topOffsetOfLastElement = lastProjectElementTarget.offsetTop + additionalOffsetTopForLastProject;
			Object.assign(this.paymentEl.style, {
				position: 'absolute',
				top: topOffsetOfLastElement + 'px',
				bottom: 'unset',
			});
		} else if (window.scrollY + 115 > singleCardAdditionalWrapperElOffsetTop) {
			Object.assign(this.paymentEl.style, { position: 'fixed', top: '115px', bottom: 'unset' });
		} else {
			Object.assign(this.paymentEl.style, { position: 'relative', top: '0', bottom: 'unset' });
		}
	}

	setPaymentWrapperStyleOnTablet() {
		const indexOfLastProject = this.projectsIdsAndOffsetsArray.length - 1;
		const projectsWrapperEl = document.getElementById('projects-wrapper');
		const projectsWrapperEllOffsetTop = this.getYPosition(projectsWrapperEl);
		if (window.scrollY + 75 >= this.projectsIdsAndOffsetsArray[indexOfLastProject].offsetTopOfPage) {
			let lastProjectElementTarget = document.getElementById(this.projectsIdsAndOffsetsArray[indexOfLastProject].elementId);
			const topOffsetOfLastElement = lastProjectElementTarget.offsetTop + 40;
			Object.assign(this.paymentEl.style, {
				position: 'absolute',
				top: topOffsetOfLastElement + 'px',
				bottom: 'unset',
			});
		} else if (window.scrollY + 115 > projectsWrapperEllOffsetTop) {
			Object.assign(this.paymentEl.style, { position: 'fixed', top: '115px', bottom: 'unset' });
		} else {
			Object.assign(this.paymentEl.style, { position: 'relative', top: '545px', bottom: 'unset' });
		}
	}

	setMobileProjectIndex(mobileActiveProject) {
		return this.globalProjectsArray
			.map(function(e) {
				return e.projectSlug;
			})
			.indexOf(mobileActiveProject);
	}

	setProjectOnMobile() {
		const { mobileActiveProject } = this.props;

		if (mobileActiveProject !== '') {
			const mobileProjectIndex = this.setMobileProjectIndex(mobileActiveProject);
			this.setState({ projectNumber: mobileProjectIndex + 1, isMobileProjectSetFromAnotherPage: true });
		} else {
			const hash = window?.location?.hash || '';
			if (hash !== '#payment-wrapper' && hash !== '') {
				const activeProjectFromUrl = hash?.substring(1) || '';
				const mobileProjectIndex = this.setMobileProjectIndex(activeProjectFromUrl);
				this.setState({
					projectNumber: mobileProjectIndex + 1,
					isMobileProjectSetFromAnotherPage: true,
				});
				const windowHeight = window.innerHeight + 45;
				window.scroll({
					top: windowHeight,
				});
			}
		}
	}

	setProjectTab() {
		const projectsAbovePaymentWrapper = this.projectsIdsAndOffsetsArray.filter(item => {
			return window.scrollY + 150 >= item.offsetTopOfPage;
		});
		const newActiveProject = projectsAbovePaymentWrapper[projectsAbovePaymentWrapper.length - 1];
		if (newActiveProject && window.scrollY + 150 >= newActiveProject.offsetTopOfPage) {
			if (this.state.projectNumber === this.projectFromHashId && this.props.differentAmountFromCalculator !== 0) {
				this.props.dispatch(setDifferentAmountFromCalculator(0));
			}

			if (this.state.projectNumber !== newActiveProject.projectId) {
				this.setState({ projectNumber: newActiveProject.projectId });
			}
		}

		if (window.scrollY + 150 < this.projectsIdsAndOffsetsArray[0].offsetTopOfPage && this.state.projectNumber !== 0) {
			this.setState({ projectNumber: 0 });
		}
	}

	updatePaymentEl() {
		if (this.state.isDesktop && !this.state.isTablet && !this.state.isMobileProjectSetFromAnotherPage) {
			this.setPaymentWrapperStyleOnDesktop();
			this.setProjectTab();
		} else if (this.state.isDesktop && this.state.isTablet && !this.state.isMobileProjectSetFromAnotherPage) {
			this.setPaymentWrapperStyleOnTablet();
			this.setProjectTab();
		} else {
			Object.assign(this.paymentEl.style, { position: 'relative', top: '0', bottom: 'unset' });
			const { dispatch } = this.props;
			dispatch(setMobileActiveProject(''));
		}
	}

	setProjectsIdsAndOffsetsArray() {
		const { data } = this.props;
		const projectsData = (data && data.modulWplatyWybierzProjekty) || [];
		this.projectsIdsAndOffsetsArray = [];
		projectsData.forEach((item, key) => {
			let elementTarget = document.getElementById(item.slug);
			let elementTargetTopOffset = this.getYPosition(elementTarget);
			this.projectsIdsAndOffsetsArray.push({
				projectId: key + 1,
				elementId: item.slug,
				offsetTopOfPage: elementTargetTopOffset,
			});
		});
	}

	checkIfTablet() {
		if (window.innerWidth >= 768 && window.innerWidth < 1280) {
			this.setState({ isTablet: true });
		} else this.setState({ isTablet: false });
	}

	setInitialTabsStates() {
		const { differentAmountFromCalculator } = this.props;

		if (this.isFirstTabVisible) {
			this.setState({
				activePack: 0,
				tabNumber: 1,
			});
		} else {
			if (this.state.differentAmountFocus || differentAmountFromCalculator !== 0) {
				this.setState({
					activePack: 3,
					tabNumber: 2,
					differentAmount: differentAmountFromCalculator,
				});
				this.setValue();
			} else {
				this.setState({
					activePack: 0,
					tabNumber: 2,
					differentAmount: 0,
				});
			}
		}
	}

	actionsOnResize() {
		this.setProjectsIdsAndOffsetsArray();

		if (typeof window !== 'undefined') {
			if (window.innerWidth >= 768 && !this.state.isDesktop) {
				this.setState({
					isDesktop: true,
					isMobileOptionSelected: false,
					projectNumber: 0,
				});
				this.setInitialTabsStates();
			} else if (window.innerWidth < 768 && this.state.isDesktop) {
				this.setState({ isDesktop: false });
				this.setInitialTabsStates();
				Object.assign(this.paymentEl.style, { position: 'relative', top: '0', bottom: 'unset' });
			}

			this.checkIfTablet();
			this.updatePaymentEl();
		}
	}

	resizeWithTimer() {
		clearTimeout(this.resizeId);
		this.resizeId = setTimeout(this.actionsOnResize, 500);
	}

	componentDidMount() {
		this.setInitialTabsStates();
		this.globalProjectsArray.forEach(item => {
			this.loadedImagesArray.push(false);
		});

		this.paymentEl = document.getElementById('payment-wrapper');
		this.paymentElOffsetTopOfPage = this.getYPosition(this.paymentEl);
		this.cashFlowWrapper = document.getElementById('cash-flow-wrapper');
		this.cashFlowWrapperTopOfPage = this.getYPosition(this.cashFlowWrapper) + this.cashFlowWrapper.offsetHeight;
		this.actionsOnResize();
		this.setProjectsIdsAndOffsetsArray();
		window.addEventListener('resize', this.resizeWithTimer);
		window.addEventListener('scroll', this.updatePaymentEl);

		const regPayU = [];
		const regPayPal = [];

		const { projectsBackBtn } = this.props;
		const globalData = (projectsBackBtn && projectsBackBtn[0] && projectsBackBtn[0]) || '';
		const globalFormData = globalData.wplatyFormularz || [];

		globalFormData.forEach(item => {
			if (item.__typename === 'Craft_wplatyFormularz_formularz_BlockType') {
				this.setState({
					form: {
						infoAboveForm: item.informacjaNadFormularzem || '',
						namePlaceholder: item.imiePlaceholder || '',
						surnamePlaceholder: item.nazwiskoPlaceholder || '',
						emailPlaceholder: item.eMailPlaceholder || '',
						payULogo: (item && item.payuLogo && item.payuLogo[0] && item.payuLogo[0].url) || '',
						paypalLogo: (item && item.paypalLogo && item.paypalLogo[0] && item.paypalLogo[0].url) || '',
					},
				});
			} else if (item.__typename === 'Craft_wplatyFormularz_regulaminPayU_BlockType') {
				regPayU.push({
					text: item.tresc || '',
					isRequired: item.zgodaWymagana || '',
					checked: false,
				});
			} else if (item.__typename === 'Craft_wplatyFormularz_regulaminPaypal_BlockType') {
				regPayPal.push({
					text: item.tresc || '',
					isRequired: item.zgodaWymagana || '',
					checked: false,
				});
			}
		});

		this.setState({ regulationsArrayPayU: regPayU, regulationsArrayPayPal: regPayPal });
		if (window.innerWidth < 768) {
			this.setProjectOnMobile();
		}
	}

	componentWillUnmount() {
		const { dispatch } = this.props;
		window.removeEventListener('resize', this.resizeWithTimer);
		window.removeEventListener('scroll', this.updatePaymentEl);
		dispatch(setMobileActiveProject(''));
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.projectNumber !== this.state.projectNumber) {
			this.setInitialTabsStates();
		} else if (prevState.imagesLoaded !== this.state.imagesLoaded) {
			this.setProjectsIdsAndOffsetsArray();
			if (window.location.hash && !this.state.isMobileProjectSetFromAnotherPage) {
				const el = document.querySelector(window.location.hash);
				if (el) {
					document.querySelector(window.location.hash).scrollIntoView();
					const projectFromHash = this.projectsIdsAndOffsetsArray.filter(el => window.location.hash.includes(el.elementId));
					this.projectFromHashId = projectFromHash[0]?.projectId || null;
				}
				this.setPaymentWrapperStyleOnDesktop();
				this.setProjectTab();
			}
		} else if (prevProps.mobileActiveProject !== this.props.mobileActiveProject && window.innerWidth < 768) {
			this.setProjectOnMobile();
		}

		if (prevState.isTablet !== this.state.isTablet) {
			this.setProjectsIdsAndOffsetsArray();
			this.updatePaymentEl();
		}
	}

	nextTab() {
		if (this.state.tabNumber < 4) {
			this.setState({ tabNumber: this.state.tabNumber + 1 });
		}
	}

	prevTab() {
		if (this.state.tabNumber > 0) {
			this.setState({ tabNumber: this.state.tabNumber - 1 });
		}
	}

	markCheckbox(name, key) {
		if (name === 'payU') {
			this.markPayUCheckbox(key);
		}
		if (name === 'payPal') {
			this.markPayPalCheckbox(key);
		}
	}

	markPayUCheckbox(key) {
		this.setState({
			regulationsArrayPayU: this.state.regulationsArrayPayU.map((item, index) =>
				key === index
					? {
							...item,
							checked: !item.checked,
					  }
					: item,
			),
		});
	}

	markPayPalCheckbox(key) {
		this.setState({
			regulationsArrayPayPal: this.state.regulationsArrayPayPal.map((item, index) =>
				key === index
					? {
							...item,
							checked: !item.checked,
					  }
					: item,
			),
		});
	}

	isAllPayURequiredCheckboxChecked() {
		return this.state.regulationsArrayPayU.find(item => item.isRequired && !item.checked);
	}

	isProcessingDataNotRequiredCheckboxCheckedPayU() {
		return this.state.regulationsArrayPayU.find(item => !item.isRequired && item.checked);
	}

	isAllPayPalRequiredCheckboxChecked() {
		return this.state.regulationsArrayPayPal.find(item => item.isRequired && !item.checked);
	}

	isProcessingDataNotRequiredCheckboxCheckedPayPal() {
		return this.state.regulationsArrayPayPal.find(item => !item.isRequired && item.checked);
	}

	markRequiredCheckboxes() {
		this.setState({ markRequiredCheckbox: true });
	}

	handleInputChange = name => event =>
		this.setState({
			personForm: {
				...this.state.personForm,
				[name]: event.target.value,
			},
		});

	handleSubmitInputChange = event => {
		const target = event.target;
		const value = target.type === 'submit' ? target.value : '';
		this.setState({ formSubmitInputValue: value });
	};

	handleSubmit = event => {
		event.preventDefault();
		try {
			if (this.state.formSubmitInputValue === '1') {
				this.showPayURegulations();
			} else if (this.state.formSubmitInputValue === '2') {
				this.showPayPalRegulations();
			} else {
			}
		} catch (err) {
			console.log(err);
		}
	};

	showPayURegulations() {
		setTimeout(() => {
			if (this.state.tabNumber < 4) {
				this.setState({ tabNumber: this.state.tabNumber + 1 });
			}
			this.setState({ arePayURegulationsShown: true });
			this.setState({ arePaypalRegulationsShown: false });
		}, 1000);
	}

	showPayPalRegulations() {
		setTimeout(() => {
			if (this.state.tabNumber < 4) {
				this.setState({ tabNumber: this.state.tabNumber + 1 });
			}
			this.setState({ arePaypalRegulationsShown: true });
			this.setState({ arePayURegulationsShown: false });
		}, 1000);
	}

	setActivePackageType(key) {
		this.setState({ activePack: key });
	}

	setAmountPenny(amount) {
		if (amount) {
			const amountPenny = parseInt(amount, 0) * 100;
			this.setState({ activePackAmountPenny: amountPenny });
		} else this.setState({ activePackAmountPenny: 0 });
	}

	handleAmountClick(key, amount) {
		this.setActivePackageType(key);
		this.setAmountPenny(amount);
	}

	handleAmountChange(e) {
		this.setState({ differentAmount: e.target.value });
	}

	setValue() {
		this.setAmountPenny(this.state.differentAmount);
	}

	getInitialAmount(initialAmount) {
		const amountWithoutUnit = initialAmount.replace('zł', '');
		return parseInt(amountWithoutUnit, 0) * 100;
	}

	changeProject(projectsArray, searchedProject) {
		if (this.props.differentAmountFromCalculator !== 0) {
			this.props.dispatch(setDifferentAmountFromCalculator(0));
		}
		const projectIndex = projectsArray
			.map(function(e) {
				return e.titleVariety;
			})
			.indexOf(searchedProject);
		this.setState({
			projectNumber: projectIndex,
			isMobileOptionSelected: true,
		});
		this.setInitialTabsStates();
	}

	handleImageLoaded() {
		const index = this.loadedImagesArray.indexOf(false);

		if (index !== -1) {
			this.loadedImagesArray[index] = true;
		}

		if (!this.loadedImagesArray.includes(false)) {
			this.setState({ imagesLoaded: true });
		}
	}

	handlePaymentType = isRecurring => {
		this.setState({ isRecurring });
	};

	handleDifferentAmountFocus = () => {
		this.setState({ differentAmountFocus: true });
	};

	handleDifferentAmountBlur = () => {
		this.setState({ differentAmountFocus: false });
		this.setValue();
	};

	scrollToClickedProject = storyAnchor => {
		if (window.innerWidth >= 768) {
			const element = document.getElementById(storyAnchor);
			window.scroll({
				behavior: 'smooth',
				top: this.getYPosition(element) - 115,
			});
		}
	};

	scrollToFirstProjectOnTablet = () => {
		const projectsWrapperEl = document.getElementById('projects-wrapper');

		window.scroll({
			behavior: 'smooth',
			top: this.getYPosition(projectsWrapperEl) - 115,
		});
	};

	render() {
		const { data, projectsBackBtn, activeSite } = this.props;
		const {
			projectNumber,
			tabNumber,
			activePack,
			arePayURegulationsShown,
			arePaypalRegulationsShown,
			isMobileOptionSelected,
			isDesktop,
			isMobileProjectSetFromAnotherPage,
		} = this.state;
		const pmmData = (data && data.modulWplatyPmm) || '';

		const pmmAdditionalPaymentsData = (data && data.modulWplatyPmmWplatyDodatkowe) || '';
		const pmmAdditionalPaymentsArray = [];
		let allFirstTabsDataArray = [];
		let amountsDataArray = [];
		let projectsArray = [];
		let mobileSelectorArray = [];
		const pmmPhotoUrl =
			(pmmData && pmmData[0] && pmmData[0].wplatyZdjecie && pmmData[0].wplatyZdjecie[0] && pmmData[0].wplatyZdjecie[0].url) || '';

		pmmAdditionalPaymentsData.forEach(item => {
			pmmAdditionalPaymentsArray.push({
				header: (item && item.naglowek) || '',
				descriptionOnHover: (item && item.opisPoNajechaniu) || '',
				image: (item && item.zdjecie && item.zdjecie[0] && item.zdjecie[0].url) || '',
				btnText: (item && item.przyciskNapis) || '',
				btnType: (item && item.przyciskRodzaj && item.przyciskRodzaj[0] && item.przyciskRodzaj[0].title) || '',
				btnTo: (item && item.przyciskWpis && item.przyciskWpis[0] && item.przyciskWpis[0].slug) || '',
				btnHref: (item && item.przyciskLink) || '',
			});
		});

		// globalne
		const globalData = (projectsBackBtn && projectsBackBtn[0] && projectsBackBtn[0]) || '';
		this.isFirstTabVisible = globalData.wplatyPierwszaZakladka;
		const bottomBtn = (globalData.wplatyPrzyciskPrzekazDarowizne && globalData.wplatyPrzyciskPrzekazDarowizne[0]) || '';
		const bottomBtnText = bottomBtn.przyciskNazwa || '';
		const bottomBtnType = (bottomBtn.przyciskRodzaj && bottomBtn.przyciskRodzaj[0] && bottomBtn.przyciskRodzaj[0].title) || '';
		const wordStep = (globalData && globalData.wplatyLicznikSlajdowSlowoKrok) || '';

		// globalne - drugi slajd
		const backBtn = (globalData.wplatyPrzyciskWstecz && globalData.wplatyPrzyciskWstecz[0]) || '';
		const backBtnText = backBtn.przyciskNapis || '';
		const backBtnIcon = (backBtn.przyciskPiktogram && backBtn.przyciskPiktogram[0] && backBtn.przyciskPiktogram[0].url) || '';
		const packGlobalBtn = (globalData.wplatyPakiety && globalData.wplatyPakiety[0]) || '';

		//globalne - formularz
		const paymentSummaryData = (globalData && globalData.wplatyPodsumowanie && globalData.wplatyPodsumowanie[0]) || '';
		const paymentSummaryAmount = (paymentSummaryData && paymentSummaryData.kwotaPodpis) || '';
		const paymentSummaryType = (paymentSummaryData && paymentSummaryData.platnoscPodpis) || '';
		const paymentSummaryProvider = (paymentSummaryData && paymentSummaryData.dostawcaPodpis) || '';
		const paymentSummaryPayOnce = (paymentSummaryData && paymentSummaryData.platnoscTypJednorazowa) || '';
		// const paymentSummaryPayMonthly = (paymentSummaryData && paymentSummaryData.platnoscTypMiesieczna) || '';
		//TODO: obsłuzyc tekst jak bedize podpięta płatność cykliczna
		// const paymentSummaryPayOnce

		const { infoAboveForm, namePlaceholder, surnamePlaceholder, emailPlaceholder, payULogo, paypalLogo } = this.state.form;
		const { regulationsArrayPayU, regulationsArrayPayPal, markRequiredCheckbox, isRadioButtonClicked } = this.state;

		let titlePmm = '';
		let titleVarietyPmm = '';
		let merchantPosIdPmm = '';
		let secondaryKeyPmm = '';
		let recurringPosIdPmm = '';
		let recurringSecondaryKeyPmm = '';

		// pmm - projekt [0]
		let pmmAmountsData = [];
		pmmData.forEach(item => {
			if (item.__typename === 'Craft_modulWplatyPmm_zakladkaWplaty_BlockType') {
				allFirstTabsDataArray.push({
					header: item.wplatyNaglowek || '',
					description: item.wplatyOpis || '',
				});
			} else if (
				item.__typename === 'Craft_modulWplatyPmm_pakietyKwota_BlockType' ||
				item.__typename === 'Craft_modulWplatyPmm_pakietInnaKwota_BlockType'
			) {
				// drugi slajd
				pmmAmountsData.push({
					amount: item.kwota || '',
					label: item.label || '',
					description: item.opisPakietu || '',
					pictogram: (item.piktogram && item.piktogram[0] && item.piktogram[0].url) || '',
				});
			} else if (item.__typename === 'Craft_modulWplatyPmm_wplataDane_BlockType') {
				titlePmm = item.tytul || '';
				titleVarietyPmm = item.tytulOdmiana || '';
				merchantPosIdPmm = item.identyfikatorWplaty || '';
				secondaryKeyPmm = item.payuDrugiKlucz || '';
				recurringPosIdPmm = item.cyklicznePayuIdentyfikator || '';
				recurringSecondaryKeyPmm = item.cyklicznePayuDrugiKlucz || '';
			}
		});

		amountsDataArray.push(pmmAmountsData);

		const projectsData = (data && data.modulWplatyWybierzProjekty) || '';

		let projectsPaymentIds = [];
		// let craftId = ""
		projectsPaymentIds.push({
			supportedCountry: titlePmm || '',
			seoName: (titlePmm && titlePmm.replace(/\s+/g, '-').toLowerCase()) || '',
			merchantPosId: merchantPosIdPmm || '',
			secondaryKey: secondaryKeyPmm || '',
			recurringPosId: recurringPosIdPmm || '',
			recurringSecondaryKey: recurringSecondaryKeyPmm || '',
		});

		projectsData.forEach(item => {
			//TODO: simplify this fragment of code
			allFirstTabsDataArray.push({
				header: item.wplatyNaglowek || '',
				description: item.wplatyOpis || '',
			});

			projectsPaymentIds.push({
				supportedCountry: item.title || '',
				seoName: (item.slug && item.slug.replace('|', '').toLowerCase()) || '',
				craftId: item.remoteId || '',
				merchantPosId: item.wplatyIdentyfikatorWplaty || '',
				secondaryKey: item.wplatyPayuDrugiKlucz || '',
				recurringPosId: item.wplatyPayuCykliczneAutoryzacja || '',
				recurringSecondaryKey: item.wplatyPayuCykliczneDrugiKlucz || '',
			});

			projectsArray.push({
				title: item.title || '',
				titleVariety: item.tytulOdmiana || '',
				photo: (item.wplatyZdjecie && item.wplatyZdjecie[0] && item.wplatyZdjecie[0].url) || '',
				photoTitleFirstLine:
					(item.wplatyTytulZdjecie && item.wplatyTytulZdjecie[0] && item.wplatyTytulZdjecie[0].pierwszaLinijka) || '',
				photoTitleSecondLine:
					(item.wplatyTytulZdjecie && item.wplatyTytulZdjecie[0] && item.wplatyTytulZdjecie[0].drugaLinijka) || '',
				projectSlug: item.slug || '',
			});

			const amounts = item.wplatyPakietyKwota || [];
			let secondTabData = [];
			amounts.forEach(amount => {
				secondTabData.push({
					amount: amount.kwota || '',
					description: amount.opisPakietu || '',
					pictogram: (amount.piktogram && amount.piktogram[0] && amount.piktogram[0].url) || '',
				});
			});

			const differentAmountData = (item.wplatyPakietInnaKwota && item.wplatyPakietInnaKwota[0]) || '';
			secondTabData.push({
				label: differentAmountData.label || '',
				description: differentAmountData.opisPakietu || '',
				pictogram:
					(differentAmountData.piktogram && differentAmountData.piktogram[0] && differentAmountData.piktogram[0].url) || '',
			});
			amountsDataArray.push(secondTabData);
		});

		mobileSelectorArray.push({
			titleVariety: titleVarietyPmm || '',
		});
		projectsArray.forEach(item => {
			mobileSelectorArray.push({
				titleVariety: item.titleVariety || '',
			});
		});

		this.globalProjectsArray = projectsArray;
		const finalProviderLogo = arePayURegulationsShown ? payULogo : arePaypalRegulationsShown ? paypalLogo : '';
		const finalProviderClassName = arePaypalRegulationsShown ? 'paypal' : '';
		const { processingPersonalData } = this.props;
		const processingData =
			(processingPersonalData &&
				processingPersonalData[0] &&
				processingPersonalData[0].informacjaOPrzetwarzaniuDanychOsobowych &&
				processingPersonalData[0].informacjaOPrzetwarzaniuDanychOsobowych[0]) ||
			'';
		const slidesCounterData = {
			currentSlideNumber: this.isFirstTabVisible ? '0' + tabNumber : '0' + (tabNumber - 1),
			allSlidesCount: this.isFirstTabVisible ? '04' : '03',
		};
		const { isWholeInfoShown } = this.state;

		return (
			<CashFlowWrapper id="cash-flow-wrapper">
				<PaymentSpace>
					<PaymentWrapperToScroll id="payment-wrapper" name={projectsPaymentIds[projectNumber].seoName}>
						<PaymentWrapper
							className={tabNumber === 1 ? 'first-tab' : tabNumber === 3 ? 'third-tab' : tabNumber === 4 ? 'fourth-tab' : ''}
						>
							<TabsHeader
								allFirstTabsDataArray={allFirstTabsDataArray}
								projectNumber={projectNumber}
								isMobileOptionSelected={isMobileOptionSelected}
								isDesktop={isDesktop}
								mobileSelectorArray={mobileSelectorArray}
								changeProject={this.changeProject.bind(this)}
								globalData={globalData}
								isMobileProjectSetFromAnotherPage={isMobileProjectSetFromAnotherPage}
							/>

							{tabNumber === 1 && (isDesktop || isMobileOptionSelected || isMobileProjectSetFromAnotherPage) && (
								<FirstTab
									projectNumber={projectNumber}
									bottomBtnType={bottomBtnType}
									bottomBtnText={bottomBtnText}
									allFirstTabsDataArray={allFirstTabsDataArray}
									nextTabMethod={this.nextTab.bind(this)}
									isDesktop={isDesktop}
									stepWord={wordStep}
									isMobileOptionSelected={isMobileOptionSelected}
									backBtnIcon={backBtnIcon}
									slidesCounterData={slidesCounterData}
									projectsPaymentIds={projectsPaymentIds}
								/>
							)}
							{tabNumber === 1 && !isDesktop && !isMobileOptionSelected && !isMobileProjectSetFromAnotherPage && (
								<FirstTabInfoMobile tabNumber={tabNumber} globalData={globalData} />
							)}
							{tabNumber === 2 && (isDesktop || isMobileOptionSelected || isMobileProjectSetFromAnotherPage) && (
								<SecondTab
									backBtnIcon={backBtnIcon}
									packGlobalBtn={packGlobalBtn}
									backBtnText={backBtnText}
									projectNumber={projectNumber}
									amountsDataArray={amountsDataArray}
									activePack={activePack}
									handleAmountMethod={this.handleAmountClick.bind(this)}
									setActivePackageTypeMethod={this.setActivePackageType.bind(this)}
									differentAmount={this.state.differentAmount}
									handleAmountChangeMethod={this.handleAmountChange.bind(this)}
									differentAmountFocus={this.state.differentAmountFocus}
									handleFocus={this.handleDifferentAmountFocus.bind(this)}
									handleBlur={this.handleDifferentAmountBlur.bind(this)}
									prevTabMethod={this.prevTab.bind(this)}
									nextTabMethod={this.nextTab.bind(this)}
									handlePaymentType={this.handlePaymentType}
									paymentType={this.state.isRecurring}
									isRadioButtonClicked={isRadioButtonClicked}
									isFirstTabVisible={this.isFirstTabVisible}
									slidesCounterData={slidesCounterData}
									activeSite={activeSite}
									stepWord={wordStep}
									projectsPaymentIds={projectsPaymentIds}
								/>
							)}
							{tabNumber === 2 && !isDesktop && !isMobileOptionSelected && !isMobileProjectSetFromAnotherPage && (
								<FirstTabInfoMobile tabNumber={tabNumber} globalData={globalData} />
							)}

							{tabNumber === 3 && (
								<>
									<FormDescription className="regular">{infoAboveForm}</FormDescription>
									<form id="payment-form" onSubmit={this.handleSubmit} ref={node => (this.node = node)}>
										<StyledInput
											placeholder={namePlaceholder}
											type="text"
											name={projectsPaymentIds[projectNumber].seoName + '-first-name'}
											value={this.state.firstName}
											onChange={this.handleInputChange('firstName')}
											required
										/>
										<StyledInput
											placeholder={surnamePlaceholder}
											type="text"
											name={projectsPaymentIds[projectNumber].seoName + '-last-name'}
											value={this.state.lastName}
											onChange={this.handleInputChange('lastName')}
											required
										/>
										<StyledInput
											placeholder={emailPlaceholder}
											type="email"
											name={projectsPaymentIds[projectNumber].seoName + '-email'}
											value={this.state.email}
											onChange={this.handleInputChange('email')}
											required
										/>
										<BottomButtonsWrapper className="providers">
											<BackBtnWrapper
												onClick={this.prevTab.bind(this)}
												name={
													projectsPaymentIds[projectNumber].seoName +
													'-powrot-krok-' +
													slidesCounterData.currentSlideNumber
												}
											>
												<ArrowBackIcon src={backBtnIcon} />
												<BackBtn className="orange">{backBtnText}</BackBtn>
											</BackBtnWrapper>
											<PaymentProvidersWrapper>
												{this.state.isRecurring ? (
													<PayuWidget
														firstName={this.state.personForm.firstName}
														lastName={this.state.personForm.lastName}
														email={this.state.personForm.email}
														merchantPosId={projectsPaymentIds[projectNumber].recurringPosId}
														secondaryKey={projectsPaymentIds[projectNumber].recurringSecondaryKey}
														supportedCountry={projectsPaymentIds[projectNumber].supportedCountry}
														craftProjectId={
															projectsPaymentIds[projectNumber].craftId
																? projectsPaymentIds[projectNumber].craftId
																: 0
														}
														totalAmount={
															activePack === 0
																? this.getInitialAmount(amountsDataArray[projectNumber][0].amount)
																: this.state.activePackAmountPenny
														}
													>
														<ValidationButtonWrapper id="pay-button">
															<ProviderIcon src={payULogo} />
														</ValidationButtonWrapper>
													</PayuWidget>
												) : (
													<>
														<ValidationButtonWrapper>
															<SingleIconWrapper
																type="submit"
																name={projectsPaymentIds[projectNumber].seoName + '-payu'}
																value="1"
																onClick={this.handleSubmitInputChange}
															></SingleIconWrapper>
															<ProviderIcon src={payULogo} />
														</ValidationButtonWrapper>
														<ValidationButtonWrapper>
															<SingleIconWrapper
																type="submit"
																name={projectsPaymentIds[projectNumber].seoName + '-paypal'}
																value="2"
																onClick={this.handleSubmitInputChange}
															></SingleIconWrapper>
															<ProviderIcon className="paypal" src={paypalLogo} />
														</ValidationButtonWrapper>
													</>
												)}
											</PaymentProvidersWrapper>
											<ColumnCenteredWrapper>
												<SlidesCounterWrapper className="third-tab">
													<StepWord className="bold custom-lh">{wordStep} </StepWord>
													<CurrentSlideNumber>{slidesCounterData.currentSlideNumber}</CurrentSlideNumber>
													<NumberOfSlides>{slidesCounterData.allSlidesCount}</NumberOfSlides>
												</SlidesCounterWrapper>
											</ColumnCenteredWrapper>
										</BottomButtonsWrapper>
									</form>
								</>
							)}
							{tabNumber === 4 && (
								<>
									<SummaryWrapper>
										<FirstColumnWrapper>
											<AmountPaymentLabel>
												{paymentSummaryAmount + ' '}
												<span>
													{activePack === 0
														? this.getInitialAmount(amountsDataArray[projectNumber][0].amount) / 100 + 'zł'
														: this.state.activePackAmountPenny / 100 + 'zł'}
												</span>
											</AmountPaymentLabel>
											<AmountPaymentLabel>
												{paymentSummaryType + ' '}
												<span>{paymentSummaryPayOnce}</span>
											</AmountPaymentLabel>
										</FirstColumnWrapper>
										<SecondColumnWrapper>
											<AmountPaymentLabel>{paymentSummaryProvider}</AmountPaymentLabel>
											<LogoWrapper className={finalProviderClassName} src={finalProviderLogo} />
										</SecondColumnWrapper>
									</SummaryWrapper>
									{arePayURegulationsShown &&
										regulationsArrayPayU.map((item, key) => {
											const text = item.text;
											const isRequired = item.isRequired;
											return (
												<RegulationsWrapper>
													<SingleRegulationsPoint
														name="isCheckboxClicked"
														className="cash-flow"
														type="checkbox"
														key={key}
														markUncheckedCheckboxes={markRequiredCheckbox}
														// isSpanClicked={this.toggleWholeInfoShown.bind(this,key)}
														required={isRequired}
														isCheckboxClicked={() => this.markCheckbox('payU', key)}
														checked={item.checked}
														seoNumber={key + 1}
														seoName={projectsPaymentIds[projectNumber].seoName + '-payu'}
													>
														<RedactorDescription
															className="regular small"
															id={'regulation-point-payment ' + key}
															onClick={this.toggleWholeInfoShown.bind(this, key)}
															dangerouslySetInnerHTML={getHtmlFromRedactorField(text)}
														/>
													</SingleRegulationsPoint>
												</RegulationsWrapper>
											);
										})}
									{isWholeInfoShown && (
										<WholeInfoProcessingPersonalData
											isFooter={false}
											isContact={false}
											isPayment={true}
											processingData={processingData}
											isSpanClicked={this.closeInfoWindow.bind(this)}
										/>
									)}
									{arePayURegulationsShown && (
										<BottomButtonsWrapper>
											<BackBtnWrapper
												onClick={this.prevTab.bind(this)}
												name={
													projectsPaymentIds[projectNumber].seoName +
													'-powrot-krok-' +
													slidesCounterData.currentSlideNumber
												}
											>
												<ArrowBackIcon src={backBtnIcon} />
												<BackBtn className="orange">{backBtnText}</BackBtn>
											</BackBtnWrapper>
											<TabButtonWrapper>
												<PayWithPayU
													btnType={bottomBtnType}
													textBtn={bottomBtnText}
													merchantPosId={projectsPaymentIds[projectNumber].merchantPosId}
													secondaryKey={projectsPaymentIds[projectNumber].secondaryKey}
													supportedCountry={projectsPaymentIds[projectNumber].supportedCountry}
													craftProjectId={projectsPaymentIds[projectNumber].craftId}
													person={this.state.personForm}
													isRecurring={this.state.isRecurring}
													disabled={this.isAllPayURequiredCheckboxChecked()}
													isProccesingDataAllowed={this.isProcessingDataNotRequiredCheckboxCheckedPayU()}
													smallerPaddingClass="smaller-padding"
													checkAllRequiredCheckboxes={this.markRequiredCheckboxes.bind(this)}
													totalAmount={
														activePack === 0
															? this.getInitialAmount(amountsDataArray[projectNumber][0].amount)
															: this.state.activePackAmountPenny
													}
													seoName={projectsPaymentIds[projectNumber].seoName}
													currentSlideNumber={slidesCounterData.currentSlideNumber}
												/>
											</TabButtonWrapper>
											<ColumnCenteredWrapper>
												<SlidesCounterWrapper className="second-tab">
													<StepWord className="bold custom-lh">{wordStep} </StepWord>
													<CurrentSlideNumber>{slidesCounterData.currentSlideNumber}</CurrentSlideNumber>
													<NumberOfSlides>{slidesCounterData.allSlidesCount}</NumberOfSlides>
												</SlidesCounterWrapper>
											</ColumnCenteredWrapper>
										</BottomButtonsWrapper>
									)}
									{arePaypalRegulationsShown &&
										regulationsArrayPayPal.map((item, key) => {
											const text = item.text;
											const isRequired = item.isRequired;
											return (
												<form id="paypal-payment-regulations">
													<RegulationsWrapper>
														<SingleRegulationsPoint
															name="isCheckboxClicked"
															className="cash-flow"
															type="checkbox"
															// isSpanClicked={this.toggleWholeInfoShown.bind(this)}
															markUncheckedCheckboxes={markRequiredCheckbox}
															required={isRequired}
															key={key}
															checked={item.checked}
															isCheckboxClicked={() => this.markCheckbox('payPal', key)}
															seoNumber={key + 1}
															seoName={projectsPaymentIds[projectNumber].seoName + '-paypal'}
														>
															<RedactorDescription
																className="regular small"
																id={'regulation-point-payment ' + key}
																onClick={this.toggleWholeInfoShown.bind(this, key)}
																dangerouslySetInnerHTML={getHtmlFromRedactorField(text)}
															/>
														</SingleRegulationsPoint>
													</RegulationsWrapper>
												</form>
											);
										})}
									{isWholeInfoShown && (
										<WholeInfoProcessingPersonalData
											isFooter={false}
											isContact={false}
											isPayment={true}
											processingData={processingData}
											isSpanClicked={this.closeInfoWindow.bind(this)}
										/>
									)}
									{arePaypalRegulationsShown && (
										<BottomButtonsWrapper>
											<BackBtnWrapper
												onClick={this.prevTab.bind(this)}
												name={
													projectsPaymentIds[projectNumber].seoName +
													'-powrot-krok-' +
													slidesCounterData.currentSlideNumber
												}
											>
												<ArrowBackIcon src={backBtnIcon} />
												<BackBtn className="orange">{backBtnText}</BackBtn>
											</BackBtnWrapper>
											<TabButtonWrapper>
												{projectNumber === 0 ? (
													<PayWithPayPal
														btnType={bottomBtnType}
														textBtn={bottomBtnText}
														person={this.state.personForm}
														isRecurring={this.state.isRecurring}
														craftProjectId={projectsPaymentIds[projectNumber].craftId}
														totalAmount={
															activePack === 0
																? this.getInitialAmount(amountsDataArray[projectNumber][0].amount)
																: this.state.activePackAmountPenny
														}
														disabled={this.isAllPayPalRequiredCheckboxChecked()}
														isProccesingDataAllowed={this.isProcessingDataNotRequiredCheckboxCheckedPayPal()}
														smallerPaddingClass="smaller-padding"
														checkAllRequiredCheckboxes={this.markRequiredCheckboxes.bind(this)}
														seoName={projectsPaymentIds[projectNumber].seoName}
														currentSlideNumber={slidesCounterData.currentSlideNumber}
													/>
												) : (
													<PayWithPayPal
														btnType={bottomBtnType}
														textBtn={bottomBtnText}
														person={this.state.personForm}
														isRecurring={this.state.isRecurring}
														craftProjectId={projectsPaymentIds[projectNumber].craftId}
														totalAmount={
															activePack === 0
																? this.getInitialAmount(amountsDataArray[projectNumber][0].amount)
																: this.state.activePackAmountPenny
														}
														smallerPaddingClass="smaller-padding"
														supportedCountry={projectsPaymentIds[projectNumber].supportedCountry}
														disabled={this.isAllPayPalRequiredCheckboxChecked()}
														isProccesingDataAllowed={this.isProcessingDataNotRequiredCheckboxCheckedPayPal()}
														checkAllRequiredCheckboxes={this.markRequiredCheckboxes.bind(this)}
														seoName={projectsPaymentIds[projectNumber].seoName}
														currentSlideNumber={slidesCounterData.currentSlideNumber}
													/>
												)}
											</TabButtonWrapper>
											<ColumnCenteredWrapper>
												<SlidesCounterWrapper className="second-tab">
													<StepWord className="bold custom-lh">{wordStep} </StepWord>
													<CurrentSlideNumber>{slidesCounterData.currentSlideNumber}</CurrentSlideNumber>
													<NumberOfSlides>{slidesCounterData.allSlidesCount}</NumberOfSlides>
												</SlidesCounterWrapper>
											</ColumnCenteredWrapper>
										</BottomButtonsWrapper>
									)}
								</>
							)}
						</PaymentWrapper>
					</PaymentWrapperToScroll>
				</PaymentSpace>
				<SingleCardAdditionalPaymentsWrapper id="single-card-additional-wrapper">
					{pmmAdditionalPaymentsArray.map((item, key) => (
						<SingleCardAdditionalPayments key={key} data={item} />
					))}
				</SingleCardAdditionalPaymentsWrapper>
				<ProjectsWrapper id="projects-wrapper">
					<PhotoWrapper onClick={this.scrollToFirstProjectOnTablet.bind(this)} className="tablet">
						<Photo src={pmmPhotoUrl} className={projectNumber === 0 ? 'active' : ''} />
						<PhotoTitle>
							<Underline className={projectNumber === 0 ? 'active' : ''}>
								Wspieraj <br /> {titleVarietyPmm}
							</Underline>
						</PhotoTitle>
					</PhotoWrapper>
					{projectsArray.map((item, key) => (
						<PhotoWrapper onClick={this.scrollToClickedProject.bind(this, item.projectSlug)} key={key} id={item.projectSlug}>
							<Photo
								onLoad={this.handleImageLoaded.bind(this)}
								src={item.photo}
								className={projectNumber === key + 1 ? 'active' : ''}
							/>
							<PhotoTitle>
								<Underline className={projectNumber === key + 1 ? 'active' : ''}>
									{item.photoTitleFirstLine}
									<br />
									{item.photoTitleSecondLine}
								</Underline>
							</PhotoTitle>
						</PhotoWrapper>
					))}
				</ProjectsWrapper>
			</CashFlowWrapper>
		);
	}
}

export default connect(
	state => ({
		mobileActiveProject: state.app.mobileActiveProject,
		differentAmountFromCalculator: state.app.differentAmountFromCalculator,
	}),
	null,
)(CashFlow);
