import React, {Component} from 'react'
import styled from "styled-components"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	padding: 60px 10px;
	background-color: ${({theme}) => theme.colors.pmmWhite};
	@media(min-width: 768px) {
		padding: 60px 30px;
	}
	@media(min-width: 1280px) {
		padding: 100px 50px;
	}
	@media(min-width: 1920px) {
		padding: 100px;
	}
`;

const HeaderWrapper = styled.p`
	font-size: 20px;
	line-height: 32px;
	position: relative;
	width: 100%;
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
	@media(min-width: 768px) {
		font-size: 25px;
		line-height: 41px;
		font-weight: ${({theme}) => theme.fontWeight.bold};
	}
	@media(min-width: 1280px) {
		width: 50%;
	}
	@media(min-width: 1920px) {
		font-size: 30px;
		line-height: 46px;
		width: 45%;
	}
`;

const ColumnsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding-top: 40px;
	@media(min-width: 1280px) {
		flex-direction: row;
		justify-content: space-between;
		padding-top: 60px;
	}
`;

const ActionsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	padding: 0;
	@media(min-width: 1280px) {
		width: 45%;
	}
`;

const RedactorDescription = styled.div`
	font-size: 13px;
	line-height: 26px;
	position: relative;
	padding-left: 30px;
	padding-bottom: 30px;
	width: 100%;
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-weight: ${({theme}) => theme.fontWeight.medium};
	&:before {
		content: '';
		position: absolute;
		top: 11px;
		left: 0;
		width: 15px;
		height: 2px;
		background-color: ${({theme}) => theme.colors.pmmOrange};
		@media(min-width: 768px) {
			width: 25px;
			height: 3px;
			top: 13px;
		}
		@media(min-width: 1920px) {
			width: 30px;
			top: 16px;
		}		
	}
	@media(min-width: 768px){
		padding-left: 60px;
		font-size: 14px;
		line-height: 28px;
	}
	@media(min-width: 1280px){
		font-size: 14px;
		line-height: 30px;
	}
	@media(min-width: 1920px){
		font-size: 18px;
		line-height: 36px;
		padding-left: 80px;
	}
	span {  
	  &.black {
	      font-weight: ${({ theme }) => theme.fontWeight.black};
	  }
	}
`;

class ListOfActions extends Component {

	render() {
		const listOfActions = this.props.data;
		const ListHeader = (listOfActions && listOfActions.modulListaDzialanNaglowek) || "";
		const listOfActionsData1Column = (listOfActions.modulListaDzialan1Kolumna) || [];
		const listOfActionsData2Column = (listOfActions.modulListaDzialan2Kolumna) || [];
		const ListOfActionsArray1Column = [];
		const ListOfActionsArray2Column = [];

		listOfActionsData1Column.forEach(item => {
			if(item.__typename === "Craft_modulListaDzialan1Kolumna_dzialanie_BlockType") {
				ListOfActionsArray1Column.push({
					"opis": (item.opis) || ""
				})
			}
		});

		listOfActionsData2Column.forEach(item => {
			if(item.__typename === "Craft_modulListaDzialan2Kolumna_dzialanie_BlockType") {
				ListOfActionsArray2Column.push({
					"opis": (item.opis) || ""
				})
			}
		});

		return (
			<ModuleWrapper>
				<HeaderWrapper>{ListHeader}</HeaderWrapper>
				<ColumnsWrapper>
					<ActionsWrapper>
						{listOfActionsData1Column.map((item, key) =>
							<RedactorDescription key={key} dangerouslySetInnerHTML={getHtmlFromRedactorField(item.opis)}/>
						)}
					</ActionsWrapper>
					<ActionsWrapper>
						{listOfActionsData2Column.map((item, key) =>
							<RedactorDescription key={key} dangerouslySetInnerHTML={getHtmlFromRedactorField(item.opis)}/>
						)}
					</ActionsWrapper>
				</ColumnsWrapper>
			</ModuleWrapper>

		)
	}
}

export default ListOfActions;
