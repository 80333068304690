import React, {Component} from "react"
import styled from "styled-components"
import Button from "../common/ButtonsSet";

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	background-color: ${({theme}) => theme.colors.pmmDarkViolet};
`;

const Header = styled.h4`
	color: ${({theme}) => theme.colors.pmmWhite};
	position: relative;
	text-align: center;
	padding: 30px 25px 20px;
	width: 100%;
	@media(min-width: 768px){
		padding: 30px 25px 15px;
	}
	@media(min-width: 1920px){
		padding: 40px 10% 20px;
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 40px;
	@media(min-width: 1280px){

	}
	@media(min-width: 1920px){
		margin-top: 45px;	
	}
`;

const Pictogram = styled.img`
	width: 200px;
	margin: 0 auto;
	@media(min-width: 1280px) {
		width: 296px;
	}
`

class SubscriptionInfo extends Component {

    render() {
        const subscriptionInfo = this.props.data;
        const subscriptionInfoData = (subscriptionInfo.modulThanksPage && subscriptionInfo.modulThanksPage[0]) ||  (subscriptionInfo.modulStartSub && subscriptionInfo.modulStartSub[0]) || (subscriptionInfo.modulCancelSub && subscriptionInfo.modulCancelSub[0]) || [];
        const subscriptionInfoPictogram = (subscriptionInfoData && subscriptionInfoData.piktogram && subscriptionInfoData.piktogram[0] && subscriptionInfoData.piktogram[0].url) || "";
        const subscriptionInfoText = (subscriptionInfoData && subscriptionInfoData.tekst) || "";
        const subscriptionInfoButtonText = (subscriptionInfoData && subscriptionInfoData.przyciskTekst) || "";
        // const subscriptionInfoButtonLink = (subscriptionInfoData && subscriptionInfoData.przyciskLink && subscriptionInfoData.przyciskLink[0] && subscriptionInfoData.przyciskLink[0].slug) || "";

        const btnType = 'pomarańczowy z serduszkiem';
        return (
            <ModuleWrapper>
                <Pictogram src={subscriptionInfoPictogram}/>
                <Header className="medium">{subscriptionInfoText}</Header>
                <ButtonWrapper>
                    <Button href="/" btnType={btnType} textBtn={subscriptionInfoButtonText}/>
                </ButtonWrapper>
            </ModuleWrapper>

        )
    }
}

export default SubscriptionInfo
