import React, {Component} from "react"
import posed, {PoseGroup} from "react-pose"
import styled from "styled-components"
import X_sign from "../../../images/newsletter/X-sign.svg"

const PosedModal = posed.div({
	enter: {
		opacity: 1,
		delay: 450,
		transition: {
			ease: 'linear',
			default: {duration: 300},
		},
	},
	exit: {
		opacity: 0,
		transition: {duration: 150},

	},
});

const PosedShade = posed.div({
	enter: {
		opacity: 1,
		y: 0,
		x: 0,
		transition: {
			ease: 'linear',
			duration: 250
		},
	},
	exit: {
		opacity: 1,
		x: 0,
		y: "-100%",
		transition: {
			ease: 'linear',
			duration: 250
		}

	},
});

const StyledPosedShade = styled(PosedShade)`
	z-index: 2000;
	background-color: ${({theme}) => theme.colors.pmmModalDarkBackground};
	position: fixed !important;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
`;

const StyledPosedModal = styled(PosedModal)`
	text-align: center;
	position: fixed !important;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: 2100;
	display: flex;
	justify-content: center;
	align-items: center;
 `;

const ModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	z-index: 10000;
`;

const XWrapper = styled.div`
	position: fixed;
	top: 45px;
	right: 25px;
	width: 19px;
	height: 19px;
	cursor: pointer;
	background-size: cover;
    background-position: center;
    background-image: url( ${({bgXImg}) => bgXImg});
    z-index: 11000;
    
    @media(min-width: 768px){
    	top: 50px;
		right: 20px;
	}
	@media(min-width: 1280px){
		top: 40px;
		right: 25px;
	}
	@media(min-width: 1920px){
		top: 60px;
		right: 60px;
	}
`
const VideoWrapper = styled.div`
	video {
		object-fit: cover;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		@media(min-width: 768px) {
			height: calc(100vh - 220px);
		}
		@media(min-width: 1280px) {
			height: calc(100vh - 120px);
		}
		@media(min-width: 1366px) {
			height: calc(100vh - 100px);
		}
		@media(min-width: 1920px) {
			height: calc(100vh - 180px);
		}
	}
`
const AudioWrapper = styled.div`
	audio {
		outline: none;
	}
`

class PopupVideoAndAudio extends Component {

	constructor(props) {
		super(props)
		this.escFunction = this.escFunction.bind(this)
	}

	escFunction(event) {
		if (this.props.isVisible && event.keyCode === 27) {
			this.setState(this.initialState);
			this.props.hide()
		}
	}

	componentDidMount() {
		document.addEventListener("keydown", this.escFunction, false)
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false)
	}

	render() {
		const {isVisible, hide, videoSrc, audioSrc} = this.props;

		return (
			<PoseGroup>
				{isVisible && [
					<StyledPosedShade pose={isVisible ? "enter" : "exit"} key="shade"/>,
					<StyledPosedModal pose={isVisible ? "enter" : "exit"} key="modal">
						<ModalWrapper>
							<XWrapper bgXImg={X_sign} onClick={hide}/>
							{videoSrc && <VideoWrapper>
								<video controls autoPlay poster="" id="video">
									<source src={videoSrc}/>
								</video>
							</VideoWrapper>
							}
							{audioSrc && <AudioWrapper>
								<audio
									controls
									src={audioSrc}>
								</audio>
							</AudioWrapper>}
						</ModalWrapper>
					</StyledPosedModal>
				]}
			</PoseGroup>
		)
	}
}

export default PopupVideoAndAudio
