import React from "react"
import Helmet from "react-helmet"


function SEO({ seoData, seoDefault }) {

	const seoTitle = seoData.seoTitle ? seoData.seoTitle : seoDefault.seoTitle ? seoDefault.seoTitle : ""
	const seoDescription = seoData.seoDescription ? seoData.seoDescription : seoDefault.seoDescription ? seoDefault.seoDescription : ""
	const seoKeywords = seoData.seoKeywordsSource ? seoData.seoKeywordsSource : seoDefault.seoKeywordsSource ? seoDefault.seoKeywordsSource : ""

	const seoFacebookTitle = seoData.facebookOpengraphTitle ? seoData.facebookOpengraphTitle : seoDefault.facebookOpengraphTitle ? seoDefault.facebookOpengraphTitle : ""
	const seoFacebookDescription = seoData.facebookOpengraphdescription ? seoData.facebookOpengraphdescription : seoDefault.facebookOpengraphdescription ? seoDefault.facebookOpengraphdescription : ""
	const seoFacebookImg = seoData.facebookOpengraphImage && seoData.facebookOpengraphImage.length > 0 ? seoData.facebookOpengraphImage[0].url : seoDefault.facebookOpengraphImage && seoDefault.facebookOpengraphImage.length > 0 ? seoDefault.facebookOpengraphImage[0].url : ""
	const seoFacebookImgDescription = seoData.facebookOpengraphImageDescription ? seoData.facebookOpengraphImageDescription : seoDefault.facebookOpengraphImageDescription ? seoDefault.facebookOpengraphImageDescription : ""

	const seoTwitterTitle = seoData.twitterTitle ? seoData.twitterTitle : seoDefault.twitterTitle ? seoDefault.twitterTitle : ""
	const seoTwitterDescription = seoData.twitterDescription ? seoData.twitterDescription : seoDefault.twitterDescription ? seoDefault.twitterDescription : ""
	const seoTwitterImg = seoData.twitterImage && seoData.twitterImage.length > 0 ? seoData.twitterImage[0].url : seoDefault.twitterImage && seoDefault.twitterImage.length > 0 ? seoDefault.twitterImage[0].url : ""
	const seoTwitterImgDescription = seoData.twitterImageDescription ? seoData.twitterImageDescription : seoDefault.twitterImageDescription ? seoDefault.twitterImageDescription : ""


	return (
		<Helmet
			title={seoTitle}
			meta={[
				{
					name: `description`,
					content: seoDescription,
				},
				{
					name: `keywords`,
					content: seoKeywords,
				},
				{
					property: `og:title`,
					content: seoFacebookTitle,
				},
				{
					property: `og:description`,
					content: seoFacebookDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					property: `og:image`,
					content: seoFacebookImg,
				},
				{
					property: `og:image:alt`,
					content: seoFacebookImgDescription,
				},
				{
					name: `twitter:card`,
					content: `summary_large_image`,
				},
				{
					name: `twitter:title`,
					content: seoTwitterTitle,
				},
				{
					name: `twitter:description`,
					content: seoTwitterDescription,
				},
				{
					property: `twitter:image`,
					content: seoTwitterImg,
				},
				{
					property: `twitter:image:alt`,
					content: seoTwitterImgDescription,
				},
			]
			}
		/>
	)
}


export default SEO
