import React from "react"
import styled from "styled-components"
import ChartWithInfoWrapper, {ChartInfo} from "../common/Charts";
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";


const Pictogram = styled.div`
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url( ${({bgImg}) => bgImg});
	width: 130px;
	height: 130px;
	
	@media(min-width: 768px){
	}
	
	@media(min-width: 1280px){
		width: 150px;
		height: 150px;
	}
	
	@media(min-width: 1366px){
	}
	
	@media(min-width: 1920px){
		width: 170px;
		height: 170px;
	}
`

const PictogramsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	padding: ${({paddingTop}) => paddingTop}px 10px  ${({paddingBottom}) => paddingBottom}px;
	
	@media(min-width: 768px){
		padding: ${({paddingTop}) => paddingTop}px 35px  ${({paddingBottom}) => paddingBottom}px;
	}
	@media(min-width: 1280px){
		padding: ${({paddingTop}) => paddingTop}px 50px  ${({paddingBottom}) => paddingBottom}px;
	}
	@media(min-width: 1920px){
		padding: ${({paddingTop}) => paddingTop}px 100px  ${({paddingBottom}) => paddingBottom}px;
	}
`

const CircleChartWrapper = styled.div`
	position: relative;
	padding-top: 30px;
	display: flex;
	align-items: center;
`

const CirclePercentage = styled.div`
	position: absolute;
	color: ${({theme}) => theme.colors.pmmOrange};
	font-weight: ${({theme}) => theme.fontWeight.bold};
	font-size: 40px;
	line-height: 45px;
	text-align: center;
	min-width: 60px;
	
	&:after {
		content: '';
		position: absolute;
		background-color: ${({theme}) => theme.colors.pmmWhite};
		top: -12px;
		left: 50%;
		transform: translateX(-50%);
		z-index: -1;
		width: 70px;
		height: 70px;
		border-radius: 100%;
	}
	
	@media(min-width: 1920px){
		font-size: 50px;
		line-height: 50px;
		&:after {
			top: -15px;
			left: 50%;
			transform: translateX(-50%);
			width: 80px;
			height: 80px;
		}
	}
`

const PercentageText = styled.div`
	color: ${({theme}) => theme.colors.pmmWhite};
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
	font-size: ${({theme}) => theme.fontSize.f6};
	min-height: 80px; 
	display: flex;
	align-items: center;
	padding-left: 85px;
		
	&.long-number {
		padding-left: 100px;
	}
	&.max-long-number {
		padding-left: 120px;
	}
	&.extra-long-number {
		padding-left: 150px;
	}
	
	@media(min-width: 375px){
		font-size: ${({theme}) => theme.fontSize.f5};
		
		&.long-number {
			padding-left: 110px;
		}
		&.max-long-number {
			padding-left: 130px;
		}
		&.extra-long-number {
			padding-left: 160px;
		}
	}
	@media(min-width: 1920px){
		font-size: ${({theme}) => theme.fontSize.f2};
		padding-left: 100px;
		
		&.long-number {
			padding-left: 130px;
		}
		&.max-long-number {
			padding-left: 155px;
		}
		&.extra-long-number {
			padding-left: 185px;
		}
	}
`

export const ChartInfoWrapper = styled.div`
	margin-top: 20px;
`


class Pictograms extends React.Component {

	render() {

		const {data} = this.props;
		const PictogramsData = (data && data.modul3Piktogramy) || ""
		const PictogramsArray = []
		let paddingTopType = '';
		let paddingBottomType = '';


		PictogramsData.forEach(item => {
			if (item.__typename === 'Craft_modul3Piktogramy_modul_BlockType') {
				PictogramsArray.push({
					"url": (item.piktogram && item.piktogram[0] && item.piktogram[0].url) || "",
					"number": item.liczba || "",
					"header": item.naglowek || "",
					"description": item.opis || ""
				})
			} else if(item.__typename === 'Craft_modul3Piktogramy_marginesy_BlockType') {
				paddingTopType = (item.marginesGorny && item.marginesGorny[0] && item.marginesGorny[0].title) || ""
				paddingBottomType = (item.marginesDolny && item.marginesDolny[0] && item.marginesDolny[0].title) || ""
			}
		})

		return (
			<PictogramsWrapper paddingTop={getPaddingTop(paddingTopType)} paddingBottom={getPaddingBottom(paddingBottomType)}>
				{PictogramsArray.map((item, key) =>
					<ChartWithInfoWrapper key={key} className="project">
						<Pictogram bgImg={item.url}/>
						<CircleChartWrapper>
							<CirclePercentage>{item.number}</CirclePercentage>
							<PercentageText className={item.number >= 100000
								? 'extra-long-number' : item.number >= 10000 ? 'max-long-number' : item.number >= 1000 ? 'long-number' : ''}>{item.header}
							</PercentageText>
						</CircleChartWrapper>
						<ChartInfoWrapper>
							<ChartInfo className="project">{item.description}</ChartInfo>
						</ChartInfoWrapper>
					</ChartWithInfoWrapper>
				)}
			</PictogramsWrapper>
		)
	}
}

export default Pictograms
