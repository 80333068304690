import React, {Component} from 'react'
import styled from "styled-components"
import GeneralAnimation from "../animations/GeneralAnimation";

const SingleStepWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	position: relative;
	margin-bottom: 50px;
	@media(min-width: 768px) {
		flex-direction: row-reverse;
		justify-content: space-between;
		padding: 0 40px;
	}
	@media(min-width: 1280px) {
		flex-direction: column;
		justify-content: flex-start;
		width: calc(100% / ${({numberOfSteps}) => numberOfSteps} );
		padding: 0 10px;
	}
	@media(min-width: 1920px) {
		padding: 0;
		flex-direction: column;
		justify-content: flex-start;
	}
`;

const PictogramWrapper = styled.div`
	position: relative;
	display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 23%;
    @media(min-width: 768px) {
    	padding: 0;
    	flex-direction: row;
    	justify-content: flex-start;
    	width: 16%;
    }
    @media(min-width: 1280px) {
    	flex-direction: row;
    	justify-content: flex-start;
    	width: auto;
    }
`;

const AnimationWrapper = styled.div`
	width: 100px;
	
	@media(min-width: 1280px) {
		height: 110px;
		width: 110px;
	}
	@media(min-width: 1920px) {
		height: 140px;
		width: 140px;
	}
	@media(min-width: 2560px) {
		height: 170px;
		width: 170px;
	}
`

const Pictogram = styled.img`
	width: 100px;
	transition: transform .2s ease-in-out;
	&:hover {
		transform: scale(1.1);
	}
 	@media(min-width: 768px) {
		width: 100px;
		left: 0;
		top: 0;
	}
	@media(min-width: 1280px) {
		max-width: 70%;
		height: 130px;
		width: auto;
	}
`;

const CircleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 23%;
	padding-top: 25px;
	@media(min-width: 500px) {
		justify-content: center;
	}	
	@media(min-width: 768px) {
		width: 105px;
		justify-content: flex-start;
	}
	@media(min-width: 1280px) {
		padding-top: 40px;
		padding-bottom: 30px;
	}
	@media(min-width: 1920px) {
		padding-bottom: 15px;
	}
`;

const Circle = styled.div`
	width: 50px;
	height: 50px;
	position: relative;
	border-radius: 50%;
	border: 1px solid ${({theme}) => theme.colors.pmmOrange};
	background: none;
`;

const StepNumber = styled.p`
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	font-weight: ${({theme}) => theme.fontWeight.bold};
	color: ${({theme}) => theme.colors.pmmDarkBlue};
	font-size: 26px;
	line-height: 28px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	@media(min-width: 1920px) {
		font-size: 28px;
		line-height: 28px;
	}
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	@media(min-width: 768px) {
		width: 84%;
	}
	@media(min-width: 1280px) {
		flex-direction: column;
		width: 100%;
	}
`;

const StepWrapper = styled.div`
	display: flex; 
	flex-direction: column;
	width: 77%;
	@media(min-width: 768px) {
		width: calc(100% - 105px);
		padding-right: 35px;
	}
	@media(min-width: 1280px) {
		margin-left: 10px;
		width: 100%;
	}
	@media(min-width: 1920px) {
		margin-left: 15px;
	}
`;

const StepTitle = styled.p`
	color: ${({theme}) => theme.colors.pmmDarkBlue};
	text-transform: uppercase;
	position: relative;
	padding-bottom: 10px;
	margin-top: 20px;
	@media(min-width: 1280px) {
		padding-bottom: 20px;
	}
	@media(min-width: 1920px) {
		margin-top: 45px;
		padding-bottom: 30px;
	}
	&::before {
		content: '';
		position: absolute;
		top: -10px;
		left: 0;
		width: 20px;
		height: 2px;
		display: none;
		background-color: ${({theme}) => theme.colors.pmmOrange};
		@media(min-width: 768px) {
			top: -20px;
		}	
		@media(min-width: 1280px) {
			display: block;
		}
	}
`;

const StepDescription = styled.p`
	color: ${({theme}) => theme.colors.pmmDarkBlue};
`;

class SingleStep extends Component {

	state = {
		isAnimationActive: false
	};

	constructor(props) {
		super(props);
		this.mouseEnter = this.mouseEnter.bind(this);
		this.mouseLeave = this.mouseLeave.bind(this);
	}

	mouseEnter() {
		this.setState({isAnimationActive: true});
	}

	mouseLeave() {
		this.setState({isAnimationActive: false});
	}

	componentDidMount() {
		this.singleStepEl.addEventListener("mouseenter", this.mouseEnter);
		this.singleStepEl.addEventListener("mouseleave", this.mouseLeave);
	}

	componentWillUnmount() {
		this.singleStepEl.removeEventListener('mouseenter', this.mouseEnter)
		this.singleStepEl.removeEventListener('mouseleave', this.mouseLeave)
	}

	render() {
		const {numberOfSteps, step, stepNumber} = this.props
		return (
			<SingleStepWrapper numberOfSteps={numberOfSteps} ref={node => this.singleStepEl = node}>
				<PictogramWrapper>
					{step.animation ? <AnimationWrapper><GeneralAnimation animationJson={step.animation}
																		  isAnimationActive={this.state.isAnimationActive}
																		  isAnimationTriggerFromParent={true}/></AnimationWrapper> :
						<Pictogram src={step.pictogram}/>
					}
				</PictogramWrapper>
				<TextWrapper>
					<CircleWrapper>
						<Circle>
							<StepNumber>
								{stepNumber + 1}
							</StepNumber>
						</Circle>
					</CircleWrapper>
					<StepWrapper>
						<StepTitle className="h7">{step.title}</StepTitle>
						<StepDescription>{step.description}</StepDescription>
					</StepWrapper>
				</TextWrapper>
			</SingleStepWrapper>
		)
	}
}

export default SingleStep;
