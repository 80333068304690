import React, {Component} from 'react';
import styled from "styled-components"
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";

const ColumnsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding: ${({paddingTop}) => paddingTop}px 10px ${({paddingBottom}) => paddingBottom}px;
	background-color: ${({theme}) => theme.colors.pmmWhite};
	flex-direction: column;
	
	@media(min-width: 768px){
		padding: ${({paddingTop}) => paddingTop}px 35px ${({paddingBottom}) => paddingBottom}px;
		flex-direction: row;
	}
	@media(min-width: 1280px){
		padding: ${({paddingTop}) => paddingTop}px 55px ${({paddingBottom}) => paddingBottom}px;
	}
	@media(min-width: 1920px){
		padding: ${({paddingTop}) => paddingTop}px 100px ${({paddingBottom}) => paddingBottom}px;
	}
`

const Column = styled.div`
	color: ${({theme}) => theme.colors.pmmDarkBlue};
	width: 100%;
	
	@media(min-width: 768px){
		width: 45%;
		margin-right: 4%;
		
		&:nth-of-type(2n) {
			margin-right: 0;
		}
	}
	@media(min-width: 1280px){
		width: 35%;
		margin-right: 5%;
		&:nth-of-type(2n) {
			margin-right: 5%;
		}
		&:nth-of-type(3n) {
			width: 20%;
			margin-right: 0;
		}
	}
`
const ColumnHeader = styled.h4`
	color: ${({theme}) => theme.colors.pmmDarkBlue};
	position: relative;
	margin-bottom: 40px;
	
	span {
		position: relative;
		z-index: 1;
		background-color: white;
		display: table;
		padding: 2px 15px 0 0;
	}
	&:after {
		content: '';
		position: absolute;
		z-index: 0;
		width: 100%;
		height: 2px;
		background-color: ${({theme}) => theme.colors.pmmDarkBlue};
		left: 0;
		bottom: 8px;
		@media(min-width: 768px){
			bottom: 10px;
		}
		@media(min-width: 1440px){
			bottom: 11px;
		}
		@media(min-width: 1920px){
			bottom: 12px;
		}
	}
`
const PhotoAndContactDataWrapper = styled.div`
	display: flex;
	flex-direction: column;
	
	@media(min-width: 768px) {
		flex-direction: row;
  	}
  	@media(min-width: 1920px) {
		min-width: 340px;
	}
`

const PictureWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	position: relative;
	background: none;
	overflow: hidden;
	width: 200px;
	height: 200px;
	@media(min-width: 768px) {
		width: 210px;
		height: 210px;
	}
	@media(min-width: 1280px) {
		width: 215px;
		height: 215px;
	}
	@media(min-width: 1366px) {
		width: 230px;
		height: 230px;
	}
	@media(min-width: 1920px) {
		width: 340px;
		height: 340px;
	}
	@media(min-width: 2200px) {
		width: 320px;
		height: 320px;
	}
`

const ZoomedPicture = styled.div`
    position: absolute;
  	width: 200px;
	height: 200px;
    transition: transform .5s;
    background-size: cover;
    background-position: top;
    background-image: url( ${({bgImg}) => bgImg});
    background-repeat: no-repeat;
    &.animate {
   		transform: scale(1.1);
    }
    &:hover {
    	transform: scale(1.1);
    }  
    
    @media(min-width: 768px) {
		width: 210px;
  	}
  	@media(min-width: 1280px) {
		margin-bottom: 30px;
		width: 215px;
  	}
  	@media(min-width: 1366px) {
		width: 230px;
	}
	@media(min-width: 1920px) {
		width: 340px;
		height: 340px;
  }
  	@media(min-width: 2200px) {
		width: 320px;
		height: 320px;
	}
`;

const PersonInfoWrapper = styled.div`
	border: 2px solid ${({theme}) => theme.colors.pmmOrange};
	margin-top: -30px;
	position: relative;
	width: 200px;
	@media(min-width: 768px) {
		width: 210px;
  	}
	@media(min-width: 1280px) {
		margin-bottom: 30px;
		width: 215px;
  	}
  	@media(min-width: 1366px) {
		width: 230px;
	}
  	 @media(min-width: 1920px) {
		margin-bottom: 0;
		width: 340px;
  	}
  	@media(min-width: 2200px) {
		width: 320px;
	}
`
const TileImgDescription = styled.p`
	padding: 10px;
	background-color: ${({theme}) => theme.colors.pmmOrange};
	display: table;
`
const ColumnDescription = styled.div`
	padding: 50px 0 80px;
`
const ContactData = styled.h5`
	margin-top: 50px;
	line-height: 30px;

	@media(min-width: 768px) {
		align-items: center;
		justify-content: center;
  	}
`
const Table = styled.div`
	@media(min-width: 1920px){
		padding-right: 70px;
	}
	p {
		padding-bottom: 40px;
	}
	li {
		display: flex;
		color: ${({theme}) => theme.colors.pmmTextOrange};
		padding: 10px 0;
		border-bottom: 1px solid ${({theme}) => theme.colors.pmmDarkestBlue};
		font-size: ${({theme}) => theme.fontSize.f10};
		font-weight: ${({theme}) => theme.fontWeight.medium};
		line-height:  ${({theme}) => theme.lineHeight.line6};

		span {
			color: ${({theme}) => theme.colors.pmmDarkestBlue};
			margin-right: 5px;
		}
			
		&:last-of-type {
			border-bottom: none;
		}	
			
		@media(min-width: 375px) {
			font-size: ${({theme}) => theme.fontSize.f12};
		}	
		@media(min-width: 768px){
			padding: 5px 0;
			width: 100%;
		}

		@media(min-width: 1920px){
			font-size: ${({theme}) => theme.fontSize.f3};
			justify-content: flex-start;
		}
	}
`
const ContentWrapper = styled.div`
	padding: 0 10px 40px;
`

const TextBox = styled.div`
	width: 100%;
	min-height: 550px;
	padding: 45px 110px 45px 0;
	display: flex;
	flex-direction: column;
`;

const SingleInfoWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 100%;
	padding: 5px 0;
`;
const PictogramWrapper = styled.div`
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: flex-start;
	padding-right: 20px;
	
`;
const Pictogram = styled.img`
	width: 24.5px;
	height: 35px;
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const Placeholder = styled.p`
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	text-transform: uppercase;
`;

const Info = styled.p`
	font-size: 20px;
	line-height: 34px;
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	font-weight: ${({theme}) => theme.fontWeight.regular};
	padding-bottom: 40px;
	&.price {
		font-weight: ${({theme}) => theme.fontWeight.black};
	}
`;

class Enrollment extends Component {
	render() {
		const {data} = this.props
		const GlobalCourses = this.props.szkolenie;
		const GlobalCoursesData = (GlobalCourses && GlobalCourses[0]) || "";
		const datePlaceholder = GlobalCoursesData.szkolenieWizytowkaTerminPodpis || "";
		const placePlaceholder = GlobalCoursesData.szkolenieWizytowkaMiejscePodpis || "";
		const seatsLeftPlaceholder = GlobalCoursesData.szkolenieWizytowkaPozostaloPodpis || "";
		const pricePlaceholder = GlobalCoursesData.szkolenieWizytowkaCenaPodpis || "";
		const datePictogram = (GlobalCoursesData.szkolenieWizytowkaTerminPiktogram && GlobalCoursesData.szkolenieWizytowkaTerminPiktogram[0] &&
			GlobalCoursesData.szkolenieWizytowkaTerminPiktogram[0].url) || "";
		const placePictogram = (GlobalCoursesData.szkolenieWizytowkaMiejscePiktogram && GlobalCoursesData.szkolenieWizytowkaMiejscePiktogram[0] &&
			GlobalCoursesData.szkolenieWizytowkaMiejscePiktogram[0].url) || "";
		const seatsLeftPictogram = (GlobalCoursesData.szkolenieWizytowkaPozostaloPiktogram && GlobalCoursesData.szkolenieWizytowkaPozostaloPiktogram[0] &&
			GlobalCoursesData.szkolenieWizytowkaPozostaloPiktogram[0].url) || "";
		const pricePictogram = (GlobalCoursesData.szkolenieCenaPiktogram && GlobalCoursesData.szkolenieCenaPiktogram[0] &&
			GlobalCoursesData.szkolenieCenaPiktogram[0].url) || "";

		const firstColumn = (data && data.modulZapisy1Kolumna && data.modulZapisy1Kolumna[0]) || ""
		const firstColumnHeader = firstColumn.naglowek || ""
		const courseData = (firstColumn.szkolenie && firstColumn.szkolenie[0]) || ""

		const date = courseData.szkolenieWizytowkaTermin || "";
		const place = courseData.szkolenieWizytowkaMiejsce || "";
		const wholeAddress = courseData.szkolenieWizytowkaAdresDokladny || "";
		const seatsLeft = courseData.szkolenieWizytowkaPozostalo || "";
		const allSeats = courseData.szkolenieWizytowkaWszystkichMiejsc || "";
		const price = courseData.szkolenieWizytowkaCena || "";
		// const slug = courseData.slug || "";

		const finalAddress = (wholeAddress !== "") ? wholeAddress : place;

		const secondColumn = (data && data.modulZapisy2Kolumna && data.modulZapisy2Kolumna[0]) || ""
		const secondColumnHeader = secondColumn.naglowek || ""
		const secondColumnDescription = secondColumn.opis || ""
		const secondColumnTableName = secondColumn.tytulTabeli || ""
		const secondColumnTable = secondColumn.tabela || []

		const thirdColumn = (data && data.modulZapisy3Kolumna && data.modulZapisy3Kolumna[0]) || ""
		const thirdColumnHeader = thirdColumn.naglowek || ""
		const thirdColumnPhoto = (thirdColumn.zdjecie && thirdColumn.zdjecie[0] && thirdColumn.zdjecie[0].url) || ""
		const thirdColumnPhotoDescription = thirdColumn.zdjeciePodpis || ""
		const thirdColumnContactData = thirdColumn.daneKontaktowe || ""

		const margins = (data.marginesy && data.marginesy[0]) || ""
		const paddingTopType = (margins && margins.marginesGorny && margins.marginesGorny[0] && margins.marginesGorny[0].title) || ""
		const paddingBottomType = (margins && margins.marginesDolny && margins.marginesDolny[0] && margins.marginesDolny[0].title) || ""

		return (
			<ColumnsWrapper paddingTop={getPaddingTop(paddingTopType)}
							paddingBottom={getPaddingBottom(paddingBottomType)}>
				<Column>
					<ColumnHeader className="semi-bold">
						<span>
							{firstColumnHeader}
						</span>
					</ColumnHeader>
					<TextBox>
						{date && <SingleInfoWrapper>
							<PictogramWrapper>
								<Pictogram src={datePictogram}/>
							</PictogramWrapper>
							<TextContainer>
								<Placeholder className="h8 black">{datePlaceholder}</Placeholder>
								<Info>{date}</Info>
							</TextContainer>
						</SingleInfoWrapper>}
						{finalAddress && <SingleInfoWrapper>
							<PictogramWrapper>
								<Pictogram src={placePictogram}/>
							</PictogramWrapper>
							<TextContainer>
								<Placeholder className="h8 black">{placePlaceholder}</Placeholder>
								<Info>{finalAddress}</Info>
							</TextContainer>
						</SingleInfoWrapper>}
						{(seatsLeft && allSeats) && <SingleInfoWrapper>
							<PictogramWrapper>
								<Pictogram src={seatsLeftPictogram}/>
							</PictogramWrapper>
							<TextContainer>
								<Placeholder className="h8 black">{seatsLeftPlaceholder}</Placeholder>
								<Info>{seatsLeft} / {allSeats}</Info>
							</TextContainer>
						</SingleInfoWrapper>}
						{price && <SingleInfoWrapper>
							<PictogramWrapper>
								<Pictogram src={pricePictogram}/>
							</PictogramWrapper>
							<TextContainer>
								<Placeholder className="h8 black">{pricePlaceholder}</Placeholder>
								<Info className="price">{price}</Info>
							</TextContainer>
						</SingleInfoWrapper>}
					</TextBox>

				</Column>
				<Column>
					<ColumnHeader className="semi-bold">
						<span>
							{secondColumnHeader}
						</span>
					</ColumnHeader>
					<ColumnDescription className="paragraph"
									   dangerouslySetInnerHTML={getHtmlFromRedactorField(secondColumnDescription)}/>
					<Table>
						<p className="h7">{secondColumnTableName}</p>
						{secondColumnTable.map((item, key) =>
							<li key={key}><span>{item.opis}</span>{item.dane}</li>
						)}
					</Table>
				</Column>
				<Column>
					<ColumnHeader className="semi-bold">
						<span>
							{thirdColumnHeader}
						</span>
					</ColumnHeader>
					<PhotoAndContactDataWrapper>
						<PictureWrapper>
							<ZoomedPicture bgImg={thirdColumnPhoto}/>
						</PictureWrapper>
					</PhotoAndContactDataWrapper>
					<PersonInfoWrapper>
						<TileImgDescription className="h7 white">{thirdColumnPhotoDescription}</TileImgDescription>
						<ContentWrapper>
							<ContactData className="dark-blue semi-bold"
										 dangerouslySetInnerHTML={getHtmlFromRedactorField(thirdColumnContactData)}/>
						</ContentWrapper>
					</PersonInfoWrapper>
				</Column>
			</ColumnsWrapper>
		);
	}
}

export default Enrollment;
