import React, { Component } from 'react';
import styled from 'styled-components';
import SingleCourseCard from './SingleCourseCard';

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	//min-height: 400px;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.pmmWhite};
	padding: 40px 15px;
	@media (min-width: 768px) {
		padding: 40px 30px;
	}
	@media (min-width: 1280px) {
		//min-height: 700px;
		padding: 40px 100px;
	}
`;

// const PictureWrapper = styled.div`
// 	margin: 40px auto 0;
// 	width: 70px;
// 	height: 88px;
// 	background-size: 71px 88px;
//     background-position: center;
//     background-image: url( ${({bgImg}) => bgImg});
//     position: relative;
//     &.pictogram-empty {
//     	margin: 0 auto;
//     }
// 	&:before {
// 		content: '';
// 		position: absolute;
// 		width: 140%;
// 		height: 4px;
// 		background-color: ${({theme}) => theme.colors.pmmWhite};
// 		top: 120px;
// 		left: -20%;
// 	}
// 	@media(min-width: 768px) {
// 		margin: 0 auto;
// 	}
// `;

// const RedactorHeader = styled.div`
// 	font-size: 30px;
// 	line-height: 50px;
// 	position: relative;
// 	text-align: center;
// 	padding: 100px 10% 0;
// 	width: 100%;
// 	color: ${({theme}) => theme.colors.pmmWhite};
// 	font-family: ${({theme}) => theme.fontFamily.mainFont};
// 	font-weight: ${({theme}) => theme.fontWeight.semiBold};
// 	@media(min-width: 768px){
// 		font-size: 36px;
// 		line-height: 50px;
// 		padding: 100px 10% 10px;
// 	}
// 	@media(min-width: 1280px){
// 		font-size: 50px;
// 		line-height: 75px;
// 		padding-bottom: 0;
// 	}
// 	@media(min-width: 1366px){
// 	}
// 	@media(min-width: 1920px){
// 	}
// 	span {
// 	  &.black {
// 	      font-weight: ${({theme}) => theme.fontWeight.black};
// 	  }
// 	}
// 	&.header-empty {
// 		padding: 0;
// 	}
// `;

// const RedactorDescription = styled.div`
// 	position: relative;
// 	text-align: center;
// 	padding: 30px 10% 40px;
// 	width: 100%;
// 	color: ${({theme}) => theme.colors.pmmWhite};
// 	span {
// 	  &.black {
// 	      font-weight: ${({theme}) => theme.fontWeight.black};
// 	  }
// 	}
// 	&.header-empty {
// 		font-size: 28px;
// 		line-height: 36px;
// 		padding: 35px 20px 40px;
// 		margin-top: 40px;
// 		@media(min-width: 768px) {
// 			padding: 35px 50px 40px;
// 		}
// 		@media(min-width: 1280px) {
// 			padding: 35px 120px 40px;
// 		}
// 		@media(min-width: 1920px) {
// 			padding: 50px 15% 60px;
// 		}
// 	}
// 	&.header-not-empty {
// 		font-size: 20px;
// 		line-height: 32px;
// 		font-weight: ${({theme}) => theme.fontWeight.regular};
// 		padding: 10px 10% 30px;
// 		@media(min-width: 768px) {
// 			font-size: 28px;
// 			line-height: 52px;
// 		}
// 	}
// `;
// const ButtonWrapper = styled.div`
// 	display: flex;
// 	flex-direction: row;
// 	justify-content: center;
// 	margin-bottom: 40px;
// 	@media(min-width: 768px){
// 		margin-bottom: 50px;
// 	}
// `;

class CoursesCards extends Component {
	render() {
		const CoursesCards = this.props.data;
		const GlobalCourses = this.props.szkolenie;
		const { activeSite } = this.props;
		const CoursesCardsData = CoursesCards.modulWizytowkiSzkolen;
		const GlobalCoursesData = (GlobalCourses && GlobalCourses[0]) || '';
		const singleCourseData = [];

		CoursesCardsData.forEach(item => {
			singleCourseData.push({
				title: item.szkolenieWizytowkaTytul || '',
				image: (item.szkolenieWizytowkaZdjecie && item.szkolenieWizytowkaZdjecie[0] && item.szkolenieWizytowkaZdjecie[0].url) || '',
				date: item.szkolenieWizytowkaTermin || '',
				place: item.szkolenieWizytowkaMiejsce || '',
				seatsLeft: item.szkolenieWizytowkaPozostalo || '',
				allSeats: item.szkolenieWizytowkaWszystkichMiejsc || '',
				price: item.szkolenieWizytowkaCena || '',
				slug: item.slug || '',
			});
		});

		return (
			<ModuleWrapper>
				{singleCourseData.map((course, key) => (
					<SingleCourseCard key={key} activeSite={activeSite} index={key} data={course} globalCourses={GlobalCoursesData} />
				))}
			</ModuleWrapper>
		);
	}
}

export default CoursesCards;
