import React from 'react';
import styled from 'styled-components';
import { getPaddingBottom, getPaddingTop } from '../../utils/setModulePadding';
import setBackgroundColor from '../../utils/setBackgroundColor';

const ColumnsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding: ${({ paddingTop }) => paddingTop}px 10px ${({ paddingBottom }) => paddingBottom}px;
	background-color: ${({ background, theme }) => setBackgroundColor(background, theme)};
	flex-direction: column;

	@media (min-width: 768px) {
		padding: ${({ paddingTop }) => paddingTop}px 35px ${({ paddingBottom }) => paddingBottom}px;
		flex-direction: row;
	}
	@media (min-width: 1280px) {
		padding: ${({ paddingTop }) => paddingTop}px 55px ${({ paddingBottom }) => paddingBottom}px;
	}
	@media (min-width: 1920px) {
		padding: ${({ paddingTop }) => paddingTop}px 100px ${({ paddingBottom }) => paddingBottom}px;
	}
`;

const Column = styled.div`
	color: ${({ theme }) => theme.colors.pmmDarkBlue};
	width: 100%;

	@media (min-width: 768px) {
		width: 45%;
		margin-right: 5%;

		&:nth-of-type(2n) {
			margin-right: 0;
		}
	}
	@media (min-width: 1280px) {
		width: 30%;
		margin-right: 5%;
		&:nth-of-type(2n) {
			margin-right: 5%;
		}
		&:nth-of-type(3n) {
			margin-right: 0;
		}
	}
`;

const ColumnHeaderWrapper = styled.div`
	width: 100%;
	display: flex;
	border-bottom: 2px solid ${({ theme }) => theme.colors.pmmDarkestBlue};
	margin-bottom: 40px;
`;
const ColumnHeader = styled.h5`
	background-color: ${({ theme }) => theme.colors.pmmWhite};
	margin-bottom: -2px;
	margin-right: 30px;
	border: 2px solid ${({ theme }) => theme.colors.pmmDarkestBlue};
	color: ${({ theme }) => theme.colors.pmmDarkBlue};
	padding: 8px 20px;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		bottom: -2px;
		right: -17px;
		height: 2px;
		width: 15px;
		background-color: white;
	}
	@media (min-width: 768px) {
		&:after {
			right: -32px;
			width: 30px;
		}
	}
`;
const ColumnContent = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 20px;
`;

const ColumnInfo = styled.div`
	width: auto;
	margin-left: 30px;
`;
const PictureWrapper = styled.div`
	width: 100px;

	&.logo {
		cursor: pointer;
	}
	@media (min-width: 1366px) {
		width: 120px;
	}
	@media (min-width: 1920px) {
		width: 172px;
	}
`;

const Picture = styled.div`
	background-image: url(${props => props.back});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 100px;
	height: 100px;

	&.circle {
		border-radius: 100%;
	}
	@media (min-width: 1366px) {
		width: 120px;
		height: 120px;
	}
	@media (min-width: 1920px) {
		width: 172px;
		height: 172px;
	}
`;

const NameStyled = styled.div`
	color: ${({ theme }) => theme.colors.pmmOrange};
	font-weight: ${({ theme }) => theme.fontWeight.semiBold};
	font-size: ${({ theme }) => theme.fontSize.f17};
	line-height: ${({ theme }) => theme.lineHeight.line22};
	margin-bottom: 20px;
`;

const DescriptionStyled = styled.div`
	color: ${({ theme }) => theme.colors.pmmDarkViolet};
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	font-size: ${({ theme }) => theme.fontSize.f12};
	line-height: ${({ theme }) => theme.lineHeight.line22};
`;
export const setMarginsAndBg = marginsAndBg => {
	let moduleBg = '';
	let paddingTopType = '';
	let paddingBottomType = '';

	marginsAndBg.forEach(item => {
		if (item.__typename === 'Craft_marginesyIBackground_marginesy_BlockType') {
			paddingTopType = (item.marginesGorny && item.marginesGorny[0] && item.marginesGorny[0].title) || '';
			paddingBottomType = (item.marginesDolny && item.marginesDolny[0] && item.marginesDolny[0].title) || '';
		} else if (item.__typename === 'Craft_marginesyIBackground_background_BlockType') {
			moduleBg = item.background && item.background[0] && item.background[0].title;
		}
	});
	return { moduleBg: moduleBg, paddingTopType: paddingTopType, paddingBottomType: paddingBottomType };
};

class WhoHelps extends React.Component {
	render() {
		const { data } = this.props;
		const marginsAndBg = (data && data.marginesyIBackground) || [];
		const firstColumnData = (data && data.modulKtoPomaga1Kolumna) || [];
		const secondColumnData = (data && data.modulKtoPomaga2Kolumna) || [];
		const thirdColumnData = (data && data.modulKtoPomaga3Kolumna) || [];

		let headersArray = [];
		let firstColumn = [];
		let secondColumn = [];
		let thirdColumn = [];

		let { moduleBg, paddingTopType, paddingBottomType } = setMarginsAndBg(marginsAndBg);

		firstColumnData.forEach(item => {
			if (item.__typename === 'Craft_modulKtoPomaga1Kolumna_naglowek_BlockType') {
				headersArray.push(item);
			} else if (item.__typename === 'Craft_modulKtoPomaga1Kolumna_dane_BlockType') {
				firstColumn.push({
					nazwa: item.nazwa || '',
					opis: item.opis || '',
					zdjecie: (item.zdjecie && item.zdjecie[0] && item.zdjecie[0].url) || '',
				});
			}
		});

		secondColumnData.forEach(item => {
			if (item.__typename === 'Craft_modulKtoPomaga2Kolumna_naglowek_BlockType') {
				headersArray.push(item);
			} else if (item.__typename === 'Craft_modulKtoPomaga2Kolumna_dane_BlockType') {
				secondColumn.push({
					nazwa: item.nazwa || '',
					opis: item.opis || '',
					logo: (item.logo && item.logo[0] && item.logo[0].url) || '',
					logoLink: item.logoLink || '',
				});
			}
		});

		thirdColumnData.forEach(item => {
			if (item.__typename === 'Craft_modulKtoPomaga3Kolumna_naglowek_BlockType') {
				headersArray.push(item);
			} else if (item.__typename === 'Craft_modulKtoPomaga3Kolumna_dane_BlockType') {
				thirdColumn.push({
					nazwa: item.nazwa || '',
					opis: item.opis || '',
					logo: (item.logo && item.logo[0] && item.logo[0].url) || '',
					logoLink: item.logoLink || '',
				});
			}
		});

		const column1Header = headersArray?.[0]?.naglowek || '';
		const column2Header = headersArray?.[1]?.naglowek || '';
		const column3Header = headersArray?.[2]?.naglowek || '';

		return (
			<ColumnsWrapper
				background={moduleBg}
				paddingTop={getPaddingTop(paddingTopType)}
				paddingBottom={getPaddingBottom(paddingBottomType)}
			>
				{column1Header && (
					<Column>
						<ColumnHeaderWrapper>
							<ColumnHeader className="header-with-line">{column1Header}</ColumnHeader>
						</ColumnHeaderWrapper>

						{firstColumn.map((personData, key) => (
							<ColumnContent key={key}>
								<PictureWrapper>
									<Picture className="circle" back={personData.zdjecie} />
								</PictureWrapper>
								<ColumnInfo>
									<NameStyled>{personData.nazwa}</NameStyled>
									<DescriptionStyled>{personData.opis}</DescriptionStyled>
								</ColumnInfo>
							</ColumnContent>
						))}
					</Column>
				)}
				{column2Header && (
					<Column>
						<ColumnHeaderWrapper>
							<ColumnHeader className="header-with-line">{column2Header}</ColumnHeader>
						</ColumnHeaderWrapper>
						{secondColumn.map((personData, key) => (
							<ColumnContent key={key}>
								<PictureWrapper className="logo" as="a" href={personData.logoLink} target="_blank">
									<Picture back={personData.logo} />
								</PictureWrapper>
								<ColumnInfo>
									<NameStyled>{personData.nazwa}</NameStyled>
									<DescriptionStyled>{personData.opis}</DescriptionStyled>
								</ColumnInfo>
							</ColumnContent>
						))}
					</Column>
				)}
				{column3Header && (
					<Column>
						<ColumnHeaderWrapper>
							<ColumnHeader className="header-with-line">{column3Header}</ColumnHeader>
						</ColumnHeaderWrapper>
						{thirdColumn.map((personData, key) => (
							<ColumnContent key={key}>
								<PictureWrapper className="logo" as="a" href={personData.logoLink} target="_blank">
									<Picture back={personData.logo} />
								</PictureWrapper>
								<ColumnInfo>
									<NameStyled>{personData.nazwa}</NameStyled>
									<DescriptionStyled>{personData.opis}</DescriptionStyled>
								</ColumnInfo>
							</ColumnContent>
						))}
					</Column>
				)}
			</ColumnsWrapper>
		);
	}
}

export default WhoHelps;
