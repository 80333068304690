import React, {Component} from 'react';
import styled from "styled-components"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";

const PictogramsWrapper = styled.div`
	color: ${({theme}) => theme.colors.pmmWhite};
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	padding: ${({paddingTop}) => paddingTop}px 10px ${({paddingBottom}) => paddingBottom}px;
	
	@media(min-width: 768px) {
		padding: ${({paddingTop}) => paddingTop}px 35px ${({paddingBottom}) => paddingBottom}px;
	}
	
	@media(min-width: 1280px) {
		padding: ${({paddingTop}) => paddingTop}px 50px ${({paddingBottom}) => paddingBottom}px;
	}
	@media(min-width: 1920px) {
		padding: ${({paddingTop}) => paddingTop}px 100px  ${({paddingBottom}) => paddingBottom}px;
	}
`

const SinglePictogramWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 60px;
	
	&:last-of-type {
		margin-right: 0;
	}
	
	@media(min-width: 768px) {
		margin-right: 50px;
		width: calc(50% - 50px);
	}
	@media(min-width: 1920px) {
		margin-right: 50px;
		width: calc(25% - 50px);
	}
`

const PictogramWrapper = styled.div`
	position: relative;
	display: flex;
    flex-direction: column;
    justify-content: center;

    @media(min-width: 768px) {
    	padding: 0;
    	flex-direction: row;
    	justify-content: flex-start;
    }
    @media(min-width: 1280px) {
    	flex-direction: row;
    	justify-content: flex-start;
    	width: auto;
    }
`;

const Pictogram = styled.img`
	width: 100px;
	
 	@media(min-width: 768px) {
		width: 100px;
		left: 0;
		top: 0;
	}
	@media(min-width: 1280px) {
		height: auto;
		width: 180px;
	}
`;

const Description = styled.div`
	font-weight: ${({theme}) => theme.fontWeight.medium};
	color: ${({theme}) => theme.colors.pmmWhite};
	font-size: ${({theme}) => theme.fontSize.f12};
	line-height: ${({theme}) => theme.lineHeight.line1};
	text-align: center;
	margin-top: 60px;
	position: relative;
	
	&:before {
		content: '';
		position: absolute;
		top: -40px;
		left: 50%;
		transform: translateX(-50%);
		width: 22px;
		height: 2px;
		background-color: ${({theme}) => theme.colors.pmmOrange};
	}
	@media(min-width: 768px ) {
		font-size: 16px;
		line-height: 30px;
	}
	@media(min-width: 1920px) {
		font-size: ${({theme}) => theme.fontSize.f6};
		line-height: ${({theme}) => theme.lineHeight.line6};
	}
`

class PictogramDescriptionCentered extends Component {

	render() {
		const {data} = this.props

		const pictogramsData = (data && data.modulPiktogramOpisWysrodkowane) || [];

		const pictogramsArray = [];
		let paddingTopType = ''
		let paddingBottomType = ''
		pictogramsData.forEach(item => {
			if (item.__typename === 'Craft_modulPiktogramOpisWysrodkowane_margines_BlockType') {
				paddingTopType = (item.marginesGorny && item.marginesGorny[0] && item.marginesGorny[0].title) || ""
				paddingBottomType = (item.marginesDolny && item.marginesDolny[0] && item.marginesDolny[0].title) || ""
			} else if (item.__typename === 'Craft_modulPiktogramOpisWysrodkowane_piktogram_BlockType') {
				pictogramsArray.push({
					"description": item.opis || "",
					"pictogram": (item.piktogram && item.piktogram[0] && item.piktogram[0].url) || "",
				})
			}
		})

		return (
			<PictogramsWrapper paddingTop={getPaddingTop(paddingTopType)} paddingBottom={getPaddingBottom(paddingBottomType)}>
				{pictogramsArray.map((item, key) =>
					<SinglePictogramWrapper key={key}>
						<PictogramWrapper>
							<Pictogram src={item.pictogram}/>
						</PictogramWrapper>
						<Description className="paragraph text-in-border" dangerouslySetInnerHTML={getHtmlFromRedactorField(item.description)}/>
					</SinglePictogramWrapper>
				)}

			</PictogramsWrapper>
		);
	}
}

export default PictogramDescriptionCentered;
