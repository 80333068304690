const setFontColor = (background, theme) => {
	if (background === 'background-orange') {
		return theme.colors.pmmWhite;
	}
	if (background === 'background-dark-violet') {
		return theme.colors.pmmWhite;
	}
	if (background === 'background-violet') {
		return theme.colors.pmmWhite;
	}
	if (background === 'background-dark-blue') {
		return theme.colors.pmmOrange;
	}
	if (background === 'background-white') {
		return theme.colors.pmmDarkViolet;
	}
	if (background === 'background-grey') {
		return theme.colors.pmmDarkViolet;
	}
	if (background === 'background-transparent') {
		return theme.colors.pmmDarkestBlue;
	}
	return theme.colors.pmmWhite;
};

export default setFontColor