import React from "react"
import styled from "styled-components"
import ChartWithInfoWrapper, {ChartInfo, InfoNumber, InfoText, InfoWrapper} from "../../common/Charts";

const ChartContent = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	height: 140px;
	padding-right: 24%;
	
	@media(min-width: 768px){
		padding-right: 0;
		margin-top: 60px;
	}
	
	@media(min-width: 1920px){
		height: 200px;
		margin-top: 0;
	}
	@media(min-width: 2560px) {
		height: 350px;
	}
`

const ChartImg = styled.img`
	width: auto;
	height: 100%;
`

class ImgChart extends React.Component {

	render() {
		const {data} = this.props;
		const chartInfo = data?.obrazek?.[0] || ""
		const chartImg = chartInfo?.obrazek?.[0]?.url || ""
		const number = chartInfo?.wartosc || ""
		const caption = chartInfo?.podpis || ""
		const description = chartInfo?.opis || ""


		return (
			<ChartWithInfoWrapper>
				<ChartContent>
					<ChartImg src={chartImg}/>
				</ChartContent>
				<InfoWrapper>
					<InfoNumber>{number}</InfoNumber>
					<InfoText>{caption}</InfoText>
					<ChartInfo>{description}</ChartInfo>
				</InfoWrapper>
			</ChartWithInfoWrapper>
		)
	}
}

export default ImgChart
