import React from "react"
import styled from "styled-components"
import Button from "../common/ButtonsSet";
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";
import SingleCard from "./SingleCard";

const ModuleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.colors.pmmWhite};
  width: 100%;
  padding-top: ${({paddingTop}) => paddingTop}px;
  padding-bottom: ${({paddingBottom}) => paddingBottom}px;
  padding-left: 12px;
  padding-right: 12px;
  @media(min-width: 768px){
	padding-right: 35px;
	padding-left: 35px;
  }
  @media(min-width: 1280px){
    padding-right: 50px;
    padding-left: 50px;
  }
  @media(min-width: 1920px){
	padding-right: 100px;
	padding-left: 100px;
  }
`;

const ContainerSliderVisible = styled.div`
  display: block;
`;

const StyledWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
`;

const ButtonWrapper = styled.div`
	min-height: 70px;
	margin-top: 30px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	&.hidden {
		display: none;
	}
	@media(min-width: 1280px) {
		margin-top: 70px;
	}
	@media(min-width: 1920px) {
		margin-top: 100px;
	}
`;

class NewsCardsOnSubpage extends React.Component {
	state = {
		pageNumber: 1,
		cardsOnOneLoad: 6,
		isOldBrowserEdge: false,
	};

	componentDidMount() {
		if (typeof window !== "undefined") {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({isOldBrowserEdge: isOldBrowser});
		}
	}

	showMoreNews = () => {
		this.setState({pageNumber: this.state.pageNumber + 1});

	};

	render() {
		const newsCardsOnSubpage = this.props.data;
		const { activeSite, aktualnosciWszystkie } = this.props;
		const isOldBrowserEdge = this.state.isOldBrowserEdge;
		const newsCardsData = (newsCardsOnSubpage.modulWizytowkiNaPodstronieAktualnosci) || [];
		const newsCardsArray = [];
		let paddingTop = '';
		let paddingBottom = '';
		let textBtn = '';
		let typeBtn = '';
		// let toBtn = '';
		// let hrefBtn = '';
		let addCardsAutomaticaly;

		newsCardsData.forEach(item => {
			if (item.__typename === "Craft_modulWizytowkiNaPodstronieAktualnosci_wizytowka_BlockType") {
				const newsSingleCardData = item.wybierzAktualnosc;
				addCardsAutomaticaly = (item.dodawajWizytowkiAutomatycznie) || '';
				if (addCardsAutomaticaly) {
					aktualnosciWszystkie.forEach(card => {
						newsCardsArray.push({
							"imageUrl": (card.aktualnoscWizytowka && card.aktualnoscWizytowka[0] && card.aktualnoscWizytowka[0].zdjecie &&
								card.aktualnoscWizytowka[0].zdjecie[0] && card.aktualnoscWizytowka[0].zdjecie[0].url) || "",
							"title": (card.title) || "",
							"dateCreate": card.postDate || "",
							"slug": card.slug || "",
						})
					})
				} else {
					newsSingleCardData.forEach(card => {
						newsCardsArray.push({
							"imageUrl": (card.aktualnoscWizytowka && card.aktualnoscWizytowka[0] && card.aktualnoscWizytowka[0].zdjecie &&
								card.aktualnoscWizytowka[0].zdjecie[0] && card.aktualnoscWizytowka[0].zdjecie[0].url) || "",
							"title": (card.aktualnoscWizytowka && card.aktualnoscWizytowka[0] && card.aktualnoscWizytowka[0].tytul) || "",
							"dateCreate": card.postDate || "",
							"slug": card.slug || "",
						})
					})
				}
			} else if(item.__typename === "Craft_modulWizytowkiNaPodstronieAktualnosci_margines_BlockType") {
					paddingTop = (item.marginesGorny && item.marginesGorny[0] && item.marginesGorny[0].title) || ""
					paddingBottom = (item.marginesDolny && item.marginesDolny[0] && item.marginesDolny[0].title) || ""
			}
			else if(item.__typename === "Craft_modulWizytowkiNaPodstronieAktualnosci_przyciskPodWizytowkami_BlockType") {
					textBtn = item.przyciskNapis || ""
					typeBtn = (item.przyciskRodzaj && item.przyciskRodzaj[0] && item.przyciskRodzaj[0].title) || ""
					//TODO: not used toBtn and hrefBtn
					// toBtn = (item.przyciskWpis && item.przyciskWpis[0] && item.przyciskWpis[0].slug) || ""
					// hrefBtn = item.przyciskLink || ""
			}

		});

		const finalArrayOfCards = addCardsAutomaticaly ? newsCardsArray : newsCardsArray.reverse();
		let limit = this.state.cardsOnOneLoad * this.state.pageNumber;
		let numberOfNews = finalArrayOfCards.length;
		let buttonVisibilityOnStart = (numberOfNews <= limit );
		const cardsToShow = finalArrayOfCards.slice(0,limit);
		const IsButton = (limit < numberOfNews);
		return (
			<>
				<ModuleWrapper paddingTop={getPaddingTop(paddingTop)} paddingBottom={getPaddingBottom(paddingBottom)}>
					<ContainerSliderVisible>
						<StyledWrapper>
								{cardsToShow.map((cards, key) =>
									<SingleCard key={key} index={ key } data={ cards } activeSite={activeSite}/>
								)}
						</StyledWrapper>
					</ContainerSliderVisible>
					{IsButton &&
					<ButtonWrapper className={buttonVisibilityOnStart ? 'hidden' : ''}>
						<Button isOldBrowserEdge={isOldBrowserEdge} btnType={typeBtn} onClick={this.showMoreNews} textBtn={textBtn}/>
					</ButtonWrapper> }
				</ModuleWrapper>
			</>
		)
	}
}

export default NewsCardsOnSubpage
