import React, {Component} from 'react'
import styled from "styled-components"
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	background-color: ${({theme}) => theme.colors.pmmWhite};
	padding: ${({paddingTop}) => paddingTop}px 30px ${({paddingBottom}) => paddingBottom}px;
	@media(min-width: 1280px) {
		padding-right: 50px;
		padding-left: 50px;
	}
	@media(min-width: 1920px) {
		padding-right: 100px;
		padding-left: 100px;
	}
`;

const PictogramWrapper = styled.img`
	width: 120px;
	height: 120px;
	margin: 0 auto;
	@media(min-width: 1280px) {
		width: 180px;
		height: 180px;
	}
`;

const OpinionWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	padding: 20px 10% 30px;
	@media(min-width: 1280px) {
		padding: 20px 15% 60px;
	}
`;

const Opinion = styled.h4`
	position: relative;
	text-align: center;
	font-style: italic;
	font-weight: ${({theme}) => theme.fontWeight.light};
	width: 100%;
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
`;

const LinesWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	
`;
const SingleLineWrapper = styled.div`
	height: 25px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
`

const SingleLine = styled.div`
	width: 40px;
	height: 4px;
	margin: 0 8px;
	cursor: pointer;
	background-color: ${({theme}) => theme.colors.pmmDarkestBlue};
	@media(min-width: 1280px) {
		width: 50px;
		height: 4px;
		margin: 0 10px;
	}
	&.active {
		background-color: ${({theme}) => theme.colors.pmmOrange}
	}
`

class Opinions extends Component {
	state = {
		isOldBrowserEdge: false,
		currentOpinionIndex: 0,
	};

	componentDidMount() {
		if (typeof window !== "undefined") {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({isOldBrowserEdge: isOldBrowser});
		}
	}

	setActiveOpinion(key) {
		this.setState({currentOpinionIndex: key})
	}

	render() {
		const opinionsModule = this.props.data;
		const { currentOpinionIndex } = this.state;
		const opinionsModuleData = (opinionsModule.modulOpinie) || [];
		const OpinionsDataArray = [];
		let pictogram;
		let paddingTop = '';
		let paddingBottom = '';

		opinionsModuleData.forEach(item => {
			if(item.__typename === "Craft_modulOpinie_marginesy_BlockType") {
				paddingTop = (item.marginesGorny && item.marginesGorny[0] && item.marginesGorny[0].title) || ''
				paddingBottom = (item.marginesDolny && item.marginesDolny[0] && item.marginesDolny[0].title) || ''
			} else if (item.__typename === "Craft_modulOpinie_piktogram_BlockType") {
				pictogram = (item.piktogram && item.piktogram[0] && item.piktogram[0].url ) || ''
			} else if (item.__typename === "Craft_modulOpinie_opinia_BlockType")  {
				OpinionsDataArray.push({
					"opinion": item.trescOpinii || '',
				})
			}
		});

		return (
			<>
				<ModuleWrapper paddingTop={getPaddingTop(paddingTop)}
				               paddingBottom={getPaddingBottom(paddingBottom)}>
					<PictogramWrapper src={pictogram} />
					{OpinionsDataArray && <OpinionWrapper>
						<Opinion>{OpinionsDataArray[currentOpinionIndex].opinion}</Opinion>
					</OpinionWrapper> }
					{OpinionsDataArray && <LinesWrapper>
						{OpinionsDataArray.map((item,key) =>
							<SingleLineWrapper key={key} onClick={this.setActiveOpinion.bind(this, key)}>
								<SingleLine className={currentOpinionIndex === key ? 'active' : ''}/>
							</SingleLineWrapper>
						)}
					</LinesWrapper> }
				</ModuleWrapper>
			</>
		)
	}
}

export default Opinions;
