import React, {Component} from 'react';
import styled from "styled-components";
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";

const DescriptionStyled = styled.div`
	color: ${({theme}) => theme.colors.pmmDarkBlue};
	padding: 0 10px;
	
	@media(min-width: 768px) {
		padding: 0 100px;
	}
	@media(min-width: 1280px) {
		padding: 0 350px;
	}
	@media(min-width: 1366px) {
		padding: 0 370px;
	}
	@media(min-width: 1920px) {
		padding: 0 530px;
	}
`
class ContentDescription extends Component {
	render() {
		const { data } = this.props

		return (
			<DescriptionStyled className="paragraph text-in-border" dangerouslySetInnerHTML={getHtmlFromRedactorField(data.opis)}/>
		);
	}
}

ContentDescription.propTypes = {};

export default ContentDescription;
