import React, {Component} from "react";
import styled from "styled-components";
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";
import {Link} from "gatsby";
import getSlug from "../../utils/getSlug";
import {isMobile} from 'react-device-detect';

const TilesWrapper = styled.div`
  	background-color: ${({ theme }) => theme.colors.pmmWhite};
  	padding: ${({paddingTop}) => paddingTop}px 10px ${({paddingBottom}) => paddingBottom}px;
  
	@media(min-width: 768px) {
		padding: ${({paddingTop}) => paddingTop}px 30px ${({paddingBottom}) => paddingBottom}px;
	}
	@media(min-width: 1280px) {
		padding: ${({paddingTop}) => paddingTop}px 50px ${({paddingBottom}) => paddingBottom}px;
	}
	@media(min-width: 1920px) {
		padding: ${({paddingTop}) => paddingTop}px 100px ${({paddingBottom}) => paddingBottom}px;
	}
	
	.grid {
		margin: 0 auto;
		max-width: 1680px;
	}
	
	/* clear fix */
	.grid:after {
		content: '';
		display: block;
		clear: both;
	}
	
	.grid-item {
		float: left;
		padding: 25px 10px;
	  	width: 100%;

		@media(min-width: 375px) {
		width: 50%;
		}

		@media(min-width: 768px) {
			padding: 25px 20px;
		}

		@media(min-width: 1360px) {
			width: 420px;
			height: 480px;
			padding: 50px 20px;
		}
	  
		&.animation {
			&:hover {
				.tile {
					transform: scale(1.05);
				}
			}
		}
		.tile {
			transition: 0.3s ease-in;
			width: 100%;
			height: 0;
			padding-top: 100%;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			&.beige {
				background-color: ${({ theme }) => theme.colors.pmmBeige};
			}
			&.brown {
				background-color: ${({ theme }) => theme.colors.pmmBrown};
			}
			&.orange {
				background-color: ${({ theme }) => theme.colors.pmmOrange};
			}
			&.light-orange {
				background-color: ${({ theme }) => theme.colors.pmmLightOrange};
			}
			&.dark-violet {
				background-color: ${({ theme }) => theme.colors.pmmDarkViolet};
			}
		}
		.tile-description {
			position: absolute;
			bottom: -20px;
			left: 0;
			padding: 15px 20px;
			font-size: 14px;
			line-height: 18px;
			font-weight: ${({ theme }) => theme.fontWeight.bold};
			color: ${({ theme }) => theme.colors.pmmDarkBlue};
			max-width: 100%;
			color: ${({ theme }) => theme.colors.pmmWhite};
			background-color: ${({ theme }) => theme.colors.pmmDarkestBlue};
			
			@media(min-width: 768px) {
				font-size: 18px;
				line-height: 28px;
				max-width: 85%;
			}
			@media(min-width: 1360px) {
				font-size: 24px;
				line-height: 34px;
			}
		}		  
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 40%;
			height: 40%;
			max-width: 150px;
			max-height: 150px;
		}
	}
	
	.grid-item-large {
		.tile {
			background-color: ${({ theme }) => theme.colors.pmmOrange};
		}
		
		@media(min-width: 1360px) {
			width: 840px;
			height: 960px;
			
			.tile {
				height: 100%;
			img {
				max-width: 280px;
				max-height: 280px;
			}
		}
	}
}
`

const StyledPhoto = styled.div`
	background-image: url( ${({ bgImg }) => bgImg});
	position: absolute;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
`

class TilesWithPictogramsGrid extends Component {

	componentDidMount () {
		if (typeof window !== `undefined`) {
			const Isotope = require("isotope-layout/js/isotope");
			const grid = document.querySelector('.grid');
			new Isotope(grid, {
				itemSelector: '.grid-item',
			});
		}
	}

	render() {
		const {data, activeSite} = this.props
		const moduleData = (data && data.modulKafelkiPiktogramOpisLink) || []
		let paddingTop = '';
		let paddingBottom = '';
		const tilesArray = []

		const getBgColor = (bgColor) => {
			switch (bgColor) {
				case "bezowy":
					return "beige";
				case "brazowy":
					return "brown";
				case "czerwony":
					return "orange";
				case "brzoskwiniowy":
					return "light-orange";
				case "granatowy":
					return "dark-violet";
				default:
					return "#DECDC5";
			}
		};

		moduleData.forEach(item => {
			if (item.__typename === "Craft_modulKafelkiPiktogramOpisLink_marginesy_BlockType") {
				paddingTop = (item.marginesGorny && item.marginesGorny[0] && item.marginesGorny[0].title) || ''
				paddingBottom = (item.marginesDolny && item.marginesDolny[0] && item.marginesDolny[0].title) || ''
			} else {
				tilesArray.push({
					"pictogram": (item.piktogram && item.piktogram[0] && item.piktogram[0].url) || "",
					"photo": (item.zdjecie && item.zdjecie[0] && item.zdjecie[0].url) || "",
					"bgColor": item.kolorTla ? getBgColor(item.kolorTla) : "",
					"isLargeTile": item.duzyKafelek || false,
					"description": item.opis || "",
					"to": (item.wybierzPodstrone && item.wybierzPodstrone[0] && item.wybierzPodstrone[0].slug) || ""
				})
			}
		});
		
		return (
			<TilesWrapper paddingTop={getPaddingTop(paddingTop)} paddingBottom={getPaddingBottom(paddingBottom)}>
				<div className="grid">
					{tilesArray.map((item, key) =>
						<Link key={key} className={`grid-item ${key !== 0 && item.isLargeTile ? 'grid-item-large' : ''} ${!isMobile && 'animation'}`} to={`/${getSlug(item.to, activeSite)}`}>
							<div className={`tile ${item.bgColor}`}>
								{item.pictogram && <img
									src={item.pictogram}/>}
								{item.photo && <StyledPhoto
									bgImg={item.photo}/>}
								<div className="tile-description">
									{item.description}
								</div>
							</div>
						</Link>
					)}
				</div>
			</TilesWrapper>
		)
	}
}

export default TilesWithPictogramsGrid;
