import React, {Component} from 'react';
import styled from "styled-components";
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";
import setFontColor from "../../utils/setFontColor";
import setBackgroundColor from "../../utils/setBackgroundColor";

const DescriptionStyled = styled.div`
	color: ${({background, theme}) => setFontColor(background, theme)};
	background-color: ${({background, theme}) => setBackgroundColor(background, theme)};
	padding: 20px 10px;
	
	@media(min-width: 768px) {
		padding: 20px 100px;
	}
	@media(min-width: 1280px) {
		padding: 20px 350px;
	}
	@media(min-width: 1366px) {
		padding: 20px 370px;
	}
	@media(min-width: 1920px) {
		padding: 20px 530px;
	}
`
class DescriptionWithBackground extends Component {
	render() {
		const { data } = this.props
		const descriptionData = (data && data.modulOpisBackground && data.modulOpisBackground[0]) || '';
		const Description = (descriptionData && descriptionData.opis) || '';
		const background = (descriptionData && descriptionData.tlo && descriptionData.tlo[0] && descriptionData.tlo[0].title) || '';
		return (
			<DescriptionStyled background={background} className="paragraph text-in-border" dangerouslySetInnerHTML={getHtmlFromRedactorField(Description)}/>
		);
	}
}

export default DescriptionWithBackground;
