import React from "react"
import styled from "styled-components"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";

const ContentWrapper = styled.div`
  display: block;
  width: 100%;
  color: ${ ({background, theme})  => background === 'bialy' ? theme.colors.pmmDarkViolet : theme.colors.pmmWhite };
  background-color: ${ props  => props.background === 'bialy' ? props.theme.colors.pmmWhite : props.theme.colors.pmmDarkViolet };
  padding-top: ${({paddingTop}) => paddingTop}px;
  padding-bottom: ${({paddingBottom}) => paddingBottom}px;
`;

const ModuleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 25px 0 10px;
  width: 100%;
  @media(min-width: 768px){
  	padding: 0 30% 0 35px;
  }
  @media(min-width: 1280px){
  	padding: 0 0 0 50px;
  	width: 50%;
  }
  @media(min-width: 1920px){
  	padding-left: 100px;
  }
`;

const Header = styled.h3`
  position: relative;
  padding-bottom: 20px;
  //color: white;
  @media(min-width: 1920px){
  	padding-bottom: 40px;
  }
  span {  
      &.black {
          font-weight: ${({ theme }) => theme.fontWeight.black};
      }
  }
`;

const Description = styled.div`
  position: relative;
  span {  
	  &.black {
	      font-weight: ${({theme}) => theme.fontWeight.black};
	  }
	}
  a {
		color: ${({theme}) => theme.colors.pmmOrange};
		text-decoration: underline;
		cursor: pointer;	
	}
`


class HeaderDescriptionBackground extends React.Component {

    render() {

		// const {data, isNew} = this.props;
		const {data} = this.props;
		// const headerDescriptionbackgroundData = isNew ? data?.modulNaglowekOpisBackgroundNowy?.[0] || "" : data?.modulNaglowekOpisBackground?.[0] || ""
		const headerDescriptionbackgroundData = data?.modulNaglowekOpisBackground?.[0] || ""
		const header =  headerDescriptionbackgroundData?.naglowek || ""
		const description = headerDescriptionbackgroundData?.opis || "";
		const paddingTopType = headerDescriptionbackgroundData?.marginesGorny?.[0]?.title || '';
		const paddingBottomType = headerDescriptionbackgroundData?.marginesDolny?.[0]?.title || '';
		const background = headerDescriptionbackgroundData?.background || '';

        return (
            <>
                <ContentWrapper background={background} paddingTop={getPaddingTop(paddingTopType)} paddingBottom={getPaddingBottom(paddingBottomType)}>
                    <ModuleWrapper>
                        <Header dangerouslySetInnerHTML={getHtmlFromRedactorField(header)}/>
                        <Description className="paragraph" dangerouslySetInnerHTML={getHtmlFromRedactorField(description)}/>
                    </ModuleWrapper>
                </ContentWrapper>
            </>
        )
    }
}

export default HeaderDescriptionBackground
