import React, {Component} from 'react';
import styled from "styled-components";
import setFontColor from "../../utils/setFontColor";
import setBackgroundColor from "../../utils/setBackgroundColor";

const HeaderStyled = styled.h3`
	padding: 20px 10px;
	color: ${({background, theme}) => setFontColor(background, theme)};
	background-color: ${({background, theme}) => setBackgroundColor(background, theme)};
		
	@media(min-width: 768px) {
		padding: 20px 100px;
	}
	@media(min-width: 1280px) {
		padding: 20px 350px;
	}
	@media(min-width: 1366px) {
		padding: 20px 370px;
	}
	@media(min-width: 1920px) {
		padding: 20px 530px;
	}
`
class HeaderWithBackground extends Component {
	render() {
		const { data } = this.props
		const headerData = (data && data.naglowekBackground && data.naglowekBackground[0]) || '';
		const Header = (headerData && headerData.naglowek) || '';
		const background = (headerData && headerData.tlo && headerData.tlo[0] && headerData.tlo[0].title) || '';
		return (
				<HeaderStyled background={background}>{Header}</HeaderStyled>
		);
	}
}

export default HeaderWithBackground;
