import styled from "styled-components";

export const ChartInfoWrapper = styled.div`

`

export const ChartWithInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 80px;
	
	&.project {
		margin-top: 50px;
	}

	@media(min-width: 768px){
		width: 43%;
		margin-right: 14%;
		margin-bottom: 30px;
		//margin-bottom: 50px;
		
		&:nth-of-type(2n) {
			margin-right: 0;
		}
	}
	
	@media(min-width: 1280px){
		//width: 30%;
		//margin-right: 20%;
		//margin-bottom: 150px;
		width: 20%;
		margin-right: 15%;
		&:nth-of-type(2n) {
			margin-right: 15%;
		}
		&:nth-of-type(3n) {
			margin-right: 10%;
	}
	}
	
	@media(min-width: 1366px){
	}
	
	@media(min-width: 1920px){
		width: 20%;
		margin-right: 15%;
		margin-bottom: 50px;
		
		&:nth-of-type(3n) {
			margin-right: 10%;
	}
}
`

export const ChartInfo = styled.div`
	color: ${({theme}) => theme.colors.pmmWhite};
	font-weight: ${({theme}) => theme.fontWeight.medium};
	font-size: ${({theme}) => theme.fontSize.f6};
	line-height: ${({theme}) => theme.lineHeight.line6};
	@media(min-width: 768px){
		width: 295px;
	}
	@media(min-width: 1920px){
		width: 100%;
	}

	&.project {
		@media(min-width: 1920px){
			width: 345px;
		}
	}
`

export const InfoWrapper = styled.div`
	position: relative;
	margin-top: 60px;
`

export const InfoNumber = styled.div`
	position: absolute;
	color: ${({theme}) => theme.colors.pmmOrange};
	font-weight: ${({theme}) => theme.fontWeight.bold};
	font-size: 40px;
	line-height: 45px;
	text-align: center;
	min-width: 60px;

	&:after {
		content: '';
		position: absolute;
		background-color: ${({theme}) => theme.colors.pmmWhite};
		top: -12px;
		left: 50%;
		transform: translateX(-50%);
		z-index: -1;
		width: 70px;
		height: 70px;
		border-radius: 100%;
	}
	
	@media(min-width: 1920px){
		font-size: 50px;
		line-height: 50px;
		min-width: 75px;
		&:before {
			font-size: 45px;
			right: -60px;
		}
		&:after {
			top: -15px;
			width: 80px;
			height: 80px;
		}
	}
`


export const InfoText = styled.div`
	color: ${({theme}) => theme.colors.pmmWhite};
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
	font-size: ${({theme}) => theme.fontSize.f5};
	padding-left: 75px;
	min-height: 70px; 
	white-space: nowrap;
	line-height: 45px;
	
	&.pictogram {
		color: ${({theme}) => theme.colors.pmmOrange};
		padding-left: 95px;
		line-height: 45px;
	}
	@media(min-width: 768px){
		padding-right: 0;
		min-height: 80px;
	}
	@media(min-width: 1920px){
		font-size: ${({theme}) => theme.fontSize.f2};
		padding-left: 90px;

		&.pictogram {
			padding-left: 160px;
			line-height: unset;
		}
	}
`

export default ChartWithInfoWrapper
