import React, { Component } from 'react';
import SingleNewsBanner from './SingleNewsBanner';
import getDateInProperFormat from '../../utils/getDateInProperFormat';
import SingleNewsExtendedContent from '../singleNewsExtendedContent';

class SingleNews extends Component {
	render() {
		const { activeSite, data, pageContext, newsBtns, isPrivacyPolicy, isActions, actWithUsBackButton } = this.props;
		const postDate = (data && data.postDate) || '';
		const postDateCreated = getDateInProperFormat(pageContext, postDate);
		const bannerData = (data && data.modulBanerNaAktualnosci && data.modulBanerNaAktualnosci[0]) || [];
		const bannerOnActionsData = (data && data.modulBanerNaDzialaniach && data.modulBanerNaDzialaniach[0]) || [];
		const content = (data && data.trescPodstrony) || [];
		const finalBannerData = isActions ? bannerOnActionsData : bannerData;
		// console.log(postDate);
		return (
			<div>
				<SingleNewsBanner
					actWithUsBackButton={actWithUsBackButton}
					isGreyBackground={isActions}
					isPrivacyPolicy={isPrivacyPolicy}
					postDate={postDateCreated}
					bannerData={finalBannerData}
					pageContext={pageContext}
					newsBtns={newsBtns}
					activeSite={activeSite}
				/>
				<SingleNewsExtendedContent data={content} activeSite={activeSite} />
			</div>
		);
	}
}

export default SingleNews;
