import React, {Component} from 'react'
import styled from "styled-components"
import {getPaddingBottom, getPaddingTop} from "../../utils/setModulePadding";
import ArrowRightHovered from "../../icons/arrows/arrow-right-orange-bold.svg"
import ArrowLeftHovered from "../../icons/arrows/arrow-left-orange-bold.svg"
import {Link} from "gatsby";
import getSlug from "../../utils/getSlug";

const ModuleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	background-color: ${({theme, isGreyBackground}) => isGreyBackground ? theme.colors.pmmGreyBackground : theme.colors.pmmWhite};
	padding: ${({paddingTop}) => paddingTop}px 15px ${({paddingBottom}) => paddingBottom}px;
	@media(min-width: 768px) {
		flex-direction: row;
		justify-content: space-between;
	}
	@media(min-width: 1280px) {
		padding-right: 50px;
		padding-left: 50px;
	}
	@media(min-width: 1920px) {
		padding-right: 100px;
		padding-left: 100px;
	}
`;

const SingleEntryWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 50%;
	height: 100%;
	padding: 0 10px;
	position: relative;
	margin: 0 auto;
	&:last-child {
		margin-top: 80px;
		&:before{
			content: '';
			position: absolute;
			left: calc(50% - 20px);
			top: -40px;
			height: 2px;
			width: 40px;
			background-color: ${({theme}) => theme.colors.pmmDarkestBlue};
			@media(min-width: 768px) {
				left: 0;
				height: 60px;
				width: 2px;
				top: calc(50% - 30px);
			}
		}
		@media(min-width: 768px) {
			margin-top: 0;
		}
	}
`;

const NavigationArrow = styled.div`
	width: 33px;
	height: 33px;
	margin: 0 auto;
	transition: .3s transform ease-in-out;
	background-size: cover;
	background-position: center;
	background-image: url( ${({bgImg}) => bgImg} );
	@media(min-width: 1280px) {
		width: 53px;
		height: 53px;
	}
	&:hover {
		transform: ${({isEdge}) => isEdge ? "scale(1)" : "scale(1.113)"};
	}
	&.is-hovered {
		transform: ${({isEdge}) => isEdge ? "scale(1)" : "scale(1.113)"};
	}
`;

const ArrowDescriptionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	cursor: pointer;
	text-decoration: none;
	text-align: center;
`;


const Header = styled.h2`
	letter-spacing: -0.84px;
	line-height: 42px;
	position: relative;
	text-align: center;
	padding: 20px 5% 0px;
	width: 100%;
	color: ${({theme}) => theme.colors.pmmDarkestBlue};
	font-family: ${({theme}) => theme.fontFamily.mainFont};
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
	@media(min-width: 768px) {
		letter-spacing: -0.9px;
	}
	@media(min-width: 1280px) {
		padding: 35px 10% 0px;
		letter-spacing: -1.12px;
	}
	@media(min-width: 1366px) {
		letter-spacing: -1.2px;
	}
	@media(min-width: 1920px) {
		line-height: 52px;
		letter-spacing: -1.5px;
	}
`;

const Label = styled.p`
	color: ${({theme}) => theme.colors.pmmOrange};
	text-transform: uppercase;
	margin: 15px auto 0;
	letter-spacing: 1.2px;
`;


class SeeAlso extends Component {
	state = {
		isOldBrowserEdge: false,
		currentImageIndex: 0,
		isDivHovered: false,
	};

	animateArrow () {
		this.setState({isDivHovered: !this.state.isDivHovered})
	}
	componentDidMount() {
		if (typeof window !== "undefined") {
			const isIE = false || !!document.documentMode;
			const isEdge = !isIE && !!window.StyleMedia;
			const isOldBrowser = isIE || isEdge;
			this.setState({isOldBrowserEdge: isOldBrowser});
		}
	}

	render() {
		const seeAlso = this.props.data;
		const {activeSite} = this.props;
		const {isDivHovered} = this.state;
		const seeAlsoData = (seeAlso && seeAlso.modulZobaczRowniez) || [];
		let entryArrowRightTitle = '';
		let entryArrowRightSlug = '';
		let entryArrowLeftTitle = '';
		let entryArrowLeftSlug = '';
		let arrowRightLabel = '';
		let arrowLeftLabel = '';
		let paddingTop = '';
		let paddingBottom = '';
		let isGreyBackground = '';

		seeAlsoData.forEach(item => {
			if(item.__typename === "Craft_modulZobaczRowniez_marginesy_BlockType") {
				paddingTop = (item.marginesGorny && item.marginesGorny[0] && item.marginesGorny[0].title) || ''
				paddingBottom = (item.marginesDolny && item.marginesDolny[0] && item.marginesDolny[0].title) || ''
			} else if (item.__typename === "Craft_modulZobaczRowniez_modulZobaczRowniez_BlockType") {
				arrowLeftLabel = (item.podpisStrzalkaWLewo )|| '';
				arrowRightLabel = (item.podpisStrzalkaWPrawo) || '';
				entryArrowRightTitle = (item.wpisStrzalkaWPrawo && item.wpisStrzalkaWPrawo[0] && item.wpisStrzalkaWPrawo[0].title) || '';
				entryArrowLeftTitle = (item.wpisStrzalkaWLewo && item.wpisStrzalkaWLewo[0] && item.wpisStrzalkaWLewo[0].title) || '';
				entryArrowRightSlug = (item.wpisStrzalkaWPrawo && item.wpisStrzalkaWPrawo[0] && item.wpisStrzalkaWPrawo[0].slug) || '';
				entryArrowLeftSlug = (item.wpisStrzalkaWLewo && item.wpisStrzalkaWLewo[0] && item.wpisStrzalkaWLewo[0].slug) || '';
				isGreyBackground = (item && item.szareTlo ) || '';
			}
		});
		const classNameTriggerAnimation = (isDivHovered) ? "is-hovered" : "";
		return (
			<>
			<ModuleWrapper isGreyBackground={isGreyBackground} paddingTop={getPaddingTop(paddingTop)}
			               paddingBottom={getPaddingBottom(paddingBottom)}>
				<SingleEntryWrapper>
					<ArrowDescriptionWrapper as={Link} to={`/${getSlug(entryArrowLeftSlug, activeSite)}`}
					                         onMouseOver={this.animateArrow.bind(this)}
					                         onMouseOut={this.animateArrow.bind(this)}>
						<NavigationArrow className={classNameTriggerAnimation} bgImg={ArrowLeftHovered} isEdge={this.state.isOldBrowserEdge}/>
						{arrowLeftLabel && <Label className="h8 black">{arrowLeftLabel}</Label> }
						<Header>{entryArrowLeftTitle}</Header>
					</ArrowDescriptionWrapper>
				</SingleEntryWrapper>
				<SingleEntryWrapper>
					<ArrowDescriptionWrapper as={Link} to={`/${getSlug(entryArrowRightSlug, activeSite)}`}
					                         onMouseOver={this.animateArrow.bind(this)}
					                         onMouseOut={this.animateArrow.bind(this)}>
						<NavigationArrow className={classNameTriggerAnimation} bgImg={ArrowRightHovered} isEdge={this.state.isOldBrowserEdge}/>
						{arrowRightLabel && <Label className="h8 black">{arrowRightLabel}</Label> }
						<Header>{entryArrowRightTitle}</Header>
					</ArrowDescriptionWrapper>
				</SingleEntryWrapper>

			</ModuleWrapper>
			{/*	TODO: remove this component*/}
			{/*<Payments/>*/}
			</>
		)
	}
}

export default SeeAlso;
